import React, {AllHTMLAttributes, PropsWithChildren, ReactNode, useContext} from "react";
import {MultiSelectContext, MultiSelectContextType} from "../../MultiSelectContext";
import {cn, resultIf} from "../../../../../../utils";
import './MultiSelectHead.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    placeholder?: string | undefined
}

const MultiSelectHead = <T, >({placeholder, children, ...props}: PropsWithChildren<Props>) => {
  const context: MultiSelectContextType<T> = useContext(MultiSelectContext);
  const renderSelectedOptions = (): ReactNode => {
    if (children !== undefined) {
      return children;
    }
    if (context.selectedOptions.length > 0) {
      return "Выбрано: " + context.selectedOptions.length;
    }
    return placeholder ?? "Выберите элементы из списка";
  };
  return (
    <div
      {...props}
      className={cn(
        "multi-select__head",
        resultIf(context.active, "active"),
        props.className
      )}
      onClick={context.toggle}
    >
      { renderSelectedOptions() }
    </div>
  );
};

export default MultiSelectHead;