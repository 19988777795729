import React from "react";
import {ChildrenProps} from "../../../../../utils";
import {ClassNameDecorator} from "../../../../decorators";
import {ClassInjector} from "../../../../injectors";
import './ColoredText.scoped.scss';

export enum TextColor {
    PRIMARY_GREEN = "text-primary-green",
    PRIMARY_GREEN_LIGHT = "text-primary-green-light",
    GRAY_COLOR_3 = "text-gray-3",
    LIGHTER_GRAY_COLOR_2 = "text-lighter-gray-2",
    BLACK = "text-black"
}

interface Props extends ChildrenProps {
    color: TextColor
}

const ColoredText: ClassNameDecorator<Props> = ({color, children, className}) => {
  return (
    <ClassInjector
      classNames={
        color
      }
      oldClassName={className}
    >
      { children }
    </ClassInjector>
  );
};

export default ColoredText;