import {useState} from "react";
import {UploadedFile} from "../../api-client";

export const usePersonOgrnipData = () => {
  const [ogrnip, setOgrnip] = useState("");
  const [entrepreneurDocument, setEntrepreneurDocument] = useState<Array<UploadedFile>>([]);
  return {
    ogrnip,
    setOgrnip,
    entrepreneurDocument,
    setEntrepreneurDocument
  };
};