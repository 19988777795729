import React, {FormEvent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useErrorCallback, useNavigateByName} from "../../../../../hooks";
import {createInvestmentManager} from "../../../../../di";
import {InvestmentConfirmation as Confirmation} from "../../../../../api-client/models/investment";
import {RouteDictionary} from "../../../../../utils";
import {Button, CloseButton, PrimaryButton} from "../../../../../components";
import IFrame from "../../../../../components/common/utils/IFrame";
import {PrimaryButtonColor} from "../../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import useBodyOverflow from "../../../../../hooks/useBodyOverflow";
import WrapInQuotes from "../../../../../components/common/typography/texts/WrapInQuotes";
import './InvestmentConfirmation.scoped.scss';
import InvestmentConfirmationModal from "../../../../../components/common/modals/InvestmentConfirmationModal";

const InvestmentConfirmation = () => {
  const [investmentConfirmation, setInvestmentConfirmation] = useState<Confirmation | null>(null);
  const handleError = useErrorCallback();
  // const [agreementAccepted, setAgreementAccepted] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const {uuid, confirmationUuid} = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigateByName();

  useBodyOverflow();

  useEffect(() => {
    const requestConfirmation = async () => {
      setLoading(true);
      try {
        const manager = await createInvestmentManager();
        const result = await manager.getConfirmation(confirmationUuid!);
        setInvestmentConfirmation(result);
      } catch (err: any) {
        await handleError(err);
        setInvestmentConfirmation(null);
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await requestConfirmation();
    })();
  }, []);

  const handleSubmit = async (e: FormEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      const manager = await createInvestmentManager();
      await manager.sendCode(confirmationUuid!);
      setCodeSent(true);
    } catch (err: any) {
      await handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const closePage = () => {
    navigate(RouteDictionary.DETAILED_PROJECT, {uuid: uuid});
  };

  return (
    <div className="investment-confirmation">
      <div className="investment-confirmation__close">
        <CloseButton>
          <Button onClick={closePage} />
        </CloseButton>
      </div>
      <div className="investment-confirmation__content">
        <div className="investment-confirmation__block">
          <div className="investment-confirmation__header">
            Агентское поручение на заключение сделки
          </div>
          <div className="investment-confirmation__text">
            { loading && "Загружаем" }
            {investmentConfirmation && <>
              <div className="investment-confirmation__document">
                <IFrame src={investmentConfirmation.documentUrl} />
              </div>
              <div>
                <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
                  <Button onClick={handleSubmit} disabled={loading}>
                    Подтвердить
                  </Button>
                </PrimaryButton>
              </div>
              <div>
              </div>
              <div className="investment-confirmation__info">
                <div>
                  Подписанием настоящего агентского поручения Принципал подтверждает факт своего
                  предварительного ознакомления со всеми локальными актами Площадки, размещенными
                  в информационно-коммуникационной сети <WrapInQuotes>Интернет</WrapInQuotes>
                  на сайте: cabinet.conomica.ru и действующими на момент подписания, и подтверждает свое согласие
                  выполнять требования указанных локальных актов Площадки.
                </div>
                <div>
                  Подписание настоящего агентского поручения осуществляется Принципалом и фиксируется
                  в электронной форме с использованием информационных
                </div>
              </div>
            </> }
          </div>
          <InvestmentConfirmationModal
            active={codeSent}
            onClose={() => setCodeSent(false)}
            investmentConfirmation={investmentConfirmation!}
            oldConfirmationUuid={confirmationUuid!}
          />
        </div>
      </div>
    </div>
  );
};

export default InvestmentConfirmation;