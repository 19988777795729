import React, {FC, PropsWithChildren, useEffect, useState} from "react";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {PaginationContent, PaginationContextType, PaginationHeader, PaginationPaginator} from "../index";
import {useCompare, useEffectOnUpdate} from "../../../hooks";
import {getQueryObject} from "../../../utils";
import buildQuery from "../../../utils/router/buildQuery";
import PaginationFilter from "../PaginationFilter/PaginationFilter";
import {Filter} from "../../pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";
import PaginationContextProvider from "../PaginationContext";

export type PaginationProps = {
    onLoad: (page: number, perPage: number, filter: any) => void;
    count: number;
    totalCount: number;
    config?: Partial<PaginationConfig>,
    filterObject?: any | undefined;
    initialChangeFilter?: (currentFilter: any) => void;
}

export type PaginationConfig = {
    firstPage: number;
    defaultPerPage: number;
    pageParam: string;
    perPageParam: string;
}

type PaginationExtensions = {
    Paginator: typeof PaginationPaginator;
    Filter: typeof PaginationFilter;
    Content: typeof PaginationContent;
    Header: typeof PaginationHeader;
}

const defaultConfig: PaginationConfig = {
  firstPage: 1,
  defaultPerPage: 20,
  pageParam: "page",
  perPageParam: "perPage"
};

const Pagination: FC<PropsWithChildren<PaginationProps>> & PaginationExtensions = (
  {onLoad, 
    count, 
    totalCount, 
    filterObject, 
    initialChangeFilter, 
    config, 
    children}
) => {
  const [searchParams] = useSearchParams({});
  const location = useLocation();
  const navigate = useNavigate();
  const compiledConfig = {
    ...defaultConfig,
    ...config
  };
  const [page, setPage] = useState(compiledConfig.firstPage);
  const [perPage, setPerPage] = useState(0);
  const [filtered, setFiltered] = useState(false);

  const value: PaginationContextType = {
    count: count,
    totalCount: totalCount,
    page: page,
    pageParam: compiledConfig.pageParam,
    perPage: perPage,
    perPageParam: compiledConfig.perPageParam
  };

  const wasPageChanged = useCompare(page);
  const wasPerPageChanged = useCompare(perPage);
  const wasQueryChanged = useCompare(location.search);
  const isFilterEqual = useCompare<Filter>(filterObject);

  useEffectOnUpdate(() => {
    if (!isFilterEqual) {
      handleFilterChange();
    }
  }, [isFilterEqual]);

  const getFilterObject = () => {
    return getQueryObject(decodeURI(location.search)).filter;
  };

  const update = () => {
    const pageParam = searchParams.get(compiledConfig.pageParam);
    setPage(pageParam !== null ? parseInt(pageParam) : compiledConfig.firstPage);
    const perPageParam = searchParams.get(compiledConfig.perPageParam);
    setPerPage(perPageParam !== null ? parseInt(perPageParam) : compiledConfig.defaultPerPage);

    const filter = getFilterObject();
    if (filter) {
      setFiltered(true);
    }
  };

  useEffect(() => {
    const filter = getFilterObject();
    if (filter !== undefined && initialChangeFilter !== undefined) {
      initialChangeFilter(filter);
    }
  }, []);

  useEffectOnUpdate(() => {
    if (filtered) {
      onLoad(page, perPage, filterObject);
      setFiltered(false);
    } else if (wasPageChanged || wasPerPageChanged) {
      onLoad(page, perPage, {});
    }
  }, [page, perPage, filtered]);

  // Runs when filter is reset
  useEffectOnUpdate(() => {
    // This is the most stupid thing I've ever done in this project
    if (page === 1 && !wasPageChanged && wasQueryChanged && isFilterEqual && !filtered && location.search === "") {
      onLoad(page, perPage, {});
    }
  }, [isFilterEqual]);

  // Update current flags and params according to query in url
  useEffect(() => {
    update();
  }, [searchParams]);

  const handleFilterChange = (): void => {
    const filter = {filter: filterObject};
    const query = buildQuery(filter);
    navigate({
      pathname: location.pathname,
      search: query
    });
  };

  return (
    <PaginationContextProvider props={value}>
      { children }
    </PaginationContextProvider>
  );
};

Pagination.Paginator = PaginationPaginator;
Pagination.Filter = PaginationFilter;
Pagination.Content = PaginationContent;
Pagination.Header = PaginationHeader;

export default Pagination;