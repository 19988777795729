import React, {FC} from "react";
import {ChildrenProps, invariant} from "../../../../../utils";

const WrapInQuotes: FC<ChildrenProps> = ({children}) => {
  invariant(
    typeof children === "string",
    "Children given to Quotes component are not type of 'string'"
  );
  return (
    <span>&laquo;{children}&raquo;</span>
  );
};

export default WrapInQuotes;