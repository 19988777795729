import React, {useContext, useMemo, useRef, useState} from "react";
import {useErrorCallback, useNavigateByName,} from "../../../../../../hooks";
import {Money as MoneyModel} from "../../../../../../api-client";
import {cn, resultIf, RouteDictionary} from "../../../../../../utils";
import config from "../../../../../../configs/app";
import {createOfferPurchaseManager} from "../../../../../../di";
import {Button, Money, PrimaryButton} from "../../../../../../components";
import {PrimaryButtonColor} from "../../../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import "./SecondaryProjectInvest.scoped.scss";
import {SecondaryProjectContext} from "../SecondaryProjectContext/SecondaryProjectContext";

const SecondaryProjectInvest = () => {
  const offer = useContext(SecondaryProjectContext).offer;

  const [focused, setFocused] = useState(false);
  const handleError = useErrorCallback();
  const money = offer.price;
  const stickyBlock = useRef(null);

  const investmentFee = useMemo((): MoneyModel => {
    return new MoneyModel(money.amount * config.appFeePercent / 100, "RUB");
  }, [money]);

  const finalAmount = useMemo((): MoneyModel => {
    return new MoneyModel(money.amount + investmentFee.amount, "RUB");
  }, [money]);

  const navigate = useNavigateByName();
  const handleSubmit = async (): Promise<void> => {
    try {
      const manager = await createOfferPurchaseManager();
      const result = await manager.createConfirmation(offer.uuid!);
      navigate(RouteDictionary.PURCHASE_CONFIRMATION, {offerUuid: offer.uuid!, confirmationUuid: result.uuid});
    } catch (error: any) {
      await handleError(error);
    }
  };
  return (
    <div className="secondary-project-invest">
      <div ref={stickyBlock} className={cn("secondary-project-invest__container", resultIf(focused, "active"))}>
        <div className="secondary-project-invest__header">
          Вторичный рынок
        </div>
        <div className="secondary-project-invest__main--container">
          <div className="secondary-project-invest__main">
            <div className="secondary-project-invest__money secondary-project-invest__money--price">
              <div>
                Цена:
              </div>
              <div>
                <Money money={offer.price} />
              </div>
            </div>
            <div className="secondary-project-invest__input">
              <PrimaryButton expanded color={PrimaryButtonColor.GREEN}>
                <Button className="secondary-project-invest__input-btn" onClick={handleSubmit}>
                  Купить
                </Button>
              </PrimaryButton>
            </div>
            <div className="secondary-project-invest__money">
              <div>
                Комиссия:
              </div>
              <div>
                <Money money={investmentFee} />
              </div>
            </div>
            <div className="secondary-project-invest__money">
              <div>
                Итого:
              </div>
              <div>
                <Money money={finalAmount} />
              </div>
            </div>
          </div>
          <div className="secondary-project-invest__submit">
            <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
              <Button onClick={handleSubmit}>
                Купить
              </Button>
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryProjectInvest;