import {useEffect, useState} from "react";
import {TransactionInfo, TransactionType} from "../api-client";
import {useAppDispatch, useAppSelector} from "./store";
import {set, TransactionsTranslationsState} from "../store/transactionsTranslations";
import {createTransactionInfoProvider} from "../di";
import {useErrorCallback} from "./useErrorCallback";

interface TransactionTranslationsKit {
    loading: boolean;
    findByType: (type: TransactionType) => TransactionInfo;
}

export const useTransactionsTranslations = () => {
  const [loadingTranslations, setLoadingTranslations] = useState(false);
  const current = useAppSelector<TransactionsTranslationsState>(state => state.transactionsTranslations);
  const handleError = useErrorCallback();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const callback = async () => {
      setLoadingTranslations(true);
      try {
        const provider = await createTransactionInfoProvider();
        const collection = await provider.getAll();
        dispatch(set(collection));
      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoadingTranslations(false);
      }
    };
    if (current.transactionsInfo.length === 0) {
      (async () => {
        await callback();
      })();
    }
  }, []);

  const findByType = (type: TransactionType): TransactionInfo => {
    return current.transactionsInfo.filter((item) => item.type === type)[0];
  };

  return {
    loading: loadingTranslations,
    findByType
  } as TransactionTranslationsKit;
};