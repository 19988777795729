import {ApiClientInterface} from "./clients";
import {ChangeCredential} from "./models";

export abstract class ChangeCredentialManager {
  protected constructor(protected readonly apiClient: ApiClientInterface) {}

  public async request(): Promise<ChangeCredential> {
    return this.apiClient.post('request');
  }

  public async confirm(confirmationUuid: string, verificationCode: string): Promise<void> {
    return this.apiClient.post("confirm", {confirmationUuid, verificationCode});
  }
}