import {useContext, useState} from "react";
import {ProjectsContext} from "../views/dashboard/lender/investment/projects/ProjectsContext";
import {useEffectOnUpdate} from "./useEffectOnUpdate";
import {MAX_MATURITY_DATE, MAX_YEAR_PROFIT, MIN_MATURITY_DATE, MIN_YEAR_PROFIT, SliderValue} from "../utils";

export const useProjectsFilter = () => {
  const context = useContext(ProjectsContext);
  const [search, setSearch] = useState(context.filter.search);
  const [economicClasses, setEconomicClasses] = useState<Array<string>>(context.filter.economicClasses);
  const [legalClasses, setLegalClasses] = useState<Array<string>>(context.filter.legalClasses);
  const [initialTermFrom, setInitialTermFrom] = useState(context.filter.initialTermFrom);
  const [initialTermTo, setInitialTermTo] = useState(context.filter.initialTermTo);
  const [interestRateFrom, setInterestRateFrom] = useState(context.filter.interestRateFrom);
  const [interestRateTo, setInterestRateTo] = useState(context.filter.interestRateTo);
  const [statuses, setStatuses] = useState(context.filter.statuses);

  useEffectOnUpdate(() => {
    const filter = context.filter;
    setSearch(filter.search);
    setLegalClasses(filter.legalClasses);
    setEconomicClasses(filter.economicClasses);
    setInitialTermFrom(filter.initialTermFrom);
    setInitialTermTo(filter.initialTermTo);
    setInterestRateFrom(filter.interestRateFrom);
    setInterestRateTo(filter.interestRateTo);
    setStatuses(filter.statuses);
  }, [context.filter]);

  const handleMaturityDateChange = (value: SliderValue) => {
    setInitialTermFrom(value.min === MIN_MATURITY_DATE ? null : value.min);
    setInitialTermTo(value.max === MAX_MATURITY_DATE ? null : value.max);
  };

  const handleYearProfitChange = (value: SliderValue) => {
    setInterestRateFrom(value.min === MIN_YEAR_PROFIT ? null : value.min);
    setInterestRateTo(value.max === MAX_YEAR_PROFIT ? null : value.max);
  };

  const handleSubmit = () => {
    context.setFilter({
      ...context.filter,
      economicClasses,
      legalClasses,
      initialTermFrom,
      initialTermTo,
      interestRateFrom,
      interestRateTo
    });
    context.toggleFilter();
  };

  return {
    search,
    setSearch,
    economicClasses,
    setEconomicClasses,
    legalClasses,
    setLegalClasses,
    initialTermFrom,
    setInitialTermFrom,
    initialTermTo,
    setInitialTermTo,
    interestRateFrom,
    setInterestRateFrom,
    interestRateTo,
    setInterestRateTo,
    handleMaturityDateChange,
    handleYearProfitChange,
    handleSubmit,
    statuses,
    setStatuses
  };
};

export default useProjectsFilter;