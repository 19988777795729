import React from "react";
import {Container, Section} from "../../../../../components";
import './Projects.scoped.scss';
import {Outlet} from "react-router-dom";

const Projects = () => {
  return (
    <Container>
      <Section>
        <Outlet />
      </Section>
    </Container>
  );
};

export default Projects;