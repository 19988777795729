export enum PaymentType {
    CREDIT = "credit",
    DEBIT = "debit"
}

type PaymentTypeRecord = Record<PaymentType, string>;

export const PaymentTypeMapped: PaymentTypeRecord = {
  [PaymentType.CREDIT]: "Взыскание",
  [PaymentType.DEBIT]: "Списание"
};

export const translatePaymentType = (type: PaymentType): string => {
  return PaymentTypeMapped[type];
};