import React, {FC} from "react";
import DatePickerComponent, {DatePickerProps} from "react-date-picker";
import './DatePicker.scoped.scss';

interface Props extends DatePickerProps {
    value: Date | null | undefined;
    onSelected: (date: Date) => void;
}

const DatePicker: FC<Props> = ({value, onSelected, ...props}) => {
  const handleChange = (date: Date | null) => {
    if (date === null) {
      onSelected(new Date());
      return;
    }
    onSelected(date);
  };
  return (
    <DatePickerComponent
      {...props}
      showLeadingZeros
      clearIcon={null}
      calendarIcon={null}
      className="date-select"
      value={value}
      onChange={handleChange}
      format="dd.MM.y"
      prev2Label={null}
      next2Label={null}
      locale="ru"
    />
  );
};

export default DatePicker;