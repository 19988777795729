import {configureStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";
import thunk from 'redux-thunk';

import profileReducer from './profile';
import myMoneyReducer from './my-money';
import overlayReducer from './overlay';
import alertNotificationsReducer from './alertNotifications';
import transactionsTranslationsReducer from "./transactionsTranslations";

const reducers = combineReducers({
  profile: profileReducer,
  overlay: overlayReducer,
  alertNotifications: alertNotificationsReducer,
  transactionsTranslations: transactionsTranslationsReducer,
  myMoney: myMoneyReducer,
});

const store = configureStore({
  reducer: reducers,
  middleware: [thunk]
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;