import React, {FC, PropsWithChildren} from "react";
import {CSSTransition} from "react-transition-group";

interface Props {
    active: boolean;
}

const AnimatedFilterSearch: FC<PropsWithChildren<Props>> = ({active, children}) => {
  return (
    <CSSTransition
      in={active}
      timeout={500}
      classNames="filter-search__input-"
    >
      { children }
    </CSSTransition>
  );
};

export default AnimatedFilterSearch;