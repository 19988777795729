import React, {createContext, PropsWithChildren} from "react";

export type PaginationContextType = {
    count: number;
    totalCount: number;
    page: number;
    pageParam: string;
    perPage: number;
    perPageParam: string;
}

type Props = {
    props: PaginationContextType;
}

export const PaginationContext = createContext<PaginationContextType>({
  count: 0,
  totalCount: 0,
  page: 1,
  pageParam: "page",
  perPage: 20,
  perPageParam: "perPage"
});

function PaginationContextProvider<T>(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <PaginationContext.Provider value={value.props}>
    { children }
  </PaginationContext.Provider>;
}

export default PaginationContextProvider;