import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import {cn} from "../../../../utils";
import './BlockFooter.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const BlockFooter: FC<PropsWithChildren<Props>> = ({children, ...props}) => {
  return (
    <footer {...props} className={cn("block-footer", props.className)}>
      <div className="block-footer__inner">
        { children }
      </div>
    </footer>
  );
};

export default BlockFooter;