import styles from './DocumentsToProject.module.scss';
import {Pagination} from "../../../../../../components";
import React, {useCallback, useState} from "react";
import {DataCollection} from "../../../../../../utils";
import {createAgreementProvider} from "../../../../../../di";
import {useErrorCallback} from "../../../../../../hooks";
import {DocumentsToProjectItem} from "../DocumentsElements";
import {ProjectDocumentsAgreement} from "../../../../../../api-client/models/documents/ProjectDocumentsAgreement";
import FilterIcon from "../../../../../../components/filters/FilterIcon";
import {FilterDocuments} from "../FilterDocuments";
import {Filter} from "../FilterDocuments/FilterDocuments";

export const defaultFilter: Filter = {
  search: "",
  dateFrom: "",
  dateTo: "",
  type: [],
  isCommercialOffer: null
};

const DocumentsToProject = () => {
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState<Filter>(defaultFilter);

  const [agentDocuments, setAgentDocuments] =
        useState<DataCollection<ProjectDocumentsAgreement>>(new DataCollection());
  const handleError = useErrorCallback();

  const loadData = useCallback(async (page: number, perPage: number) => {
    setLoading(true);
    try {
      const provider = await createAgreementProvider();
      const results = await provider.getProjectAgreements(page, perPage, filter);
      const newCollection = new DataCollection<ProjectDocumentsAgreement>();
      newCollection.setCollection(results);
      setAgentDocuments(newCollection);
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const renderedDocuments = useCallback((documents: DataCollection<ProjectDocumentsAgreement>) => {
    if (loading) {
      return <div>
        Загрузка...
      </div>;
    }
    if (documents.items.length === 0) {
      return <div>
        У Вас еще нет документов
      </div>;
    } else return <DocumentsToProjectItem documentsList={documents}/>;
  }, []);

  const updateFilter = (filter: Filter): void => {
    setFilter({...filter});
  };
  return (<div className={styles.documents}>
    <Pagination
      onLoad={loadData}
      count={agentDocuments.count}
      totalCount={agentDocuments.totalCount}
      filterObject={filter}
      initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
    >
      <div className={styles.documents__filtersContainer}>
        <div onClick={() => setActive((prevState) => !prevState)}><FilterIcon/></div>
      </div>
      {active && <FilterDocuments filter={filter}
        onChange={updateFilter} isDocumentToProject={true}/>}
      <ul className={styles.documents__title}>
        <Pagination.Content loading={loading}>
          {renderedDocuments(agentDocuments)}
        </Pagination.Content>
      </ul>
      <Pagination.Paginator/>
    </Pagination>
  </div>
  );
};

export default DocumentsToProject;