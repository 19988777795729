import React, {SyntheticEvent, useRef, useState} from "react";
import {
  Block,
  BoldText,
  Button,
  Container,
  Heading,
  InputErrors,
  InputMoney,
  Modal,
  PrimaryButton,
  PrimaryHeading,
  Section
} from "../../../../../components";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import './Withdraw.scoped.scss';
import {WithdrawFAQ} from "../../../../../components/pages";
import {PrimaryButtonColor} from "../../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import {Money, UuidResult} from "../../../../../api-client";
import MainGrid from "../../../../../components/layout/MainGrid";
import {useEffectOnUpdate, useErrorCallback, useProfileData} from "../../../../../hooks";
import {createWithdrawManager} from "../../../../../di";
import {Errors} from "../../../../../utils";

type WithdrawErrors = {
  minAmount?: Errors,
  maxAmount?: Errors,
}
const Withdraw = () => {
  const [money, setMoney] = useState<Money>(new Money(100000, "RUB"));
  const [result, setResult] = useState<UuidResult | null>(null);
  const [loading, setLoading] = useState(false);
  const handleError = useErrorCallback();
  const focused = useRef<boolean>(false);
  const {balance} = useProfileData();
  const [errors, setErrors] = useState<WithdrawErrors>({});
  const minSum = 100000;

  const onSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const manager = await createWithdrawManager();
      const result = await manager.create(money);
      setResult(result);
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };
  const validateBlock = (): boolean => {
    const errors: WithdrawErrors = {};

    if (money.amount < minSum) {
      errors.minAmount = [`Минимальная сумма: ${minSum/100} ₽`];
    }
    if (money.amount > balance.amount) {
      errors.maxAmount = ["Недостаточно средств на счете"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(( ) => {
    validateBlock();
  }, [money]);

  return (
    <Container>
      <Section className="withdraw-section">
        <PrimaryHeading>
          <Heading headingType={HeadingType.H1} className="withdraw-section__heading">
            Вывод средств
          </Heading>
        </PrimaryHeading>
        <MainGrid>
          <div className="withdraw">
            <div className="withdraw-main">
              <Block>
                <Block.Content>
                  <Block.Header withPadding>
                    <div className="withdraw-heading">
                      Запросить вывод средств
                    </div>
                  </Block.Header>
                  <Block.Body withPadding>
                    <form className="withdraw-form" onSubmit={onSubmit}>
                      <div className="withdraw-form__text">
                        Осуществить вывод денег возможно только на банковский расчётный счёт, с которого вы делали перечисления на номинальный счет платформы.
                      </div>
                      <div className="withdraw-form__container">
                        <BoldText>
                          <div className="withdraw-form__heading">
                            Введите сумму
                          </div>
                        </BoldText>
                        <div className="withdraw-form__input">
                          <InputMoney
                            money={money}
                            onValueChanged={setMoney}
                            placeholder="1000"
                            onFocus={() => focused.current = true}
                            onBlur={() => focused.current = false}
                          />
                          {!errors.minAmount && <div className="withdraw-form__min">
                            {`Минимальная сумма: ${minSum / 100} ₽`}
                          </div>}
                          {errors.minAmount && <InputErrors errors={errors.minAmount}/>}
                          {errors.maxAmount && <InputErrors errors={errors.maxAmount}/>}
                        </div>
                        <div className="withdraw-form__explanation-container">
                          <div className="withdraw-form__explanation">
                            Согласно правилам пользования платформой, оператор платформы
                            обязан осуществить вывод средств на счет в течении 2 дней
                            с момента получения запроса.
                          </div>
                        </div>
                      </div>
                      {/*<div className="withdraw-form__container">*/}
                      {/*    <BoldText>*/}
                      {/*        <div className="withdraw-form__heading">*/}
                      {/*            Выберите счёт*/}
                      {/*        </div>*/}
                      {/*    </BoldText>*/}
                      {/*    <BankAccountSelect />*/}
                      {/*</div>*/}
                      <div className="withdraw-form__submit">
                        {loading? 
                          <div>Загрузка</div>
                          :
                          <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
                            <Button type="submit" disabled={Object.keys(errors).length !== 0}>
                              Вывести
                            </Button>
                          </PrimaryButton>}
                        {/*<div className="withdraw-form__check">*/}
                        {/*    <label onClick={() => setRulesAccepted((accepted) => !accepted)}>*/}
                        {/*        <Checkbox*/}
                        {/*            checked={rulesAccepted}*/}
                        {/*        >*/}
                        {/*            Согласен с <ColoredText color={TextColor.PRIMARY_GREEN}>*/}
                        {/*            <a href="#">условиями</a>*/}
                        {/*        </ColoredText> использования*/}
                        {/*            платформы и <ColoredText color={TextColor.PRIMARY_GREEN}>*/}
                        {/*            <a href="#">обработки</a>*/}
                        {/*        </ColoredText> персональных*/}
                        {/*            данных*/}
                        {/*        </Checkbox>*/}
                        {/*    </label>*/}
                        {/*</div>*/}
                      </div>
                    </form>
                  </Block.Body>
                </Block.Content>
              </Block>
            </div>
          </div>
          <Modal active={result !== null} onClose={() => setResult(null)}>
            Мы получили ваш запрос. Ожидайте своих средств в течение 2-х дней.
          </Modal>
          <WithdrawFAQ />
        </MainGrid>
      </Section>
    </Container>
  );
};

export default Withdraw;