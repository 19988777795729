export class EconomicClassProvider {
  private classes: Array<string> = ['A', 'B', 'C', 'D', 'E, T, R, M'];

  public getMainClasses(): Array<string> {
    return this.classes;
  }

  public getAllClasses(): Array<string> {
    return this.classes.reduce((accum: Array<string>, current) => {
      accum.push(`${current}-`, current, `${current}+`);
      return accum;
    }, []);
  }
}