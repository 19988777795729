import React, {InputHTMLAttributes, ReactNode, useId} from "react";
import './Checkbox.scoped.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children: ReactNode;
}

const Checkbox = ({children, id, ...props}: Props) => {
  const fallbackId = useId();
  return (
    <div className="custom-checkbox">
      <input type="checkbox" className="custom-checkbox__input" {...props} id={id ?? fallbackId}/>
      <label htmlFor={id ?? fallbackId} className="custom-checkbox__label">
        { children }
      </label>
    </div>
  );
};

export default Checkbox;