import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {
  Forbidden,
  HttpError,
  PropertyError,
  Unauthorized,
  ValidationError,
} from '../errors';
import {Headers} from "../types";
import {ApiClientInterface} from "./ApiClientInterface";
import * as Sentry from "@sentry/react";

export class ApiClient implements ApiClientInterface{
  constructor(
        private readonly baseUrl: string,
        private readonly headers: Headers,
        private readonly authToken: string = ""
  ) {}

  public async get(endpoint: string = "", params?: any): Promise<any> {
    try {
      const client = this.createClient(params);
      const response = await client.get(endpoint);
      return response.data;
    } catch (e) {
      this.handleError(e);
    }
  }

  public async post(endpoint: string = "", data?: any): Promise<any> {
    try {
      const client = this.createClient();
      const response = await client.post(endpoint, data);
      return response.data;
    } catch (e) {
      this.handleError(e);
    }
  }

  public async uploadFile(endpoint: string = "", formData: FormData): Promise<any> {
    try {
      const client = this.createClient();
      const response = await client.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      });
      return response.data;
    } catch (err) {
      this.handleError(err);
    }
  }

  private createClient(params: object = {}): AxiosInstance {
    const config: AxiosRequestConfig = {
      baseURL: this.baseUrl,
      headers: this.headers,
      params: params
    };
    if (this.authToken) {
      config.headers = {
        Authorization: `Bearer ${this.authToken}`,
      };
    }
    return axios.create(config);
  }

  private handleError(error: any): never {
    // TODO create one global exception handler
    Sentry.captureException(error);

    if (!error.response) {
      throw new HttpError(error.message);
    } else if (error.response.status === 400) {
      const errors: Array<any> =
                error.response.data.errors instanceof Array
                  ? error.response.data.errors
                  : [];
      throw new ValidationError(
        error.response.data.message,
        errors
          .filter((item) => item && item.propertyPath && item.message)
          .map((item) => new PropertyError(item.propertyPath, item.message))
      );
    } else if (error.response.status === 401) {
      throw new Unauthorized(error.response.data);
    } else if (error.response.status === 403) {
      throw new Forbidden(error.response.data);
    } else {
      throw error;
    }
  }
}