import {AuthTokenManager, AuthTokenStorage, RouteUrlProvider, SmsFormStateStorage} from "utils";
import {createAuthManager} from "./api-client";
import {LockFactory} from "utils";
import {EventBus} from "../utils";
import {RouteProvider} from "../utils/router/RouteProvider";
import {EconomicClassProvider, LegalClassProvider} from "../utils/debtClass";

export function createAuthTokenManager(): AuthTokenManager {
  return new AuthTokenManager (
    createAuthManager(),
    new AuthTokenStorage(),
    new LockFactory(window.localStorage)
  );
}

export function createRouteUrlProvider(): RouteUrlProvider {
  return new RouteUrlProvider(
    new RouteProvider()
  );
}

export function createEventBus(): EventBus {
  return new EventBus();
}

export function createSmsFormStateStorage(prefix: string): SmsFormStateStorage {
  return new SmsFormStateStorage(prefix);
}

export function createEconomicClassProvider(): EconomicClassProvider {
  return new EconomicClassProvider();
}

export function createLegalClassProvider(): LegalClassProvider {
  return new LegalClassProvider();
}
