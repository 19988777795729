import React, {FC} from "react";

export enum CalendarSvgColor {
    WHITE = "#fff",
    GRAY = "#9299a2",
    BLACK = "#000"
}

interface Props {
    color?: CalendarSvgColor | undefined;
}

const CalendarSvg: FC<Props> = ({color= CalendarSvgColor.GRAY}) => {
  return (
    <svg id="Сгруппировать_12187" data-name="Сгруппировать 12187" xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16">
      <rect id="Прямоугольник_3246" data-name="Прямоугольник 3246" width="14" height="4" transform="translate(0 2)" fill={color} />
      <rect id="Прямоугольник_3247" data-name="Прямоугольник 3247" width="2" height="5" transform="translate(2)" fill={color} />
      <rect id="Прямоугольник_3249" data-name="Прямоугольник 3249" width="3" height="3" transform="translate(3 7)" fill={color} />
      <rect id="Прямоугольник_3254" data-name="Прямоугольник 3254" width="2" height="11" transform="translate(0 5)" fill={color} />
      <rect id="Прямоугольник_3255" data-name="Прямоугольник 3255" width="2" height="11" transform="translate(12 5)" fill={color} />
      <rect id="Прямоугольник_3256" data-name="Прямоугольник 3256" width="14" height="2" transform="translate(0 14)" fill={color} />
      <rect id="Прямоугольник_3248" data-name="Прямоугольник 3248" width="2" height="5" transform="translate(10)" fill={color} />
    </svg>
  );
};

export default CalendarSvg;