import React, {useContext} from "react";
import './FilterIcon.scoped.scss';
import {ProjectsContext} from "../../../views/dashboard/lender/investment/projects/ProjectsContext";
import {resultIf} from "../../../utils";
import CommonFilterIcon from "../CommonFilterIcon";
import FilterSvg from "../../svg/FilterSvg";

const FilterIcon = () => {
  const context = useContext(ProjectsContext);
  const toggle = () => {
    context.toggleFilter();
  };
  return (
    <CommonFilterIcon onClick={toggle} className={resultIf(context.filterActive, "active")}>
      <FilterSvg />
    </CommonFilterIcon>
  );
};

export default FilterIcon;