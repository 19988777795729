import {ApiClientInterface} from "./clients";
import {AutoInvestmentSettings, GlobalState, UuidResult} from "./models";
import {AutoInvestmentConfirmation} from "./models/autoinvestment/AutoInvestmentConfirmation";

export class AutoInvestmentSettingsManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getUserSettings(): Promise<AutoInvestmentSettings|null> {
    return this.apiClient.get("");
  }

  public async changeState(enabled: boolean): Promise<void> {
    return this.apiClient.post("change-state", {enabled});
  }

  public async getConfirmation(confirmationUuid: string): Promise<AutoInvestmentConfirmation> {
    return this.apiClient.get(`confirmation/${confirmationUuid}`);
  }

  public async setUserSettings(settings: AutoInvestmentSettings): Promise<UuidResult> {
    return this.apiClient.post("", {settings});
  }

  public async sendCode(confirmationUuid: string): Promise<void> {
    return this.apiClient.post("send-code", {confirmationUuid});
  }

  public async confirmCode(confirmationUuid: string, smsCode:string): Promise<void> {
    return this.apiClient.post("confirm-code", {confirmationUuid, smsCode});
  }

  public async globalState(): Promise<GlobalState> {
    return this.apiClient.get("global-state");
  }
}