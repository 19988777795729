import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const authRoutes: Array<NamedUrl> = [
  {
    name: RouteDictionary.REDIRECT_AFTER_AUTH,
    url: `/redirect-after-auth`
  },
  {
    name: RouteDictionary.LOGOUT,
    url: `/logout`
  },
];