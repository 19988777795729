import React, {useContext, useMemo} from "react";
import './DetailedProjectMobileNavigation.scoped.scss';
import {CustomLink} from "../../../common";
import {RouteDictionary} from "../../../../utils";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {
  SecondaryProjectContext
} from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectContext/SecondaryProjectContext";


const DetailedProjectMobileNavigation = () => {
  const project = useContext(DetailedProjectContext).project;
  const offer = useContext(SecondaryProjectContext) ? useContext(SecondaryProjectContext).offer : null;
  const navigation = useMemo(() => {
    if (offer) {
      return (
        <ul className="detailed-project-mobile-navigation">
          <CustomLink
            id="economic-analysis"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.OFFER_ECONOMIC_ANALYSIS}
            params={{uuid: offer.uuid}}
          >
            Экономический анализ
          </CustomLink>
          <CustomLink
            id="legal-analysis"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.OFFER_LEGAL_ANALYSIS}
            params={{uuid: offer.uuid}}
          >
            Юридический анализ
          </CustomLink>
          <CustomLink
            id="strategy"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.OFFER_STRATEGY}
            params={{uuid: offer.uuid}}
          >
            Стратегия взыскания
          </CustomLink>
          <CustomLink
            id="documents"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.OFFER_DOCUMENTS}
            params={{uuid: offer.uuid}}
          >
            Документы
          </CustomLink>
        </ul>
      );
    }
    else {
      return (
        <ul className="detailed-project-mobile-navigation">
          <CustomLink
            id="economic-analysis"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.PROJECT_ECONOMIC_ANALYSIS}
            params={{uuid: project.uuid}}
          >
            Экономический анализ
          </CustomLink>
          <CustomLink
            id="legal-analysis"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.PROJECT_LEGAL_ANALYSIS}
            params={{uuid: project.uuid}}
          >
            Юридический анализ
          </CustomLink>
          <CustomLink
            id="strategy"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.PROJECT_STRATEGY}
            params={{uuid: project.uuid}}
          >
            Стратегия взыскания
          </CustomLink>
          <CustomLink
            id="documents"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.PROJECT_DOCUMENTS}
            params={{uuid: project.uuid}}
          >
            Документы
          </CustomLink>
          <CustomLink
            id="documents"
            className="detailed-project-mobile-navigation__link"
            to={RouteDictionary.PROJECT_STAGES}
            params={{uuid: project.uuid}}
          >
            События
          </CustomLink>
        </ul>
      );
    }
  }, [offer]);
  return (
    navigation
  );
};

export default DetailedProjectMobileNavigation;