import React from "react";
import {BannerType} from "../Banner/Banner";
import {Banner} from "../index";
import {ChildrenProps} from "../../../utils";

type Props = ChildrenProps;

const BannerInfo = ({children}: Props) => {
  return (
    <>
      <Banner type={BannerType.INFO}>
        {children}
      </Banner>
    </>
  );
};

export default BannerInfo;