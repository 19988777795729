import styles from './AgentDocuments.module.scss';
import stylesItems from './../DocumentsElements/DocumentsToProjectsItem.module.scss';
import {Image, Pagination} from "../../../../../../components";
import React, {useEffect, useState} from "react";
import {Agreement} from "../../../../../../api-client/models/documents/Agreement";
import {useErrorCallback} from "../../../../../../hooks";
import {createAgreementProvider} from "../../../../../../di";
import {formatDate} from "../../../../../../formatters";
import documentIcon from "../../../../../../assets/images/svg/document.svg";
import {
  Collection,
  LegalAgreements,
  LegalDocument,
  LegalDocumentType,
  translateLegalDocumentType
} from "../../../../../../api-client";
import {AgentDocumentAgreement} from "../DocumentsElements/AgentDocumentAgreement";


const AgentDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [agentDocuments, setAgentDocuments] =
      useState<LegalAgreements>();
  const handleError = useErrorCallback();

  const loadData = async (page: number = 1, perPage: number = 10) => {
    setLoading(true);
    try {
      const provider = await createAgreementProvider();
      const results = await provider.getLegalAgreements(page, perPage);
      setAgentDocuments(results);
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (loadData)();
  }, []);

  const renderedDocuments = (documents:Collection<Agreement>) => {
    return <>
      {documents.items.map((document: Agreement) => {
        return (
          <AgentDocumentAgreement document={document} key={document.uuid}/>
        );
      })}
    </>;
  };

  const renderedLegalDocuments = (documents:Collection<LegalDocument>) => {
    
    return <>
      {documents.items.map((document: LegalDocument) => {
        const isAgentAgreement = document.type === LegalDocumentType.AGENT || document.type === LegalDocumentType.AGENT_CHANGE;
        return (
          <ul key={document.uuid} className={stylesItems.documentsToProjectBody__list}>
            <li  className={stylesItems.documentsToProjectBody__item}>
              <Image className={stylesItems.documentsToProjectBody__icon} src={documentIcon}/>
              <a href={document.url} target="_blank" rel="noreferrer" className={stylesItems.documentsToProjectBody__title}>
                {document.type === LegalDocumentType.COMMON? document.title: translateLegalDocumentType(document.type)}</a>
              <a href={document.url} target="_blank" rel="noreferrer" className={stylesItems.documentsToProjectBody__size}>pdf, {document.size} Кб</a>
              <a href={document.url} target="_blank" rel="noreferrer" className={stylesItems.documentsToProjectBody__watch} >Нажмите, чтобы посмотреть</a>
              <a href={document.url} target="_blank" rel="noreferrer" className={stylesItems.documentsToProjectBody__date}>{formatDate(new Date(document.effectiveDate || ""))}</a>
              <a href={document.url} target="_blank" rel="noreferrer" className={stylesItems.documentsToProjectBody__dateText}>{isAgentAgreement?
                "дата вступления в силу": "дата подписания"}</a>
            </li>
          </ul>
        );
      })}
    </>;
  };
  let legalDocumentsCount = 0;
  let legalDocumentsTotalCount = 0;
  if (agentDocuments) {
    legalDocumentsCount = agentDocuments.collectionLegalDocuments.count;
    legalDocumentsTotalCount = agentDocuments.collectionLegalDocuments.totalCount;
  }

  let agreementsCount = 0;
  let agreementsTotalCount = 0;
  if (agentDocuments) {
    agreementsCount = agentDocuments.collectionAgreements.count;
    agreementsTotalCount = agentDocuments.collectionAgreements.totalCount;
  }
  
  const count = agreementsCount+legalDocumentsCount;
  const totalCount = legalDocumentsTotalCount+agreementsTotalCount;
  return (<div className={styles.documents}>
    <Pagination
      onLoad={loadData}
      count={count}
      totalCount={totalCount}
    >
      <div className={styles.documents__container}>
        <Pagination.Content loading={loading}>
          {agentDocuments && renderedLegalDocuments(agentDocuments.collectionLegalDocuments)}
          {agentDocuments && renderedDocuments(agentDocuments.collectionAgreements)}
        </Pagination.Content>
      </div>
      <Pagination.Paginator/>
    </Pagination>
  </div>
  );
};

export default AgentDocuments;