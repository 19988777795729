import React, {createContext, PropsWithChildren} from "react";
import {DetailedProject} from "../../../../api-client";

export type DetailedProjectContextType = {
    project: DetailedProject;
}

type Props = {
    props: DetailedProjectContextType;
}

// @ts-ignore
export const DetailedProjectContext = createContext<DetailedProjectContextType>();

function DetailedProjectContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <DetailedProjectContext.Provider value={value.props}>
    { children }
  </DetailedProjectContext.Provider>;
}

export default DetailedProjectContextProvider;