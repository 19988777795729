import React from "react";
import './ProjectsSubFilter.scoped.scss';
import ProjectsFilterClearAll from "../ProjectsFilterClearAll";
import ProjectFilterFields from "../ProjectFilterFields";
import ProjectFilterActive from "../ProjectFilterActive";

const ProjectsSubFilter = () => {
  return (
    <ProjectFilterActive>
      <div className="projects-sub-filter">
        <ProjectFilterFields />
        <ProjectsFilterClearAll />
      </div>
    </ProjectFilterActive>
  );
};

export default ProjectsSubFilter;