import React from "react";
import {ClassInjector} from "../../../../injectors";
import {ChildrenProps, cn} from "../../../../../utils";
import './TertiaryButton.scoped.scss';
import {ClassNameDecorator} from "../../../../decorators";

export enum TertiaryButtonColor {
    GREEN = "tertiary-button--green",
    GRAY = "tertiary-button--gray",
    WHITE = "tertiary-button--white"
}

interface Props extends ChildrenProps {
    color: TertiaryButtonColor;
    expanded?: boolean;
    large?: boolean;
}

const TertiaryButton: ClassNameDecorator<Props> = (
  {
    color,
    children,
    expanded,
    large,
    className
  }) => {
  return (
    <ClassInjector classNames={
      cn(
        "tertiary-button",
        `${color}`,
        `${expanded ? "tertiary-button--expanded" : ""}`,
        `${large ? "tertiary-button--large" : ""}`
      )
    } oldClassName={className}>
      { children }
    </ClassInjector>
  );
};

export default TertiaryButton;