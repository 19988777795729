import {useState} from "react";

export const useCompanyData = () => {
  const [companyName, setCompanyName] = useState("");
  const [companySiteUrl, setCompanySiteUrl] = useState("");
  const [companyInn, setCompanyInn] = useState("");
  const [companyOgrnip, setCompanyOgrnip] = useState("");
  const [companyKpp, setCompanyKpp] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  return {
    companyName,
    setCompanyName,
    companySiteUrl,
    setCompanySiteUrl,
    companyInn,
    setCompanyInn,
    companyOgrnip,
    setCompanyOgrnip,
    companyKpp,
    setCompanyKpp,
    companyAddress,
    setCompanyAddress
  };
};