import React, {FC, PropsWithChildren, ReactNode, useEffect, useRef, useState} from "react";
import './MultiSelect.scoped.scss';
import {Nullable} from "../../../../utils";
import {MultiSelectContent, MultiSelectHead} from "./composables";
import MultiSelectContextProvider, {MultiSelectContextType} from "./MultiSelectContext";

type Props<T> = {
    options: Array<T>;
    selectedOptions: Array<T>;
    renderOption?: (option: T) => ReactNode | undefined;
    onFocusLost?: () => void;
}

type MultiSelectExtensions = {
    Head: typeof MultiSelectHead;
    Content: typeof MultiSelectContent;
}

const MultiSelect: FC<PropsWithChildren<Props<any>>>
    & MultiSelectExtensions = <T, >(
      {
        options,
        selectedOptions,
        renderOption,
        onFocusLost,
        children
      }: PropsWithChildren<Props<T>>
    ) => {
      const [active, setActive] = useState(false);
      const toggle = () => {
        setActive((active) => !active);
      };

      const value: MultiSelectContextType<T> = {
        options: options,
        selectedOptions: selectedOptions,
        render: renderOption !== undefined ? renderOption : (option: T) => option as unknown as ReactNode,
        active: active,
        toggle: toggle
      };

      const elem = useRef(null);

      const initSelection = () => {
        setActive(false);
        onFocusLost && onFocusLost();
      };

      const handleClick = (e: MouseEvent): void => {
        if (e.target instanceof HTMLElement) {
          let element: Nullable<HTMLElement> = e.target;
          while (element && element !== elem.current) {
            element = element.parentElement;
          }
          if (element === null) {
            initSelection();
          }
        }
      };

      const handleKeyPress = function (e: KeyboardEvent) {
        if (active && e.key === 'Escape') {
          initSelection();
        }
      };

      useEffect(() => {
        if (active) {
          window.addEventListener('click', handleClick);
          window.addEventListener('keydown', handleKeyPress);
        }
        return () => {
          window.removeEventListener('click', handleClick);
          window.removeEventListener('keydown', handleKeyPress);
        };
      }, [active]);


      return (
        <MultiSelectContextProvider props={value}>
          <div className="multi-select" ref={elem}>
            { children }
          </div>
        </MultiSelectContextProvider>
      );
    };

MultiSelect.Head = MultiSelectHead;
MultiSelect.Content = MultiSelectContent;

export default MultiSelect;