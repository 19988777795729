import React, {useContext} from "react";
import './ProjectFilter.scoped.scss';
import {ProjectsContext} from "../../../../../views/dashboard/lender/investment/projects/ProjectsContext";
import {
  cn,
  Nullable,
  resultIf,
} from "../../../../../utils";
import {BoldText, Button, TertiaryButton} from "../../../../common";
import {EconomicClasses, LegalClasses, MaturityDateSlider, YearProfitSlider} from "../../../autoinvest";
import {TertiaryButtonColor} from "../../../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import {useProjectsFilter} from "../../../../../hooks";
import ProjectsSubFilter from "../ProjectsSubFilter";
import ProjectsFilterModal from "../../../../common/modals/ProjectsFilterModal";
import {useWindowWidth} from "../../../detailed-project/hooks";
import {ProjectStatus} from "../../../../../api-client";

export type Filter = {
    search: string;
    economicClasses: Array<string>;
    legalClasses: Array<string>;
    initialTermFrom: Nullable<number>;
    initialTermTo: Nullable<number>;
    interestRateFrom: Nullable<number>;
    interestRateTo: Nullable<number>;
    statuses: Array<ProjectStatus>;
}

const ProjectsFilter = () => {
  const context = useContext(ProjectsContext);
  const projectsFilter = useProjectsFilter();
  const isMobile = useWindowWidth();

  return (
    <>
      { !isMobile ? <div className="projects-filter-desktop">
        <ProjectsSubFilter />
        <div className={cn("projects-filter", resultIf(context.filterActive, "active"))}>
          <div className="projects-filter__debt projects-filter__values">
            <BoldText>
              <div>
                Категория долга
              </div>
            </BoldText>
            <div className="projects-filter__classes">
              <div>
                <div className="projects-filter__debt-heading">
                  Экономическая сторона
                </div>
                <EconomicClasses
                  selectedClasses={projectsFilter.economicClasses}
                  onSelected={projectsFilter.setEconomicClasses}
                />
              </div>
              <div>
                <div className="projects-filter__debt-heading">
                  Юридическая сторона
                </div>
                <LegalClasses
                  selectedClasses={projectsFilter.legalClasses}
                  onSelected={projectsFilter.setLegalClasses}
                />
              </div>
            </div>
          </div>
          <div className="projects-filter__slider projects-filter__values">
            <BoldText>
              <div>
                Срок взыскания
              </div>
            </BoldText>
            <MaturityDateSlider
              handleChange={projectsFilter.handleMaturityDateChange}
              currentValue={[projectsFilter.initialTermFrom, projectsFilter.initialTermTo]}
            />
          </div>
          <div className="projects-filter__slider projects-filter__values">
            <BoldText>
              <div>
                Годовая доходность
              </div>
            </BoldText>
            <YearProfitSlider
              handleChange={projectsFilter.handleYearProfitChange}
              currentValue={[projectsFilter.interestRateFrom, projectsFilter.interestRateTo]}
            />
          </div>
          <div className="projects-filter__submit">
            <TertiaryButton expanded color={TertiaryButtonColor.GREEN}>
              <Button onClick={projectsFilter.handleSubmit}>
                Применить фильтр
              </Button>
            </TertiaryButton>
          </div>
        </div>
      </div> : <ProjectsFilterModal active={context.filterActive} onClose={context.toggleFilter} /> }
    </>
  );
};

const MemoizedProjectsFilter = React.memo(ProjectsFilter);

export default MemoizedProjectsFilter;