import React, {FC, InputHTMLAttributes, PropsWithChildren} from "react";
import './CommonFilterIcon.scoped.scss';
import {cn} from "../../../utils";

export enum FilterIcon {
    Download = "common-icon--download",
    Favorites = "common-icon--favorites"
}

interface Props extends InputHTMLAttributes<HTMLDivElement> {
    type?: FilterIcon | undefined
}

const CommonFilterIcon: FC<PropsWithChildren<Props>> = ({type, children, ...props}) => {
  return (
    <div {...props} className={cn("common-icon", type, props.className)}>
      { children }
    </div>
  );
};

export default CommonFilterIcon;