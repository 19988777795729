import React, {FC} from "react";
import './Main.scoped.scss';
import {ChildrenProps} from "../../../utils";

const Main: FC<ChildrenProps> = ({children}) => {
  return (
    <main className="main">
      { children }
    </main>
  );
};

export default Main;