import React from "react";
import './SurveyDocuments.scoped.scss';
import {FileSurvey} from "../../../../api-client";


export const renderDocuments = (files: Array<FileSurvey>) => {
  if (files === undefined || files.length === 0) {
    return <div className="survey-documents">Документы не прилагаются</div>;
  }
  const renderedDocuments = files.map((document: FileSurvey) => {
    return <li key={document.name}>
      <div className="survey-documents">
        <div className="survey-documents__name">
          {document.name}
          {document.size && <> <span> | </span><span>{document.size}</span>
          </>}
        </div>
        <a href={document.uri} className="survey-documents__button">Смотреть</a>
      </div>
    </li>;
  });
  return <ul className="survey-documents__container">{renderedDocuments}</ul>;
};

