import React, {FC, useMemo, useState} from "react";
import './InputSmsCode.scoped.scss';
import smsConfig from 'configs/sms';
import {cn, resultIf} from "../../../../utils";

const smsCodeLength = smsConfig.smsCodeLength;

type Props = {
    onChange: (code: string) => void,
    code: string
}

const InputSmsCode: FC<Props> = ({onChange, code}) => {
  const [focused, setFocused] = useState(false);

  const active = () => {
    return focused || code !== "";
  };

  const renderCode = useMemo(() => {
    //TODO: CHANGE REGEXP TO INSERT WHITESPACES AFTER AND NOT BEFORE
    return code.replace(/.{1,2}(?=(.{2})+$)/g, '$& ').trim();
  }, [code]);
  const handleChange = (newCode: string): void => {
    const actualCode = newCode.replace(/\s+/g, "").trim().slice(0, 6);
    onChange(actualCode);
  };
  return (
    <div className={cn("input-sms-code", resultIf(active(), "active"))}>
      <label htmlFor="input-sms-code" className="input-sms-code__label">
        Проверочный код
      </label>
      <input
        id="input-sms-code"
        value={renderCode}
        type="text"
        className="input-sms-code__input"
        onInput={event => handleChange((event.target as HTMLInputElement).value)}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
      />
    </div>
  );
};

export default InputSmsCode;