import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {AgreementProvider} from "../AgreementProvider";

export class AgreementProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/agreement`,
      headers
    );
  }

  public createAgreementProvider(token: string): AgreementProvider {
    return new AgreementProvider(this.apiClientFactory.createAuthorizedClient(token));
  }
}