import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import './PaginationHeader.scoped.scss';
import {cn} from "../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const PaginationHeader: FC<PropsWithChildren<Props>> = ({children, ...props}) => {
  return (
    <div {...props} className={cn("pagination-header", props.className)}>
      { children }
    </div>
  );
};

export default PaginationHeader;