import React, {InputHTMLAttributes, ReactNode, useId} from "react";
import './RadioButton.scoped.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    children: ReactNode;
}

const RadioButton = ({children, id, ...props}: Props) => {
  const fallbackId = useId();
  return (
    <>
      <div className="custom-radio-button">
        <input type="radio" className="custom-radio-button__input" { ...props } id={id ?? fallbackId}/>
        <label htmlFor={id ?? fallbackId} className="custom-radio-button__label">
          { children }
        </label>
      </div>
    </>
  );
};

export default RadioButton;