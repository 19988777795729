import style from './FilterDocuments.module.scss';
import {BoldText, Button, TertiaryButton} from "../../../../../../components";
import React, {useEffect, useState} from "react";
import IdentificationDateSelect from "../../../../../../components/pages/identification/IdentificationDateSelect";
import {useSearchParams} from "react-router-dom";
import {AgreementType, filters, translateAgreementType} from "../../../../../../api-client/enums/AgreementType";
import moment from "moment";
import {
  TertiaryButtonColor
} from "../../../../../../components/common/buttons/decorators/TertiaryButton/TertiaryButton";

export type Filter = {
  search: string;
  dateFrom: string;
  dateTo: string;
  type: Array<AgreementType>;
  isCommercialOffer: boolean | null;
}

type Props = {
  filter: Filter;
  onChange: (filter: Filter) => void;
  isDocumentToProject: boolean
}

enum Dates {
  WEEK = "WEEK",
  MONTH = "MONTH",
  ALL = "ALL",
}
const FilterDocuments = ({filter, onChange, isDocumentToProject}: Props) => {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [params, setParams] = useSearchParams();
  const [activeCommercialOffer, setActiveCommercialOffer] = useState(true);
  const [activeTypes, setActiveTypes] = useState<Array<AgreementType>>([
    AgreementType.INVESTMENT_OFFER, 
    AgreementType.PROJECT_REPORT, 
    AgreementType.AUTO_INVESTMENT_SETTINGS]);
  const [activeDates, setActiveDates] = useState<Dates>(Dates.ALL);

  useEffect(() => {
    const dateFrom = params.get(`filter[dateFrom]`);
    const dateTo = params.get(`filter[dateTo]`);
    dateFrom && setDateFrom(new Date(dateFrom));
    dateTo && setDateTo(new Date(dateTo));
  }, []);


  useEffect(() => {
    if (activeDates === Dates.MONTH) {
      setDateFrom(moment().subtract(30, 'days').toDate());
      setDateTo(moment().toDate());
    }
    if (activeDates === Dates.WEEK) {
      setDateFrom(moment().subtract(7, 'days').toDate());
      setDateTo(moment().toDate());
    }
  }, [activeDates]);

  const onActiveTypeChange = (type: AgreementType) => {
    if (activeTypes.includes(type)) setActiveTypes(activeTypes.filter(item => item !== type));
    else setActiveTypes([...activeTypes, type]);
  };

  const onActiveDateChange = (type: Dates) => {
    if (activeDates === type) setActiveDates(Dates.ALL);
    else setActiveDates(type);
  };
  
  const acceptFilter = () => {
    onChange(
      {
        ...filter,
        isCommercialOffer: activeCommercialOffer,
        type: activeTypes,
        dateFrom: dateFrom ? dateFrom.toISOString() : "",
        dateTo: dateTo ? dateTo.toISOString() : ""
      });
  };

  return (
    <div className={style.filter}>
      <div className={isDocumentToProject? style.filter__container: style.filter__containerOnlyForDate}>
        {isDocumentToProject && <div className={style.filter__type}>
          <BoldText>
            <div className={style.filter__title}>Тип документа</div>
          </BoldText>
          <div className={style.filter__buttonContainer}>
            <Button className={activeCommercialOffer ? style.filter__button__active: style.filter__button}
              onClick={() => setActiveCommercialOffer(prevState => !prevState)}>Коммерческое предложение
            </Button>
            {filters.map((type, index) => {
              return <Button className={activeTypes.includes(type) ? style.filter__button__active : style.filter__button}
                onClick={() => onActiveTypeChange(type)} key={index}>{translateAgreementType(type)}</Button>;
            })}
          </div>
        </div>}
        
        <div className={isDocumentToProject? style.filter__type: style.filter__typeOnlyForDate}>
          <BoldText>
            <div className={style.filter__title}>Дата</div>
          </BoldText>    
          <div className={style.filter__dateContainer}>
            <IdentificationDateSelect value={dateFrom} onSelected={setDateFrom} header='с' direction="row"/>
            <IdentificationDateSelect value={dateTo} onSelected={setDateTo} header='по' direction="row"/>

            <Button className={activeDates === Dates.WEEK ? style.filter__button__active : style.filter__button}
              onClick={() => onActiveDateChange(Dates.WEEK)}>Последняя неделя </Button>
            <Button className={activeDates === Dates.MONTH ? style.filter__button__active : style.filter__button}
              onClick={() => onActiveDateChange(Dates.MONTH)}>Последний месяц </Button>
          </div>
        </div>
      </div>
      <TertiaryButton expanded color={TertiaryButtonColor.GREEN} className={style.filter__buttonAccept}>
        <Button onClick={() => acceptFilter()}>
          Применить фильтр
        </Button>
      </TertiaryButton>
    </div>
  );
};

export default FilterDocuments;