import React, {ReactElement} from 'react';
import {ColoredText, Tooltip} from "../../../common";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";
import {Block} from "../../../layout";
import './PortfolioBody.scoped.scss';

interface Props {
    projectMoney: {
        title: string,
        count: ReactElement,
        content: string
    }[]
    projectOptions: {
        header: string,
        title: string,
        count: string,
        content: string
    }[]
    isClosed?: boolean
}

const PortfolioBody = ({projectMoney, projectOptions, isClosed = false}: Props) => {
  return (
    <Block.Body withPadding>
      <div className="my-money__static">
        {projectMoney.map(project => (
          <div className="my-money__static-block" key={project.title}>
            <div className="my-money__static-header-container">
              <Tooltip content={project.content}>
                <div className="my-money__static-header">
                  {project.title}
                </div>
              </Tooltip>
            </div>
            <div className="my-money__static-amount">
              {project.count}
            </div>
          </div>
        ))}
      </div>
      <div className={isClosed? "portfolio-stats-closed": "portfolio-stats"}>
        {projectOptions.map(option => (
          <div className="portfolio-stats__block" key={option.title}>
            {option.header ?
              (<div>
                <ColoredText color={TextColor.GRAY_COLOR_3}>
                  <div>
                    {option.header}
                  </div>
                </ColoredText>
                <div className="my-money__static-header-container">
                  <Tooltip content={option.content}>
                    <div className="my-money__static-header">
                      {option.title}
                    </div>
                  </Tooltip>
                </div>
              </div>)
              :
              (<div className="my-money__static-header-container">
                <Tooltip content={option.content}>
                  <div className="my-money__static-header">
                    {option.title}
                  </div>
                </Tooltip>
              </div>)
            }
            <div>
              {option.count}
            </div>
          </div>
        ))}
      </div>
    </Block.Body>
  );
};

export default PortfolioBody;