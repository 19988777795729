import React  from "react";
import Header from "../../components/layout/Header/Header";
import Footer from "../../components/layout/Footer/Footer";
import Overlay from "../../components/layout/Overlay";
import {ChildrenProps} from "../../utils";
import {Content, Main, ModalContainer, ScrollToTopButton} from "../../components";

const LayoutPublic = ({children}: ChildrenProps) => {
  return (
    <>
      <Content>
        <Header isPublic={true} />
        <Overlay />
        <Main>
          {children}
        </Main>
        <Footer />
        <ModalContainer />
        <ScrollToTopButton />
      </Content>
    </>
  );
};

export default LayoutPublic;