import React from "react";
import {useProfileData} from "../../hooks";

const Username = () => {
  const profileData = useProfileData();
  const username = () => {
    if (profileData.name === null) {
      return "Новый пользователь";
    }
    return profileData.name;
  };
  return (
    <>
      { username() }
    </>
  );
};

export default Username;