import React, {createContext, PropsWithChildren, ReactNode} from "react";
import {Nullable} from "../../../../utils";

export type OptionBoxContextType<T> = {
    options: Array<T>;
    selectedOption: Nullable<T>;
    render: (option: T) => ReactNode;
    active: boolean;
    toggle: () => void;
}

type Props<T> = {
    props: OptionBoxContextType<T>
}

export const OptionBoxContext = createContext<OptionBoxContextType<any>>({
  options: [],
  selectedOption: {},
  render: () => <></>,
  active: false,
  toggle: () => {}
});

function OptionBoxContextProvider<T>(props: PropsWithChildren<Props<T>>) {
  const {children, ...value} = props;
  return <OptionBoxContext.Provider value={value.props}>
    { children }
  </OptionBoxContext.Provider>;
}

export default OptionBoxContextProvider;