import React, {useCallback, useMemo, useState} from "react";
import {useErrorCallback} from "hooks";
import {DataCollection} from "utils";
import {Money, OwnedInvestment as MyInvestment, ProjectStatus} from "../../../../../api-client";
import {createInvestmentManager} from "../../../../../di";
import {Pagination} from "../../../../pagination";
import {OwnedInvestment} from "../index";
import {Block} from "../../../../layout";
import MyProjectsHeader from "../../MyProjectsHeader";
import InvestmentCountContextProvider from "../../InvestmentCountContext";
import './MyInvestments.scoped.scss';
import CommonFilterIcon from "../../../../filters/CommonFilterIcon";
import {FilterIcon} from "../../../../filters/CommonFilterIcon/CommonFilterIcon";
import DownloadSvg from "../../../../svg/DownloadSvg";
import apiClientConfig from "../../../../../configs/app";
import {ProjectsCount} from "../../../../../api-client/models/project/ProjectsCount";

export type Filter = {
    statuses: Array<ProjectStatus>;
}

export const defaultFilter: Filter = {
  statuses: []
};

const MyInvestments = () => {
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [myInvestments, setMyInvestments] =
        useState<DataCollection<MyInvestment>>(new DataCollection());
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const [projectsCount, setProjectsCount] = useState<ProjectsCount>({
    closed: 0,
    current: 0,
    fundRaising: 0,
    autoInvestment: 0,
    refunded: 0
  });

  const loadData = useCallback(async (page: number, perPage: number, filter: Filter): Promise<void> => {
    setLoading(true);
    try {
      const manager = await createInvestmentManager();
      const dataCollection = new DataCollection<MyInvestment>();
      const investments = await manager.getListOwnedInvestments(page, perPage, filter);
      dataCollection.setCollection(investments);
      setMyInvestments(dataCollection);
      const projectsCountResponse = await manager.getProjectsCount();
      setProjectsCount(projectsCountResponse);
    } catch (err) {
      if (err instanceof Error) await errorCallback(err);
    } finally {
      setLoading(false);
    }
  }, [filter]);

  const projects = useMemo(() => {
    return myInvestments.items.map((item: MyInvestment) => {
      return <OwnedInvestment investment={item} key={item.project.uuid}/>;
    });
  }, [myInvestments]);

  const myInvestmentAmount = useMemo(() => {
    const invested = myInvestments.items.reduce((prev, cur) => {
      return prev + cur.investedSum.amount;
    }, 0);
    return new Money(invested, "RUB");
  }, [myInvestments]);

  const exportStatistics = async (): Promise<void> => {
    setLoading(true);
    try {
      const manager = await createInvestmentManager();
      const response = await manager.exportListOwnedInvestments(1, myInvestments.totalCount, filter);
      window.location.assign(apiClientConfig.apiBaseUrl + response.url);
    } catch (err) {
      if (err instanceof Error) await errorCallback(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="my-investments">
      <Pagination
        onLoad={loadData}
        count={myInvestments.count}
        totalCount={myInvestments.totalCount}
        filterObject={filter}
        initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
      >
        <Block>
          <Block.Content>
            <Block.Header>
              <div className="my-investments__download-container">
                <InvestmentCountContextProvider props={{fundraisingCount: myInvestments.totalCount}}>
                  <MyProjectsHeader invested={myInvestmentAmount} projectsCount={projectsCount}
                    setFilter={setFilter}/>
                </InvestmentCountContextProvider>
                <div onClick={exportStatistics} className="my-investments__download">
                  <CommonFilterIcon type={FilterIcon.Download}>
                    <DownloadSvg/>
                  </CommonFilterIcon>
                </div>
              </div>
            </Block.Header>
            <Block.Body withPadding={false} className="my-investments__block-body">
              <Pagination.Content loading={loading}>
                <ul className="my-investments__list">
                  {projects}
                </ul>
              </Pagination.Content>
            </Block.Body>
          </Block.Content>
        </Block>
        <div className="my-investments__paginator">
          <Pagination.Paginator/>
        </div>
      </Pagination>
    </div>
  );
};

export default MyInvestments;