import {useAppSelector} from "./store";
import {TransactionsTranslationsState} from "../store/transactionsTranslations";
import {TransactionType} from "../api-client";

type Translation = {
    readonly text: string;
    readonly description: string;
}

export const useTransactionsTranslation = () => {
  const current = useAppSelector<TransactionsTranslationsState>(state => state.transactionsTranslations).transactionsInfo;
  return (type: TransactionType): Translation => {
    const transactionInfo = current.filter((item) => item.type === type)[0];

    //Если инфо о транзакциях не успело\не смогло загрузится
    if (transactionInfo === undefined) {
      return {text: 'Ошибка', description: 'Ошибка'};
    }

    return {text: transactionInfo.text, description: transactionInfo.description};
  };
};