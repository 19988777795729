import React, {AllHTMLAttributes, FC, PropsWithChildren, useEffect, useRef} from "react";
import './Dropdown.scoped.scss';
import {useToggle} from "../../../../hooks";
import {DropdownContent, DropdownHead} from "./composables";
import DropdownContextProvider, {DropdownContextType} from "./composables/DropdownContext";
import {cn} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement>{}

type DropdownExtensions = {
    Head: typeof DropdownHead;
    Content: typeof DropdownContent;
}

const Dropdown: FC<PropsWithChildren<Props>> & DropdownExtensions = ({children, ...props}) => {
  const [active, toggle, setActive] = useToggle();
  const button = useRef(null);

  const value: DropdownContextType = {
    active,
    toggle
  };

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (event.target instanceof HTMLElement) {
        let element : HTMLElement | null = event.target;
        while (element && element !== button.current) {
          element = element.parentElement;
        }
        if (!element) {
          setActive(false);
        }
      }
    };
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <DropdownContextProvider props={value}>
      <div className={cn("dropdown", props.className)} ref={button}>
        { children }
      </div>
    </DropdownContextProvider>
  );
};

Dropdown.Head = DropdownHead;
Dropdown.Content = DropdownContent;

export default Dropdown;