import React, {FC, ReactNode} from "react";
import './IdentificationDateSelect.scoped.scss';
import {DatePicker} from "../../../common";
import {cn} from "../../../../utils";


interface Props {
    header: ReactNode;
    value: Date | null | undefined;
    onSelected: (date: Date) => void;
    direction?: 'column' | 'row'
}

const IdentificationDateSelect: FC<Props> = ({header, value, onSelected, direction = 'column'}) => {
  return (
    <div className={cn((direction === 'column')? "identification-date-select" : "identification-date-select__row")}>
      <div className="identification-date-select__label">
        { header }
      </div>
      <DatePicker value={value} onSelected={onSelected} maxDate={new Date()}/>
    </div>
  );
};

export default IdentificationDateSelect;