import {ApiClientInterface} from "./clients";
import {LenderInput} from "./types";
import {RegisteredUser} from "./models";

export class RegistrationManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public registerLender(lenderInput: LenderInput): Promise<RegisteredUser> {
    return this.apiClient.post('/lender', lenderInput);
  }
}