import React, {FC} from "react";
import {ChildrenProps} from "../../../utils";

interface Props extends ChildrenProps {}

const PaginationFilter: FC<Props> = ({children}) => {
  return (
    <>
      { children }
    </>
  );
};

export default PaginationFilter;