import React from "react";
import {BackButton, Button, ConfirmSmsCodeForm} from "../../common";
import {AuthInput} from "../../../views/auth/Enter/Enter";
import {PhoneVerificationResult} from "../../../api-client";
import {createVerificationManager} from "../../../di";
import {useNavigate} from "react-router-dom";

type Props = {
    input: AuthInput;
    onBack: () => void;
    onPhoneVerified: () => void;
}

const VerifyPhone = ({input, onBack, onPhoneVerified}: Props) => {
  const sendSmsCallback = async (smsCode: string): Promise<PhoneVerificationResult> => {
    const verificationManager = await createVerificationManager();
    return await verificationManager.verifyPhone(
      input.phone,
      smsCode
    );
  };

  const resendSmsCallback = async (): Promise<void> => {
    const verificationManager = await createVerificationManager();
    return await verificationManager.resendPhoneVerificationCode(
      input.phone
    );
  };

  const successCallback = async (response: PhoneVerificationResult): Promise<void> => {
    if (response.phoneVerified) {
      onPhoneVerified();
    }
  };
  const navigate = useNavigate();

  const closePage = () => {
    navigate(-1);
  };

  return (
    <>
      <div>
        <BackButton onClick={closePage}/>
        <ConfirmSmsCodeForm
          sendCallback={sendSmsCallback}
          resendCallback={resendSmsCallback}
          successCallback={successCallback}
          prefix={`confirmPhone-${input.phone.number}`}
          phone={input.phone}
        />
      </div>
    </>
  );
};

export default VerifyPhone;