import React, {FC, HTMLAttributes} from "react";
import './Flex.scoped.scss';
import {cn} from "../../../utils";

export enum JustifyContent {
    START = "justify-content-start",
    END = "justify-content-end",
    CENTER = "justify-content-center",
    BETWEEN = "justify-content-between",
    AROUND = "justify-content-around",
}

export enum AlignItems {
    CENTER = "align-items-center",
    START = "align-items-start",
    END = "align-items-end",
    STRETCH = "align-items-stretch",
}

export enum Direction {
    ROW = "flex-row",
    COLUMN = "flex-column",
    ROW_REVERSE = "flex-row-reverse",
    COLUMN_REVERSE = "flex-column-reverse",
}

export enum Wrap {
    WRAP = "flex-wrap",
    NOWRAP = "flex-nowrap"
}

interface Props extends HTMLAttributes<HTMLDivElement> {
    justifyContent?: JustifyContent | undefined;
    alignItems?: AlignItems | undefined;
    direction?: Direction | undefined;
    wrap?: Wrap | undefined;
    gap?: string | undefined;
}

const Flex: FC<Props> = ({justifyContent, alignItems, direction, wrap, children, gap, ...props}) => {
  return (
    <div
      {...props}
      style={{gap: gap, ...props.style}}
      className={cn(
        "d-flex",
        justifyContent,
        alignItems,
        direction,
        wrap,
        props.className
      )}>
      { children }
    </div>
  );
};

export default Flex;