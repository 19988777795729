import React, {ElementType} from "react";
import './Banner.scoped.scss';
import {ChildrenProps, cn} from "../../../utils";

export enum BannerType {
    INFO = "banner--info",
    ALERT = "banner--alert",
    WARNING = "banner--warning",
    QUESTION = "banner--question",
}

type Props<T extends ElementType> = {
    type: BannerType
} & ChildrenProps;

const Banner = <T extends ElementType = "div">({children, type, ...props}: Props<T>) => {
  return (
    <>
      <div className={cn("banner", `${type}`)} {...props}>
        { children }
      </div>
    </>
  );
};

export default Banner;