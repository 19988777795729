import {ApiClientInterface} from "./clients";
import {Filter as FundRaisingProjectsFilter} from "../components/pages/projects/filter/ProjectsFilter/ProjectsFilter";
import {Collection, SellingOffer} from "./models";
import buildQuery from "../utils/router/buildQuery";
import {DetailedOffer} from "./models/offer/DetailedOffer";

export class OfferProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getListSellingOffers(page: number, perPage: number, filter: FundRaisingProjectsFilter): Promise<Collection<SellingOffer>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/selling?${query}`);
  }

  public async getOne(uuid: string): Promise<DetailedOffer>
  {
    return this.apiClient.get(`/${uuid}`);
  }
}