import React, {AllHTMLAttributes, FC, PropsWithChildren, useEffect, useRef, useState} from "react";
import './Modal.scoped.scss';
import {useAppDispatch, useEffectOnUpdate, useOverlayState} from "../../../../hooks";
import {set} from "../../../../store/overlay";
import {cn} from "../../../../utils";
import {useLocation} from "react-router-dom";
import {createPortal} from "react-dom";
import ModalContextProvider, {ModalContextType} from "./ModalContext";
import {ModalClose} from "./composables";
import {CSSTransition} from "react-transition-group";

export interface ModalProps extends AllHTMLAttributes<HTMLDivElement> {
    active: boolean;
    onClose: () => void;
}

type ModalExtensions = {
    Close: typeof ModalClose;
}

const Modal: FC<PropsWithChildren<ModalProps>> & ModalExtensions = ({active, onClose, children, ...props}) => {
  const dispatch = useAppDispatch();
  const isOverlayActive = useOverlayState();
  const {pathname} = useLocation();
  const elRef = useRef<HTMLElement | null>(null);
  const [domReady, setDomReady] = useState(false);

  if (!elRef.current) {
    elRef.current = document.getElementById("modal-container");
  }

  const closeModal = () => {
    onClose();
  };

  useEffectOnUpdate(() => {
    if (!isOverlayActive && active) {
      closeModal();
    }
  }, [isOverlayActive]);

  useEffect(() => {
    dispatch(set(active));
  }, [active]);

  useEffectOnUpdate(() => {
    closeModal();
  }, [pathname]);

  const value: ModalContextType = {
    onClose: closeModal
  };

  useEffect(() => {
    setDomReady(true);
  }, []);

  return (
    <>
      { domReady && createPortal(<>
        <CSSTransition
          in={active}
          timeout={500}
          classNames="my-modal-"
          mountOnEnter
          unmountOnExit
        >
          <div {...props} className={cn("my-modal", props.className)}>
            <ModalContextProvider props={value}>
              <div className="my-modal__content">
                { children }
              </div>
            </ModalContextProvider>
          </div>
        </CSSTransition>
      </>, elRef.current!) }
    </>
  );
};

Modal.Close = ModalClose;

export default Modal;