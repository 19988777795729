import React, {FC, PropsWithChildren, useContext} from "react";
import './PaginationContent.scoped.scss';
import {PaginationContext} from "../PaginationContext";

interface Props {
    loading: boolean;
}

const PaginationContent: FC<PropsWithChildren<Props>> = ({loading, children}) => {
  const context = useContext(PaginationContext);
  if (loading) {
    return (
      <div className="pagination-content">Загрузка</div>
    );
  }
  return (
    <>
      { context.count !== 0 ? children : <div className="pagination-content">Нет данных</div> }
    </>
  );
};

export default PaginationContent;