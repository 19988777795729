import styles from './AutoinvestDocuments.module.scss';
import {Pagination} from "../../../../../../components";
import React, {useState} from "react";
import {DataCollection} from "../../../../../../utils";
import {createAgreementProvider} from "../../../../../../di";
import {Agreement} from "../../../../../../api-client/models/documents/Agreement";
import {useErrorCallback} from "../../../../../../hooks";
import {AutoInvestAgreement} from "../../../../../../api-client";
import {AutoinvestDocumentsItem} from "../DocumentsElements";
import {FilterDocuments} from "../FilterDocuments";
import {Filter} from "../FilterDocuments/FilterDocuments";
import FilterIcon from "../../../../../../components/filters/FilterIcon";
import {defaultFilter} from "../DocumentsToProject/DocumentsToProject";


const AutoinvestDocuments = () => {
  const [active, setActive] = useState(false);
  const [filter, setFilter] = useState<Filter>(defaultFilter);
  const [loading, setLoading] = useState(false);
  const [autoInvestAgreement, setAutoInvestAgreement] =
      useState<DataCollection<AutoInvestAgreement>>(new DataCollection());
  const handleError = useErrorCallback();


  const loadData = async (page: number = 1, perPage: number = 10) => {
    setLoading(true);
    try {
      const provider = await createAgreementProvider();
      const results = await provider.getAutoInvestAgreements(page, perPage, filter);
      const newCollection = new DataCollection<AutoInvestAgreement>();
      newCollection.setCollection(results);
      setAutoInvestAgreement(newCollection);

    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const renderedDocuments = (documents:AutoInvestAgreement[]) => {
    return <>{documents.map((document: Agreement) => {
      return <AutoinvestDocumentsItem document={document} key={document.uuid}/>;
    })}
    </>;
  };

  const updateFilter = (filter: Filter): void => {
    setFilter({...filter});
  };

  return (<div className={styles.documents}>
    <Pagination
      onLoad={loadData}
      count={autoInvestAgreement.count}
      totalCount={autoInvestAgreement.totalCount}
      filterObject={filter}
      initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
    >
      <div className={styles.documents__filtersContainer}>
        <div onClick={() => setActive((prevState) => !prevState)}><FilterIcon/></div>
      </div>
      {active &&
        <FilterDocuments filter={filter}
          onChange={updateFilter} isDocumentToProject={false}/>
      }
      <div className={styles.documents__container}>
        <Pagination.Content loading={loading}>
          {renderedDocuments(autoInvestAgreement.items)}
        </Pagination.Content>
      </div>
      <Pagination.Paginator/>
    </Pagination>
  </div>
  );
};

export default AutoinvestDocuments;