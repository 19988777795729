import React, {FC} from "react";
import Modal from "../Modal";
import {ModalProps} from "../Modal/Modal";
import {NotificationsReceiveOptions} from "../../../pages/notifications";

const NotificationModal: FC<ModalProps> = ({active, onClose}) => {
  return (
    <Modal active={active} onClose={onClose}>
      <NotificationsReceiveOptions modalActive={active}/>
    </Modal>
  );
};

export default NotificationModal;