export enum TransactionType {
    DEPOSIT = "deposit",
    INVESTMENT_REFUND = "investment_refund",
    INVESTMENT_CANCEL = "investment_cancel",
    INVESTMENT_FEE_CANCEL = "investment_fee_cancel",
    LOAN = "loan",
    DIVIDEND_PRINCIPAL = "dividend_principal",
    DIVIDEND_REFUND = "dividend_refund",
    DIVIDEND_REFUND_FEE = "dividend_refund_fee",
    DIVIDEND_INTEREST = "dividend_interest",
    DIVIDEND_PENALTY = "dividend_penalty",
    DIVIDEND_COMPENSATION = "dividend_compensation",
    GUARANTOR_INTEREST = "guarantor_interest",
    GUARANTOR_COMPENSATION = "guarantor_compensation",
    GUARANTOR_PENALTY = "guarantor_penalty",
    SALE = "sale",

    // DEBIT TYPES
    WITHDRAW = "withdraw",
    INVESTMENT = "investment",
    REPAYMENT_REFUND = "repayment_refund",
    REPAYMENT_REFUND_FEE = "repayment_refund_fee",
    REPAYMENT_PRINCIPAL = "repayment_principal",
    REPAYMENT_INTEREST = "repayment_interest",
    REPAYMENT_PENALTY = "repayment_penalty",
    REPAYMENT_COMPENSATION = "repayment_compensation",
    PURCHASE = "purchase",

    // FEE (DEBIT) TYPES
    LOAN_FEE = "loan_fee",
    PURCHASE_FEE = "purchase_fee",
    SALE_FEE = "sale_fee",
    DIVIDEND_INTEREST_FEE = "dividend_interest_fee",
    DIVIDEND_PENALTY_FEE = "dividend_penalty_fee",
    DIVIDEND_COMPENSATION_FEE = "dividend_compensation_fee",
    GUARANTOR_INTEREST_FEE = "guarantor_interest_fee",
    GUARANTOR_COMPENSATION_FEE = "guarantor_compensation_fee",
    GUARANTOR_PENALTY_FEE = "guarantor_penalty_fee",
    PROJECT_MANAGEMENT_FEE = "guarantor_management_fee",
    WITHDRAW_FEE = "withdraw_fee",
    INVESTMENT_FEE = "investment_fee"
}

type TransactionTypeRecord = Record<TransactionType, string>

export const TransactionTypeMapped: TransactionTypeRecord = {
  [TransactionType.WITHDRAW]: "Снятие денег",
  [TransactionType.WITHDRAW_FEE]: "Комиссия за снятие",
  [TransactionType.INVESTMENT_FEE]: "Комиссия за инвестицию",
  [TransactionType.DEPOSIT]: "Внесение денег",
  [TransactionType.INVESTMENT]: "Инвестирование в проект",
  [TransactionType.INVESTMENT_CANCEL]: "Отмена инвестирования",
  [TransactionType.INVESTMENT_FEE_CANCEL]: "Отмена комиссииза инвестирование",
  [TransactionType.INVESTMENT_REFUND]: "Возврат инвестиции",
  [TransactionType.LOAN]: "Выдача займа",
  [TransactionType.LOAN_FEE]: "Комиссия за публикацию проекта",
  [TransactionType.SALE]: "Продажа на вторичном рынке",
  [TransactionType.SALE_FEE]: "Продажа на вторичном рынке",
  [TransactionType.DIVIDEND_PRINCIPAL]: "Выплата дивидендов",
  [TransactionType.DIVIDEND_REFUND]: "Возврат инвестиции",
  [TransactionType.DIVIDEND_REFUND_FEE]: "Возврат комиссии за инвестицию",
  [TransactionType.DIVIDEND_INTEREST]: "Выплата дивидендов",
  [TransactionType.DIVIDEND_INTEREST_FEE]: "Комиссия за выплату дивидендов",
  [TransactionType.DIVIDEND_COMPENSATION]: "Компенсация за просроченный платеж",
  [TransactionType.DIVIDEND_COMPENSATION_FEE]: "Комиссия с компенсации за просроченный платеж",
  [TransactionType.DIVIDEND_PENALTY]: "Штраф за просроченный платеж",
  [TransactionType.DIVIDEND_PENALTY_FEE]: "Комисиия с штрафа за просроченный платеж",
  [TransactionType.GUARANTOR_INTEREST]: "Выплата дивидендов",
  [TransactionType.GUARANTOR_INTEREST_FEE]: "Комиссия за выплату дивидендов",
  [TransactionType.GUARANTOR_COMPENSATION]: "Компенсация за просроченный платеж",
  [TransactionType.GUARANTOR_COMPENSATION_FEE]: "Комиссия с компенсации за просроченный платеж",
  [TransactionType.GUARANTOR_PENALTY]: "Комиссия за выплату дивидендов",
  [TransactionType.GUARANTOR_PENALTY_FEE]: "Комисиия с штрафа за просроченный платеж",
  [TransactionType.REPAYMENT_PRINCIPAL]: "Выплата дивидендов",
  [TransactionType.REPAYMENT_REFUND]: "Возврат инвестиции",
  [TransactionType.REPAYMENT_REFUND_FEE]: "Возврат комиссии",
  [TransactionType.REPAYMENT_INTEREST]: "Выплата дивидендов",
  [TransactionType.REPAYMENT_PENALTY]: "Выплата дивидендов",
  [TransactionType.REPAYMENT_COMPENSATION]: "Выплата дивидендов",
  [TransactionType.PURCHASE]: "Покупка на вторичном рынке",
  [TransactionType.PURCHASE_FEE]: "Комиссия с покупки на вторичном рынке",
  [TransactionType.PROJECT_MANAGEMENT_FEE]: "Комисиия за управление займом",
};

export const translateTransactionType = (type: TransactionType): string => {
  return TransactionTypeMapped[type];
};