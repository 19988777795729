import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {WithdrawManager} from "../WithdrawManager";

export class WithdrawManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/withdraw`,
      headers
    );
  }

  public createWithdrawManager(token: string): WithdrawManager {
    return new WithdrawManager(this.apiClientFactory.createAuthorizedClient(token));
  }
}