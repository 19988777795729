import React, {FC, ImgHTMLAttributes} from "react";
import telegramImg from '../../../../assets/images/svg/telegram.svg';
import Image from "../Image";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {}

const TelegramImage: FC<Props> = ({...props}) => {
  return (
    <Image {...props} src={telegramImg} alt="Telegram" />
  );
};

export default TelegramImage;