import React from 'react';
import {ChildrenProps, cn} from "../../../../../utils";
import './PrimaryButton.scoped.scss';
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

export enum PrimaryButtonColor {
    GREEN = "primary-button--green",
    BLUE = "primary-button--blue",
    GRAY = "primary-button--gray"
}

interface Props extends ChildrenProps {
    color: PrimaryButtonColor;
    expanded?: boolean;
    large?: boolean;
    disabled?: boolean;
}

const PrimaryButton: ClassNameDecorator<Props> = (
  {
    color,
    children,
    expanded,
    large,
    className
  }
) => {
  return (
    <ClassInjector classNames={
      cn(
        "primary-button",
        `${color}`,
        `${expanded ? "primary-button--expanded" : ""}`,
        `${large ? "primary-button--large" : ""}`,
      )
    } oldClassName={className}>
      { children }
    </ClassInjector>
  );
};

export default PrimaryButton;