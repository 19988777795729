import React, {FC, SyntheticEvent, useState} from "react";
import {ConfirmSmsCodeForm, InputErrors, InputPassword, Modal, PrimaryButton} from "../../../index";
import {Errors as ValidationErrors, sleep} from "../../../../utils";
import './ChangePasswordModal.scoped.scss';
import {useEffectOnUpdate, useErrorCallback, useNotifier,} from "../../../../hooks";
import {createChangePasswordManager} from "../../../../di";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {ModalProps} from "../Modal/Modal";

type FormErrors = {
    oldPass?: ValidationErrors;
    newPass?: ValidationErrors;
    confirmNewPass?: ValidationErrors;
}

const ChangePasswordModal: FC<ModalProps> = ({active, onClose}) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [confirmationUuid, setConfirmationUuid] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const notifier = useNotifier();
  const handleError = useErrorCallback();

  useEffectOnUpdate(() => {
    validateForm();
  }, [oldPass, newPass, confirmNewPass]);

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (oldPass === "") {
      errors.oldPass = ['Укажите ранее используемый пароль'];
    }
    if (newPass === "") {
      errors.newPass = ['Укажите новый пароль'];
    }
    if (confirmNewPass === "") {
      errors.confirmNewPass = ['Подтвердите новый пароль'];
    } else if (newPass !== confirmNewPass) {
      errors.confirmNewPass = ['Пароли должны совпадать'];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    if (active) {
      const callback = async () => {
        const manager = await createChangePasswordManager();
        const result = await manager.request();
        setConfirmationUuid(result.uuid);
      };
      (async () => {
        setLoading(true);
        try {
          await callback();
        } catch (err: any) {
          await handleError(err);
          setError(true);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [active]);

  const sendSms = async (smsCode: string) => {
    try {
      const manager = await createChangePasswordManager();
      await manager.confirm(confirmationUuid, smsCode);
    } catch (error: any) {
      await handleError(error);
      setError(true);
    }
  };

  const resendSms = async () => {
    //TODO: ADD RESENDING SMS
    try {
      await sleep(3000);
    } catch (error: any) {
      await handleError(error);
    }
  };

  const successCallback = () => {
    setIsConfirmed(true);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const changePasswordManager = await createChangePasswordManager();
        await changePasswordManager.changePassword(confirmationUuid, oldPass, newPass);
        notifier.createSuccess("Ваш пароль был изменен");
        onClose();
      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal active={active} onClose={onClose}>
      <Modal.Close />
      { (isConfirmed && !error) ? <div>
        <div>
          Изменить пароль
        </div>
        { loading && "Загрузка" }
        <div className="change-password-modal">
          <form className="form" onSubmit={handleSubmit}>
            <div className="form__group">
              <label htmlFor="oldPass" className="form__label">
                Старый пароль
              </label>
              <InputPassword
                password={oldPass}
                onInput={setOldPass}
                title="Старый пароль"
                id="oldPass"
              />
              { errors.oldPass && <InputErrors errors={errors.oldPass} /> }
            </div>
            <div className="form__group">
              <label htmlFor="newPass" className="form__label">
                Новый пароль
              </label>
              <InputPassword
                password={newPass}
                onInput={setNewPass}
                title={"Новый пароль"}
                id={"newPass"}
              />
              { errors.newPass && <InputErrors errors={errors.newPass} /> }
            </div>
            <div className="form__group">
              <label htmlFor="confirmNewPass" className="form__label">
                Подтверждение пароля
              </label>
              <InputPassword
                password={confirmNewPass}
                onInput={setConfirmNewPass}
                title={"Подтвердите пароль"}
                id={"confirmNewPass"}
              />
              { errors.confirmNewPass && <InputErrors errors={errors.confirmNewPass} /> }
            </div>
            <div className="form__group">
              <PrimaryButton color={PrimaryButtonColor.GREEN}>
                <button>
                  Отправить
                </button>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </div> : <div>
        {error && <InputErrors errors={['Произошла ошибка при отправке формы']}/>}
        <ConfirmSmsCodeForm
          sendCallback={sendSms}
          resendCallback={resendSms}
          successCallback={successCallback}
          prefix={`changePassword-${confirmationUuid}`}
        />
      </div> }
    </Modal>
  );
};

export default ChangePasswordModal;