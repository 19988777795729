import React, {FC} from "react";
import './FavoriteSvg.scoped.scss';
import {cn, resultIf} from "../../../utils";

interface Props {
    active: boolean
    width?: number | undefined;
    height?: number | undefined
}

const FavoriteSvg: FC<Props> = ({active, width = 12, height= 16}) => {
  return (
    <svg
      className={cn("favorite-svg", resultIf(active, "active"))}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 12 16"
    >
      <g id="Сгруппировать_12469" data-name="Сгруппировать 12469" transform="translate(4.269 1.387)">
        <path id="Контур_22628" data-name="Контур 22628" d="M0,0H12V16L6,12,0,16Z" transform="translate(-4.269 -1.387)" fill="#9299a2"/>
      </g>
    </svg>
  );
};

export default FavoriteSvg;