import {Outlet} from "react-router-dom";
import React from "react";
import MyDocuments from "./MyDocuments";

export const MyDocumentsLayout = () => {
  return (
    <MyDocuments>
      <Outlet/>
    </MyDocuments>
  );
};