import React, {FC} from "react";
import {ProfileInput} from "../index";
import './ProfileCredentialInput.scoped.scss';
import {ChildrenProps} from "../../../../utils";

interface Props extends ChildrenProps {}

const ProfileCredentialTitle: FC<Props> = ({children}) => {
  return (
    <span className="profile-credential-title">
      { children }
    </span>
  );
};

type ProfileCredentialsExtensions = {
    Title: typeof ProfileCredentialTitle;
}

const ProfileCredentialInput: FC<Props> & ProfileCredentialsExtensions = ({children}) => {
  return (
    <ProfileInput>
      <div className="profile-credential-input">
        { children }
      </div>
    </ProfileInput>
  );
};

ProfileCredentialInput.Title = ProfileCredentialTitle;

export default ProfileCredentialInput;