import React, {useMemo, useState} from "react";
import {
  Block,
  Button,
  ColoredText,
  CopyToClipboard,
  ProfileNavigation,
  TertiaryButton, ReferralBlock, ReferralUserList, ReferralTransactions
} from "../../../../../components";
import {TextColor} from "../../../../../components/common/typography/texts/ColoredText/ColoredText";
import {TertiaryButtonColor} from "../../../../../components/common/buttons/decorators/TertiaryButton/TertiaryButton";
import './ReferralProgram.scoped.scss';
import {cn, resultIf} from "../../../../../utils";
import {useMountAnimation} from "../../../../../components/pages/detailed-project/hooks";
import {useProfileData} from "../../../../../hooks";
import apiClientConfig from 'configs/app';

const ReferralProgram = () => {
  const mounted = useMountAnimation();
  const [isRequested, requestCode] = useState(false);
  const profileData = useProfileData();
  const referralLink = useMemo(() => {
    return `${apiClientConfig.apiBaseUrl}/?r=${profileData.paymentCode}`;}, []);
  return (
    <div className={cn("referral", resultIf(mounted, "mounted"))}>
      <div>
        <Block>
          <Block.Content>
            <Block.Header>
              <ProfileNavigation />
            </Block.Header>
            {
              !isRequested &&
                <Block.Body className="referral-program" withPadding>
                  <div className="referral-program__info">
                    <span>
                      Запросив индивидуальный реферальный код,
                    </span>
                    <span>
                      вы заключаете <ColoredText color={TextColor.PRIMARY_GREEN}>
                        <a href="#">Договор Реферальной программы</a>
                      </ColoredText>
                    </span>
                  </div>
                  <TertiaryButton expanded large color={TertiaryButtonColor.GREEN}>
                    <Button className="referral-program__button"
                      onClick={() => requestCode((isRequested) => {
                        return !isRequested;
                      })}>
                      Запросить реферальный код
                    </Button>
                  </TertiaryButton>
                </Block.Body>
            }
            {
              isRequested &&
                <Block.Body className="referral-program" withPadding>
                  <div className="referral-program__code">
                    <span>Ваш реферальный код:</span>
                    <div>{profileData.paymentCode}</div>
                  </div>
                  <div className="referral-program__link-container">
                    <div>
                      <div className="referral-program__heading">Реферальная ссылка</div>
                      <div>{ referralLink }</div>
                    </div>
                    <CopyToClipboard textToCopy={referralLink} withDescription />
                  </div>
                </Block.Body>
            }
          </Block.Content>
        </Block>
      </div>
      <ReferralBlock />
      <ReferralUserList />
      <ReferralTransactions />
    </div>
  );
};

export default ReferralProgram;
