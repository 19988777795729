import React, {AllHTMLAttributes, FC, ReactNode} from "react";
import {cn, resultIf} from "../../../../utils";
import './BlockBody.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    withPadding?: boolean | undefined;
    children: ReactNode | undefined;
}

const BlockBody: FC<Props> = ({withPadding = true, children, ...props}) => {
  return (
    <div
      {...props}
      className={cn(
        "block-body",
        resultIf(withPadding, "block-body--padding"),
        props.className
      )}
    >
      { children }
    </div>
  );
};

export default BlockBody;