import React, {useMemo, useState} from "react";
import './Orders.scoped.scss';
import {useErrorCallback} from "../../../../../hooks";
import {Money, PreviewOrder} from "../../../../../api-client";
import {createOrdersProvider} from "../../../../../di";
import {Pagination} from "../../../../pagination";
import {MyOrder} from "../index";
import {Block} from "../../../../layout";
import InvestmentCountContextProvider from "../../InvestmentCountContext";
//This component isn't used
const Orders = () => {
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [myOrders, setMyOrders] = useState<Array<PreviewOrder>>([]);

  const loadData = async (): Promise<void> => {
    setLoading(true);
    try {
      const provider = await createOrdersProvider();
      const orders = await provider.getList();
      setMyOrders(orders);
    } catch (error) {
      if (error instanceof Error) {
        await errorCallback(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const orders = useMemo(() => {
    return myOrders.map((item: PreviewOrder, index) => {
      return <MyOrder order={item} key={index} />;
    });
  }, [myOrders]);

  const myOrdersAmount = useMemo(() => {
    const invested = myOrders.reduce((prev: any, cur: any) => {
      return prev + Number(cur.investment.amount);
    }, 0);
    return new Money(invested, "RUB");
  }, [myOrders]);


  return (
    <div className="order-projects">
      <Pagination
        onLoad={loadData}
        count={myOrders.length}
        totalCount={myOrders.length}
      >
        <Block>
          <Block.Content>
            <Block.Header>
              <InvestmentCountContextProvider props={{ordersCount:myOrders.length}}>
                {/*<MyProjectsHeader invested={myOrdersAmount}/>*/}
              </InvestmentCountContextProvider>
            </Block.Header>
            <Block.Body withPadding={false} className="my-projects__block-body">
              <Pagination.Content loading={loading}>
                <Pagination.Header className="order-projects-header">
                  <div className="order-projects-header__item">
                    Проект
                  </div>
                  <div className="order-projects-header__item order-projects-header__category">
                    Категория
                  </div>
                  <div className="order-projects-header__item order-projects-header__sum">
                    Размер инвестиции
                  </div>
                  <div className="order-projects-header__item order-projects-header__forecast">
                    Прогноз взыскания
                  </div>
                  <div className="order-projects-header__item">
                    Срок
                  </div>
                  <div className="order-projects-header__item order-projects-header__simple">
                    Доходность простая
                  </div>
                  <div className="order-projects-header__item order-projects-header__yearly">
                    Доходность годовая
                  </div>
                  <div className="order-projects-header__item order-projects-header__collected">
                    Собрано
                  </div>
                  <div className="order-projects-header__item order-projects-header__date">
                    Сбор до
                  </div>
                  <div className="order-projects-header__item">
                    Действия
                  </div>
                  <div className="order-projects-header__item">
                    Статус заявки
                  </div>
                </Pagination.Header>
                <ul className="order-projects__list">
                  { orders }
                </ul>
              </Pagination.Content>
            </Block.Body>
          </Block.Content>
        </Block>
        <div className="order-projects__paginator">
          <Pagination.Paginator />
        </div>
      </Pagination>
    </div>
  );
};

export default Orders;