
export enum LegalDocumentType {
    AGENT = "AGENT",
    AGENT_CHANGE = "AGENT_CHANGE",
    COMMON = "COMMON"
}

type LegalDocumentTypeRecord = Record<LegalDocumentType, string>;

export const LegalDocumentMapped: LegalDocumentTypeRecord = {
  [LegalDocumentType.COMMON]: "",
  [LegalDocumentType.AGENT]: "Агентский договор",
  [LegalDocumentType.AGENT_CHANGE]: "Уведомление об изменении агентского договора"
};

export const translateLegalDocumentType = (type: LegalDocumentType): string => {
  return LegalDocumentMapped[type];
};