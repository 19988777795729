import React from "react";
import {CustomLink} from "../../../links";
import './NotificationLink.scoped.scss';
import {RouteDictionary} from "../../../../../utils";
import {NotificationsSvg} from "../../../../svg";
import {matchPath, useLocation} from "react-router-dom";
import {NotificationSvgColor} from "../../../../svg/NotificationsSvg";

const NotificationLink = () => {
  const {pathname} = useLocation();
  const isActive = matchPath('dashboard/notifications', pathname) !== null;
  return (
    <CustomLink to={RouteDictionary.NOTIFICATIONS} className="notifications-link">
      <NotificationsSvg color={isActive ? NotificationSvgColor.BLACK : NotificationSvgColor.GREEN} />
    </CustomLink>
  );
};

export default NotificationLink;