import React from "react";
import {LenderType, LenderTypeMapped} from "../../../api-client";
import {OptionBox} from "../../common";
import {Nullable} from "../../../utils";

type Props = {
    onChange: (selected: LenderType) => void;
    selectedType: Nullable<LenderType>;
    placeholder?: string;
}

type LenderTypeOption = {
    type: keyof typeof LenderType;
    title: string;
}

const options: Array<LenderTypeOption> = Object.keys(LenderType).map((lenderType): LenderTypeOption => {
  const current = lenderType as keyof typeof LenderType;
  return {
    type: current,
    title: LenderTypeMapped[current]
  };
});

const LenderTypeSelect = ({onChange, selectedType}: Props) => {
  const selectedOption = () => {
    return selectedType !== null ? options.filter((option) => {
      return option.type === selectedType;
    })[0] : null;
  };
  const selectOption = (option: LenderTypeOption): void => {
    onChange(option.type as LenderType);
  };
  return (
    <OptionBox
      options={options}
      selectedOption={selectedOption()}
      renderOption={(option) => option.title}
    />
  );
};

export default LenderTypeSelect;