import {ApiClientInterface} from "./clients";
import {NotificationSettings} from "./models";

export class NotificationSettingsManager {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public getNotifications(): Promise<NotificationSettings> {
    return this.apiClient.get("");
  }

  public toggleSmsNotification(enabled: boolean): Promise<void> {
    return this.apiClient.post("toggle-sms-notifications", {enabled});
  }

}