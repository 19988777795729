import React, {FC, PropsWithChildren, ReactNode, useContext} from "react";
import {BlockDropdown} from "../../../layout";
import './ProjectDropdown.scoped.scss';
import DetailedProjectNavigation from "../DetailedProjectNavigation";
import {cn, resultIf} from "../../../../utils";
import {
  SecondaryProjectContext
} from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectContext/SecondaryProjectContext";
import SecondaryProjectNavigation
  from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectNavigation";

interface Props {
    header?: ReactNode;
}

const ProjectDropdown: FC<PropsWithChildren<Props>> = ({header, children}) => {
  const offer = !!useContext(SecondaryProjectContext);
  const blockHeader = (): ReactNode => {
    if (header !== undefined) {
      return header;
    }
    if (offer) {
      return <SecondaryProjectNavigation/>;
    }
    return <DetailedProjectNavigation />;
  };
  return (
    <BlockDropdown
      header={blockHeader()}
      showToggle={header !== undefined}
      headerClassname={cn(
        resultIf(header === undefined, "detailed-project__header")
      )}
    >
      { children }
    </BlockDropdown>
  );
};

export default ProjectDropdown;