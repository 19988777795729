import {ApiClientInterface} from "./clients";
import {Money} from "./models";

export class OrdersManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public createOrder(projectUuid: string,investment: Money): Promise<string> {
    return this.apiClient.post("", {projectUuid, investment});
  }

}