import React, {createContext, PropsWithChildren} from "react";

export type DropdownContextType = {
    active: boolean;
    toggle: () => void;
}

type Props = {
    props: DropdownContextType
}

export const DropdownContext = createContext<DropdownContextType>({
  active: false,
  toggle: () => {}
});

function DropdownContextProvider<T>(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <DropdownContext.Provider value={value.props}>
    { children }
  </DropdownContext.Provider>;
}

export default DropdownContextProvider;