export enum UserGender {
    MALE = "MALE",
    FEMALE = "FEMALE"
}

type UserGenderRecord = Record<UserGender, string>;

export const UserGenderMapped: UserGenderRecord = {
  [UserGender.MALE]: "Мужской",
  [UserGender.FEMALE]: "Женский"
};

export const translateUserGender = (gender: UserGender): string => {
  return UserGenderMapped[gender];
};