import React, {useEffect, useMemo, useState} from "react";
import {
  Block,
  Container,
  Heading,
  Pagination,
  PrimaryHeading,
  Section,
  Transaction,
  TransactionDirectionSelect,
  TransactionsPartialFilter
} from "../../../../../components";
import './Transactions.scoped.scss';
import {useErrorCallback, useTransactionsTranslations} from "../../../../../hooks";
import {DataCollection} from "../../../../../utils";
import {
  Collection,
  Transaction as TransactionModel,
  TransactionDirection,
  TransactionProject,
} from "../../../../../api-client";
import {createTransactionProjectProvider, createTransactionProvider} from "../../../../../di";
import {Filter} from "../../../../../components/pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import TransactionsProjectSelect from "../../../../../components/pages/transactions/TransactionsProjectSelect";
import TransactionDateRangeCalendarSelect
  from "../../../../../components/pages/transactions/TransactionDateRangeCalendarSelect";

const Transactions = () => {
  const errorCallback = useErrorCallback();
  const [projects, setProjects] = useState<DataCollection<TransactionProject>>(new DataCollection());
  const [loading, setLoading] = useState(false);
  const transactionsTranslations = useTransactionsTranslations();
  const [transactions, setTransactions] =
        useState<DataCollection<TransactionModel>>(new DataCollection());
  const handleError = useErrorCallback();


  const defaultFilter: Filter = {
    search: "",
    dateFrom: "",
    dateTo: "",
    transactionDirection: null,
    projectUuids: []
  };

  const allProjectsOption: TransactionProject = {
    code: "-",
    uuid: "-",
    projectName: "Все проекты"
  };

  const [filter, setFilter] = useState<Filter>(defaultFilter);

  useEffect(() => {
    const onMount = async () => {
      try {
        const projectProvider = await createTransactionProjectProvider();
        const projects = await projectProvider.getProjects();
        const projectCollection = new DataCollection<TransactionProject>();
        projectCollection.setCollection(new Collection([allProjectsOption].concat(projects.items), projects.count, projects.totalCount));
        setProjects(projectCollection);
      } catch (error) {
        if (error instanceof Error) {
          await handleError(error);
        }
      }
    };
    (async () => {
      await onMount();
    })();
  }, []);

  const updateFilterProjects = (newProjects: Array<TransactionProject>): void => {
    if (newProjects.map(item => item.uuid).includes(allProjectsOption.uuid)) {
      setFilter({...filter, projectUuids: []});
      return;
    }
    setFilter({...filter, projectUuids: newProjects.map(item => item.uuid)});
  };

  const updateFilterTransactionDirection = (filter: Filter): void => {
    if (filter.transactionDirection === TransactionDirection.ALL) {
      setFilter({...filter, transactionDirection: null});
    } else {
      setFilter({...filter, transactionDirection: filter.transactionDirection});
    }
  };
  const selectedProjects = (): Array<TransactionProject> => {
    return projects.items.filter(item => filter.projectUuids.includes(item.uuid));
  };

  const loadData = async (page: number, perPage: number, filter: Filter) => {
    setLoading(true);
    try {
      const provider = await createTransactionProvider();
      const transactions = await provider.getList(page, perPage, filter);
      const dataCollection = new DataCollection<TransactionModel>();
      dataCollection.setCollection(transactions);
      setTransactions(dataCollection);

    } catch (err: any) {
      await errorCallback(err);
    } finally {
      setLoading(false);
    }
  };

  const transactionsList = useMemo(() => {
    return transactions.items.map((transaction) => {
      return <Transaction transaction={transaction} key={transaction.uuid}/>;
    });
  }, [transactions]);

  return (
    <Section>
      <Container>
        <div className="transactions-filter__header">
          <PrimaryHeading>
            <Heading headingType={HeadingType.H1}>
              История платежей
            </Heading>
          </PrimaryHeading>
        </div>
        <Pagination
          onLoad={loadData}
          count={transactions.count}
          totalCount={transactions.totalCount}
          filterObject={filter}
          initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
        >
          <Block>
            <Block.Content>
              <Block.Header withPadding>
                <Pagination.Filter>
                  <TransactionsPartialFilter
                    filter={filter}
                    onChange={updateFilterTransactionDirection}
                  />
                </Pagination.Filter>
              </Block.Header>
              <Block.Body className="transactions-body" withPadding={false}>
                <Pagination.Header className="transactions-header">
                  <div className="transactions-header__item">
                    <TransactionsProjectSelect
                      projects={projects.items}
                      selectedProjects={selectedProjects()}
                      onSelected={updateFilterProjects}
                    />
                  </div>
                  <div className="transactions-header__item">
                    ID инвестиции
                  </div>
                  <div className="transactions-header__item">
                    <TransactionDateRangeCalendarSelect
                      filter={filter}
                      onChange={updateFilterTransactionDirection}/>
                  </div>
                  <div className="transactions-header__item">
                    <TransactionDirectionSelect
                      onChange={(value: TransactionDirection) =>
                        updateFilterTransactionDirection({
                          ...filter,
                          transactionDirection: value
                        })}
                      selectedDirection={filter.transactionDirection}
                    />
                  </div>
                  <div className="transactions-header__item">

                  </div>
                  <div className="transactions-header__item">
                    Сумма платежа
                  </div>
                  <div className="transactions-header__item">
                    Пояснение
                  </div>
                </Pagination.Header>
                <Pagination.Content loading={loading}>
                  {!transactionsTranslations.loading && <ul className="transactions__list">
                    {transactionsList}
                  </ul>}
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator/>
        </Pagination>
      </Container>
    </Section>
  );
};

export default Transactions;