import React, {FC} from "react";
import Modal from "../Modal";
import {createOfferSaleManager} from "../../../../di";
import {RouteDictionary, sleep} from "../../../../utils";
import {subtractBalance} from "../../../../store/profile";
import {useAppDispatch, useNavigateByName} from "../../../../hooks";
import {ConfirmSmsCodeForm} from "../../utils";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";
import {set} from "../../../../store/overlay";
import {OfferSaleConfirmation} from "../../../../api-client/models/offer/OfferConfirmation";
import {OfferConfirmationResult} from "../../../../api-client/models/offer/OfferConfirmationResult";

//TODO: REWORK TO ONE UUID
interface Props extends ModalProps {
    offerSaleConfirmation: OfferSaleConfirmation;
}

const OfferConfirmationModal: FC<Props> = ({active, onClose, offerSaleConfirmation}) => {
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  const sendCode = async (smsCode: string): Promise<OfferConfirmationResult> => {
    const manager = await createOfferSaleManager();
    return await manager.createOffer(offerSaleConfirmation.confirmationUuid, smsCode);
  };

  const resendCode = async (): Promise<void> => {
    await sleep(3000);
  };

  const onSuccess = async (): Promise<void> => {
    dispatch(subtractBalance(offerSaleConfirmation.money.amount));
    //FIX: OVERLAY IS NOT CLOSED WHEN MODEL IS, ADD MESSAGE THAT INVESTMENT IS CONFIRMED
    dispatch(set(false));
    navigate(RouteDictionary.MY_PROJECTS);
  };
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение" onClose={onClose}>
        <ConfirmSmsCodeForm
          sendCallback={sendCode}
          resendCallback={resendCode}
          successCallback={onSuccess}
          prefix="offer-conf"
        />
      </SmsConfirmation>
    </Modal>
  );
};

export default OfferConfirmationModal;