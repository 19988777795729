import React from "react";
import {ChildrenProps} from "../../../utils";
import './Container.scoped.scss';

type Props = ChildrenProps;

const Container = ({children}: Props) => {
  return (
    <div className="my-container">
      { children }
    </div>
  );
};

export default Container;