import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {NotificationManager} from "../NotificationManager";

export class NotificationManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers = {}) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/notification`,
      headers
    );
  }

  createNotificationManager(token: string): NotificationManager {
    return new NotificationManager(this.apiClientFactory.createAuthorizedClient(token));
  }
    
}