import React, {FC} from "react";
import './TransactionDirectionSelect.scoped.scss';
import {TransactionDirection, transactionDirectionMapped} from "../../../../api-client";
import {cn, Nullable, resultIf} from "../../../../utils";
import {Image, OptionBox} from "../../../common";
import {OptionBoxContext} from "../../../common/selects/OptionBox/OptionBoxContext";
import arrowDown from "../../../../assets/images/svg/history-arrow.svg";
import {Flex} from "../../../layout";
import {AlignItems} from "../../../layout/Flex/Flex";

interface Props {
    onChange: (selected: TransactionDirection) => void;
    selectedDirection: Nullable<TransactionDirection>;
}

type TransactionDirectionOption = {
    type: keyof typeof TransactionDirection;
    title: string;
}

const options: Array<TransactionDirectionOption> = Object.entries(transactionDirectionMapped)
  .map(([key, value]): TransactionDirectionOption => {
    return {
      type: key as keyof typeof TransactionDirection,
      title: value
    };
  });

const TransactionDirectionSelect: FC<Props> = ({onChange, selectedDirection}) => {
  const selectedOption = (): Nullable<TransactionDirectionOption> => {
    return selectedDirection !== null ? options.filter((option) => {
      return option.type === selectedDirection;
    })[0] : null;
  };
  const selectOption = (option: TransactionDirectionOption): void => {
    onChange(option.type as TransactionDirection);
  };
  return (
    <div className="transaction-direction-select">
      <div className="transaction-direction-select__show">Тип транзакции</div>
      <OptionBox
        options={options}
        selectedOption={selectedOption()}
        renderOption={(option: TransactionDirectionOption) => option.title}
      >
        <OptionBox.Head className="transaction-direction-select__header">
          <OptionBoxContext.Consumer>
            {({active}) => <Flex alignItems={AlignItems.CENTER} gap="0.5rem">
              <Image
                src={arrowDown}
                alt="Arrow down"
                className={cn(
                  "transaction-direction-select__image",
                  resultIf(active, "active")
                )}
              />
            </Flex>}
          </OptionBoxContext.Consumer>
        </OptionBox.Head>
        <OptionBox.Content
          className="transaction-direction-select__content"
          onSelected={selectOption}
        />
      </OptionBox>
    </div>
  );
};

export default TransactionDirectionSelect;