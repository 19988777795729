export enum TABLE_COLUMNS {
    A = 'A',
    B = 'B',
    C_PLUS = 'C+',
    C_MINUS = 'C-',
    D_PLUS = 'D+',
    D = 'D',
    D_MINUS = 'D-',
    E_PLUS = 'E+',
    E_MINUS = 'E-'
}

export enum TABLE_ROWS {
    ONE = '1',
    TWO = '2',
    THREE_B = '3в',
    THREE = '3',
    THREE_N = '3н',
    FOUR = '4',
    FIVE = '5'
}

export enum TABLE_CELL {
    EXCELLENT = 'excellent',
    INTERESTING = 'interesting',
    CLASSIC = 'classic',
    PERSPECTIVE = 'perspective',
    SPECIAL = 'special',
    ILLUSORY = 'illusory',
    ORIGINAL = 'original',
    DIFFICULT = 'difficult',
    EXPERT = 'expert',
    UNDEFINED = 'undefined',
    EMPTY = 'empty'
}

export const cellNames: Record<TABLE_CELL, string> = {
  [TABLE_CELL.EXCELLENT]: 'отличный',
  [TABLE_CELL.INTERESTING]: 'интересный',
  [TABLE_CELL.CLASSIC]: 'классический',
  [TABLE_CELL.PERSPECTIVE]: 'перспективный',
  [TABLE_CELL.SPECIAL]: 'особый',
  [TABLE_CELL.ILLUSORY]: 'призрачный',
  [TABLE_CELL.ORIGINAL]: 'оригинальный',
  [TABLE_CELL.DIFFICULT]: 'сложный',
  [TABLE_CELL.EXPERT]: 'экспертный',
  [TABLE_CELL.UNDEFINED]: 'неопределенный',
  [TABLE_CELL.EMPTY]: 'пустой',
};

export const tableElementsTitle= ['1', '2', '3в', '3', '3н', '4', '5'];

export const tableElements: Record<TABLE_COLUMNS, { column: TABLE_ROWS, cell: TABLE_CELL }[]> = {
  [TABLE_COLUMNS.A]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.EXCELLENT
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.EXCELLENT
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.INTERESTING
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.B]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.EXCELLENT
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.EXCELLENT
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.INTERESTING
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.C_PLUS]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.INTERESTING
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.INTERESTING
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.INTERESTING
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.C_MINUS]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.CLASSIC
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.D_PLUS]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.ORIGINAL
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.ORIGINAL
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.ORIGINAL
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.ORIGINAL
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.D]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.DIFFICULT
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.DIFFICULT
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.DIFFICULT
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.DIFFICULT
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.D_MINUS]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.EXPERT
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.EXPERT
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.EXPERT
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.EXPERT
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.PERSPECTIVE
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.SPECIAL
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.E_PLUS]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.UNDEFINED
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.UNDEFINED
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.UNDEFINED
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.UNDEFINED
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.UNDEFINED
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.UNDEFINED
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.ILLUSORY
    },
  ],
  [TABLE_COLUMNS.E_MINUS]: [
    {
      column: TABLE_ROWS.ONE,
      cell: TABLE_CELL.EMPTY
    },
    {
      column: TABLE_ROWS.TWO,
      cell: TABLE_CELL.EMPTY
    },
    {
      column: TABLE_ROWS.THREE_B,
      cell: TABLE_CELL.EMPTY
    },
    {
      column: TABLE_ROWS.THREE,
      cell: TABLE_CELL.EMPTY
    },
    {
      column: TABLE_ROWS.THREE_N,
      cell: TABLE_CELL.EMPTY
    },
    {
      column: TABLE_ROWS.FOUR,
      cell: TABLE_CELL.EMPTY
    },
    {
      column: TABLE_ROWS.FIVE,
      cell: TABLE_CELL.EMPTY
    },
  ]

};


export const valuesOfProjectCategory = {
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.ONE}`]: cellNames.excellent,
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.TWO}`]: cellNames.excellent,
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.THREE_B}`]: cellNames.interesting,
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.THREE}`]: cellNames.classic,
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.A}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.B}${TABLE_ROWS.ONE}`]: cellNames.excellent,
  [`${TABLE_COLUMNS.B}${TABLE_ROWS.TWO}`]: cellNames.excellent,
  [`${TABLE_COLUMNS.B}${TABLE_ROWS.THREE_B}`]: cellNames.interesting,
  [`${TABLE_COLUMNS.B}${TABLE_ROWS.THREE}`]: cellNames.classic,
  [`${TABLE_COLUMNS.B}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.B}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.B}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.ONE}`]: cellNames.interesting,
  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.TWO}`]: cellNames.interesting,
  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.THREE_B}`]: cellNames.interesting,
  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.THREE}`]: cellNames.classic,
  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.C_PLUS}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.ONE}`]: cellNames.classic,
  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.TWO}`]: cellNames.classic,
  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.THREE_B}`]: cellNames.classic,
  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.THREE}`]: cellNames.classic,
  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.C_MINUS}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.ONE}`]: cellNames.original,
  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.TWO}`]: cellNames.original,
  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.THREE_B}`]: cellNames.original,
  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.THREE}`]: cellNames.original,
  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.D_PLUS}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.D}${TABLE_ROWS.ONE}`]: cellNames.difficult,
  [`${TABLE_COLUMNS.D}${TABLE_ROWS.TWO}`]: cellNames.difficult,
  [`${TABLE_COLUMNS.D}${TABLE_ROWS.THREE_B}`]: cellNames.difficult,
  [`${TABLE_COLUMNS.D}${TABLE_ROWS.THREE}`]: cellNames.difficult,
  [`${TABLE_COLUMNS.D}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.D}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.D}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.ONE}`]: cellNames.expert,
  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.TWO}`]: cellNames.expert,
  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.THREE_B}`]: cellNames.expert,
  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.THREE}`]: cellNames.expert,
  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.THREE_N}`]: cellNames.perspective,
  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.FOUR}`]: cellNames.special,
  [`${TABLE_COLUMNS.D_MINUS}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.ONE}`]: cellNames.undefined,
  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.TWO}`]: cellNames.undefined,
  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.THREE_B}`]: cellNames.undefined,
  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.THREE}`]: cellNames.undefined,
  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.THREE_N}`]: cellNames.undefined,
  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.FOUR}`]: cellNames.undefined,
  [`${TABLE_COLUMNS.E_PLUS}${TABLE_ROWS.FIVE}`]: cellNames.illusory,

  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.ONE}`]: cellNames.empty,
  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.TWO}`]: cellNames.empty,
  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.THREE_B}`]: cellNames.empty,
  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.THREE}`]: cellNames.empty,
  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.THREE_N}`]: cellNames.empty,
  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.FOUR}`]: cellNames.empty,
  [`${TABLE_COLUMNS.E_MINUS}${TABLE_ROWS.FIVE}`]: cellNames.empty,
};

export const debtDescriptionOfProjectCategory = {
  [cellNames.excellent]: 'ОТЛИЧНЫЙ ДОЛГ – обязательства наивысшего качества,\n' +
    'представленные долгами финансово-состоятельных компаний,\n' +
    'подтвержденные судебным актом. Низкий и пониженный уровень\n' +
    'риска как с юридической, так и с экономической позиций.',

  [cellNames.interesting]: 'ИНТЕРЕСНЫЙ ДОЛГ – обязательства высокого качества с\n' +
    'верифицированной позицией по долгу и достаточно устойчивым\n' +
    'положением должника. Сбалансированный уровень юридического и\n' +
    'экономического риска на уровне ниже нормального.',

  [cellNames.classic]: 'КЛАССИЧЕСКИЙ ДОЛГ – обязательства выше среднего качества\n' +
    'действующих компаний. Уровень риска не ниже нормального по\n' +
    'юридической и экономической сторонам.',

  [cellNames.original]: 'ОРИГИНАЛЬНЫЙ ДОЛГ – обязательства среднего качества должников,\n' +
    'обладающих действенными механизмами защиты от взыскания\n' +
    'долга. Уровень риска выше нормального, преимущественно в сроках\n' +
    'взыскания.',

  [cellNames.difficult]: 'СЛОЖНЫЙ ДОЛГ – крупные обязательства среднего качества\n' +
    'должников. Повышенный уровень риска, сосредоточенного\n' +
    'преимущественно в экономической части.',

  [cellNames.expert]: 'ЭКСПЕРТНЫЙ ДОЛГ – обязательства среднего качества должника,\n' +
    'находящегося в кризисном положении. Повышенный уровень риска,\n' +
    'сосредоточенного преимущественно в экономической части.',

  [cellNames.perspective]: 'ПЕРСПЕКТИВНЫЙ ДОЛГ – обязательства ниже среднего качества\n' +
    'должников разной степени финансовой устойчивости. Уровень риска\n' +
    'выше нормального в связи с досудебным статусом долга и рисками,\n' +
    'вытекающими из неопределенной позиции должника и сроков\n' +
    'просуживания. Уровень риска выше нормального и сосредоточен\n' +
    'преимущественно в юридической части.',

  [cellNames.special]: 'ОСОБЫЙ ДОЛГ – рискованные обязательства должников разной\n' +
    'степени финансовой устойчивости. Повышенный уровень риска в\n' +
    'связи с юридически неоднозначной позицией по долгу.',

  [cellNames.undefined]: 'НЕОПРЕДЕЛЕННЫЙ ДОЛГ – высоко рискованные обязательства разной\n' +
    'степени юридической зрелости. Уровень риска высокий в связи с\n' +
    'фактическим дефолтом должника по его обязательствам.',

  [cellNames.empty]: 'ПУСТОЙ ДОЛГ – дефолтные обязательства с негативным прогнозом о\n' +
    'принудительном взыскании.',

  [cellNames.illusory]: 'ПРИЗРАЧНЫЙ ДОЛГ – обязательства с не подтвержденными\n' +
    'основаниями возникновения задолженности.',
};