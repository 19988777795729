import React, {useContext, useEffect, useState} from "react";
import './Paginator.scoped.scss';
import {NavLink, useLocation} from "react-router-dom";
import {cn, getQueryObject} from "../../../utils";
import buildQuery from "../../../utils/router/buildQuery";
import {PaginationContext, PaginationContextType} from "../PaginationContext";
import {useWindowWidth} from "../../pages/detailed-project/hooks";

const maxPages = 5;

const Paginator = () => {
  const context: PaginationContextType = useContext(PaginationContext);
  const isMobile = useWindowWidth();

  const location = useLocation();
  const countPages = (): number => {
    return Math.ceil(context.totalCount / context.perPage);
  };
  const pages = (): Array<number> => {
    const count = countPages();
    const pages =
            count <= 1 ? [1] : Array.from(Array(count + 1).keys()).slice(1);
    //TODO: ADD CHECKING FOR MAXIMUM
    return pages;
  };
  const isFirstPage = (): boolean => {
    return context.page === pages()[0];
  };
  const isLastPage = (): boolean => {
    return context.page === pages()[pages().length - 1];
  };

  const generatePageUrl = (pageNumber: number): string => {
    let url = `${location.pathname}`;
    const queryObject = getQueryObject(location.search);
    pageNumber === 1 ? delete queryObject[context.pageParam] : queryObject[context.pageParam] = pageNumber;
    const query = buildQuery(queryObject);
    url += `?${query}`;
    return url;
  };

  const links = () => {
    const allPages = pages();
    const currentPage = context.page;

    let maxDisplayedPages;

    if (isMobile) {
      maxDisplayedPages = 3;
    } else {
      maxDisplayedPages = 10;
    }

    if (allPages.length <= maxDisplayedPages) {
      return allPages.map((pageNumber) => renderPageLink(pageNumber));
    }

    const displayedPages = [];
    let startPage = currentPage - Math.floor(maxDisplayedPages / 2);
    let endPage = currentPage + Math.floor(maxDisplayedPages / 2);

    if (startPage < 1) {
      endPage += Math.abs(startPage) + 1;
      startPage = 1;
    }

    if (endPage > allPages.length) {
      startPage -= endPage - allPages.length;
      endPage = allPages.length;
    }

    if (startPage > 1) {
      displayedPages.push(renderPageLink(1));
      if (startPage > 2) {
        displayedPages.push(<span key="startEllipsis">..</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      displayedPages.push(renderPageLink(i));
    }

    if (endPage < allPages.length) {
      if (endPage < allPages.length - 1) {
        displayedPages.push(<span key="endEllipsis">..</span>);
      }
      displayedPages.push(renderPageLink(allPages.length));
    }

    return displayedPages;
  };

  const renderPageLink = (pageNumber: number) => {
    const isActive = context.page === pageNumber;
    return (
      <NavLink
        className={cn('my-page-link', `${isActive ? "my-page-link--active" : ""}`)}
        key={pageNumber}
        to={generatePageUrl(pageNumber)}
      >
        {pageNumber}
      </NavLink>
    );
  };

  const previousPageLink = () => {
    const isDisabled = isFirstPage();
    const previousPageUrl = isDisabled ? "#" : generatePageUrl(context.page - 1);
    return <NavLink
      to={previousPageUrl}
      className={cn('previous-page-link', `${isDisabled ? "previous-page-link--disabled" : ""}`)}
    >
      &lt; {isMobile? '': 'Вперед'}
    </NavLink>;
  };

  const lastPageLink = () => {
    const isDisabled = isLastPage();
    const previousPageUrl = isDisabled ? "#" : generatePageUrl(context.page + 1);
    return <NavLink
      to={previousPageUrl}
      className={cn('next-page-link', `${isDisabled ? "next-page-link--disabled" : ""}`)}
    >
      &gt; {isMobile? '': 'Назад'}
    </NavLink>;
  };

  const [displayedPages, setDisplayedPages] = useState(links());

  useEffect(() => {
    const handleResize = () => {
      setDisplayedPages(links());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="paginator__container">
      <div className="paginator">
        { previousPageLink() }
        <div className="paginator__links">
          { links() }
        </div>
        { lastPageLink() }
      </div>
    </div>
  );
};

const MemoizedPaginator = React.memo(Paginator);

export default MemoizedPaginator;