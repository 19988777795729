import React, {FC} from "react";
import {NavLink, NavLinkProps} from "react-router-dom";
import {createRouteUrlProvider} from "../../../../di";
import {RouteDictionary} from "../../../../utils";

interface Props extends NavLinkProps {
    to: RouteDictionary;
    params?: Object;
    explicitActive?: boolean | undefined;
}

const CustomLink: FC<Props> = ({to, params, explicitActive, children, ...props}) => {
  const urlProvider = createRouteUrlProvider();
  const url = urlProvider.getByName(to, params);
  return (
    <NavLink
      to={url}
      {...props}
      className={({isActive}) => (isActive || explicitActive ? "active " : "") + props.className}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;