import {Filter} from "../ProjectsFilter";

export const defaultProjectsFilter: Filter = {
  search: "",
  economicClasses: [],
  legalClasses: [],
  initialTermFrom: null,
  initialTermTo: null,
  interestRateFrom: null,
  interestRateTo: null,
  statuses: []
};