import React, {useEffect, useState} from "react";
import {Outlet} from "react-router-dom";

import {useAppDispatch, useErrorCallback} from "hooks";
import {BannerQuestion, Container, Section} from "components";
import {createProfileManager, createStatsProvider, createSurveyProvider} from "di";
import {AccountNotReadyWarningSection, AutoInvestment, BalanceStats, Portfolio} from "components/pages";
import {set as setMoneyData} from "../../../../../store/my-money";
import CommonFilterIcon from "../../../../../components/filters/CommonFilterIcon";
import {FilterIcon} from "../../../../../components/filters/CommonFilterIcon/CommonFilterIcon";
import DownloadSvg from "../../../../../components/svg/DownloadSvg";
import apiClientConfig from "../../../../../configs/app";

import './MyMoney.scoped.scss';
import {Environment} from "../../../../../utils";
import {set as setProfile} from "../../../../../store/profile";
import BannerBlock, {
  BannerBlockVariant
} from "../../../../../components/pages/my-money/AccountNotReadyWarningSection/BannerBlock";
import {Survey} from "../../../../../api-client";


const MyMoney = () => {
  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState<Survey[]>();
  const handleError = useErrorCallback();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onMount = async () => {
      try {
        await initMyMoneyData();
        const provider = await createSurveyProvider();
        const surveys = await provider.getList();
        setSurveys(surveys);
      } catch (error) {
        if (error instanceof Error) {
          await handleError(error);
        }
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await onMount();
    })();
  }, []);

  const initMyMoneyData = async () => {
    const statsProvider = await createStatsProvider();
    const myMoneyData = await statsProvider.getStats();
    dispatch(setMoneyData(myMoneyData));
    const manager = await createProfileManager();
    const profile = await manager.get();
    dispatch(setProfile(profile));
  };

  const exportStatistics = async (): Promise<void> => {
    const provider = await createStatsProvider();
    const response = await provider.exportStatistics();
    window.location.assign(apiClientConfig.apiBaseUrl + response.url);
  };

  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
        || (apiClientConfig.applicationEnvironment === Environment.test);
  return (
    <>
      <AccountNotReadyWarningSection/>
      {(surveys && surveys?.length > 0) && <Container>
        <BannerQuestion>
          <BannerBlock variant={BannerBlockVariant.SURVEY} params={surveys[0].uuid ? surveys[0].uuid : ''}/>
        </BannerQuestion>
      </Container>}

      <Outlet/>
      <Section className="my-money-section">
        <Container>
          {loading ? <div>Загружаем</div> : <div className="my-money__stats">
            {devAndTest && <div onClick={exportStatistics} className="my-money__download">
              <CommonFilterIcon type={FilterIcon.Download}>
                <DownloadSvg/>
              </CommonFilterIcon>
            </div>}
            {/*<ShortStats/>*/}
            <Portfolio/>
            <div className="my-money__balance">
              <BalanceStats/>
            </div>
            <AutoInvestment/>
            {/*<Transactions/>*/}
          </div>}
        </Container>
      </Section>
    </>
  );
};

export default MyMoney;