import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const profileRoutes: Array<NamedUrl> = [
  {
    name: RouteDictionary.PROFILE,
    url: `/`
  },
  {
    name: RouteDictionary.REFERRAL,
    url: `/referral-program`
  },
  {
    name: RouteDictionary.PROFILE_CONTROL,
    url: `/control`
  },
];