import axios from "axios";

export const downloadPDF = async (pdfUrl: string, pdfName: string) => {
  try {
    const response = await axios.get(pdfUrl, {responseType: 'blob'});
    const blob = new Blob([response.data], {type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = pdfName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading PDF:', error);
  }
};