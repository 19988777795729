import React, {useContext, useEffect, useMemo, useState} from "react";
import styles from './ProjectMain.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {
  DebtClass,
  isAnnounced,
  isFundRaising,
  OrderDetails,
  TransactionType,
  translateProjectStatus
} from "../../../../api-client";
import {ProjectCategory} from "../../../project";
import {Money, Tooltip} from "../../../common";
import {debtDescriptionOfProjectCategory, valuesOfProjectCategory} from "../../../layout";
import {createOrdersProvider} from "../../../../di";
import {useErrorCallback, useLoading} from "../../../../hooks";
import apiClientConfig from "../../../../configs/app";
import {Environment} from "../../../../utils";
import ProjectMainStats from "../ProjectMainStats";
import {formatDate} from "../../../../formatters";

const ProjectMain = () => {
  const project = useContext(DetailedProjectContext).project;
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [order, setOrder] = useState<OrderDetails>();
  let investmentFeePercent = 0;
  let dividendInterestFeePercent = 0;

  project.feeSettings.map((setting) => {
    if (setting.transactionType === TransactionType.INVESTMENT_FEE && setting.percent) investmentFeePercent = setting.percent;
    if (setting.transactionType === TransactionType.DIVIDEND_INTEREST_FEE && setting.percent) dividendInterestFeePercent = setting.percent;
  });

  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createOrdersProvider();
        const order = await provider.getOne(project.uuid!);
        setOrder(order);
      } catch (error) {
        if (error instanceof Error) {
          await errorCallback(error);
        }
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);

  const renderPaymentBases = useMemo(() => {
    const paymentBases = project.paymentBases;
    if (paymentBases.length === 0) {
      return <div>
        Основания будут добавлены в процессе наполнения проекта.
      </div>;
    }
    return paymentBases.map((paymentBase) => {
      return <div key={paymentBase.heading} className={styles.detailedProjectAdditional__item}>
        <div className={styles.detailedProjectAdditional__heading}>
          {paymentBase.heading}
        </div>
        <div className={styles.detailedProjectAdditional__content}>
          {paymentBase.content}
        </div>
      </div>;
    });
  }, [project]);

  const getDescriptionOfProjectCategory = (debtClass: DebtClass) => {
    if (debtClass.economicExpert === null || debtClass.legalExpert === null) return 'Не указана категория';
    const valueCategory = `${debtClass.economicExpert}${debtClass.legalExpert}`;
    const descriptionOfProjectCategory = valuesOfProjectCategory[valueCategory];
    if (!descriptionOfProjectCategory) return 'Не известная категория';
    return debtDescriptionOfProjectCategory[descriptionOfProjectCategory] ?? 'Нет описания к категории';
  };

  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
        || (apiClientConfig.applicationEnvironment === Environment.test);

  let startDate = null;
  let endDate = null;
  
  if (project.loanIssuedAt) {
    if (!isAnnounced(project.status) || !isFundRaising(project.status)) {
      startDate = formatDate(project.loanIssuedAt);
    }
  }

  if (project.paidAt) {
    if (!isAnnounced(project.status) || !isFundRaising(project.status)) {
      endDate = formatDate(project.paidAt);
    }
  }

  if (loading) return <div>Загружаем...</div>;
  return (<>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__top}>
        <div className={styles.detailedProjectMain__status}>
          <div className={styles.detailedProjectMain__code}>
            {project.paymentCode}
          </div>
          <div>
            {translateProjectStatus(project.status)}
          </div>
        </div>
        <div className={styles.detailedProjectMain__classContainer}>
          {project.accounting.hasExtraCreditorPayment && <div className={styles.detailedProjectMain__class}>
            инкассо
          </div>}
          <div className={styles.detailedProjectMain__class}>
            <Tooltip content={"Размер вознаграждения агента за инвестицию / Размер вознаграждения агента за доход"} position={'bottom'}>
              {`${investmentFeePercent}/${dividendInterestFeePercent}\xa0%`}
            </Tooltip>
          </div>
          <div className={styles.detailedProjectMain__class}>
            <Tooltip content={getDescriptionOfProjectCategory(project.debt.debtClass)} position={'bottom'}>
              <ProjectCategory debtClass={project.debt.debtClass}/>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__project}>
        <div>
          <p className={styles.detailedProjectMain__name}>
            {project.name[0].text}
          </p>
          <p className={styles.detailedProjectMain__info}>
            ИНН {project.company.inn}. {project.company.name}.
          </p>
          <div className={styles.detailedProjectMain__dateContainerLayout}>
            <div className={styles.detailedProjectMain__dateContainer}>
              <p className={styles.detailedProjectMain__dateTitle}>
                Начало проекта
              </p>
              <p className={styles.detailedProjectMain__dateValue}>
                {startDate === null? '-': startDate}
              </p>
            </div>
            <div className={styles.detailedProjectMain__dateContainer}>
              <p className={styles.detailedProjectMain__dateTitle}>
                Конец проекта
              </p>
              <p className={styles.detailedProjectMain__dateValue}>
                {endDate === null? '-': endDate}
              </p>
            </div>
          </div>
        </div>
        {devAndTest && <div>
          <div className={styles.detailedProjectMain__oredrsSum}>
            <span className={styles.detailedProjectMain__oredrsSum__text}>Сумма ваших заявок: </span>
            <Money money={order?.investment || {amount: 0, currencyCode: 'RUB'}}/>
          </div>
        </div>}
      </div>

      {project.accounting.detailedInfo &&
      <div className={styles.detailedProjectMainIncasso}>
        <div className={styles.detailedProjectMainIncasso__title}>Особенности проекта</div>
        <div className={styles.detailedProjectMainIncasso__text}>{project.accounting.detailedInfo}</div>
      </div>
      }
    </div>
    <ProjectMainStats/>
    <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectAdditional}>
        <div className={styles.detailedProjectAdditional__title}>
          Основания предполагать будущее погашение долга
        </div>
        <div className={styles.detailedProjectAdditional__items}>
          {renderPaymentBases}
        </div>
      </div>
    </div>

  </>
  );
};

export default ProjectMain;