import React, {AllHTMLAttributes, ReactNode, useContext} from "react";
import {OptionBoxContext, OptionBoxContextType} from "../../OptionBoxContext";
import {cn, resultIf} from "../../../../../../utils";
import './OptionBoxContent.scoped.scss';

interface Props<T> extends AllHTMLAttributes<HTMLDivElement> {
    onSelected: (selected: T) => void;
}

const OptionBoxContent = <T, >({onSelected, ...props}: Props<T>) => {
  const context: OptionBoxContextType<T> = useContext(OptionBoxContext);

  const selectOption = (option: T): void => {
    onSelected(option);
    context.toggle();
  };

  const isSelected = (option: T): boolean => {
    return context.selectedOption === option;
  };

  const renderOptions = (): ReactNode => {
    return context.options.map((option: T, index: number) => {
      return <li
        key={index}
        onClick={() => selectOption(option)}
        className={cn(
          "option-box__item",
          resultIf(isSelected(option), "active")
        )}
      >
        { context.render(option) }
      </li>;
    }, []);
  };
  return (
    <div
      {...props}
      className={cn(
        "option-box__content",
        resultIf(context.active, "active"),
        props.className
      )}
    >
      <ul className="option-box__list">
        { renderOptions() }
      </ul>
    </div>
  );
};

export default OptionBoxContent;