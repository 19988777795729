import React, {FC} from "react";
import {Button} from "../index";
import {ButtonProps} from "../Button/Button";
import {cn} from "../../../../utils";
import gearImage from "../../../../assets/images/svg/gear.svg";
import {Image} from "../../images";
import './GearButton.scoped.scss';

const GearButton: FC<ButtonProps> = ({...props}) => {
  return (
    <Button {...props} className={cn("gear-button", props.className)}>
      <Image src={gearImage} />
    </Button>
  );
};

export default GearButton;