import React from "react";
import {ChildrenProps} from "../../../../../utils";
import {ClassNameDecorator} from "../../../../decorators";
import {ClassInjector} from "../../../../injectors";
import './BoldText.scoped.scss';

const BoldText: ClassNameDecorator<ChildrenProps> = ({children, className}) => {
  return (
    <ClassInjector classNames={"bold-text"} oldClassName={className}>
      { children }
    </ClassInjector>
  );
};

export default BoldText;