import React, {FC} from "react";

export enum NotificationSvgColor {
    GREEN = "#22a873",
    BLACK = "#000"
}

interface Props {
    color: NotificationSvgColor
}

const NotificationsSvg: FC<Props> = ({color}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.223" height="18" viewBox="0 0 16.223 18">
      <g id="Сгруппировать_11007" data-name="Сгруппировать 11007" transform="translate(-1358.489 -801.001)">
        <path
          id="Контур_26407"
          data-name="Контур 26407"
          d="M1372.116,811.527V808.1a5.576,5.576,0,1,0-11.1,0v3.644a5.73,5.73,0,0,1-1.524,3.762h14.223A5.372,5.372,0,0,1,1372.116,811.527Z"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
        />
        <line
          id="Линия_791"
          data-name="Линия 791"
          x2="2.649"
          transform="translate(1365.276 818.001)"
          fill="none"
          stroke={color}
        />
      </g>
    </svg>

  );
};

export default NotificationsSvg;