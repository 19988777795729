import {NamedUrl} from "./types";
import {authRoutes, dashboardRoutes, mainRoutes, profileRoutes} from "./routes";

export class RouteProvider {
  getUrls(): Array<NamedUrl> {
    return [...mainRoutes, ...this.withPrefix("dashboard", [
      ...dashboardRoutes,
      ...this.withPrefix("profile", profileRoutes)
    ]), ...this.withPrefix("auth", [
      ...authRoutes
    ])];
  }

  private withPrefix(prefix: string, urls: Array<NamedUrl>): Array<NamedUrl> {
    return urls.map((url) => {
      return {...url, url: `/${prefix}${url.url}`};
    });
  }
}