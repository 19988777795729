import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import './BlockNavigation.scoped.scss';
import {cn} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const BlockNavigation: FC<PropsWithChildren<Props>> = ({children, ...props}) => {
  return (
    <nav {...props} className={cn("block-navigation", props.className)}>
      <ul className="block-navigation__list">
        { children }
      </ul>
    </nav>
  );
};

export default BlockNavigation;