import {usePrevious} from "./usePrevious";

export function useCompare<T> (value: T) {
  const prev = usePrevious<T>(value);
  if (typeof value === "object") {
    // @ts-ignore
    const keys = Object.keys(value);
    for (const key of keys) {
      // @ts-ignore
      const first: any = value[key as keyof T];
      const second: any = prev[key as keyof T];
      if (first !== second) {
        return false;
      }
    }
    return true;
  }
  return prev !== value;
}