import React from "react";
import {Outlet} from "react-router-dom";
import {LayoutPublic} from "../../../layouts";

const Public = () => {
  return (
    <>
      <LayoutPublic>
        <Outlet />
      </LayoutPublic>
    </>
  );
};

export default Public;