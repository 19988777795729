import React, {FC} from "react";
import './ArchivedProject.scoped.scss';
import {ArchivedProject as ArchivedProjectModel} from '../../../../api-client/models/project/ArchivedProject';
import {BoldText, ColoredText, CustomLink, Money} from "../../../common";
import {ProjectCategory} from "../../../project";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";
import {RouteDictionary} from "../../../../utils";
import ProjectFavorite from "../../projects/ProjectFavorite";
import {GridRowHeader} from "../../../layout/grid";
import {formatPercents} from "../../../../formatters";
import {ProjectStatus} from "../../../../api-client";

interface Props {
    project: ArchivedProjectModel
}

const ArchivedProject: FC<Props> = ({project}) => {
  const linkToTransactions = `/dashboard/transactions?filter%5BprojectUuids%5D%5B%5D=${project.uuid}`;
  return (
    <li className="archived-project">
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link  archived-project__primary archived-project__column' } >
        <div>
          <div className="archived-project__title">
            <ProjectFavorite>
              <div>
                { project.paymentCode }
              </div>
            </ProjectFavorite>
            <BoldText>
              <div className="archived-project__name">
                { project.name[0].text }
              </div>
            </BoldText>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__category'} >
        <div>
          <GridRowHeader>
            Категория
          </GridRowHeader>
          <div>
            <BoldText>
              <ProjectCategory debtClass={project.debtClass} delimiter="/" />
            </BoldText>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__status'} >
        <div>
          <GridRowHeader>
            Статус
          </GridRowHeader>
          <div>
            <BoldText>
              <div>
                { project.companyStatus }
              </div>
            </BoldText>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__investment' } >
        <div>
          <GridRowHeader>
            {project.investment? 'Инвестировано' : 'Сумма сбора'}
          </GridRowHeader>
          {project.investment ?
            <div>
              <Money money={project.investment} />
            </div>
            :
            <div>
              <Money money={project.debtPrice} />
            </div>
          }
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__actual-payment'} >
        <div>
          <GridRowHeader>
            Фактическая выплата
          </GridRowHeader>
          <div>
            {(project.status === ProjectStatus.PAID) ?
              <BoldText>
                <div>
                  {project.actualPayment &&
                    <Money money={
                      project.actualPayment}/> }
                </div>
              </BoldText>
              :
              <div>
                {project.actualPayment &&
                  <Money money={
                    project.actualPayment}/> }
              </div>
            }
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__loan archived-project__column'} >
        <div>
          <GridRowHeader>
            {project.recoveryForecast? 'Прогноз взыскания' : 'Размер взыскания'}
          </GridRowHeader>
          {project.recoveryForecast ?
            <div>
              <Money money={project.recoveryForecast} />
            </div>
            :
            <div>
              <Money money={project.finalDebtAmount} />
            </div>
          }
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__term'} >
        <div>
          <GridRowHeader>
            Срок
          </GridRowHeader>
          <div>
            { project.initialTerm } мес.
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__simple'} >
        <div>
          <GridRowHeader>
            Доходность простая
          </GridRowHeader>
          <div>
            {formatPercents(project.simpleInterestRate)}
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__yearly'} >
        <div>
          <GridRowHeader>
            Доходность годовая
          </GridRowHeader>
          <div>
            {formatPercents(project.interestRate)}
          </div>
        </div>
      </CustomLink>
      <div className={"archived-project__column archived-project__transactions"}>
        <GridRowHeader>
          Транзакции
        </GridRowHeader>
        <ColoredText color={TextColor.PRIMARY_GREEN}>
          <a href={linkToTransactions}  className={"archived-project__transactions--link"}>
            Смотреть
          </a>
        </ColoredText>
      </div>
      <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: project.uuid}} className={'archived-project__link archived-project__column archived-project__stages'} >
        <div>
          <GridRowHeader>
            События
          </GridRowHeader>
          <div>
            <ColoredText color={TextColor.PRIMARY_GREEN}>
              <div>
                История
              </div>
            </ColoredText>
          </div>
        </div>
      </CustomLink>
    </li>
  );
};

export default ArchivedProject;