import {ApiClientInterface} from "./clients";
import buildQuery from "../utils/router/buildQuery";
import {AutoInvestAgreement, Collection, LegalAgreements, UrlResult} from "./models";
import {Agreement} from "./models/documents/Agreement";
import {ProjectDocumentsAgreement} from "./models/documents/ProjectDocumentsAgreement";
import {Filter} from "../views/dashboard/user/profile/Documents/FilterDocuments/FilterDocuments";

export class AgreementProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getLegalAgreements(page: number, perPage: number):Promise<LegalAgreements> {
    const query = buildQuery({page, perPage});
    return this.apiClient.get(`/legal?${query}`);
  }

  public async getProjectAgreements(page: number, perPage: number, filter?: Filter): Promise<Collection<ProjectDocumentsAgreement>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/projects-agreements?${query}`);
  }

  public async getAutoInvestAgreements(page: number, perPage: number, filter?: Filter): Promise<Collection<AutoInvestAgreement>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/autoinvest?${query}`);
  }

  public async getList(page: number, perPage: number): Promise<Collection<Agreement>>{
    const query = buildQuery({page, perPage});
    return this.apiClient.get(`/?${query}`);
  }

  public async createAgentReport (investUuid: string): Promise<UrlResult>{
    return this.apiClient.post(`/agent-report`, {investUuid});
  }

  public async orderIncomeCertificate (investUuid: string, year: number, sendToEmail: boolean): Promise<UrlResult>{
    return this.apiClient.post(`/order-income-certificate`, {investUuid, year, sendToEmail});
  }

  public async exportZip(url:string): Promise<UrlResult>{
    const query = buildQuery({url});
    return this.apiClient.get(`/export-zip?${query}`);
  }
}