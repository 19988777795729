import React, {FC} from "react";
import {ChildrenProps} from "../../../../utils";
import './BlockContent.scoped.scss';

type Props = ChildrenProps;

const BlockContent: FC<Props> = ({children}) => {
  return (
    <div className="block-content">
      { children }
    </div>
  );
};

export default BlockContent;