import {ApiClientInterface} from "./clients";
import {Page} from "./models";

export class PageProvider {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public async getOne(slug: string): Promise<Page> {
    return this.apiClient.get(`/${slug}`);
  }
}