import React, {createContext, PropsWithChildren} from "react";
import {DetailedOffer} from "../../../../../../api-client/models/offer/DetailedOffer";

export type SecondaryProjectContextType = {
    offer: DetailedOffer;
}

type Props = {
    props: SecondaryProjectContextType;
}

// @ts-ignore
export const SecondaryProjectContext = createContext<SecondaryProjectContextType>();

function SecondaryProjectContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <SecondaryProjectContext.Provider value={value.props}>
    { children }
  </SecondaryProjectContext.Provider>;
}

export default SecondaryProjectContextProvider;