import React from "react";
import {LayoutCabinet} from "layouts";
import {Outlet} from "react-router-dom";

const Dashboard = () => {
  return (
    <LayoutCabinet>
      <Outlet />
    </LayoutCabinet>
  );
};

export default Dashboard;