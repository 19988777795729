import {ApiClientInterface} from "./clients";
import {UuidResult} from "./models";
import {PurchaseConfirmation} from "./models/purchase/PurchaseConfirmation";

export class OfferPurchaseManager {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public createConfirmation(uuid: string, ): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/purchase/confirmation`, {});
  }

  public create(uuid: string, confirmationUuid: string, smsCode: string): Promise<UuidResult> {
    return this.apiClient.post(`/${uuid}/purchase`, {confirmationUuid, smsCode});
  }

  public getConfirmation(uuid:string, confirmationUuid: string): Promise<PurchaseConfirmation> {
    return this.apiClient.get(`/${uuid}/purchase/confirmation/${confirmationUuid}`);
  }

  public sendCode(offerUuid: string, confirmationUuid: string): Promise<void> {
    return this.apiClient.post(`/${offerUuid}/purchase/confirmation/${confirmationUuid}/send-code`);
  }
}