import React, {FC, PropsWithChildren} from "react";
import './ProjectFilterClear.scoped.scss';
import filterClear from '../../../../../assets/images/svg/hash-strong.svg';
import {Image} from "../../../../common";

interface Props {
    action: () => void;
}

const ProjectFilterClear: FC<PropsWithChildren<Props>> = ({action, children}) => {
  return (
    <div className="project-filter-clear">
      <div className="project-filter-clear__text">
        { children }
      </div>
      <div onClick={() => action()} className="project-filter-clear__button">
        <Image src={filterClear} />
      </div>
    </div>
  );
};

export default ProjectFilterClear;