import {useEffect, useState} from "react";

export const useWindowWidth = (breakpoint: number = 768) => {
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    const isMobile = () => {
      setMobile(window.innerWidth < breakpoint);
    };
    window.addEventListener('resize', isMobile);
    return () => {
      window.removeEventListener('resize', isMobile);
    };
  }, []);

  return mobile;
};