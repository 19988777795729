import React, {useEffect, useState} from "react";
import {Button} from "../index";
import arrowTop from '../../../../assets/images/svg/arrow-yellow-top.svg';
import './ScrollToTopButton.scoped.scss';

//TODO: REWORK TO INTERSECTION OBSERVER
const ScrollToTopButton = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", scrollCallback);
    return () => {
      window.removeEventListener("scroll", scrollCallback);
    };
  }, []);

  const scrollCallback = () => {
    setShow(window.scrollY > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0
    });
  };

  return (
    <>
      { show &&
      <Button onClick={scrollToTop} className="scroll-top-button">
        <img src={arrowTop} alt="Arrow Top" className={"scroll-top-button__arrow"}/>
      </Button>
      }
    </>
  );
};

export default ScrollToTopButton;