export enum AgentAssignmentStatus {
    COMPLETED = "COMPLETED",
    STOPPED = "STOPPED"
}

export const AgentAssignmentStatusMapped: Record<AgentAssignmentStatus, string> = {
  [AgentAssignmentStatus.COMPLETED]: "Исполнено",
  [AgentAssignmentStatus.STOPPED]: "Прекращено",
};

export const translateAgentAssignmentStatus = (status: AgentAssignmentStatus): string => {
  return AgentAssignmentStatusMapped[status];
};