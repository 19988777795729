import React, {FC} from "react";
import Modal from "../Modal";
import {createOfferPurchaseManager} from "../../../../di";
import {RouteDictionary, sleep} from "../../../../utils";
import {subtractBalance} from "../../../../store/profile";
import {useAppDispatch, useNavigateByName} from "../../../../hooks";
import {ConfirmSmsCodeForm} from "../../utils";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";
import {set} from "../../../../store/overlay";
import {Money, UuidResult} from "../../../../api-client";

//TODO: REWORK TO ONE UUID
interface Props extends ModalProps {
    purchaseConfirmation: {
        offerUuid: string,
        confirmationUuid: string,
        money: Money
    };
}

const PurchaseConfirmationModal: FC<Props> = ({active, onClose, purchaseConfirmation}) => {
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  const sendCode = async (smsCode: string): Promise<UuidResult> => {
    const manager = await createOfferPurchaseManager();
    return await manager.create(purchaseConfirmation.offerUuid, purchaseConfirmation.confirmationUuid, smsCode);
  };

  const resendCode = async (): Promise<void> => {
    await sleep(3000);
  };

  const onSuccess = async (): Promise<void> => {
    dispatch(subtractBalance(purchaseConfirmation.money.amount));
    //FIX: OVERLAY IS NOT CLOSED WHEN MODEL IS, ADD MESSAGE THAT INVESTMENT IS CONFIRMED
    dispatch(set(false));
    navigate(RouteDictionary.MY_PROJECTS);
  };
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение" onClose={onClose}>
        <ConfirmSmsCodeForm
          sendCallback={sendCode}
          resendCallback={resendCode}
          successCallback={onSuccess}
          prefix="offer-conf"
        />
      </SmsConfirmation>
    </Modal>
  );
};

export default PurchaseConfirmationModal;