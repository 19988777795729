import React, {AllHTMLAttributes, FC} from "react";

export enum HeadingType {
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
    H4 = "h4",
    H5 = "h5",
    H6 = "h6"
}

interface Props extends AllHTMLAttributes<HTMLHeadingElement>{
    headingType: HeadingType
}

const Heading: FC<Props> = ({headingType, children, ...props}) => {
  return React.createElement(headingType, props, children);
};

export default Heading;