import React, {FC, Fragment, ReactNode, useState} from "react";
import Information from "../../utils/Information";
import './CommonInput.scoped.scss';
import {cn, resultIf} from "../../../../utils";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
    value: any;
    onChange: (value: any) => void;
    title: string;
    helpingText?: ReactNode | undefined;
    required?: boolean | undefined;
    extensions?: Array<ReactNode> | undefined;
    id: string;
}

const CommonInput: FC<Props> = (
  {
    value,
    onChange,
    title,
    helpingText,
    required = true,
    extensions,
    id,
    ...props
  }
) => {
  const [focused, setFocused] = useState(false);

  const {className, ...inputProps} = props;

  const active = (): boolean => {
    return focused || value !== "";
  };
    
  const onInput = (event: any) => {
    onChange(event.target.value);
  };
  const renderExtensions = () => {
    return extensions !== undefined && extensions.map((extension, index) => {
      return <Fragment key={index}>{extension}</Fragment>;
    });
  };
  return (
    <div className={cn("common-input", className)}>
      <div className="common-input__left">
        <input
          id={id}
          className="common-input__input"
          value={value}
          onInput={onInput}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          {...inputProps}
        />
        <label
          className={cn(
            "common-input__label",
            resultIf(active(), "focused")
          )}
          htmlFor={id}
        >
          { title }
        </label>
      </div>
      <div className="common-input__right">
        { !required && <div className="common-input__disclaimer">
          Необязательно <span className="common-input__disclaimer--mobile">для заполнения</span>
        </div> }
        { helpingText !== undefined && <div>
          <Information>{helpingText}</Information>
        </div> }
        { renderExtensions() }
      </div>
    </div>
  );
};

export default CommonInput;