import {ApiClientInterface} from "./clients";
import {UuidResult} from "./models";
import {OfferSaleConfirmation} from "./models/offer/OfferConfirmation";
import {OfferConfirmationResult} from "./models/offer/OfferConfirmationResult";
import {CreateOfferParams} from "./models/offer/CreateOfferParams";

export class OfferSaleManager {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public createConfirmation(offerParams: CreateOfferParams): Promise<UuidResult> {
    return this.apiClient.post("/confirmation", offerParams);
  }

  public createOffer(confirmationUuid: string, smsCode: string): Promise<OfferConfirmationResult> {
    return this.apiClient.post("", {confirmationUuid, smsCode});
  }

  public getConfirmation(confirmationUuid: string): Promise<OfferSaleConfirmation> {
    return this.apiClient.get(`/confirmation/${confirmationUuid}`);
  }

  public sendCode(confirmationUuid: string): Promise<void> {
    return this.apiClient.post(`/confirmation/${confirmationUuid}/send-code`);
  }
}