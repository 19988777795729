import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ReferralTransactionProvider} from "../ReferralTransactionProvider";

export class ReferralTransactionProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/referral/transaction`,
      headers
    );
  }

  public createReferralTransactionProvider(authToken: string): ReferralTransactionProvider {
    return new ReferralTransactionProvider(this.apiClientFactory.createAuthorizedClient(authToken));
  }
}