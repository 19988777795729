import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import './ProjectFavorite.scoped.scss';
import {cn} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const ProjectFavorite: FC<PropsWithChildren<Props>> = ({children, ...props}) => {
  return (
    <div {...props} className={cn("project-favorite", props.className)}>
      { children }
    </div>
  );
};

export default ProjectFavorite;