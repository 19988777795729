import React, {FC} from "react";
import Modal from "../Modal";
import {InvestmentConfirmationResult} from "../../../../api-client";
import {createInvestmentManager, createProfileManager} from "../../../../di";
import {RouteDictionary, sleep} from "../../../../utils";
import {set as setProfile} from "../../../../store/profile";
import {useAppDispatch, useNavigateByName} from "../../../../hooks";
import {InvestmentConfirmation} from "../../../../api-client";
import {ConfirmSmsCodeForm} from "../../utils";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";
import {set} from "../../../../store/overlay";


//TODO: REWORK TO ONE UUID
interface Props extends ModalProps {
    investmentConfirmation: InvestmentConfirmation;
    oldConfirmationUuid: string;
}

const InvestmentConfirmationModal: FC<Props> = ({active, onClose, oldConfirmationUuid}) => {
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  const sendCode = async (smsCode: string): Promise<InvestmentConfirmationResult> =>  {
    const manager = await createInvestmentManager();
    return await manager.createInvestment(oldConfirmationUuid, smsCode);
  };

  const resendCode = async (): Promise<void> => {
    await sleep(3000);
  };

  const onSuccess = async (): Promise<void> => {
    const manager = await createProfileManager();
    const profile = await manager.get();
    dispatch(setProfile(profile));
    dispatch(set(false));
    navigate(RouteDictionary.MY_PROJECTS);
  };
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение" onClose={onClose}>
        <ConfirmSmsCodeForm
          sendCallback={sendCode}
          resendCallback={resendCode}
          successCallback={onSuccess}
          prefix="invest-conf"
        />
      </SmsConfirmation>
    </Modal>
  );
};

export default InvestmentConfirmationModal;