import React from "react";
import {Container, Section} from "../../../../../components";
import './MyProjects.scoped.scss';
import {Outlet} from "react-router-dom";

const MyProjects = () => {
  return (
    <Section>
      <Container>
        <Outlet />
      </Container>
    </Section>
  );
};

export default MyProjects;