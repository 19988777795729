import React, {FC, useMemo} from "react";
import {RangeSlider} from "../../../common";
import {SliderProps} from "rc-slider";
import {RangeSliderProps} from "../../../common/selects/RangeSlider/RangeSlider";
import {MAX_YEAR_PROFIT, MIN_YEAR_PROFIT} from "../../../../utils";
import "./YearProfitSlider.scoped.scss";

interface Props {
    currentValue: Array<number | undefined | null>
}

const YearProfitSlider: FC<SliderProps & RangeSliderProps & Props> = ({handleChange, currentValue, ...props}) => {
  const value = useMemo((): Array<number> => {
    return [
      currentValue[1] === 0 || currentValue[0] === undefined || currentValue[0] === null? MIN_YEAR_PROFIT : currentValue[0],
      currentValue[1] === 0 || currentValue[1] === undefined || currentValue[1] === null ? MAX_YEAR_PROFIT : currentValue[1],
    ];
  }, [currentValue]);
  return (
    <div>
      <RangeSlider
        {...props}
        min={1}
        max={100}
        defaultValue={[1, 100]}
        // marks={{1: "1%", 100: "Без ограничений"}}
        pushable={true}
        handleChange={handleChange}
        value={value}
      />
      <div className="slider-labels">
        <div>
          { currentValue[0] ?  currentValue[0] + "%" : "1%"}
        </div>
        <div>
          { currentValue[1] && (currentValue[1] < MAX_YEAR_PROFIT) ?  currentValue[1] + "%" : "Нет лимита"}
        </div>
      </div>
    </div>
  );
};

export default YearProfitSlider;