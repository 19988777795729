import React, {FC, useCallback} from "react";
import {File} from "../../../../api-client";
import {formatFileSize} from "../../../../formatters";

interface Props {
    file: File
}

const FileSize: FC<Props> = ({file}) => {
  const renderSize = useCallback(() => {
    if (file.size === undefined) {
      return "Не указан";
    }
    const fileSizeResponse = formatFileSize(file.size);
    return `${fileSizeResponse.size} ${fileSizeResponse.type}`;
  }, []);
  return <>
    { renderSize() }
  </>;
};

const MemoizedFileSize = React.memo(FileSize);

export default MemoizedFileSize;