import React, {FC, useEffect, useRef} from "react";
import {cn} from "../../../utils";
import './AlertNotification.scss';
import {useAlertNotifications, useAppDispatch} from "../../../hooks";
import {remove} from "../../../store/alertNotifications";
import {CloseButton} from "../../common";
import {useMountAnimation} from "../../pages/detailed-project/hooks";
import {CSSTransition} from "react-transition-group";

export enum AlertNotificationType {
    ERROR = "error",
    WARNING = "warning",
    INFO = "info"
}

export interface AlertNotificationData {
    id: number,
    type: AlertNotificationType,
    message: string
}

interface Props {
    data: AlertNotificationData
}

const AlertNotification: FC<Props> = ({data}) => {
  const dispatch = useAppDispatch();
  const timerId = useRef(0);
  useEffect(() => {
    timerId.current = window.setTimeout(() => {
      handleClose(data.id);
    }, 5000);
    return () => {
      clearTimeout(timerId.current);
    };
  }, []);
  const handleClose = (id: number) => {
    dispatch(remove(id));
  };
  return (
    <div className={cn("alert-notification", `alert-notification--${data.type}`)}>
      <div className="alert-notification__text">
        { data.message }
      </div>
      <div>
        <CloseButton>
          <button className="alert-notification__close" onClick={() => handleClose(data.id)}>
          </button>
        </CloseButton>
      </div>
    </div>
  );
};

export default AlertNotification;