import React, {FC} from "react";
import './MyActiveProject.scoped.scss';
import {ProjectFavorite} from "../../../projects";
import FavoriteSvg from "../../../../svg/FavoriteSvg";
import {BoldText, ColoredText, CustomLink, Money, PrimaryButton} from "../../../../common";
import {GridRowHeader} from "../../../../layout/grid";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../utils";
import {LoanPaymentProjectInvestment} from "../../../../../api-client";
import {TextColor} from "../../../../common/typography/texts/ColoredText/ColoredText";
import {ProjectCategory} from "../../../../project";
import {NotificationSvgColor} from "../../../../svg/NotificationsSvg";
import {NotificationsSvg} from "../../../../svg";
import {getAmountWithCommission} from "../../../../../utils/getAmountWithCommission";

interface Props {
    investment: LoanPaymentProjectInvestment;
}

const MyActiveProject: FC<Props> = ({investment}) => {
  const linkToTransactions = `/dashboard/transactions?filter%5BprojectUuids%5D%5B%5D=${investment.project.uuid}`;
  return (
    <li className="active-project">
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className={"active-project__link-to-project active-project__column active-project__primary"}>
        <div>
          <div className="active-project__title">
            <ProjectFavorite>
              <FavoriteSvg width={8} height={10} active/>
              <div>
                {investment.project.paymentCode}
              </div>
            </ProjectFavorite>
            <BoldText>
              <div className="active-project__name">
                {investment.project.name[0].text}
              </div>
            </BoldText>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__category">
        <div>
          <GridRowHeader>
            Категория
          </GridRowHeader>
          <div className="active-project__text">
            <BoldText>
              <ProjectCategory debtClass={investment.project.debtClass} delimiter="/"/>
            </BoldText>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__sum">
        <div>
          <GridRowHeader>
            Инвестировано
          </GridRowHeader>
          <div className="active-project__text">
            <Money money={getAmountWithCommission(investment.investedSum.amount)}/>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__actual-payment">
        <div>
          <GridRowHeader>
            Фактическая выплата
          </GridRowHeader>
          <div className="active-project__text">
            <Money money={investment.actualPayment}/>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__forecast">
        <div>
          <GridRowHeader>
            Прогноз взыскания
          </GridRowHeader>
          <div className="active-project__text">
            <Money money={investment.recoveryForecast}/>
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__term">
        <div>
          <GridRowHeader>
            Срок
          </GridRowHeader>
          <div className="active-project__text">
            {investment.project.initialTerm} мес.
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__simple">
        <div>
          <GridRowHeader>
            Доходность простая
          </GridRowHeader>
          <div className="active-project__text">
            {investment.project.simpleInterestRate?.toFixed(2)}%
          </div>
        </div>
      </CustomLink>
      <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}}
        className="active-project__link-to-project active-project__column active-project__yearly">
        <div>
          <GridRowHeader>
            Доходность годовая
          </GridRowHeader>
          <div className="active-project__text">
            {investment.project.interestRate?.toFixed(2)}%
          </div>
        </div>
      </CustomLink>
      <div className="active-project__column active-project__transactions">
        <GridRowHeader>
          Транзакции
        </GridRowHeader>
        <ColoredText color={TextColor.PRIMARY_GREEN}>
          <a href={linkToTransactions}>Смотреть</a>
        </ColoredText>
      </div>
      <div className="active-project__column active-project__stages">
        <GridRowHeader>
          События
        </GridRowHeader>
        <CustomLink to={RouteDictionary.PROJECT_STAGES} params={{uuid: investment.project.uuid}}
          className={"active-project__stages--link"}>
          <NotificationsSvg color={NotificationSvgColor.GREEN}/>
        </CustomLink>
      </div>
      <div className="active-project__column active-project__actions">
        <PrimaryButton expanded className="active-project__link" color={PrimaryButtonColor.GREEN}>
          <CustomLink to={RouteDictionary.CREATE_OFFER}
            params={{investmentUuid: investment.investments[0].uuid}}>
            Продать
          </CustomLink>
        </PrimaryButton>
      </div>
    </li>
  );
};

export default MyActiveProject;