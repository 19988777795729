import React, {Children, FC, isValidElement} from "react";
import {ChildrenProps, cn} from "../../../../utils";
import './RadioButtonGroup.scoped.scss';
import {RadioButton} from "../index";

const RadioButtonType = React.createElement(RadioButton).type;

export enum Direction {
    HORIZONTAL = "radio-button-group--horizontal",
    VERTICAL = "radio-button-group--vertical",
}

interface Props extends ChildrenProps {
    direction: Direction;
}

const RadioButtonGroup: FC<Props> = ({direction, children}) => {
  Children.map(children, child => {
    if (!isValidElement(child) || child.type !== RadioButtonType) {
      throw new Error(`Expected RadioButton type of component`);
    }
  });
  return (
    <>
      <div className={cn("radio-button-group", direction)}>
        { children }
      </div>
    </>
  );
};

export default RadioButtonGroup;