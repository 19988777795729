import {ApiClientInterface} from "./clients";
import {SurveyAnswer} from "./models";

export class SurveyManager {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public async createAnswer(answer: SurveyAnswer): Promise<void> {
    return this.apiClient.post("", {answer});
  }
}