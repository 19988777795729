import {Modal} from "../index";
import React, {SyntheticEvent, useState} from "react";
import {BoldText} from "../../typography";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {PrimaryButton} from "../../buttons";
import "./SurveyConfirmationModal.scoped.scss";
import {SurveyAnswer} from "../../../../api-client";
import {createSurveyManager} from "../../../../di";
import {useAppDispatch, useErrorCallback, useNavigateByName} from "../../../../hooks";
import {RouteDictionary} from "../../../../utils";
import {set} from "../../../../store/overlay";
import {InputErrors} from "../../inputs";

interface Props {
  active: boolean,
  onClose: () => void,
  surveyAnswer: SurveyAnswer
}

const SurveyConfirmationModal = ({active, onClose, surveyAnswer}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const handleError = useErrorCallback();
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const manager = await createSurveyManager();
      await manager.createAnswer(surveyAnswer);
      onClose();
      dispatch(set(false));
      navigate(RouteDictionary.MY_MONEY);
    } catch (err) {
      if (err instanceof Error) {
        await handleError(err);
        setError(err.message);
      }
    }
    finally {
      setLoading(false);
    }
  };
  if (loading) return <div>Отправка...</div>;

  return <Modal active={active} onClose={onClose}>
    <div className="survey-confirmation-modal__container">
      {error && <InputErrors errors={["Произошла ошибка"]}/>}
      <BoldText>
        <div className="survey-confirmation-modal__title">Подтвердить отправку формы?</div>
      </BoldText>
      <div className="survey-confirmation-modal__button-group">
        <PrimaryButton color={PrimaryButtonColor.GREEN} className="survey-confirmation-modal__button-yes">
          <button type="submit" onClick={handleSubmit}>
            Да
          </button>
        </PrimaryButton>
        <PrimaryButton color={PrimaryButtonColor.GREEN} className="survey-confirmation-modal__button-cancel">
          <button type="button" onClick={onClose}>
            Отмена
          </button>
        </PrimaryButton>
      </div>
    </div>
  </Modal>;
};

export default SurveyConfirmationModal;