import React, {useState} from "react";
import {questions} from "../../../../utils/pages/withdraw/faq";
import {formatMultilineText} from "../../../../formatters";
import {cn} from "../../../../utils";
import './WithdrawFAQ.scoped.scss';
import {useEffectOnUpdate} from "../../../../hooks";

const WithdrawFAQ = () => {
  const [activeIndexes, setActiveIndexes] = useState<Array<number>>([]);

  const isIndexActive = (index: number): boolean => {
    return activeIndexes.includes(index);
  };

  const toggleIndex = (index: number): void => {
    if (isIndexActive(index)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== index));
      return;
    }
    setActiveIndexes([...activeIndexes, index]);
  };

  const renderQuestions = questions.map((item) => {
    return <li key={item.id} className="withdraw-faq__element">
      <div
        className="withdraw-faq__question"
        onClick={() => toggleIndex(item.id)}
      >
        { item.question }
      </div>
      <div
        className={cn(
          "withdraw-faq__answer",
          `${isIndexActive(item.id) ? "active" : ""}`
        )}
      >
        <div className="withdraw-faq__content" dangerouslySetInnerHTML={{__html: formatMultilineText(item.answer)}}></div>
      </div>
    </li>;
  });

  return (
    <div className="withdraw-faq">
      <ul className="withdraw-faq__list">
        { renderQuestions }
      </ul>
    </div>
  );
};

export default WithdrawFAQ;