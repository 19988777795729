import React, {AllHTMLAttributes, FC} from "react";
import './RotatedOpener.scoped.scss';
import {cn, resultIf} from "../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    active: boolean;
    onClicked?: (active: boolean) => void;
}

const RotatedOpener: FC<Props> = ({active, onClicked, ...props}) => {
  const toggle = () => {
        onClicked!(!active);
  };
  return (
    <div
      {...props}
      className={cn(
        "rotated-opener",
        resultIf(active, "active"),
        props.className
      )}
      onClick={resultIf(onClicked !== undefined, toggle)}
    >
    </div>
  );
};

export default RotatedOpener;
