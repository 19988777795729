import React, {FC, useContext} from "react";
import {PaginationContext, Paginator} from "../index";

const PaginationPaginator: FC<any> = () => {
  const context = useContext(PaginationContext);
  return (
    <>
      { context.perPage !== 0 && context.count > 0 && <Paginator /> }
    </>
  );
};

export default PaginationPaginator;