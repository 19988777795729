import React, {useContext, useEffect, useState} from 'react';
import {RouteDictionary} from "../../../../../utils";
import {useNavigateByName} from "../../../../../hooks";
import {useParams} from "react-router-dom";
import {LogoType} from "../../../../../components/common/images/types/LogoImage";
import {LogoImage} from "../../../../../components/common/images/types";
import {Button, Modal, PrimaryButton} from "../../../../../components";
import {PrimaryButtonColor} from "../../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import './OrderConfirmation.scoped.scss';
import {
  DetailedProjectContext
} from "../../../../../components/pages/detailed-project/DetailedProjectContext/DetailedProjectContext";

const OrderConfirmation = () => {
  const {uuid} = useParams();
  const project = useContext(DetailedProjectContext).project;
  const navigate = useNavigateByName();
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (!active) {
      navigate(RouteDictionary.DETAILED_PROJECT, {uuid: uuid});
    }
  }, [active]);

  return (
    <Modal active={active} onClose={() => setActive( false)}>
      <Modal.Close/>
      <LogoImage className="order-confirmation__logo" type={LogoType.GREEN} />
      <div className="order-confirmation">
        
        <div className="order-confirmation__success">Успешно!</div>
        <div className="order-confirmation__description">Вы подали заявку на участие в проекте {project.name[0].text}</div>
        <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
          <Button onClick={() => setActive( false)}>
            Отлично
          </Button>
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default OrderConfirmation;