import {ApiClientInterface} from "./clients";
import {InitialProjectSettings, SetInitialProjectSettings} from "./models";
import buildQuery from "../utils/router/buildQuery";
import {AutoInvestmentFilter} from "../views/dashboard/lender/investment/projects/PrimaryMarket/PrimaryMarket";

export class ProjectAutoInvestmentSettingsManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public setLenderSettings(settings: SetInitialProjectSettings): Promise<void> {
    return this.apiClient.post("/", {...settings});
  }

  public getList(page = 1, perPage = 25, filter: AutoInvestmentFilter): Promise<InitialProjectSettings>{
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/?${query}`);
  }

  public getOne(projectUuid: string): Promise<InitialProjectSettings>{
    return this.apiClient.get(`/${projectUuid}`);
  }
}