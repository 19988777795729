import React, {FC, HTMLAttributes, useEffect} from "react";
import {DebtClass} from "../../../api-client";

interface Props extends HTMLAttributes<HTMLSpanElement> {
    debtClass: DebtClass;
    delimiter?: string;
}

const ProjectCategory: FC<Props> = ({debtClass, delimiter, ...props}) => {
  return (
    <div {...props}>
      {debtClass.economicExpert === null || debtClass.legalExpert === null
        ? "Не указан"
        : `${debtClass.economicExpert}${delimiter ?? ""}${debtClass.legalExpert}`
      }
    </div>
  );
};

export default ProjectCategory;