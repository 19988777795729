import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const mainRoutes: Array<NamedUrl> = [
  {
    name: RouteDictionary.INDEX,
    url: "/"
  },
  {
    name: RouteDictionary.RULES_SERVICE,
    url: "/rules_service"
  },
  {
    name: RouteDictionary.RULES_AUTOINVEST,
    url: "/rules_autoinvest"
  },
  {
    name: RouteDictionary.PERSONAL_DATA_POLICY,
    url: "/personal_data_policy"
  },
  {
    name: RouteDictionary.RULES_REGISTRATION,
    url: "/rules_registration"
  },
  {
    name: RouteDictionary.FAQ,
    url: "/faq"
  },
  {
    name: RouteDictionary.PLATFORM,
    url: "/platform"
  },
  {
    name: RouteDictionary.ABOUT_US,
    url: "/about-us"
  },
  {
    name: RouteDictionary.NEWS,
    url: "/news"
  },
  {
    name: RouteDictionary.HOW_IT_WORKS,
    url: "/how-it-works"
  },
  {
    name: RouteDictionary.ENTER,
    url: "/enter"
  },
  {
    name: RouteDictionary.NOT_FOUND,
    url: "*"
  }
];