import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useErrorCallback, useNavigateByName} from "../../../../../../hooks";
import {createAutoInvestmentSettingsManager,} from "../../../../../../di";
import {RouteDictionary} from "../../../../../../utils";
import {Button, CloseButton, PrimaryButton} from "../../../../../../components";
import IFrame from "../../../../../../components/common/utils/IFrame";
import {PrimaryButtonColor} from "../../../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import useBodyOverflow from "../../../../../../hooks/useBodyOverflow";
import './AutoInvestConfirmation.scoped.scss';
import AutoInvestmentConfirmationModal
  from "../../../../../../components/common/modals/AutoInvestmentConfirmationModal";
import {
  AutoInvestmentConfirmation
} from "../../../../../../api-client/models/autoinvestment/AutoInvestmentConfirmation";

const AutoInvestConfirmation = () => {
  const handleError = useErrorCallback();
  const [confirmation, setConfirmation] = useState<AutoInvestmentConfirmation|null>(null);
  const [codeSent, setCodeSent] = useState(false);
  const {confirmationUuid} = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigateByName();

  useBodyOverflow();

  useEffect(() => {
    const requestConfirmation = async () => {
      setLoading(true);
      try {
        const manager = await createAutoInvestmentSettingsManager();
        const result = await manager.getConfirmation(confirmationUuid!);
        setConfirmation(result);
      } catch (err: any) {
        await handleError(err);
        setConfirmation(null);
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await requestConfirmation();
    })();
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      const manager = await createAutoInvestmentSettingsManager();
      await manager.sendCode(confirmationUuid!);
      setCodeSent(true);
    } catch (err: any) {
      await handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const closePage = () => {
    navigate(RouteDictionary.AUTOINVEST);
  };

  return (
    <div className="investment-confirmation">
      <div className="investment-confirmation__close">
        <CloseButton>
          <Button onClick={closePage} />
        </CloseButton>
      </div>
      <div className="investment-confirmation__content">
        <div className="investment-confirmation__block">
          <div className="investment-confirmation__header">
            Агентское поручение
          </div>
          <div className="investment-confirmation__text">
            { loading && "Загружаем" }
            {confirmation && <>
              <div className="investment-confirmation__document">
                <IFrame src={confirmation.agreementLink} />
              </div>
              <div>
                <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
                  <Button onClick={handleSubmit}>
                    Подтвердить
                  </Button>
                </PrimaryButton>
              </div>
            </> }
          </div>
          <AutoInvestmentConfirmationModal
            active={codeSent}
            onClose={() => setCodeSent(false)}
            autoInvestmentConfirmation={{confirmationUuid: confirmationUuid!}}
          />
        </div>
      </div>
    </div>
  );
};

export default AutoInvestConfirmation;