import React, {useContext} from "react";
import IdentificationFileUpload from "../IdentificationFileUpload";
import IdentificationContext from "../../../context/IdentificationContext";
import {UploadedFile} from "../../../../api-client";

const SecondScanUpload = () => {
  const context: any = useContext(IdentificationContext);

  const loadFiles = (files: Array<UploadedFile>) => {
    context.setSecondPassportScans(files);
  };

  return (
    <IdentificationFileUpload
      prefix="second"
      text="Разворот с регистрацией"
      files={context.secondPassportScans}
      onUploaded={loadFiles}
    />
  );
};

export default SecondScanUpload;