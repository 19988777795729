import React, {createContext, PropsWithChildren} from "react";
import {Filter} from "../../../../../components/pages/projects/filter/ProjectsFilter/ProjectsFilter";

export type ProjectsContextType = {
    filter: Filter;
    setFilter: (filter: Filter) => void;
    filterActive: boolean;
    toggleFilter: () => void;
    favoritesActive: boolean;
    toggleFavorites: () => void;
}

type Props = {
    props: ProjectsContextType;
}

export const ProjectsContext = createContext<ProjectsContextType>({
  filter: {
    search: "",
    economicClasses: [],
    legalClasses: [],
    initialTermFrom: 1,
    initialTermTo: 15,
    interestRateFrom: 1,
    interestRateTo: 100,
    statuses: []
  },
  setFilter: () => {},
  filterActive: false,
  toggleFilter: () => {},
  favoritesActive: false,
  toggleFavorites: () => {}
});

function ProjectsContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <ProjectsContext.Provider value={value.props}>
    { children }
  </ProjectsContext.Provider>;
}

export default ProjectsContextProvider;