import React, {Children, FC, isValidElement} from "react";
import {ChildrenProps, cn} from "../../utils";

type Props = {
    classNames: string;
    oldClassName?: string | undefined;
} & ChildrenProps;

const ClassInjector: FC<Props> = ({classNames, oldClassName, children}) => {
  if (Children.count(children) > 1) {
    throw new Error("More than one child is passed to class injector");
  }
  const StyledChildren = () => {
    return Children.map(children, child => {
      if (isValidElement(child)) {
        return React.cloneElement(child, {
          // @ts-ignore
          className: `${cn(child.props.className, classNames, oldClassName ?? "")}`
        });
      }
    });
  };

  // @ts-ignore
  return <StyledChildren />;
};

export default ClassInjector;