import React, {FC, useEffect, useState} from "react";
import {
  Application, AutoInvestmentSettings,
  DetailedProject as DetailedProjectModel,
  InitialProjectSettings,
  isFundRaising,
  Money as MoneyModel
} from "api-client";
import "./DetailedProject.scoped.scss";
import {Outlet} from "react-router-dom";
import ProjectMain from "../../pages/detailed-project/ProjectMain";
import DetailedProjectContextProvider from "../../pages/detailed-project/DetailedProjectContext";
import {DetailedProjectContextType} from "../../pages/detailed-project/DetailedProjectContext/DetailedProjectContext";
import ProjectInvest from "../../pages/detailed-project/ProjectInvest";
import {CustomLink, Image} from "../../common";
import {RouteDictionary} from "../../../utils";
import DetailedProjectMobileNavigation from "../../pages/detailed-project/DetailedProjectMobileNavigation";
import arrowBack from "../../../assets/images/svg/header_user-arrow.svg";
import ProjectAutoInvest from "../../pages/detailed-project/ProjectAutoInvest";
import {
  createApplicationManager,
  createAutoInvestmentSettingsManager,
  createProjectAutoInvestmentSettingsManager
} from "../../../di";
import {useErrorCallback} from "../../../hooks";
import ProjectAutoInvestResults from "components/pages/detailed-project/ProjectAutoInvestResults";

type Props = {
    project: DetailedProjectModel
}

const DetailedProject: FC<Props> = ({project}) => {
  const value: DetailedProjectContextType = {
    project: project
  };
  const [loading, setLoading] = useState(false);
  const [resultAI, setResultAI] = useState<Application | null>(null);
  const [autoInvestmentSettingsGlobal,
    setAutoInvestmentSettingsGlobal] = useState<AutoInvestmentSettings | null>(null);
  const [activeToggle, setActiveToggle] = useState<boolean>(false);
  const [autoInvestmentSettings,
    setAutoInvestmentSettings] = useState<InitialProjectSettings | null>(null);
  const [minSum, setMinSum] = useState<MoneyModel>(new MoneyModel(0, "RUB"));
  const [maxSum, setMaxSum] = useState<MoneyModel>(new MoneyModel(0, "RUB"));
  const [left, setLeft] = useState<MoneyModel>(new MoneyModel(0, "RUB"));

  const handleError = useErrorCallback();

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const applicationManager = await createApplicationManager();
        const result = await applicationManager.getOne(project.uuid);
        setResultAI(result);
        const projectManager = await createProjectAutoInvestmentSettingsManager();
        const resultAISettings = await projectManager.getOne(project.uuid);
        const manager = await createAutoInvestmentSettingsManager();
        const resultAISettingsGlobal = await manager.getUserSettings();
        setAutoInvestmentSettingsGlobal(resultAISettingsGlobal);
        setAutoInvestmentSettings(resultAISettings);
        if (resultAISettings) {
          setActiveToggle(resultAISettings.isActivated);
        }
        if (resultAISettingsGlobal) {
          setLeft(new MoneyModel(resultAISettingsGlobal.maxTotalAmount.amount - resultAISettingsGlobal.totalInvestedAmount.amount,
            resultAISettingsGlobal.maxTotalAmount.currencyCode));
        }
        if (resultAISettings.minAmount) setMinSum(resultAISettings.minAmount);
        else if (resultAISettingsGlobal) setMinSum(resultAISettingsGlobal.minAmount);
        if (resultAISettings.maxAmount) setMaxSum(resultAISettings.maxAmount);
        else if (resultAISettingsGlobal) setMaxSum(resultAISettingsGlobal.maxAmount);
      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [project.uuid]);
  const props = {
    activeToggle,
    maxSum,
    minSum,
    setActiveToggle,
    autoInvestmentSettingsGlobal,
    setMinSum,
    setMaxSum,
    autoInvestmentSettings,
    left
  };
  return (
    <DetailedProjectContextProvider props={value}>
      <CustomLink className="detailed-project-back" to={RouteDictionary.PRIMARY_MARKET}>
        <Image src={arrowBack}/>
      </CustomLink>
      <div className="detailed-project">
        <ProjectMain/>
        {isFundRaising(project.status) && <ProjectInvest/>}
        {loading ? <div>Loading...</div> : !resultAI && <ProjectAutoInvest {...props}/>}
        {resultAI && <ProjectAutoInvestResults result={resultAI} minSum={minSum} maxSum={maxSum} activeToggle={activeToggle}/>}
        <div className="detailed-project-outlet">
          <DetailedProjectMobileNavigation/>
          <Outlet/>
        </div>
      </div>
    </DetailedProjectContextProvider>
  );
};

export default DetailedProject;