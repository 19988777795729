import React, {useMemo, useState} from "react";
import {DataCollection} from "../../../../utils";
import {Collection, Notification as NotificationModel} from "../../../../api-client";
import {useErrorCallback, useProfileData} from "../../../../hooks";
import {Block} from "../../../layout";
import NotificationsContextProvider, {
  NotificationsFilter,
  NotificationsFilterContextType
} from "../NotificationsFilterContext";
import NotificationsTypeOptionBox from "../NotificationsTypeOptionBox";
import './NotificationsList.scoped.scss';
import {Notification} from "../index";
import {useWindowWidth} from "../../detailed-project/hooks";
import {GearButton} from "../../../common";
import NotificationModal from "../../../common/modals/NotificationModal";
import {createNotificationManager} from "../../../../di";
import {Pagination} from "../../../pagination";
import {Filter} from "../../projects/filter/ProjectsFilter/ProjectsFilter";
import {useDispatch} from "react-redux";
import {changeNotificationCount} from "../../../../store/profile";


const NotificationsList = () => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications]
        = useState<DataCollection<NotificationModel>>(new DataCollection());
  const [filter, setFilter] = useState<NotificationsFilter>({isRead: true});
  const isMobile = useWindowWidth(992);
  const handleError = useErrorCallback();
  const profileData = useProfileData();
  const dispatch = useDispatch();

  const loadData = async (page: number, perPage: number, filter: NotificationsFilter) => {
    setLoading(true);
    try {
      const manager = await createNotificationManager();
      const list = await manager.getList(filter, page, perPage);
      const collection = new Collection(list.items, list.totalCount, list.totalCount);
      const dataCollection = new DataCollection<NotificationModel>();
      dataCollection.setCollection(collection);
      setNotifications(dataCollection);
      const notReadNotifications = list.items.filter(item => !item.isRead).length;
      await manager.markNotificationRead(list.items.filter(item => !item.isRead)
        .map((item) => item.uuid));
      dispatch(changeNotificationCount(profileData.newNotificationCount - notReadNotifications));
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const notificationsList = useMemo(() => {
    return notifications.items.map((notification) => {
      return <Notification notification={notification} key={notification.uuid} />;
    });
  }, [notifications]);
  const value: NotificationsFilterContextType = {
    filter,
    setFilter
  };

  const [editModalOpened, setEditModalOpened] = useState(false);

  return (
    <NotificationsContextProvider props={value}>
      <div className="notifications-list">
        <Pagination onLoad={loadData} count={notifications.count} totalCount={notifications.totalCount} filterObject={filter}
          initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}>
          <Block>
            <Block.Content>
              <Block.Header withPadding className="notifications-list__block-header">
                <div className="notifications-list__header">
                  <Pagination.Filter>
                    <div className="notifications-list__select">
                      <div>
                        Показать:
                      </div>
                      <NotificationsTypeOptionBox />
                    </div>
                  </Pagination.Filter>
                  { isMobile && <div className="notifications-list__modal-button">
                    <GearButton onClick={() => setEditModalOpened(true)}/>
                    <NotificationModal active={editModalOpened} onClose={() => setEditModalOpened(false)} />
                  </div> }
                </div>
              </Block.Header>
              <Block.Body>
                <Pagination.Content loading={loading}>
                  { notificationsList }
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator/>
        </Pagination>
      </div>
    </NotificationsContextProvider>
  );
};

export default NotificationsList;