import React from "react";

const PrinterSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.5" height="20.66" viewBox="0 0 24.5 20.66">
      <g id="Сгруппировать_6076" data-name="Сгруппировать 6076" transform="translate(0.25 0.25)">
        <g id="Сгруппировать_6075" data-name="Сгруппировать 6075">
          <path id="Контур_22409" data-name="Контур 22409" d="M2519.08,583.8h-2.4v-3.36a1.442,1.442,0,0,0-1.44-1.44H2502.76a1.442,1.442,0,0,0-1.441,1.44v3.36h-2.4a1.922,1.922,0,0,0-1.92,1.92v7.68a1.922,1.922,0,0,0,1.92,1.92h2.4v2.4a1.442,1.442,0,0,0,1.441,1.44h12.479a1.442,1.442,0,0,0,1.44-1.44v-2.4h2.4a1.922,1.922,0,0,0,1.92-1.92v-7.68A1.922,1.922,0,0,0,2519.08,583.8Zm-16.8-3.36a.481.481,0,0,1,.481-.48h12.479a.48.48,0,0,1,.48.48v3.36h-13.44Zm13.44,17.28a.48.48,0,0,1-.48.48H2502.76a.481.481,0,0,1-.481-.48v-6.24h13.44Zm4.32-4.32a.961.961,0,0,1-.96.96h-2.4v-2.88a.961.961,0,0,0-.96-.96h-13.44a.961.961,0,0,0-.96.96v2.88h-2.4a.961.961,0,0,1-.959-.96v-7.68a.961.961,0,0,1,.959-.96h20.16a.961.961,0,0,1,.96.96Z" transform="translate(-2497 -579)" fill="#9299a2" stroke="#9299a2" strokeWidth="0.5"/>
          <path id="Контур_22410" data-name="Контур 22410" d="M2512.48,609.96h8.64a.48.48,0,0,0,0-.96h-8.64a.48.48,0,0,0,0,.96Z" transform="translate(-2504.8 -594.6)" fill="#9299a2" stroke="#9299a2" strokeWidth="0.5"/>
          <path id="Контур_22411" data-name="Контур 22411" d="M2512.48,613.96h6.24a.48.48,0,1,0,0-.96h-6.24a.48.48,0,0,0,0,.96Z" transform="translate(-2504.8 -596.68)" fill="#9299a2" stroke="#9299a2" strokeWidth="0.5"/>
        </g>
        <circle id="Эллипс_249" data-name="Эллипс 249" cx="0.985" cy="0.985" r="0.985" transform="translate(17.91 8.11)" fill="#9299a2" stroke="#9299a2" strokeWidth="0.5"/>
      </g>
    </svg>
  );
};

export default PrinterSvg;