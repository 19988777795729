import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AlertNotificationData} from "../components/alert-notifications/AlertNotification/AlertNotification";

export type AlertNotificationsState = {
    notifications: Array<AlertNotificationData>
}

const initialState: AlertNotificationsState = {
  notifications: []
};

export const alertNotificationsSlice = createSlice({
  name: "alertNotifications",
  initialState,
  reducers: {
    add(state: AlertNotificationsState, {payload}: PayloadAction<AlertNotificationData>) {
      state.notifications = [...state.notifications, payload];
    },
    remove(state: AlertNotificationsState, {payload}: PayloadAction<number>) {
      state.notifications = state.notifications.filter((item) => item.id !== payload);
    },
  }
});

export const {add, remove} = alertNotificationsSlice.actions;

export default alertNotificationsSlice.reducer;