import React, {useEffect} from "react";
import './Overlay.scoped.scss';
import {cn, resultIf} from "../../../utils";
import {set} from '../../../store/overlay';
import {useAppDispatch, useOverlayState} from "../../../hooks";

const Overlay = () => {
  const dispatch = useAppDispatch();
  const isOverlayActive = useOverlayState();

  const closeOverlay = () => {
    dispatch(set(false));
  };

  useEffect(() => {
    dispatch(set(false));
    const callback = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeOverlay();
      }
    };
    window.addEventListener('keyup', callback);
    return () => {
      window.removeEventListener('keyup', callback);
    };
  }, []);
  return (
    <>
      <div
        id="overlay"
        className={cn(resultIf(isOverlayActive, "active"))}
        onClick={closeOverlay}
      />
    </>
  );
};

export default Overlay;