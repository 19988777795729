import React, {useEffect, useState} from "react";
import {Outlet, useParams} from "react-router-dom";
import {DetailedProject as DetailedProjectComponent, Section} from "components";
import {Container} from "../../../../../components";
import {DetailedProject as DetailedProjectModel} from "api-client";
import {createProjectProvider} from "di";
import {useErrorCallback, useLoading} from "hooks";
import './DetailedProject.scoped.scss';

const DetailedProject = () => {
  const {uuid} = useParams();
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const [project, setProject] = useState<DetailedProjectModel | null>(null);
  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createProjectProvider();
        const project = await provider.getOne(uuid!);
        setProject(project);
      } catch (err: any) {
        await errorCallback(err);
        setProject(null);
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);
  return (
    <Container>
      <Section>
        { loading && <div>Загрузка...</div> }
        { !loading && project === null && <div>Проект не найден</div> }
        { project && <DetailedProjectComponent project={project} /> }
      </Section>
    </Container>
  );
};

export default DetailedProject;