import React, {useContext} from "react";
import {
  DetailedProjectContext
} from "../../../../../../components/pages/detailed-project/DetailedProjectContext/DetailedProjectContext";
import {matchPath, useLocation} from "react-router-dom";
import {BlockNavigation, CustomLink} from "../../../../../../components";
import {RouteDictionary} from "../../../../../../utils";

const SecondaryProjectNavigation = () => {
  const project = useContext(DetailedProjectContext).project;
  const {pathname} = useLocation();
  return (
    <BlockNavigation className="detailed-project-navigation">
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.OFFER_ECONOMIC_ANALYSIS}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
          explicitActive={matchPath('dashboard/project/:uuid', pathname) !== null}
        >
          Экономический анализ
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.OFFER_LEGAL_ANALYSIS}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          Юридический анализ
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.OFFER_STRATEGY}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          Стратегия взыскания
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.OFFER_DOCUMENTS}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          Документы
        </CustomLink>
      </li>
      <li className="detailed-project-navigation__element">
        <CustomLink
          to={RouteDictionary.OFFER_STAGES}
          params={{uuid: project.uuid}}
          className="detailed-project-navigation__link"
        >
          События
        </CustomLink>
      </li>
    </BlockNavigation>
  );
};

export default SecondaryProjectNavigation;