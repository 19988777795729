import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ReferralUserProvider} from "../ReferralUserProvider";

export class ReferralUserProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/referral/user`,
      headers
    );
  }

  public createReferralUserProvider(authToken: string): ReferralUserProvider {
    return new ReferralUserProvider(this.apiClientFactory.createAuthorizedClient(authToken));
  }
}