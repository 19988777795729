import React, {useMemo, useState} from "react";
import {
  Button,
  CreatePassword,
  EnterCreds,
  Image,
  MultiStepForm,
  SendCode,
  VerifyPhone,
  WithinBlockDecorator
} from "../../../components";
import {Phone} from "../../../api-client";
import './Enter.scoped.scss';
import logoImage from "../../../assets/images/svg/logo-green.svg";
import {useNavigateByName} from "../../../hooks";
import CloseButton from "../../../components/common/buttons/decorators/CloseButton";
import {RouteDictionary} from "../../../utils";

export type AuthInput = {
    phone: Phone;
    setPhone: (phone: Phone) => void;
    password: string;
    setPassword: (password: string) => void;
}

enum EnterSteps {
    EnterPhone = 0,
    SendVerificationCode = 1,
    VerifyNewPhone = 2,
    CreatePassword = 3
}

const firstStep = EnterSteps.EnterPhone;

const Enter = () => {
  const [step, setStep] = useState(firstStep);
  const [phone, setPhone] = useState<Phone>(new Phone("+7", ""));
  const [password, setPassword] = useState("");
  const navigate = useNavigateByName();

  const currentInput = useMemo((): AuthInput => {
    return {phone, setPhone, password, setPassword};
  }, [phone, setPhone, password, setPassword]);

  const canViewLogo = () => {
    return step !== EnterSteps.VerifyNewPhone;
  };

  const onClose = () => {
    navigate(RouteDictionary.INDEX);
  };

  return (
    <>
      <div className="enter-page">
        <div className="enter-page__close">
          <CloseButton>
            <Button onClick={onClose} />
          </CloseButton>
        </div>
        <div className="enter-page__form">
          { canViewLogo() && <div className="enter-page__image-container">
            <WithinBlockDecorator>
              <Image src={logoImage} alt="Logo image" className="enter-page__image" />
            </WithinBlockDecorator>
          </div> }
          <MultiStepForm step={step}>
            <EnterCreds
              input={currentInput}
              onNewNumber={() => setStep(EnterSteps.SendVerificationCode)}
              onPhoneNotVerified={() => setStep(EnterSteps.VerifyNewPhone)}
              onPasswordNotPresent={() => setStep(EnterSteps.CreatePassword)}
            />
            <SendCode
              input={currentInput}
              onBack={() => setStep(EnterSteps.EnterPhone)}
              onSubmit={() => setStep(EnterSteps.VerifyNewPhone)}
            />
            <VerifyPhone
              input={currentInput}
              onBack={() => setStep(EnterSteps.EnterPhone)}
              onPhoneVerified={() => setStep(EnterSteps.CreatePassword)}
            />
            <CreatePassword
              input={currentInput}
              onRegister={() => setStep(EnterSteps.EnterPhone)}
            />
          </MultiStepForm>
        </div>
        <div className="enter-page__disclaimer">
          <span>
            Никому не сообщайте код из СМС или свой пароль.
            Сотрудник Платформы никогда их не спросит.
            Убедитесь, что код пришел от отправителя Conomica.
            Проверяйте сайт Платформы в адресной строке: https://conomica.ru/
          </span>
        </div>
      </div>
    </>
  );
};

export default Enter;