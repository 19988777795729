export enum RouteDictionary {
    REDIRECT_AFTER_AUTH = "redirect-after-auth",
    LOGOUT = "logout",
    INDEX = "index",
    RULES_AUTOINVEST = "rules_autoinvest",
    RULES_REGISTRATION = "rules_registration",
    RULES_SERVICE = "rules_service",
    PERSONAL_DATA_POLICY = "personal_data_policy",
    FAQ = "faq",
    PLATFORM = "platform",
    ABOUT_US = "about-us",
    NEWS = "news",
    HOW_IT_WORKS = 'how-it-works',
    ENTER = "enter",
    PROFILE = "profile",
    REFERRAL = "referral",
    DOCUMENTS = "my-documents",
    DOCUMENTS_TO_PROJECT = "documents-to-project",
    PLATFORM_DOCUMENTS = "platform-documents",
    AUTOINVEST_DOCUMENTS = "autoinvest-documents",
    PROFILE_CONTROL = "control",
    NOTIFICATIONS = "notifications",
    SURVEY = "survey",
    TRANSACTIONS = "transactions",
    DETAILED_TRANSACTION = "transaction",
    IDENTIFICATION = "identification",
    MY_MONEY = "my-money",
    MY_PROJECTS = "my-projects",
    FUNDRAISING_PROJECTS = "fundraising-projects",
    ACTIVE_PROJECTS = "active-projects",
    ORDERS_PROJECTS = "orders-projects",
    MY_ARCHIVE = "my-archive",
    WITHDRAW = "withdraw",
    DEPOSIT = "deposit",
    PRIMARY_MARKET = "primary-market",
    SECONDARY_MARKET = "secondary-market",
    DETAILED_PROJECT = "project",
    PROJECT_ECONOMIC_ANALYSIS = "project-economic-analysis",
    PROJECT_LEGAL_ANALYSIS = "project-legal-analysis",
    PROJECT_STRATEGY = "project-strategy",
    PROJECT_DOCUMENTS = "project-documents",
    PROJECT_STAGES = "projects-stages",
    ARCHIVE = "archived",
    AUTOINVEST = "autoinvest",
    AUTOINVEST_CONFIRMATION = "autoinvest-confirmation",
    SIGN_CONTRACT = "sign-contract",
    INVESTMENT_CONFIRMATION = "investment-confirmation",
    ORDER_CONFIRMATION = "order-confirmation",
    NOT_FOUND = 'not-found',
    CREATE_OFFER = 'create-offer',
    OFFER_CONFIRMATION = 'offer-confirmation',
    PURCHASE_OFFER = 'purchase-offer',
    PURCHASE_CONFIRMATION = 'purchase-confirmation',
    OFFER_ECONOMIC_ANALYSIS = "offer-economic-analysis",
    OFFER_LEGAL_ANALYSIS = "offer-legal-analysis",
    OFFER_STRATEGY = "offer-strategy",
    OFFER_DOCUMENTS = "offer-documents",
    OFFER_STAGES = "offers-stages",
}