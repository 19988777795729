import React, {FC, useContext} from "react";
import './ProjectsFilterModal.scoped.scss';
import Modal from "../Modal";
import ProjectsFilterClearAll from "../../../pages/projects/filter/ProjectsFilterClearAll";
import {ProjectsContext} from "../../../../views/dashboard/lender/investment/projects/ProjectsContext";
import {Flex} from "../../../layout";
import {AlignItems} from "../../../layout/Flex/Flex";
import {EconomicClasses, LegalClasses, MaturityDateSlider, YearProfitSlider} from "../../../pages";
import {useEffectOnUpdate, useProjectsFilter} from "../../../../hooks";
import ProjectFilterFields from "../../../pages/projects/filter/ProjectFilterFields";
import {Button, TertiaryButton} from "../../buttons";
import {TertiaryButtonColor} from "../../buttons/decorators/TertiaryButton/TertiaryButton";
import ProjectFilterActive from "../../../pages/projects/filter/ProjectFilterActive";
import {ModalProps} from "../Modal/Modal";

const ProjectsFilterModal: FC<ModalProps> = ({active, onClose}) => {
  const projectsFilter = useProjectsFilter();

  return (
    <Modal active={active} onClose={onClose} className="projects-filter-modal">
      <header className="projects-filter-modal__header">
        <Flex alignItems={AlignItems.CENTER} gap={"1rem"}>
          <Modal.Close className="projects-filter-modal__close" />
          <div className="projects-filter-modal__heading">
            Фильтр
          </div>
        </Flex>
        <div>
          <ProjectsFilterClearAll />
        </div>
      </header>
      <main className="projects-filter-modal__main">
        <div className="projects-filter-modal__block">
          <div className="projects-filter-modal__title">
            Категория долга
          </div>
          <div className="projects-filter-modal__classes">
            <div className="projects-filter-modal__head">
              Экономическая сторона
            </div>
            <EconomicClasses
              selectedClasses={projectsFilter.economicClasses}
              onSelected={projectsFilter.setEconomicClasses}
            />
          </div>
          <div className="projects-filter-modal__title">
            Категория долга
          </div>
          <div className="projects-filter-modal__classes">
            <div className="projects-filter-modal__head">
              Юридическая сторона
            </div>
            <LegalClasses
              selectedClasses={projectsFilter.legalClasses}
              onSelected={projectsFilter.setLegalClasses}
            />
          </div>
        </div>
        <div className="projects-filter-modal__block">
          <div className="projects-filter-modal__slider">
            <div className="projects-filter-modal__title">
              Срок взыскания
            </div>
            <div>
              <MaturityDateSlider
                handleChange={projectsFilter.handleMaturityDateChange}
                currentValue={[projectsFilter.initialTermFrom, projectsFilter.initialTermTo]}
              />
            </div>
          </div>
          <div className="projects-filter-modal__slider">
            <div className="projects-filter-modal__title">
              Годовая доходность
            </div>
            <div className="projects-filter-modal__slider">
              <YearProfitSlider
                handleChange={projectsFilter.handleYearProfitChange}
                currentValue={[projectsFilter.interestRateFrom, projectsFilter.interestRateTo]}
              />
            </div>
          </div>
        </div>
        <div className="projects-filter-modal__block projects-filter-modal__filter-clear">
          <div>
            <ProjectFilterActive>
              <ProjectFilterFields />
            </ProjectFilterActive>
          </div>
          <div>
            <TertiaryButton expanded large color={TertiaryButtonColor.GREEN}>
              <Button onClick={projectsFilter.handleSubmit}>
                Применить фильтр
              </Button>
            </TertiaryButton>
          </div>
        </div>
      </main>
    </Modal>
  );
};

export default ProjectsFilterModal;