import {NamedUrl} from "../types";
import {RouteDictionary} from "./RouteDictionary";

export const dashboardRoutes: Array<NamedUrl> = [
  {
    name: RouteDictionary.NOTIFICATIONS,
    url: `/notifications`
  },
  {
    name: RouteDictionary.TRANSACTIONS,
    url: `/transactions`
  },
  {
    name: RouteDictionary.DETAILED_TRANSACTION,
    url: `/transaction/:uuid`
  },
  {
    name: RouteDictionary.IDENTIFICATION,
    url: `/identification`
  },
  {
    name: RouteDictionary.MY_MONEY,
    url: `/my-money`
  },
  {
    name: RouteDictionary.SIGN_CONTRACT,
    url: `/my-money/sign-contract`
  },
  {
    name: RouteDictionary.MY_PROJECTS,
    url: `/my-projects`
  },
  {
    name: RouteDictionary.FUNDRAISING_PROJECTS,
    url: `/my-projects/my-investments`
  },
  {
    name: RouteDictionary.ACTIVE_PROJECTS,
    url: `/my-projects/active`
  },
  {
    name: RouteDictionary.ORDERS_PROJECTS,
    url: `/my-projects/orders`
  },
  {
    name: RouteDictionary.MY_ARCHIVE,
    url: `/my-archive`
  },
  {
    name: RouteDictionary.WITHDRAW,
    url: `/withdraw`
  },
  {
    name: RouteDictionary.DEPOSIT,
    url: `/deposit`
  },
  {
    name: RouteDictionary.PRIMARY_MARKET,
    url: "/market/primary"
  },
  {
    name: RouteDictionary.SECONDARY_MARKET,
    url: "/market/buyback"
  },
  {
    name: RouteDictionary.DETAILED_PROJECT,
    url: `/project/:uuid`
  },
  {
    name: RouteDictionary.PROJECT_ECONOMIC_ANALYSIS,
    url: `/project/:uuid/economic`
  },
  {
    name: RouteDictionary.PROJECT_LEGAL_ANALYSIS,
    url: `/project/:uuid/legal`
  },
  {
    name: RouteDictionary.PROJECT_STRATEGY,
    url: `/project/:uuid/strategy`
  },
  {
    name: RouteDictionary.PROJECT_DOCUMENTS,
    url: `/project/:uuid/documents`
  },
  {
    name: RouteDictionary.PROJECT_STAGES,
    url: `/project/:uuid/stages`
  },
  {
    name: RouteDictionary.ARCHIVE,
    url: `/archived`
  },
  {
    name: RouteDictionary.DOCUMENTS,
    url: `/my-documents`
  },
  {
    name: RouteDictionary.DOCUMENTS_TO_PROJECT,
    url: `/my-documents/documents-to-project`
  },
  {
    name: RouteDictionary.AUTOINVEST_DOCUMENTS,
    url: `/my-documents/autoinvest-documents`
  },
  {
    name: RouteDictionary.PLATFORM_DOCUMENTS,
    url: `/my-documents/platform-documents`
  },
  {
    name: RouteDictionary.AUTOINVEST,
    url: `/autoinvest`
  },
  {
    name: RouteDictionary.AUTOINVEST_CONFIRMATION,
    url: `/autoinvest/confirmation/:confirmationUuid`
  },
  {
    name: RouteDictionary.INVESTMENT_CONFIRMATION,
    url: `/project/:uuid/confirmation/:confirmationUuid`
  },
  {
    name: RouteDictionary.ORDER_CONFIRMATION,
    url: `/project/:uuid/order-confirmation/:confirmationUuid`
  },
  {
    name: RouteDictionary.CREATE_OFFER,
    url: `/create-offer/:investmentUuid`
  },
  {
    name: RouteDictionary.OFFER_CONFIRMATION,
    url: `/create-offer/:investmentUuid/confirmation/:confirmationUuid`
  },
  {
    name: RouteDictionary.PURCHASE_OFFER,
    url: `/purchase-offer/:offerUuid`
  },
  {
    name: RouteDictionary.OFFER_ECONOMIC_ANALYSIS,
    url: `/purchase-offer/:uuid/economic`
  },
  {
    name: RouteDictionary.OFFER_LEGAL_ANALYSIS,
    url: `/purchase-offer/:uuid/legal`
  },
  {
    name: RouteDictionary.OFFER_STRATEGY,
    url: `/purchase-offer/:uuid/strategy`
  },
  {
    name: RouteDictionary.OFFER_DOCUMENTS,
    url: `/purchase-offer/:uuid/documents`
  },
  {
    name: RouteDictionary.OFFER_STAGES,
    url: `/purchase-offer/:uuid/stages`
  },
  {
    name: RouteDictionary.PURCHASE_CONFIRMATION,
    url: `/purchase-offer/:offerUuid/confirmation/:confirmationUuid`
  },
  {
    name: RouteDictionary.SURVEY,
    url: `/survey/:uuid`
  },
];