import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type OverlayState = {
    active: boolean
}

const initialState: OverlayState = {
  active: false
};

export const overlaySlice = createSlice({
  name: "overlay",
  initialState,
  reducers: {
    set(state: OverlayState, {payload}: PayloadAction<boolean>) {
      state.active = payload;
    }
  }
});

export const {set} = overlaySlice.actions;

export default overlaySlice.reducer;