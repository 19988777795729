import React, {InputHTMLAttributes, useState} from 'react';
import './InputPassword.scoped.scss';
import eyeImage from '../../../../assets/images/svg/eye.svg';
import eyeImageActive from '../../../../assets/images/svg/eye-active.svg';
import {CommonInput} from "../index";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    password: string;
    onInput: (password: any) => void;
    title: string;
    id: string;
}

const InputPassword = ({password, onInput, title, id, ...props}: Props) => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    setVisible(visible => !visible);
  };

  const EyeExtension = <div onClick={toggleVisible} className="input-password__toggle">
    { visible ? <img src={ eyeImageActive } alt="EyeActive" /> : <img src={ eyeImage } alt="Eye" /> }
  </div>;

  return <CommonInput
    value={password}
    onChange={onInput}
    title={title}
    type={visible ? "text" : "password"}
    extensions={[EyeExtension]}
    className="input-password"
    id={id}
    {...props}
  />;
};

export default InputPassword;