import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {TransactionProvider} from "../TransactionProvider";

export class TransactionProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/transaction`,
      headers
    );
  }

  public createTransactionProvider(authToken: string): TransactionProvider {
    return new TransactionProvider(this.apiClientFactory.createAuthorizedClient(authToken));
  }
}