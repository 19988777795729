import {useCallback, useState} from "react";
import {UserInput} from "../api-client";

export const useSurveyData = () => {
  const [surveyUuid, setSurveyUuid] = useState("");
  const [answers, setAnswers] =
        useState<Record<string, UserInput>>({});

  const addAnswer = useCallback((answer: UserInput) => {
    setAnswers(prevState =>({...prevState, [answer.questionIndex]: answer}));},
  [answers]);

  return {
    setSurveyUuid,
    surveyUuid,
    answers: Object.values(answers),
    addAnswer,
  };
};