import React, {FC, SyntheticEvent, useState} from "react";
import {ConfirmSmsCodeForm, Modal, PrimaryButton} from "../../../index";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {Errors as ValidationErrors, sleep} from "../../../../utils";
import './ChangeEmailModal.scoped.scss';
import {
  useEffectOnMount,
  useEffectOnUpdate,
  useErrorCallback,
  useNotifier,
  useProfileData
} from "../../../../hooks";
import {createChangeEmailManager} from "../../../../di";
import {CommonInput, InputErrors} from "../../inputs";
import {ModalProps} from "../Modal/Modal";

type FormErrors = {
    newEmail?: ValidationErrors;
}

const ChangeEmailModal: FC<ModalProps> = ({active, onClose}) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [newEmail, setNewEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmationUuid, setConfirmationUuid] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const profileData = useProfileData();
  const notifier = useNotifier();
  const handleError = useErrorCallback();

  useEffectOnUpdate(() => {
    validateForm();
  }, [newEmail]);

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (newEmail === "") {
      errors.newEmail = ["Это поле обязательно для заполнения"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  useEffectOnUpdate(() => {
    if (active) {
      const callback = async () => {
        const manager = await createChangeEmailManager();
        const result = await manager.request();
        setConfirmationUuid(result.uuid);
      };
      (async () => {
        setLoading(true);
        try {
          await callback();
        } catch (error: any) {
          await handleError(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [active]);

  const sendSms = async (smsCode: string): Promise<void> => {
    try {
      const manager = await createChangeEmailManager();
      await manager.confirm(confirmationUuid, smsCode);
    } catch (error: any) {
      await handleError(error);
    }
  };

  const resendSms = async () => {
    //TODO: ADD RESENDING SMS
    try {
      await sleep(3000);
    } catch (error: any) {
      await handleError(error);
    }
  };

  const successCallback = () => {
    setIsConfirmed(true);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const manager = await createChangeEmailManager();
        await manager.change(confirmationUuid, newEmail);
        notifier.createSuccess("Ваш электронный адрес был изменен. Подтвердите его, перейдя по ссылке, направленной вам на почту, чтобы обновить профиль");
        onClose();
      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Modal active={active} onClose={onClose}>
      <Modal.Close />
      { isConfirmed ? <>
        { loading && <div>
          Пожалуйста, подождите...
        </div> }
        <div>
          Ваш e-mail: { profileData.email ? profileData.email!.email : "Не указан" }
        </div>
        <div className="email-modal__add">
          <form onSubmit={handleSubmit}>
            <div className="form__group">
              <CommonInput
                value={newEmail}
                onChange={(value) => setNewEmail(value)}
                title="Электронный адрес"
                id="email"
              />
              { errors.newEmail && <InputErrors errors={errors.newEmail} /> }
            </div>
            <div className="form__group">
              <PrimaryButton color={PrimaryButtonColor.GREEN}>
                <button disabled={loading}>
                  Изменить
                </button>
              </PrimaryButton>
            </div>
          </form>
        </div>
      </> : <div>
        <ConfirmSmsCodeForm
          sendCallback={sendSms}
          resendCallback={resendSms}
          successCallback={successCallback}
          prefix={`change-email-${confirmationUuid}`}
        />
      </div> }
    </Modal>
  );
};

export default ChangeEmailModal;