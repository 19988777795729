import {Environment} from "../utils";

export default {
  get publicBaseUrl(): string {
    return process.env.REACT_APP_PUBLIC_BASE_URL || "";
  },
  get apiBaseUrl(): string {
    return process.env.REACT_APP_API_BASE_URL || "";
  },
  get appFeePercent(): number {
    return parseInt(process.env.REACT_APP_FEE_PERCENT || "0");
  },
  get appInvestmentLimit(): number {
    return parseInt(process.env.REACT_APP_INVESTMENT_LIMIT || "1000000");
  },
  get secondaryMarketEnabled(): boolean {
    return !!process.env.REACT_APP_SECONDARY_MARKET_ENABLED;
  },
  get depositAccount(): string {
    return process.env.REACT_APP_DEPOSIT_ACCOUNT || "";
  },
  get depositBank(): string {
    return process.env.REACT_APP_DEPOSIT_BANK || "";
  },
  get depositBIC():string {
    return process.env.REACT_APP_DEPOSIT_BIC || '';
  },
  get depositCorespondentAccount():string {
    return process.env.REACT_APP_DEPOSIT_CORRESPONDENT_ACCOUNT  || '';
  },
  get INN():string {
    return process.env.REACT_APP_INN || '';
  },
  get KPP():string {
    return process.env.REACT_APP_KPP || '';
  },
  get OGRN():string {
    return process.env.REACT_APP_OGRN || '';
  },
  get depositINN():string {
    return process.env.REACT_APP_DEPOSIT_INN || '';
  },
  get depositKPP():string {
    return process.env.REACT_APP_DEPOSIT_KPP || '';
  },
  get depositName():string {
    return process.env.REACT_APP_DEPOSIT_NAME || "";
  },
  get correspAcc():string {
    return process.env.REACT_APP_CORRESP_ACC || "";
  },
  get applicationEnvironment(): Environment {
    return process.env.REACT_APP_ENVIRONMENT as Environment || Environment.dev;
  }
};