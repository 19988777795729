import React from 'react';
import {Block, ColoredText, CustomLink, Image, Money} from "../../../index";
import portfolioIconGray from "../../../../assets/images/svg/portfel-2.svg";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";
import {declOfNum, RouteDictionary} from "utils";
import {useProfileData} from "hooks";
import {formatPercents} from "formatters";
import "./AutoInvesment.scoped.scss";
import PortfolioBody from "../PortfolioBody";
import {useMyMoneyData} from "../../../../hooks/useMyMoneyData";

const AutoInvestment = () => {
  const profileData = useProfileData();
  const stats = useMyMoneyData();

  const hasFirstDeposit = (): boolean => {
    return profileData.agentCode !== null && profileData.hasTransactions;
  };

  const projectMoney = [
    {
      title: 'Инвестировано', count: <Money money={stats.closed.invested}/>,
      content: 'Сумма ваших инвестиций и вознаграждений за инвестиции в завершенных проектах'
    },
    {
      title: 'Возвращено', count: <Money money={stats.closed.comebackAmount}/>,
      content: 'Сумма взысканий на вашу долю за вычетом вознаграждений за доход и инкассо платежей кредитору в завершенных проектах '
    },
    {
      title: 'Чистая прибыль', count: <Money money={stats.closed.netProfit}/>,
      content: 'Разность "Возвращено" и "Инвестировано"'
    },
  ];

  const projectOptions = [
    {
      header: '',
      title: 'Фактический ROI',
      count: `${formatPercents(stats.closed.simpleInterestRate)}`,
      content: 'Средневзвешенная фактическая простая доходность ROI по завершенным проектам (включая дефолты)'
    },

    {
      header: '', title: 'Фактический годовой ROI', count: `${formatPercents(stats.closed.factYearROI)}`,
      content: 'Средневзвешенная фактическая годовая доходность ROI по завершенным проектам (включая дефолты)'
    },
    {
      header: '', title: 'Фактический годовой IRR', count: `${formatPercents(stats.closed.interestRate)}`,
      content: 'Средневзвешенная фактическая годовая доходность IRR по завершенным проектам (включая дефолты)'
    },
    {
      header: '', title: 'Фактический срок', count: `${stats.closed.averageInitialTerm} мес.`,
      content: 'Средневзвешенный фактический срок завершенных проектов'
    },
    {
      header: '',
      title: 'Прогноз ROI',
      count: `${formatPercents(stats.closed.forecastSimpleInterestRate)}`,
      content: 'Средневзвешенный прогноз простой доходности ROI по завершенным проектам'
    },
    {
      header: '', title: 'Прогноз годового ROI', count: `${formatPercents(stats.closed.forecastYearROI)}`,
      content: 'Средневзвешенный прогноз годовой доходности ROI по завершенным проектам'
    },
    {
      header: '',
      title: 'Прогноз годового IRR',
      count: `${formatPercents(stats.closed.forecastInterestRate)}`,
      content: 'Средневзвешенный прогноз годовой доходности IRR по завершенным проектам'
    },
    {
      header: '', title: 'Плановый срок', count: `${stats.closed.forecastInitialTerm} мес.`,
      content: 'Средневзвешенный плановый срок по завершенным проектам'
    }
  ];

  return (
    <div className="invest-my-money__rest">
      {/*<AutoInvestHeader/>*/}
      {hasFirstDeposit() &&
      <div className="finished-portfolio">
        <Block>
          <Block.Content>
            <Block.Header className="invest-my-money__block-header" withPadding>
              <div className="invest-my-money__header finished-portfolio__header">
                <div className="invest-my-money__header-inner">
                  <div
                    className="invest-my-money__icon invest-my-money__icon--gray finished-portfolio__icon">
                    <Image src={portfolioIconGray} alt="Portfolio icon"/>
                  </div>
                  <div className="invest-my-money__heading invest-my-money__heading--main">
                    Завершенный портфель
                  </div>
                </div>
                <ColoredText color={TextColor.PRIMARY_GREEN}>
                  <div className="invest-my-money__heading invest-my-money__heading--main">
                    <ColoredText color={TextColor.PRIMARY_GREEN}>
                      <CustomLink to={RouteDictionary.MY_PROJECTS}>
                        {stats.closed.projects} {declOfNum(stats.closed.projects, ["проект", "проекта", "проектов"])}
                      </CustomLink>
                    </ColoredText>
                  </div>
                </ColoredText>
              </div>
            </Block.Header>
            <PortfolioBody projectMoney={projectMoney} projectOptions={projectOptions} isClosed={true}/>
          </Block.Content>
        </Block>
      </div>
      }
    </div>

  );
};

export default AutoInvestment;