import React, {FC, SyntheticEvent, useMemo, useState} from "react";
import {useCompanyData, useErrorCallback, usePassportData, usePersonalData} from "../../../../hooks";
import {Identification, LegalEntityIdentification} from "../../../../api-client";
import {createIdentificationManager} from "../../../../di";
import IdentificationContext from "../../../context/IdentificationContext";
import {
  IdentificationBlockGroup, IdentificationCompany,
  IdentificationConclusion,
  IdentificationPassport,
  IdentificationPersonal
} from "../blocks";

interface Props {
    onSubmit: () => void;
    identification: Identification|undefined;

}

const IdentificationLegalEntity: FC<Props> = ({onSubmit, identification}) => {
  const value = {...usePersonalData(), ...usePassportData(), ...useCompanyData()};
  const handleError = useErrorCallback();
  const [personalValid, setPersonalValid] = useState(false);
  const [passportValid, setPassportValid] = useState(false);
  const [companyValid, setCompanyValid] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);


  const dataValid = personalValid && passportValid && companyValid;

  const identificationInput: LegalEntityIdentification = useMemo(() => {
    return {
      person: {
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        birthday: value.birthday === null ? (new Date()).toISOString() : value.birthday.toISOString(),
        birthplace: value.birthplace,
        gender: value.gender
      },
      inn: value.inn,
      registerAddress: value.registerAddress,
      email: value.email,
      passport: {
        series: value.passportSeries,
        number: value.passportNumber,
        issuedDate: value.passportIssuedDate === null ? (new Date()).toISOString() : value.passportIssuedDate.toISOString(),
        issuedBy: value.passportIssuedBy,
        departmentCode: value.passportDepartmentCode,
        firstPassportScans: value.firstPassportScans,
        secondPassportScans: value.secondPassportScans,
        thirdPassportScans: value.thirdPassportScans,
      },
      company: {
        name: value.companyName,
        siteUrl: value.companySiteUrl,
        inn: value.companyInn,
        kpp: value.companyKpp,
        ogrnip: value.companyOgrnip,
        address: value.companyAddress
      }
    };
  }, [
    value.firstName,
    value.middleName,
    value.lastName,
    value.birthday,
    value.birthplace,
    value.gender,
    value.inn,
    value.registerAddress,
    value.email,
    value.passportSeries,
    value.passportNumber,
    value.passportIssuedDate,
    value.passportIssuedBy,
    value.passportDepartmentCode,
    value.firstPassportScans,
    value.secondPassportScans,
    value.thirdPassportScans,
    value.companyName,
    value.companySiteUrl,
    value.companyInn,
    value.companyOgrnip,
    value.companyKpp,
    value.companyAddress
  ]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const manager = await createIdentificationManager();
      await manager.createLegalEntityIdentification(identificationInput);
      onSubmit();
    } catch (error: any) {
      await handleError(error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <IdentificationContext.Provider value={value}>
        <IdentificationBlockGroup>
          <IdentificationPersonal isLegalEntity={true} onValidated={(value: boolean) => setPersonalValid(value)} identification={identification} />
          <IdentificationPassport onValidated={(value: boolean) => setPassportValid(value)} passport={identification?.passport} />
          <IdentificationCompany onValidated={(value: boolean) => setCompanyValid(value)} company={identification?.company}/>
          <IdentificationConclusion dataValid={dataValid} loading={loading}/>
        </IdentificationBlockGroup>
      </IdentificationContext.Provider>
    </form>
  );
};

export default IdentificationLegalEntity;