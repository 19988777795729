import {ApiClientInterface} from "./clients";
import {EmailVerificationResult, Phone, PhoneVerificationResult} from "./models";

export class VerificationManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public verifyPhone(phone: Phone, verificationCode: string): Promise<PhoneVerificationResult> {
    return this.apiClient.post("/phone", {phone, verificationCode});
  }

  public resendPhoneVerificationCode(phone: Phone): Promise<void> {
    return this.apiClient.post("/resend-phone", {phone});
  }

  public async verifyEmail(verificationCode: string): Promise<EmailVerificationResult> {
    return this.apiClient.post("/email", {verificationCode});
  }
}