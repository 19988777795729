import React from "react";
import {Container, Section} from "../../../components";

const News = () => {
  return (
    <Section>
      <Container>
        Новости
      </Container>
    </Section>
  );
};

export default News;