import React, {useContext} from "react";
import IdentificationFileUpload from "../IdentificationFileUpload";
import IdentificationContext from "../../../context/IdentificationContext";
import {UploadedFile} from "../../../../api-client";

const EntrepreneurScanUpload = () => {
  const context: any = useContext(IdentificationContext);

  const loadFiles = (files: Array<UploadedFile>) => {
    context.setEntrepreneurDocument(files);
  };

  return (
    <IdentificationFileUpload
      prefix="entrepreneur"
      text="Свидетельство о регистрации ФЛ как ИП,ЕГРИП"
      files={context.entrepreneurDocument}
      onUploaded={loadFiles}
    />
  );
};

export default EntrepreneurScanUpload;