import React, {useCallback} from "react";
import './LenderNavigation.scoped.scss';
import {CustomLink} from "../../common";
import TertiaryButton from "../../common/buttons/decorators/TertiaryButton";
import {TertiaryButtonColor} from "../../common/buttons/decorators/TertiaryButton/TertiaryButton";
import coinIcon from "../../../../src/assets/images/svg/coin-icon.svg";
import exchangeIcon from "../../../../src/assets/images/svg/exchange-icon.svg";
import portfolioIcon from "../../../../src/assets/images/svg/portfolio-icon.svg";
import projectsIcon from "../../../../src/assets/images/svg/projects-icon.svg";
import {RouteDictionary} from "../../../utils";

const LenderNavigation = () => {
  const handleClick = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <nav className="lender-navigation">
      <div className="lender-navigation__left">
        <ul className="lender-navigation__list">
          <li className="lender-navigation__element">
            <CustomLink onClick={handleClick} className="lender-navigation__link" to={RouteDictionary.MY_MONEY}>
              <div className="lender-navigation__link-image">
                <img src={portfolioIcon} alt="Portfolio icon" />
              </div>
              <span>Мои Деньги</span>
            </CustomLink>
          </li>
          <li className="lender-navigation__element">
            <CustomLink onClick={handleClick} className="lender-navigation__link" to={RouteDictionary.PRIMARY_MARKET}>
              <div className="lender-navigation__link-image">
                <img src={coinIcon} alt="Coin icon" />
              </div>
              <span>Все проекты</span>
            </CustomLink>
          </li>
          <li className="lender-navigation__element">
            <CustomLink onClick={handleClick} className="lender-navigation__link" to={RouteDictionary.MY_PROJECTS}>
              <div className="lender-navigation__link-image">
                <img src={projectsIcon} alt="Projects icon" />
              </div>
              <span>Мои инвестиции</span>
            </CustomLink>
          </li>
          <li className="lender-navigation__element">
            <CustomLink onClick={handleClick} className="lender-navigation__link" to={RouteDictionary.TRANSACTIONS}>
              <div className="lender-navigation__link-image">
                <img src={exchangeIcon} alt="Exchange icon" />
              </div>
              <span>Транзакции</span>
            </CustomLink>
          </li>
          <li className="lender-navigation__element">
            <CustomLink className="lender-navigation__link" to={RouteDictionary.DOCUMENTS_TO_PROJECT}>
              <span>Документы</span>
            </CustomLink>
          </li>
          <li className="lender-navigation__element">
            <CustomLink className="lender-navigation__link" to={RouteDictionary.AUTOINVEST}>
              <span>Автоинвестирование</span>
            </CustomLink>
          </li>
        </ul>
      </div>
      <div className="lender-navigation__right">
        <ul className="lender-navigation__money-links">
          <li className="lender-navigation__element">
            <TertiaryButton color={TertiaryButtonColor.GREEN}>
              <CustomLink className="lender-navigation__money-link" to={RouteDictionary.DEPOSIT}>
                Пополнить
              </CustomLink>
            </TertiaryButton>
          </li>
          <li className="lender-navigation__element">
            <TertiaryButton color={TertiaryButtonColor.GRAY}>
              <CustomLink className="lender-navigation__money-link" to={RouteDictionary.WITHDRAW}>
                Вывести
              </CustomLink>
            </TertiaryButton>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default LenderNavigation;