import React, {FC, useState} from "react";
import {Header, Footer, Content} from "../../components/layout";
import {ChildrenProps} from "../../utils";
import {createProfileManager} from "../../di";
import {useAppDispatch, useEffectOnMount, useErrorCallback, useLoggedInStatus} from "../../hooks";
import {set} from "../../store/profile";
import {Main, ModalContainer, Overlay, ScrollToTopButton} from "../../components";
import AlertNotifications from "../../components/alert-notifications/AlertNotifications/AlertNotifications";

const LayoutCabinet: FC<ChildrenProps> = ({children}) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useLoggedInStatus();
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(true);

  useEffectOnMount(() => {
    const mountCallback = async () => {
      setLoading(true);
      try {
        if (!isLoggedIn) {
          const initSession = async () => {
            await initProfile();
          };
          await initSession();
        }
      } catch (error: any) {
        await errorCallback(error);
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await mountCallback();
    })();
  });

  const initProfile = async () => {
    const manager = await createProfileManager();
    const profile = await manager.get();
    dispatch(set(profile));
  };

  return (
    <Content>
      { loading ? "Загружаем профиль" : <>
        <Header />
        <Overlay />
        <AlertNotifications />
        <Main>
          {children}
        </Main>
        <Footer />
        <ModalContainer />
        <ScrollToTopButton />
      </> }
    </Content>
  );
};

export default LayoutCabinet;