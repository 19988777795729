import React from "react";
import {LayoutPublic} from "../../../layouts";
import {Outlet} from "react-router-dom";

const Auth = () => {
  return (
    <LayoutPublic>
      <Outlet />
    </LayoutPublic>
  );
};

export default Auth;