import React from "react";
import {Container, Heading, PrimaryHeading, Section} from "../../../../../components";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import './Notifications.scoped.scss';
import {NotificationsList, NotificationsReceiveOptions} from "../../../../../components/pages/notifications";

const Notifications = () => {
  return (
    <Section>
      <Container>
        <div className="notifications">
          <PrimaryHeading>
            <Heading headingType={HeadingType.H1}>
              Уведомления
            </Heading>
          </PrimaryHeading>
          <div className="notifications__container">
            <NotificationsList />
            <NotificationsReceiveOptions />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Notifications;