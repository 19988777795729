import React, {FC, InputHTMLAttributes, useEffect, useState} from "react";
import './FilterSearch.scoped.scss';
import {useDebounce, useEffectOnUpdate} from "../../../hooks";
import {cn, resultIf} from "../../../utils";
import CommonFilterIcon from "../CommonFilterIcon";
import {SearchSvg} from "../../svg";
import AnimatedFilterSearch from "../../animations/AnimatedFilterSearch";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    search: string;
    onSearchChange: (newSearch: string) => void;
}

const FilterSearch: FC<Props> = ({search, onSearchChange, ...props}) => {
  const [cachedSearch, setCachedSearch] = useState(search);
  const debouncedSearch = useDebounce(cachedSearch, 600);
  useEffectOnUpdate(() => {
    setCachedSearch(search);
  }, [search]);
  
  useEffect(() => {
    if (debouncedSearch || search !== cachedSearch) onSearchChange(debouncedSearch);
  }, [debouncedSearch]);

  const handleSearch = (): void => {
    if (search !== cachedSearch) {
      onSearchChange(cachedSearch);
    }
  };
  const [active, setActive] = useState(false);
  const toggle = () => {
    setActive((active) => !active);
  };
  return (
    <>
      <AnimatedFilterSearch active={active}>
        <input
          { ...props }
          type="search"
          placeholder={props.placeholder}
          className={cn(
            "filter-search__input",
            resultIf(active, "active"),
            props.className
          )}
          value={decodeURIComponent(cachedSearch)}
          onChange={e => setCachedSearch(e.target.value)}
          onBlur={handleSearch}
        />
      </AnimatedFilterSearch>
      <CommonFilterIcon onClick={toggle} className={resultIf(active, "active")}>
        <SearchSvg />
      </CommonFilterIcon>
    </>
  );
};

export default FilterSearch;