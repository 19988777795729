import {useState} from "react";
import {UploadedFile} from "../../api-client";

export const usePassportData = () => {
  const [passportSeries, setPassportSeries] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [passportIssuedDate, setPassportIssuedDate] = useState<Date | null>(null);
  const [passportIssuedBy, setPassportIssuedBy] = useState("");
  const [passportDepartmentCode, setPassportDepartmentCode] = useState("");
  const [firstPassportScans, setFirstPassportScans] = useState<Array<UploadedFile>>([]);
  const [secondPassportScans, setSecondPassportScans] = useState<Array<UploadedFile>>([]);
  const [thirdPassportScans, setThirdPassportScans] = useState<Array<UploadedFile>>([]);
  return {
    passportSeries,
    setPassportSeries,
    passportNumber,
    setPassportNumber,
    passportIssuedDate,
    setPassportIssuedDate,
    passportIssuedBy,
    setPassportIssuedBy,
    passportDepartmentCode,
    setPassportDepartmentCode,
    firstPassportScans,
    setFirstPassportScans,
    secondPassportScans,
    setSecondPassportScans,
    thirdPassportScans,
    setThirdPassportScans
  };
};