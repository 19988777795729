export enum AgreementType {
    INVESTMENT_OFFER = "INVESTMENT_OFFER",
    // INVESTMENT_FEE_AGREEMENT = "INVESTMENT_AGR", //там ограничение в 16 символов в БД sodruzhestvo-documents
    // INVESTMENT_ACCEPT_OFFER = "INVESTMENT_ACC1",
    // INVESTMENT_ACCEPT_FEE_AGREEMENT = "INVESTMENT_ACC2",
    // SALE_ORDER = "SALE_ORDER",
    // PURCHASE_ORDER = "PURCHASE_ORDER",
    AUTO_INVESTMENT_SETTINGS = "SETTINGS_AGR",
    PROJECT_REPORT = "PROJECT_REPORT",
    PROJECT_ACCEPT = "PROJECT_ACCEPT",
    FEE_SETTINGS_AGREEMENT = "FEE_SETTINGS_AGR"
}

type AgreementTypeRecord = Record<AgreementType, string>;

export const typeRangeMapped: AgreementTypeRecord = {
  [AgreementType.PROJECT_REPORT]: "Отчет агента",
  [AgreementType.PROJECT_ACCEPT]: "Приобретение права требования",
  [AgreementType.INVESTMENT_OFFER]: "Агентское поручение",
  [AgreementType.AUTO_INVESTMENT_SETTINGS]: "Общее агентское поручение",
  [AgreementType.FEE_SETTINGS_AGREEMENT]:  "Соглашение об индивидуальном вознаграждении"
};

export const translateAgreementType = (agreementType: AgreementType): string => {
  return typeRangeMapped[agreementType];
};

export const filters = [AgreementType.INVESTMENT_OFFER, AgreementType.PROJECT_REPORT, AgreementType.AUTO_INVESTMENT_SETTINGS];