import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {Checkbox, InputErrors} from "../../../common";
import {Questions, QuestionType, UserInput} from "../../../../api-client";
import {Errors as ValidationErrors} from "../../../../utils";
import {useEffectOnUpdate} from "../../../../hooks";
import './SurveyCheckboxGroup.scoped.scss';

interface Props {
  questions: Questions,
  addAnswer: (answer: UserInput) => void;
  setErrors: Dispatch<SetStateAction<Record<number, ValidationErrors>>>,
  errors: Record<number, ValidationErrors>
}
export const SurveyCheckboxGroup = ({questions, addAnswer, setErrors, errors}: Props) => {
  const {text, index, options} = questions;
  const [value, setValue] = useState<number[]>([]);

  const handleCheckboxChange = (selectedOption: number) => {
    if (value.includes(selectedOption)) {
      setValue(value.filter((option) => option !== selectedOption));
    } else {
      setValue([...value, selectedOption]);
    }
  };

  useEffect(() => {
    if (value.length !== 0) {
      addAnswer({
        questionIndex: index,
        type: QuestionType.CHECKBOX,
        chosenIndexes: value
      });
    }
  }, [value]);

  const validateBlock = (): void => {
    if (value.length === 0) {
      setErrors((prevState) => ({...prevState, ...{[index]:["Обязательно для заполнения"]}}));
    }
    else delete errors[index];
  };

  useEffectOnUpdate(() => {
    validateBlock();
  }, [value]);

  return <div className="survey__checkbox-group">
    <label className="survey__checkbox-group__question">
      Вопрос №{index}. {text}
    </label>
    {options?.list.map((option) => (
      <div key={option.text} className="survey__checkbox-group__answer">
        <Checkbox 
          name={text}
          checked={value && value.includes(option.index)}
          onChange={() => handleCheckboxChange(option.index)}
        >
          {option.text}
        </Checkbox>
      </div>)
    )}
    {errors[index] && <InputErrors errors={errors[index]}/>}
  </div>;
};