import React, {FC} from "react";
import DetailedProjectContextProvider, {
  DetailedProjectContextType
} from "../../../../../../components/pages/detailed-project/DetailedProjectContext/DetailedProjectContext";
import {CustomLink, Image} from "../../../../../../components";
import {RouteDictionary} from "../../../../../../utils";
import arrowBack from "../../../../../../assets/images/svg/header_user-arrow.svg";
import SecondaryProjectMain from "../SecondaryProjectMain";
import DetailedProjectMobileNavigation
  from "../../../../../../components/pages/detailed-project/DetailedProjectMobileNavigation";
import {Outlet} from "react-router-dom";
import {DetailedOffer} from "../../../../../../api-client/models/offer/DetailedOffer";
import SecondaryProjectInvest from "../SecondaryProjectInvest";
import SecondaryProjectContextProvider from "../SecondaryProjectContext";
import {SecondaryProjectContextType} from "../SecondaryProjectContext/SecondaryProjectContext";
import "./SecondaryProject.scoped.scss";
type Props = {
    offer: DetailedOffer
}

const SecondaryProject: FC<Props> = ({offer}) => {
  const value: SecondaryProjectContextType = {
    offer: offer
  };
  const detailedProjectValue: DetailedProjectContextType = {
    project: offer.project
  };
  return (
    <SecondaryProjectContextProvider props={value}>
      <DetailedProjectContextProvider props={detailedProjectValue}>
        <CustomLink className="secondary-project-back" to={RouteDictionary.PRIMARY_MARKET}>
          <Image src={arrowBack} />
        </CustomLink>
        <div className="secondary-project">
          <SecondaryProjectMain />
          <SecondaryProjectInvest />
          <div className="secondary-project-outlet">
            <DetailedProjectMobileNavigation />
            <Outlet />
          </div>
        </div>
      </DetailedProjectContextProvider>
    </SecondaryProjectContextProvider>
  );
};

export default SecondaryProject;