import {useRef, useState} from "react";

export const useLoading = (initialState: boolean = false) => {
  const [loading, setLoading] = useState(initialState);
  const initialLoading = useRef(true);

  const withLoading = async (callback: any): Promise<void> => {
    setLoading(true);
    if (initialLoading.current) {
      initialLoading.current = false;
    }
    await callback();
    setLoading(false);
  };

  return [loading, withLoading, initialLoading.current] as const;
};