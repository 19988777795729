export enum ProjectStageEnum {
    OFFER_CREATED = "OFFER_CREATED",
    FUNDS_RAISED = "FUNDS_RAISED",
    CONTRACT_SIGNED = "CONTRACT_SIGNED",
    FUNDS_TRANSFERRED = "FUNDS_TRANSFERRED",
    PROJECT_CANCELLED = "PROJECT_CANCELLED",
    COMPLAINT_FILLED = "COMPLAINT_FILLED",
    AGENT_APPEALED = "AGENT_APPEALED",
    DEBTOR_APPEALED = "DEBTOR_APPEALED",
    COURT_SCHEDULED = "COURT_SCHEDULED",
    COURT_TOOK_PLACE = "COURT_TOOK_PLACE",
    DECISION_MADE = "DECISION_MADE",
    EXECUTIVE_DOCUMENT_RECEIVED = "EXECUTIVE_DOCUMENT_RECEIVED",
    DEBTOR_UNAVAILABLE = "DEBTOR_UNAVAILABLE",
    CONTACTING_DEBTOR = "CONTACTING_DEBTOR",
    PROJECTION_PERIOD_CHANGED = "PROJECTION_PERIOD_CHANGED",
    DEBT_CLASS_CHANGED = "DEBT_CLASS_CHANGED",
    DEBT_VALUE_CHANGED = "DEBT_VALUE_CHANGED"
}

type ProjectStageEnumRecord = Record<ProjectStageEnum, string>

export const ProjectStageEnumMapped = {
  [ProjectStageEnum.OFFER_CREATED]: "Размещение оферты",
  [ProjectStageEnum.FUNDS_RAISED]: "Окончание сбора средств",
  [ProjectStageEnum.CONTRACT_SIGNED]: "Договор уступки заключен",
  [ProjectStageEnum.FUNDS_TRANSFERRED]: "Средства переведены",
  [ProjectStageEnum.PROJECT_CANCELLED]: "Проект отменен",
  [ProjectStageEnum.COMPLAINT_FILLED]: "Подан иск",
  [ProjectStageEnum.AGENT_APPEALED]: "Подана аппеляция агентом",
  [ProjectStageEnum.DEBTOR_APPEALED]: "Подана аппеляция должником",
  [ProjectStageEnum.COURT_SCHEDULED]: "Судебное заседание назначено",
  [ProjectStageEnum.COURT_TOOK_PLACE]: "Судебное заседание состоялось",
  [ProjectStageEnum.DECISION_MADE]: "Получено судебное решение",
  [ProjectStageEnum.EXECUTIVE_DOCUMENT_RECEIVED]: "Получен исполнительный документ",
  [ProjectStageEnum.DEBTOR_UNAVAILABLE]: "Должник не контактен",
  [ProjectStageEnum.CONTACTING_DEBTOR]: "Коммуникация с должником",
  [ProjectStageEnum.PROJECTION_PERIOD_CHANGED]: "Изменения прогнозного срока",
  [ProjectStageEnum.DEBT_CLASS_CHANGED]: "Изменение класса долга",
  [ProjectStageEnum.DEBT_VALUE_CHANGED]: "Изменение оценки долга"
};

export const translateProjectStage = (stage: ProjectStageEnum): string => {
  return ProjectStageEnumMapped[stage];
};