import styles from './MyDocuments.module.scss';
import {Block, Container, CustomLink, Section} from "../../../../../../components";
import {ChildrenProps, RouteDictionary} from "../../../../../../utils";
import React, {FC} from "react";


const MyDocuments: FC<ChildrenProps> = ({children}) => {
  return (
    <Section>
      <Container>
        <Block>
          <Block.Content>
            <div className={styles.documents}>
              <nav className={styles.documents__container}>
                <ul className={styles.documents__list}>
                  <li className={styles.documents__element}>
                    <CustomLink className={styles.documents__link} to={RouteDictionary.DOCUMENTS_TO_PROJECT}>
                      Документы по сделкам
                    </CustomLink>
                  </li>
                  <li className={styles.documents__element}>
                    <CustomLink className={styles.documents__link} to={RouteDictionary.PLATFORM_DOCUMENTS}>
                      Агентский договор
                    </CustomLink>
                  </li>
                  <li className={styles.documents__element}>
                    <CustomLink className={styles.documents__link} to={RouteDictionary.AUTOINVEST_DOCUMENTS}>
                      Автоинвест
                    </CustomLink>
                  </li>
                </ul>
              </nav>
            </div>
            <div>
              {children}
            </div>
          </Block.Content>
        </Block>
      </Container>
    </Section>
  );
};

export default MyDocuments;