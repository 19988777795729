import React, {useEffect, useState} from "react";
import {Block} from "../../../layout";
import './NotificationsReceiveOptions.scoped.scss';
import {useErrorCallback, useProfileData, useToggle} from "../../../../hooks";
import hidePhone from "../../../../formatters/hidePhone";
import {Toggle} from "../../../common";
import {createNotificationSettingsManager} from "../../../../di";

const NotificationsReceiveOptions = ({modalActive}: { modalActive?: boolean }) => {
  const profileData = useProfileData();
  const [turning, setTurning] = useState(false);
  const handleError = useErrorCallback();
  const [phoneActive, togglePhone, setPhoneSettings] = useToggle();
  const [emailActive, toggleEmail, setEmailSettings] = useToggle();

  const getNotificationData = async () => {
    const manager = await createNotificationSettingsManager();
    const data = await manager.getNotifications();
    setPhoneSettings(data.smsSettings?.systemNotifications ?? false);
    setEmailSettings(data.mailSettings?.systemNotifications ?? false);
  };

  useEffect(() => {
    getNotificationData();
  }, []);

  const handleToggle = () => {
    (async () => {
      setTurning(true);
      try {
        const manager = await createNotificationSettingsManager();
        await manager.toggleSmsNotification(!phoneActive);
      } catch (error) {
        if (error instanceof Error) {
          await handleError(error);
        }
      } finally {
        setTurning(false);
      }
    })();
    togglePhone();
  };

  return (
    <div
      className={modalActive ? "notifications-options" : "notifications-options notifications-options-non-modal"}>
      <Block>
        <Block.Content>
          <Block.Header className="notifications-options__block-header">
            <div className="notifications-options__header">
              Настройка
              <div className="notifications-options__subheader">
                Дублировать уведомления:
              </div>
            </div>
          </Block.Header>
          <Block.Body className="notifications-options__block-body">
            <ul className="notifications-options__list">
              {profileData.email !== null && <li className="notifications-options__element">
                <div className="notifications-options__start">
                  <div className="notifications-options__text">
                    На почту
                  </div>
                  <div className="notifications-options__credential">
                    {profileData.email.email}
                  </div>
                </div>
                <div>
                  <Toggle disabled={turning}
                    active={emailActive}
                    onToggled={toggleEmail}
                    className="notifications-options__toggle"/>
                </div>
              </li>}
              <li className="notifications-options__element">
                <div className="notifications-options__start">
                  <div className="notifications-options__text">
                    На номер телефона
                  </div>
                  <div className="notifications-options__credential">
                    {hidePhone(profileData.phone)}
                  </div>
                </div>
                <div>
                  <Toggle disabled={turning}
                    active={phoneActive}
                    onToggled={handleToggle}
                    className="notifications-options__toggle"/>
                </div>
              </li>
            </ul>
          </Block.Body>
        </Block.Content>
      </Block>
    </div>
  );
};

export default NotificationsReceiveOptions;