type UserInfo = Record<string, any>;

export function identifyUser(data: UserInfo): void {
  if (!window.carrotquest || typeof window.carrotquest.identify !== 'function') {
    console.warn('Carrotquest or identify does not exist');
    return;
  }
	
  const formattedData: UserInfo = {};
	
  Object.entries(data).forEach(([key, value]) => {
    formattedData[`$${key}`] = value;
  });
	
  window.carrotquest.identify(formattedData);
}