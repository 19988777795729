import React, {FC} from "react";

interface Props {
    fill?: string;
}

const SnippetSvg: FC<Props> = ({fill = "#9299a2"}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.079" height="18.041" viewBox="0 0 16.079 18.041">
      <path id="Контур_19209" data-name="Контур 19209" d="M16.079,4.933A5.181,5.181,0,0,1,14.461,8.6q-3.437,3.408-6.849,6.841c-.54.54-1.061,1.1-1.617,1.619a3.483,3.483,0,0,1-2.707.973A3.493,3.493,0,0,1,.223,15.7a3.466,3.466,0,0,1,.9-3.795C3.754,9.26,6.4,6.631,9.034,3.993A2.146,2.146,0,0,1,12.08,3.9a2.124,2.124,0,0,1,.006,3.079c-1,1.013-2.011,2.015-3.018,3.021L6.1,12.967c-.051.051-.1.1-.155.15a.708.708,0,0,1-1.03-.012.7.7,0,0,1,.03-1.029q.928-.937,1.866-1.865c.266-.263.554-.5.818-.768.67-.677,1.329-1.365,2-2.045.459-.468.927-.927,1.389-1.392a.7.7,0,0,0,.172-.926.674.674,0,0,0-1.031-.149C9.626,5.44,9.111,5.967,8.59,6.488Q5.742,9.334,2.9,12.183c-.329.33-.676.648-.975,1a2.075,2.075,0,0,0,.924,3.29,2.083,2.083,0,0,0,2.292-.6q3.916-3.935,7.846-7.858A6.41,6.41,0,0,0,14.134,6.7a3.426,3.426,0,0,0,.407-2.445,3.488,3.488,0,0,0-4.008-2.77A3.906,3.906,0,0,0,8.344,2.744C5.614,5.49,5.749,5.444,3.01,8.181a.753.753,0,0,1-.91.2.713.713,0,0,1-.182-1.136c.952-.962.472-.529,1.429-1.487,1.8-1.8,2.167-2.213,3.964-4.02A5.83,5.83,0,0,1,9.012.458a4.987,4.987,0,0,1,2.823-.4,4.769,4.769,0,0,1,2.484,1.123,4.671,4.671,0,0,1,1.643,2.879C16.007,4.347,16.041,4.64,16.079,4.933Z" transform="translate(16.079 18.041) rotate(-180)" fill={fill}/>
    </svg>
  );
};

export default SnippetSvg;