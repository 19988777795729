import {AgreementType} from "../../enums/AgreementType";
import {ShortProject} from "../project";
import {File} from "../common";
import {Tag} from "./Tag";

export enum AutoInvestAgreementStatus {
    ACTIVE = 'ACTIVE',
    NOT_ACTIVE = 'NOT_ACTIVE'
}
export interface AutoInvestAgreement {
    readonly uuid: string;
    readonly type: AgreementType;
    readonly agreementNumber: string;
    readonly agreementUrls: Array<string>;
    readonly createdAt: Date;
    readonly file: File;
    readonly status: AutoInvestAgreementStatus;
    readonly tags: Array<Tag>;
    readonly project?: ShortProject;
    readonly name?: string;
    readonly number?: string;
    readonly reportCreated: boolean;
    readonly digitalSign?: {
        readonly createdAt: string;
        readonly signedAt: string;
        readonly signerName: string;
    };
}