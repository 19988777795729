import {ApiClientFactory} from "./clients";
import {Headers} from "../types";
import {OrdersManager} from "../OrdersManager";

export class OrdersManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/order`,
      headers
    );
  }

  public createOrderManager(authToken: string): OrdersManager {
    return new OrdersManager(
      this.apiClientFactory.createAuthorizedClient(authToken)
    );
  }
}