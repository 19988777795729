import React, {FC, PropsWithChildren, useRef, useState} from "react";
import './Tooltip.scoped.scss';
import {CSSTransition} from "react-transition-group";

interface Props {
    content: string;
    className?: string;
    position?: 'top' | 'bottom'
}

const Tooltip: FC<PropsWithChildren<Props>> = (props) => {
  const {content, children, className, position = 'top'} = props;

  const [active, setActive] = useState(false);
  const timeoutRef = useRef(0);

  const onClick = () => {
    clearTimeout(timeoutRef.current);
    setActive(true);
  };

  const onLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setActive(false);
    }, 250);
  };

  const getClassNames = () => {
    let styles = ['tooltip__answer'];
    if (position === 'bottom') styles.push('tooltip__position_bottom');
    if (className) styles.push(className);
    return styles.join(' ');
  };

  return (
    <div
      className="tooltip"
      onClick={onClick}
      onMouseLeave={onLeave}
    >
      {children}
      <CSSTransition
        in={active}
        timeout={250}
        classNames="tooltip__answer-"
      >
        <div className={getClassNames()}>
          <div className="tooltip__text">
            {content}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Tooltip;