import {ApiClientInterface} from "./clients";
import buildQuery from "../utils/router/buildQuery";
import {Collection} from "./models";
import {Filter} from "../components/pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";
import {ReferralUser} from "./models";

export class ReferralUserProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  getList(page = 1, perPage = 25, filter?: Filter): Promise<Collection<ReferralUser>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`?${query}`);
  }
}