import React, {useContext} from "react";
import {ProjectsContext} from "../../../../../views/dashboard/lender/investment/projects/ProjectsContext";
import {defaultProjectsFilter} from "../ProjectsFilter/utils";
import {Image} from "../../../../common";
import clearFilterImage from "../../../../../assets/images/svg/hash-strong_2.svg";
import './ProjectsFilterClearAll.scoped.scss';

const ProjectsFilterClearAll = () => {
  const {setFilter, toggleFilter} = useContext(ProjectsContext);
  const clearFilter = () => {
    setFilter({...defaultProjectsFilter, economicClasses: [], legalClasses: []});
    toggleFilter();
  };
  return (
    <div onClick={clearFilter} className="projects-filter-clear-all">
      <Image src={clearFilterImage} />
      <div>
        Сбросить весь фильтр
      </div>
    </div>
  );
};

export default ProjectsFilterClearAll;