import {ApiClientInterface} from "./clients";
import {OrderDetails, PreviewOrder} from "./models";

export class OrdersProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getOne(uuid: string): Promise<OrderDetails> {
    return this.apiClient.get(`/${uuid}`);
  }

  public async getList(): Promise<Array<PreviewOrder>> {
    return this.apiClient.get(``);
  }

}