import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {AgentDocumentProvider} from "../AgentDocumentProvider";

export class AgentDocumentProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/agent`,
      headers
    );
  }

  public createAgentDocumentProvider(authToken: string): AgentDocumentProvider {
    return new AgentDocumentProvider(this.apiClientFactory.createAuthorizedClient(authToken));
  }
}