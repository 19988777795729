import {Image} from "../../../../../../../components";
import documentIcon from "../../../../../../../assets/images/svg/document.svg";
import FileSize from "../../../../../../../components/common/utils/FileSize/FileSize";
import {formatDate} from "../../../../../../../formatters";
import React from "react";
import styles from '../DocumentsToProjectsItem.module.scss';
import {Agreement} from "../../../../../../../api-client/models/documents/Agreement";


interface Props {
    document: Agreement,
    isSupplemented?: boolean
}

export const AgentReport = ({document, isSupplemented}: Props) => {
  return (
    <ul key={document.uuid} className={styles.documentsToProjectBody__list}>
      <li>
        <a href={document.file.url} className={styles.documentsToProjectBody__item}
          target="_blank" rel="noreferrer">
          <Image className={styles.documentsToProjectBody__icon}
            src={documentIcon}/>
          <div
            className={styles.documentsToProjectBody__title}>{isSupplemented ? 'Итоговый отчет агента (дополненный)': 'Итоговый отчет агента'}
          </div>
          <div
            className={styles.documentsToProjectBody__size}>pdf, <FileSize
              file={document.file}/></div>
          <div className={styles.documentsToProjectBody__watch}>Нажмите, чтобы
            посмотреть
          </div>
          <div
            className={styles.documentsToProjectBody__date}>{formatDate(document.createdAt)}</div>
          <div
            className={styles.documentsToProjectBody__dateText}>дата
            публикации
          </div>
        </a>
      </li>
    </ul>
  );
};