import React from "react";
import './PillButton.scoped.scss';
import {ClassInjector} from "../../../../injectors";
import {ChildrenProps, cn} from "../../../../../utils";
import {ClassNameDecorator} from "../../../../decorators";

export enum PillLinkColor {
    GREEN = "pill-button--green"
}

interface Props extends ChildrenProps {
    color: PillLinkColor;
}

const PillButton: ClassNameDecorator<Props> = ({color, className, children}) => {
  return (
    <ClassInjector
      classNames={cn(
        "pill-button",
        `${color}`
      )}
      oldClassName={className}
    >
      { children }
    </ClassInjector>
  );
};

export default PillButton;