import React, {FC} from "react";
import './Content.scoped.scss';
import {ChildrenProps} from "../../../utils";

const Content: FC<ChildrenProps> = ({children}) => {
  return (
    <div className="content">
      { children }
    </div>
  );
};

export default Content;