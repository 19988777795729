import React, {useState} from "react";
import {ProfileCredentialInput} from "../index";
import {AsLink, Button, ChangePasswordModal, ColoredText} from "../../../common";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";

const PasswordInput = () => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <ProfileCredentialInput>
        <ProfileCredentialInput.Title>
          XXXXXXXXXX
        </ProfileCredentialInput.Title>
        <div>
          <ColoredText color={TextColor.PRIMARY_GREEN}>
            <AsLink>
              <Button onClick={() => setModalOpened(true)}>
                Изменить
              </Button>
            </AsLink>
          </ColoredText>
        </div>
      </ProfileCredentialInput>
      <ChangePasswordModal active={modalOpened} onClose={() => setModalOpened(false)} />
    </>
  );
};

export default PasswordInput;