import React, {ReactNode, useContext, useMemo} from "react";
import './NotificationsTypeOptionBox.scoped.scss';
import {NotificationsContext, NotificationsFilter} from "../NotificationsFilterContext";
import {Image, OptionBox} from "../../../common";
import {OptionBoxContext} from "../../../common/selects/OptionBox/OptionBoxContext";
import arrowDown from "../../../../assets/images/svg/arrow-b.svg";
import {cn, resultIf} from "../../../../utils";

type NotificationsType = {
    name: ReactNode;
    callback: () => NotificationsFilter;
}

const notificationsTypeOptions: Array<NotificationsType> = [
  {
    name: "Все",
    callback: () => { return {isRead: true} }
  },
  {
    name: "Важные",
    callback: () => { return {isImportant: true} }
  },
];

const NotificationsTypeOptionBox = () => {
  const {filter, setFilter} = useContext(NotificationsContext);

  const compareFilters = (newFilter: NotificationsFilter): boolean => {
    return filter.isImportant === newFilter.isImportant && filter.isRead === newFilter.isRead;
  };

  const selectedOption = useMemo(() => {
    return notificationsTypeOptions.filter((option) => {
      return compareFilters(option.callback());
    })[0];
  }, [filter]);

  const renderOption = (option: NotificationsType): ReactNode => {
    if (!option) return null;
    return <div>
      { option.name }
    </div>;
  };

  const handleSelect = (selected: NotificationsType) => {
    setFilter({...selected.callback()});
  };

  return (
    <OptionBox
      options={notificationsTypeOptions}
      selectedOption={selectedOption}
      renderOption={renderOption}
    >
      <OptionBox.Head>
        <OptionBoxContext.Consumer>
          {({active, selectedOption}) => <>
            <div className="notifications-type-select__header">
              <div className="notifications-type-select__heading">
                { renderOption(selectedOption) }
              </div>
              <Image
                src={arrowDown}
                alt="Arrow down"
                className={cn(
                  "notifications-type-select__image",
                  resultIf(active, "active")
                )}
              />
            </div>
          </>
          }
        </OptionBoxContext.Consumer>
      </OptionBox.Head>
      <OptionBox.Content onSelected={handleSelect} className="notifications-type-select__content" />
    </OptionBox>
  );
};

export default NotificationsTypeOptionBox;