import React, {FC} from "react";
import './DetailedProjectHtml.scoped.scss';
import {Translation} from "../../../../api-client";

interface Props {
    translations: Array<Translation>;
}

const DetailedProjectHtml: FC<Props> = ({translations}) => {
  return (
    <div className="detailed-project-html">
      { translations.length !== 0
        ? <div dangerouslySetInnerHTML={{__html: translations[0].text}}></div>
        : <div>Данные отсутствуют</div> }
    </div>
  );
};

export default DetailedProjectHtml;