import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {SurveyManager} from "../SurveyManager";

export class SurveyManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/survey`,
      headers
    );
  }

  public createSurveyManager(token: string): SurveyManager {
    return new SurveyManager(this.apiClientFactory.createAuthorizedClient(token));
  }
}