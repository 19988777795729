import React from "react";
import {CustomLink} from "../../../../../../components";
import {Environment, RouteDictionary} from "../../../../../../utils";
import './MarketNavigation.scoped.scss';
import MarketNavigationDropdown from "../MarketNavigationDropdown";
import apiClientConfig from "../../../../../../configs/app";

const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
    || (apiClientConfig.applicationEnvironment === Environment.test);

const MarketNavigation = () => {
  return (
    <>
      <ul className="market-navigation">
        {/*<li className="market-navigation__item">*/}
        {/*  <CustomLink to={RouteDictionary.PRIMARY_MARKET} className="market-navigation__link">*/}
        {/*    В процессе сбора средств*/}
        {/*  </CustomLink>*/}
        {/*</li> comment because maybe in future it needs*/ }
        {devAndTest && <li className="market-navigation__item">
          <CustomLink to={RouteDictionary.SECONDARY_MARKET} className="market-navigation__link">
            Вторичный рынок
          </CustomLink>
        </li>}
      </ul>
      {/*<MarketNavigationDropdown/>*/}
    </>
  );
};

export default MarketNavigation;