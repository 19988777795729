export enum LenderType {
    UNKNOWN = "UNKNOWN",
    INDIVIDUAL = "INDIVIDUAL", // Физ. лицо
    LEGAL_ENTITY = "LEGAL_ENTITY", // Юр. лицо
    ENTREPRENEUR = "ENTREPRENEUR" // ИП
}

type LenderTypeRecord = Record<LenderType, string>;

export const LenderTypeMapped: LenderTypeRecord = {
  [LenderType.UNKNOWN]: "Не определено",
  [LenderType.INDIVIDUAL]: "Физическое лицо",
  [LenderType.LEGAL_ENTITY]: "Юридическое лицо",
  [LenderType.ENTREPRENEUR]: "ИП",
};

export const translateLenderType = (type: LenderType): string => {
  return LenderTypeMapped[type];
};