import React from 'react';
import {createRoot} from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import "assets/scss/app.scss";
import App from './App';
import store from "store/store";
import {Provider} from "react-redux";
import sentryConfig from './configs/sentry';
import {
  AgentDocuments,
  ArchivedProjects,
  Auth,
  AutoInvest,
  AutoinvestDocuments,
  AutoinvestRules,
  Control,
  CreateOffer,
  Dashboard,
  Deposit,
  DetailedProject,
  DocumentsToProject,
  Enter,
  Identification,
  InvestmentConfirmation,
  Logout,
  MyDocumentsLayout,
  MyMoney,
  MyProjects,
  NotFound,
  Notifications,
  OrderConfirmation,
  PersonalDataPolicy,
  Profile,
  Projects,
  RedirectAfterAuth,
  ReferralProgram,
  RegistrationRules,
  Rules,
  SignContract,
  Transactions,
  VerificationEmail,
  Withdraw
} from "./views";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {AboutUs, FAQ, HowItWorks, News, Platform} from "./views/public";
import Public from "./views/public/Public/Public";
import {VerifyEmail} from "./views/verification";
import MyArchive from "./views/dashboard/lender/stats/MyArchive";
import {AuthProtectedRouteGroup} from "./components/routing";
import PrimaryMarket from "./views/dashboard/lender/investment/projects/PrimaryMarket";
import ProjectEconomicAnalysis from "./components/pages/detailed-project/ProjectEconomicAnalysis";
import ProjectLegalAnalysis from "./components/pages/detailed-project/ProjectLegalAnalysis";
import ProjectStrategy from "./components/pages/detailed-project/ProjectStrategy";
import ProjectDocuments from "./components/pages/detailed-project/ProjectDocuments";
import ProjectStages from "./components/pages/detailed-project";
import {ActiveProjects, MyInvestments, Orders, Survey} from "./components";
import OfferConfirmation from "./views/dashboard/lender/investment/offer/OfferConfirmation";
import AutoInvestConfirmation from "./views/dashboard/lender/investment/AutoInvest/AutoInvestConfirmation";
import PurchaseOffer from "./views/dashboard/lender/investment/purchase";
import PurchaseOfferConfirmation from "./views/dashboard/lender/investment/purchase/PurchaseConfirmation";

const root = createRoot(
    document.getElementById('root') as HTMLElement
);

Sentry.init({
  dsn: sentryConfig.sentryDsn,
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
});

function Index() {
  return (
    <Routes>
      <Route path="auth" element={<Auth/>}>
        <Route path="redirect-after-auth" element={<RedirectAfterAuth/>}/>
        <Route path="logout" element={<Logout/>}/>
        <Route path="verification/:verificationCode" element={<VerificationEmail/>}/>
      </Route>
      <Route path="enter" element={<Enter/>}/>
      <Route path="dashboard" element={<Dashboard/>}>
        <Route element={<AuthProtectedRouteGroup/>}>
          <Route path="notifications" element={<Notifications/>}/>
          <Route path="survey/:uuid" element={<Survey/>}/>
          <Route path="identification" element={<Identification/>}/>
          <Route path="transactions" element={<Transactions/>}/>
          <Route path="transaction/:uuid"/>
          <Route path="my-money" element={<MyMoney/>}>
            <Route path="sign-contract" element={<SignContract/>}/>
          </Route>
          <Route path="my-projects" element={<MyProjects/>}>
            <Route index element={<MyInvestments/>}/>
            <Route path="my-investments" element={<MyInvestments/>}/>
            <Route path="active" element={<ActiveProjects/>}/>
            <Route path="orders" element={<Orders/>}/>
          </Route>
          <Route path="my-archive" element={<MyArchive/>}/>
          <Route path="withdraw" element={<Withdraw/>}/>
          <Route path="deposit" element={<Deposit/>}/>
          <Route path="my-documents" element={<MyDocumentsLayout/>}>
            <Route path="documents-to-project" element={<DocumentsToProject/>}/>
            <Route path="platform-documents" element={<AgentDocuments/>}/>
            <Route path="autoinvest-documents" element={<AutoinvestDocuments/>}/>
          </Route>
          <Route path="autoinvest" element={<AutoInvest/>}>
            <Route path="confirmation/:confirmationUuid" element={<AutoInvestConfirmation/>}/>
          </Route>
          <Route path="market" element={<Projects/>}>
            <Route index element={<PrimaryMarket/>}/>
            <Route path="primary" element={<PrimaryMarket/>}/>
            {/*<Route path="buyback" element={<SecondaryMarket/>}/>*/}
          </Route>
          <Route path="archived" element={<ArchivedProjects/>}/>
          <Route path="project/:uuid" element={<DetailedProject/>}>
            <Route index element={<ProjectEconomicAnalysis/>}/>
            <Route path="economic" element={<ProjectEconomicAnalysis/>}/>
            <Route path="legal" element={<ProjectLegalAnalysis/>}/>
            <Route path="strategy" element={<ProjectStrategy/>}/>
            <Route path="documents" element={<ProjectDocuments/>}/>
            <Route path="stages" element={<ProjectStages/>}/>
            <Route path="confirmation/:confirmationUuid" element={<InvestmentConfirmation/>}/>
            <Route path="order-confirmation/:confirmationUuid" element={<OrderConfirmation/>}/>
          </Route>
          <Route path="create-offer/:investmentUuid" element={<CreateOffer/>}>
            <Route path="confirmation/:confirmationUuid" element={<OfferConfirmation/>}/>
          </Route>
          <Route path="purchase-offer/:offerUuid" element={<PurchaseOffer/>}>
            <Route index element={<ProjectEconomicAnalysis/>}/>
            <Route path="economic" element={<ProjectEconomicAnalysis/>}/>
            <Route path="legal" element={<ProjectLegalAnalysis/>}/>
            <Route path="strategy" element={<ProjectStrategy/>}/>
            <Route path="documents" element={<ProjectDocuments/>}/>
            <Route path="stages" element={<ProjectStages/>}/>
            <Route path="confirmation/:confirmationUuid" element={<PurchaseOfferConfirmation/>}/>
          </Route>
          <Route path="profile" element={<Profile/>}>
            <Route index element={<ReferralProgram/>}/>
            <Route path="control" element={<Control/>}/>
            <Route path="referral-program" element={<ReferralProgram/>}/>
          </Route>
          <Route path="*" element={<NotFound/>}/>
        </Route>
      </Route>
      <Route element={<Public/>}>
        <Route path="/" element={<App/>}/>
        <Route path="/rules_service" element={<Rules/>}/>
        <Route path="/rules_autoinvest" element={<AutoinvestRules/>}/>
        <Route path="/rules_registration" element={<RegistrationRules/>}/>
        <Route path="/personal_data_policy" element={<PersonalDataPolicy/>}/>
        <Route path="/faq" element={<FAQ/>}/>
        <Route path="/how-it-works" element={<HowItWorks/>}/>
        <Route path="/platform" element={<Platform/>}/>
        <Route path="/news" element={<News/>}/>
        <Route path="/about-us" element={<AboutUs/>}/>
      </Route>
      <Route path="/verify-email/:verificationCode" element={<VerifyEmail/>}/>
      <Route path="*" element={<NotFound/>}/>
    </Routes>
  );
}

const Root = () => {
  //TODO: ADD FALLBACK COMPONENT
  return (
    <Sentry.ErrorBoundary onError={(error) => Sentry.captureException(error)}>
      <Provider store={store}>
        <BrowserRouter>
          <Index/>
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  );
};

root.render(<Root/>);
