import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {VerificationManager} from "../VerificationManager";

export class VerificationManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/verification`,
      headers
    );
  }

  public createVerificationManager(): VerificationManager {
    return new VerificationManager(this.apiClientFactory.createClient());
  }
}