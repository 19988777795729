import {ApiClientInterface} from "./clients";
import {Collection, Notification} from "./models";
import buildQuery from "../utils/router/buildQuery";
import {NotificationsFilter} from "../components/pages/notifications/NotificationsFilterContext";

export class NotificationManager {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public async getList(filter: NotificationsFilter, page: number, perPage: number,): Promise<Collection<Notification>> {
    const query = buildQuery({filter, page, perPage});
    return this.apiClient.get(`/?${query}`);
  }

  public async markNotificationRead(uuids: string[]): Promise<void> {
    return this.apiClient.post(`/mark-read`, {uuids});
  }
}