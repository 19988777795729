import React, {FC} from "react";
import {
  isAnnounced,
  isFundRaising,
  isLoanPayment,
  isPaid,
  isRefunded,
  PrimaryMarketProject,
  translateProjectStatus
} from "api-client";
import {BoldText, CustomLink, Money, PrimaryButton, Toggle} from "components/common";
import {ProjectCategory} from "components/project";
import {Environment, RouteDictionary} from "utils";
import {formatDate} from "formatters";
import apiClientConfig from "configs/app";
import {PrimaryButtonColor} from "components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import styles from './PrimaryMarketOffer.module.scss';

interface Props {
    project: PrimaryMarketProject;
}

const PrimaryMarketOffer: FC<Props> = ({project}) => {
  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
            || (apiClientConfig.applicationEnvironment === Environment.test);
  const restOfMoney = project.debtPrice.amount - project.investedSum.amount;

  let date = null;
  if (project.conductedAutoInvestmentDate) date = formatDate(project.conductedAutoInvestmentDate, "DD.MM.YYYY HH:mm");
  else if (project.scheduledAutoInvestmentDate) date = formatDate(project.scheduledAutoInvestmentDate, "DD.MM.YYYY HH:mm");
  return (
    <li className={styles.primaryMarketOffer}>
      <div className={styles.primaryMarketOffer__categoryContainer}>
        <div className={styles.primaryMarketOffer__category}>
          <ProjectCategory debtClass={project.debtClass}/>
        </div>
        {project.hasExtraCreditorPayment && <div className={styles.primaryMarketOffer__incasso}>
          инкассо
        </div>}
      </div>
      <BoldText>
        <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: project.uuid}}
          className={styles.primaryMarketOffer__linkToProject} >
          <div className={styles.primaryMarketOffer__primary}>
            {project.name[0].text}
          </div>
        </CustomLink>
      </BoldText>
      <div className={styles.primaryMarketOffer__sum}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Сумма сбора
        </div>
        <div>
          <Money money={project.debtPrice}/>
        </div>
      </div>

      <div className={styles.primaryMarketOffer__forecast}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Прогноз взыскания
        </div>
        <div>
          {project.recoveryForecast && <Money money={project.recoveryForecast}/>}
        </div>
      </div>

      <div className={styles.primaryMarketOffer__term}>
        <div className={styles.primaryMarketOffer__rowHeader}>
          Прогнозный срок
        </div>
        <div>
          {project.initialTerm.toFixed(2)} мес.
        </div>
      </div>
      {isAnnounced(project.status) &&
      <div className={styles.primaryMarketOffer__statusContainer}>
        <BoldText>
          <div className={styles.primaryMarketOffer__status}>
            {translateProjectStatus(project.status)}
          </div>
        </BoldText>
        <div className={styles.primaryMarketOffer__date}>
          <div className={styles.primaryMarketOffer__rowHeader}>
            Дата автоинвеста
          </div>
          <div>
            {date ? date : '-'}
          </div>
        </div>
        <div className={styles.primaryMarketOffer__toggle}>
          <div className={styles.primaryMarketOffer__toggleContainer}>
            <Toggle onToggled={() => {
            }} active={project.isProjectAutoInvestSettingsActivated ? project.isProjectAutoInvestSettingsActivated : false} size={'s'}/>вход по АИ
          </div>

        </div>
        <div
          className={styles.primaryMarketOffer__actions}>
          <PrimaryButton expanded className={styles.primaryMarketOffer__link}
            color={PrimaryButtonColor.GREEN}>
            <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: project.uuid}}>
              Настроить АИ
            </CustomLink>
          </PrimaryButton>
        </div>
      </div>
      }
      {isFundRaising(project.status) &&
      <div className={styles.primaryMarketOffer__statusContainer}>
        <BoldText>
          <div className={styles.primaryMarketOffer__status}>
            {translateProjectStatus(project.status)}
          </div>
        </BoldText>
        <div className={styles.primaryMarketOffer__date}>
          <div className={styles.primaryMarketOffer__rowHeader}>
            Сбор до
          </div>
          <div>
            {project.maturityDate ? formatDate(project.maturityDate) : '-'}
          </div>
        </div>
        <div className={styles.primaryMarketOffer__money}>
          <div className={styles.primaryMarketOffer__rowHeader}>
            Осталось собрать
          </div>
          <div>
            <Money money={{amount: restOfMoney, currencyCode: project.investedSum.currencyCode}}/>
          </div>
        </div>
        {(restOfMoney !== 0) && <div className={styles.primaryMarketOffer__actions}>
          <PrimaryButton expanded className={styles.primaryMarketOffer__link}
            color={PrimaryButtonColor.GREEN}>
            <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: project.uuid}}>
              {devAndTest ? 'Подать заявку' : 'Инвестировать'}
            </CustomLink>
          </PrimaryButton>
        </div>}
      </div>
      }
      {
        isLoanPayment(project.status) &&
        <div className={styles.primaryMarketOffer__statusContainer}>
          <BoldText>
            <div className={styles.primaryMarketOffer__status}>
              {translateProjectStatus(project.status)}
            </div>
          </BoldText>
          <div className={styles.primaryMarketOffer__date}>
            <div className={styles.primaryMarketOffer__rowHeader}>
              Начало проекта
            </div>
            <div>
              {project.loanIssuedAtDate ? formatDate(project.loanIssuedAtDate) : '-'}
            </div>
          </div>
          <div className={styles.primaryMarketOffer__money}>
            <div className={styles.primaryMarketOffer__rowHeader}>
              Взыскано
            </div>
            <div>
              {project.actualPayment && <Money money={project.actualPayment}/>}
            </div>
          </div>
        </div>
      }
      {
        isPaid(project.status) &&
        <div className={styles.primaryMarketOffer__statusContainer}>
          <BoldText>
            <div className={styles.primaryMarketOffer__status}>
              {translateProjectStatus(project.status)}
            </div>
          </BoldText>
          <div className={styles.primaryMarketOffer__date}>
            <div className={styles.primaryMarketOffer__rowHeader}>
              Конец проекта
            </div>
            <div>
              {project.paidAt ? formatDate(project.paidAt) : '-'}
            </div>
          </div>
          <div className={styles.primaryMarketOffer__money}>
            <div className={styles.primaryMarketOffer__rowHeader}>
              Фактический срок
            </div>
            <div>
              {project.realTerm?.toFixed(2)} мес.
            </div>
          </div>
        </div>
      }
      {
        isRefunded(project.status) &&
        <div className={styles.primaryMarketOffer__statusContainer}>
          <BoldText>
            <div className={styles.primaryMarketOffer__status}>
              {translateProjectStatus(project.status)}
            </div>
          </BoldText>
          <div className={styles.primaryMarketOffer__date}>
            <div className={styles.primaryMarketOffer__rowHeader}>
              Дата отмены
            </div>
            <div>
              {project.cancelledAt ? formatDate(project.cancelledAt) : '-'}
            </div>
          </div>
        </div>
      }
    </li>
  )
  ;
}
;

export default PrimaryMarketOffer;