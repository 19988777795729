import React from "react";
import './AlertNotifications.scss';
import AlertNotification from "../AlertNotification/AlertNotification";
import {useAlertNotifications} from "../../../hooks";
import {TransitionGroup, CSSTransition} from "react-transition-group";

const AlertNotifications = () => {
  const notifications = useAlertNotifications();

  return (
    <>
      <TransitionGroup component="ul" className="alert-list">
        { notifications.map((notification) => {
          return <CSSTransition
            key={notification.id}
            timeout={500}
            classNames="alert-list__element-"
            appear
            unmountOnExit
          >
            <li className="alert-list__element">
              <AlertNotification data={notification} />
            </li>
          </CSSTransition>;
        }) }
      </TransitionGroup>
    </>
  );
};

export default AlertNotifications;