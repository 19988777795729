import {ApiClientFactory} from "./clients";
import {Headers} from "../types";
import {AuthManager} from "../AuthManager";

export class AuthManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string, headers: Headers = {}) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/auth`,
      headers
    );
  }

  createAuthManager(): AuthManager {
    return new AuthManager(this.apiClientFactory.createClient());
  }
}