import React, {AllHTMLAttributes, FC, PropsWithChildren, useContext} from "react";
import './DropdownHead.scoped.scss';
import {DropdownContext} from "../DropdownContext";
import {cn, resultIf} from "../../../../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const DropdownHead: FC<PropsWithChildren<Props>> = ({children, ...props}) => {
  const context = useContext(DropdownContext);
  return (
    <div
      {...props}
      className={cn(
        "dropdown__head",
        resultIf(context.active, "active"),
        props.className
      )}
      onClick={context.toggle}
    >
      { children }
    </div>
  );
};

export default DropdownHead;