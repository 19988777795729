import React, {FC} from "react";
import {ChildrenProps} from "../../../../utils";
import BlockBody from "../BlockBody";
import BlockContent from "../BlockContent";
import BlockHeader from "../BlockHeader";
import './Block.scoped.scss';
import BlockFooter from "../BlockFooter";
import BlockNavigation from "../BlockNavigation";

type Props = ChildrenProps;

type BlockExtensions = {
    Content: typeof BlockContent;
    Header: typeof BlockHeader;
    Body: typeof BlockBody;
    Footer: typeof BlockFooter;
    Navigation: typeof BlockNavigation;
}

const Block: FC<Props> & BlockExtensions = ({children}) => {
  return (
    <div className="block">
      {children}
    </div>
  );
};

Block.Content = BlockContent;
Block.Header = BlockHeader;
Block.Body = BlockBody;
Block.Footer = BlockFooter;
Block.Navigation = BlockNavigation;

export default Block;