import React, {FC, memo} from "react";
import './Footer.scoped.scss';
import {Button, CustomLink, Image, WithinBlockDecorator} from "../../common";
import {Container} from "../index";
import {LogoImage, TelegramImage} from "../../common/images/types";
import {LogoType} from "../../common/images/types/LogoImage";
import {RouteDictionary} from "../../../utils";
import phoneIcon from "../../../assets/images/svg/phone.svg";
import internetIcon from "../../../assets/images/svg/internet.svg";
import apiClientConfig from "../../../configs/app";


const Footer: FC<any> = () => {
  return (
    <footer className="footer">
      <Container>
        <div className="footer__grid">
          <div className="footer__main">
            <address className="footer__address">
              <div className="footer__text">
                2023 © Общество с ограниченной ответственностью<br/>
                &quot;Кономика&quot;<br/>
              </div>
              <div className="footer__ogrn-inn-kpp">
                <div className="footer__text">
                  ИНН: {apiClientConfig.INN}
                </div>
                <div className="footer__text">
                  КПП: {apiClientConfig.KPP}
                </div>
                <div className="footer__text">
                  ОГРН: {apiClientConfig.OGRN}
                </div>
              </div>
            </address>
            <div >
              <CustomLink className="footer__text footer__link" to={RouteDictionary.RULES_REGISTRATION}>
                Правила регистрации
              </CustomLink>
              <CustomLink className="footer__text footer__link" to={RouteDictionary.RULES_SERVICE}>
                Правила пользования сервисом
              </CustomLink>
              <CustomLink className="footer__text footer__link" to={RouteDictionary.PERSONAL_DATA_POLICY}>
                Политика обработки персональных данных
              </CustomLink>
              <CustomLink className="footer__text footer__link" to={RouteDictionary.RULES_AUTOINVEST}>
                Правила пользования автоинвестом
              </CustomLink>
            </div>
            <div className="footer__logo">
              <CustomLink to={RouteDictionary.INDEX}>
                <WithinBlockDecorator>
                  <LogoImage type={LogoType.YELLOW}/>
                </WithinBlockDecorator>
              </CustomLink>
            </div>
          </div>
          <div className="footer__content">
            <div className="footer__contacts">
              <div className="footer__contact-block">
                <div className="footer__contact">
                  <Image className="footer__icon" src={phoneIcon} alt="Phone icon"/>
                  <div>
                    +7 (495) 150-16-67
                  </div>
                </div>
              </div>
              <div className="footer__contact-block">
                <div className="footer__contact">
                  <Image className="footer__icon" src={internetIcon} alt="Internet icon"/>
                  invest@conomica.ru
                </div>
              </div>
            </div>
            <div className="footer__links">
              <ul className="footer__menu">
                {/*<li className="footer__element">*/}
                {/*    <CustomLink className="footer__text footer__link" to={RouteDictionary.RULES}>*/}
                {/*        Правила платформы*/}
                {/*    </CustomLink>*/}
                {/*</li>*/}
                {/*</li>*/}
                {/*<li className="footer__element">*/}
                {/*    <CustomLink className="footer__text footer__link" to={RouteDictionary.PLATFORM}>*/}
                {/*        О платформе*/}
                {/*    </CustomLink>*/}
                {/*</li>*/}
                {/*<li className="footer__element">*/}
                {/*    <CustomLink className="footer__text footer__link" to={RouteDictionary.FAQ}>*/}
                {/*        Помощь*/}
                {/*    </CustomLink>*/}
                {/*</li>*/}
                {/*<li className="footer__element">*/}
                {/*    <CustomLink className="footer__text footer__link" to={RouteDictionary.DOCUMENTS}>*/}
                {/*        Документы*/}
                {/*    </CustomLink>*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
          <div className="footer__social">
            <div className="footer-telegram">
              <div className="footer-telegram__info">
                <div>
                  <div className="footer-telegram__text">
                    Присоединяйся к нашему сообществу инвесторов
                  </div>
                </div>
              </div>
              <div className="footer-telegram__link">
                <TelegramImage className={"footer-telegram__icon"}/>
                <Button className={"footer-telegram__button"}>
                  <a className={"footer-telegram__button-link"} href="https://t.me/conomica_chat_invest">
                    Перейти к чату
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default memo(Footer);