import React from "react";
import './MyProjectsNavigation.scoped.scss';
import {Environment, RouteDictionary} from "../../../../utils";
import {useLocation} from "react-router-dom";
import {CustomLink} from "../../../common";
import  {MyProjectsNavigationDropdown} from "../index";
import {useContext} from "react";

import {InvestmentCountContext} from "../InvestmentCountContext";
import apiClientConfig from "../../../../configs/app";

const MyProjectsNavigation = () => {
  //TODO: ADD PROJECTS COUNT
  const {pathname} = useLocation();
  const context = useContext(InvestmentCountContext);
  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
      || (apiClientConfig.applicationEnvironment === Environment.test);
  return (
    <>
      <nav className="my-projects-nav">
        <ul className="my-projects-nav__list">
          {/*<li className="my-projects-nav__element">*/}
          {/*  <CustomLink*/}
          {/*    to={RouteDictionary.FUNDRAISING_PROJECTS}*/}
          {/*    className="my-projects-nav__link"*/}
          {/*    explicitActive={matchPath('dashboard/my-projects', pathname) !== null}*/}
          {/*  >*/}
          {/*    {context.fundraisingCount ? `В процессе сбора средств (${context.fundraisingCount})` :*/}
          {/*      'В процессе сбора средств'}*/}
          {/*  </CustomLink>*/}
          {/*</li>*/}
          {/*<li className="my-projects-nav__element">*/}
          {/*  <CustomLink*/}
          {/*    to={RouteDictionary.ACTIVE_PROJECTS}*/}
          {/*    className="my-projects-nav__link"*/}
          {/*  >*/}
          {/*    {context.activeCount ?*/}
          {/*      `Текущие (${context.activeCount})` : 'Текущие'}*/}
          {/*  </CustomLink>*/}
          {/*</li> maybe need in future*/}
          {devAndTest && <li className="my-projects-nav__element">
            <CustomLink
              to={RouteDictionary.ORDERS_PROJECTS}
              className="my-projects-nav__link"
            >
              {context.ordersCount ?
                `Заявки (${context.ordersCount})` : 'Заявки'}
            </CustomLink>
          </li>}
        </ul>
      </nav>
      {/*<MyProjectsNavigationDropdown />*/}
    </>
  );
};

export default MyProjectsNavigation;