import {Collection, TransactionInfo, TransactionType} from "../api-client";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type TransactionsTranslationsState = {
    transactionsInfo: Array<TransactionInfo>;
}

const initialState: TransactionsTranslationsState = {
  transactionsInfo: []
};

export const transactionsTranslationsSlice = createSlice({
  name: "transactionsTranslations",
  initialState,
  reducers: {
    set: (state: TransactionsTranslationsState, {payload}: PayloadAction<Collection<TransactionInfo>>) => {
      state.transactionsInfo = payload.items;
    }
  }
});

export const {set} = transactionsTranslationsSlice.actions;

export default transactionsTranslationsSlice.reducer;