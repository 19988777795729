import React, {FC} from "react";
import {ChildrenProps, cn} from "../../../../utils";
import './IdentificationTypeChanger.scss';

interface Props extends ChildrenProps {
    active: boolean,
    onClick: () => void;
}

const IdentificationTypeChanger: FC<Props> = ({active, onClick, children}) => {
  return (
    <div onClick={onClick} className={
      cn("identification-type-changer", `${active ? "active" : ""}`)
    }>
      <div className={cn(
        "identification-type-changer__point",
        `${active ? "active" : ""}`
      )}></div>
      <div>
        { children }
      </div>
    </div>
  );
};

export default IdentificationTypeChanger;