import React, {AllHTMLAttributes, FC} from "react";
import './Section.scoped.scss';
import {cn} from "../../../utils";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const Section: FC<Props> = ({children, ...props}) => {
  return (
    <section {...props} className={cn("section", props.className)}>
      { children }
    </section>
  );
};

export default Section;