import {useState} from "react";
import {UserGender} from "../../api-client";

export const usePersonalData = () => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [inn, setInn] = useState("");
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState(UserGender.MALE);
  const [birthplace, setBirthplace] = useState("");
  const [registerAddress, setRegisterAddress] = useState("");
  return {
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    inn,
    setInn,
    birthday,
    setBirthday,
    email, setEmail,
    gender,
    setGender,
    birthplace,
    setBirthplace,
    registerAddress,
    setRegisterAddress
  };
};