import React, {FC, InputHTMLAttributes, useMemo, useRef, useState} from "react";
import {formatAmount} from "utils";
import './InputMoney.scoped.scss';
import {Money} from "../../../../api-client";
import {useEffectOnUpdate} from "../../../../hooks";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    money: Money;
    onValueChanged: (money: Money) => void;
    boundaries?: {
        min?: number
        max?: number
    };
}

const InputMoney: FC<Props> = ({money, boundaries, onValueChanged, ...props}) => {
  const inputElement = useRef<HTMLInputElement>(null);
  const [prevPosition, setPrevPosition] = useState(0);

  const inputValue = useMemo((): string => {
    let amount;
    let value = money.amount.toString();
    let sanitizedValue =
            value || value.length === 0 ? value.toString().replace(/\D+/g, "") : "";
    if (sanitizedValue.length > 0) {
      if (sanitizedValue.length > 15) {
        sanitizedValue = sanitizedValue.slice(0, 15);
      }
      const parsedAmount = parseInt(sanitizedValue);
      if (!isNaN(parsedAmount)) {
        amount = parsedAmount;

        if (boundaries !== undefined) {
          const {min, max} = boundaries;
          if (min && amount < min) {
            amount = min;
          }
          if (max && amount > max) {
            amount = max;
          }
        }
      }
    }
    if (amount !== undefined) {
      return formatAmount(amount / 100);
    }
    return "";
  }, [money]);

  useEffectOnUpdate(() => {
    if (inputElement.current) {
      inputElement.current.selectionStart = inputElement.current.selectionEnd =
                inputElement.current.value!.length - prevPosition;
    }
  }, [money]);

  const updateValue = (value: string) => {
    // if (value.indexOf(".") !== -1) {
    //     const numberOfPennies = value.split(".")[1].length;
    //     if (numberOfPennies < 2) {
    //         value = updatePennies(value);
    //     } else if (numberOfPennies >= 3) {
    //         value = value.slice(0, value.length - 1);
    //     }
    // }
    const sanitizedValue = value.replace(/\D/g, '');
    const numberValue = parseInt(sanitizedValue);
    setPrevPosition(inputElement.current!.value.length - inputElement.current!.selectionStart!);
    onValueChanged(new Money(isNaN(numberValue) ? 0 : numberValue, money.currencyCode));
  };

  // const updatePennies = (amount: string): string => {
  //     let currentPosition = inputElement.current!.selectionStart;
  //     if (currentPosition === null) {
  //         currentPosition = 0;
  //     }
  //     if (amount.length === currentPosition) {
  //         return `${amount}0`;
  //     }
  //     return amount.slice(0, currentPosition) + "0" + amount.slice(currentPosition);
  // }

  return (
    <div className="input-money__container">
      <input
        type="text"
        className="input-money"
        ref={inputElement}
        value={inputValue}
        onChange={e => updateValue((e.target as HTMLInputElement).value)}
        {...props}
      />
      <div>
        ₽
      </div>
    </div>
  );
};

export default InputMoney;