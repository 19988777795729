import React, {FC} from "react";
import FilterSearch from "../../../../filters/FilterSearch";
import './ProjectsHeaderOptions.scoped.scss';
import FilterIcon from "../../../../filters/FilterIcon";

type Props = {
    search: string;
    handleSearch: (search: string) => void;
}

const ProjectsHeaderOptions: FC<Props> = ({search, handleSearch}) => {
  return (
    <div className="projects-header-options">
      <FilterSearch search={search} onSearchChange={handleSearch} placeholder="Проект..." />
      <FilterIcon />
    </div>
  );
};

export default ProjectsHeaderOptions;