import {Heading, SecondaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import React, {useMemo, useState} from "react";
import {Block, Container, Section} from "../../../layout";
import {Pagination} from "../../../pagination";
import {Filter} from "../../../pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";
import {useErrorCallback} from "../../../../hooks";
import {DataCollection} from "../../../../utils";
import {
  ReferralUser as ReferralUserModel,
} from "../../../../api-client";
import {
  createReferralUserProvider,
} from "../../../../di";
import ReferralUser from "../ReferralUser";
import "./ReferralUserList.scss";

const ReferralUserList = () => {
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [referralUsers, setReferralUsers] =
        useState<DataCollection<ReferralUserModel>>(new DataCollection());


  const loadData = async (page: number, perPage: number, filter: Filter) => {
    setLoading(true);
    try {
      const provider = await createReferralUserProvider();
      const referralUsers = await provider.getList(page, perPage, filter);
      const dataCollection = new DataCollection<ReferralUserModel>();
      dataCollection.setCollection(referralUsers);
      setReferralUsers(dataCollection);
    } catch (err: any) {
      await errorCallback(err);
    } finally {
      setLoading(false);
    }
  };

  const usersList = useMemo(() => {
    return referralUsers.items.map((user) => {
      return <ReferralUser user={user} key={user.uuid} />;
    });
  }, [referralUsers]);
  return (
    <Section className="referral-users-section">
      <Container>
        <div>
          <SecondaryHeading>
            <Heading headingType={HeadingType.H2}>
              Список рефералов
            </Heading>
          </SecondaryHeading>
        </div>
        <Pagination
          onLoad={loadData}
          count={referralUsers.count}
          totalCount={referralUsers.totalCount}
        >
          <Block>
            <Block.Content>
              <Block.Body className="referral-users-body" withPadding={false}>
                <Pagination.Content loading={loading}>
                  <div className="referral-users-header">
                    <div className="referral-users-header__item">
                      Инвестор
                    </div>
                    <div className="referral-users-header__item">
                      Дата регистрации
                    </div>
                    <div className="referral-users-header__item">
                      Тип пользователя
                    </div>
                  </div>
                  <ul className="referral-transactions__list">
                    {usersList}
                  </ul>
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator />
        </Pagination>
      </Container>
    </Section>
  );
};
export default ReferralUserList;