import React, {useContext, useMemo} from "react";
import './ProjectLegalAnalysis.scoped.scss';
import {Block} from "../../../layout";
import DetailedProjectNavigation from "../DetailedProjectNavigation";
import ProjectDropdown from "../ProjectDropdown";
import {useMountAnimation, useWindowWidth} from "../hooks";
import {cn, resultIf} from "../../../../utils";
import DetailedProjectHtml from "../DetailedProjectHtml";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {
  SecondaryProjectContext
} from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectContext/SecondaryProjectContext";
import SecondaryProjectNavigation
  from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectNavigation";

const ProjectLegalAnalysis = () => {
  const project = useContext(DetailedProjectContext).project;
  const mounted = useMountAnimation();
  const isMobile = useWindowWidth();
  const offer = !!useContext(SecondaryProjectContext);
  const navigation = useMemo(() => {
    if (offer) {
      return <SecondaryProjectNavigation />;
    }
    else {
      return <DetailedProjectNavigation />;
    }
  }, [offer]);
  const legalInfo = useMemo(() => {
    return project.information !== null && <div className={cn("project-legal", resultIf(mounted, "mounted"))}>
      <DetailedProjectHtml translations={project.information.legalContent} />
    </div>;
  }, [mounted]);

  return (
    <div className="project-legal-container">
      { isMobile ?
        <ProjectDropdown header="Юридический анализ">
          { legalInfo }
        </ProjectDropdown>
        : <Block>
          <Block.Content>
            <Block.Header withPadding className="detailed-project__header">
              { navigation }
            </Block.Header>
            <Block.Body>
              { legalInfo }
            </Block.Body>
          </Block.Content>
        </Block> }
      <ProjectDropdown header="Обязательства должника">
        { project.information !== null && <DetailedProjectHtml translations={project.information.obligationsContent} /> }
      </ProjectDropdown>
    </div>
  );
};

export default ProjectLegalAnalysis;