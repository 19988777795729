import {createOfferProvider} from "di";
import './PurchaseOffer.scoped.scss';
import {DetailedOffer} from "../../../../../api-client/models/offer/DetailedOffer";
import {useParams} from "react-router-dom";
import {useErrorCallback, useLoading} from "../../../../../hooks";
import React, {useEffect, useState} from "react";
import {Container,Section} from "../../../../../components";
import SecondaryProject from "./SecondaryProject";

const PurchaseOffer = () => {
  const {offerUuid} = useParams();
  const [offer, setOffer] = useState<DetailedOffer | null>(null);
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createOfferProvider();
        const offer = await provider.getOne(offerUuid!);
        setOffer(offer);
      } catch (err: any) {
        await errorCallback(err);
        setOffer(null);
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);

  return (
    <Container>
      <Section>
        { loading && <div>Загрузка...</div> }
        { !loading && offer === null && <div>Предложение не найдено</div> }
        { offer && <SecondaryProject  offer={offer!} /> }
      </Section>
    </Container>
  );
};

export default PurchaseOffer;