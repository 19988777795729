export const numbersOnly = (str: string): boolean => {
  return /^\d+$/.test(str);
};

export const lengthBetween = (str: string, lowerBound: number, upperBound: number): boolean => {
  const len = str.length;
  return len >= lowerBound && len <= upperBound;
};

export const isEmail = (str: string): boolean => {
  return /^.*?@.*?\..*?$/.test(str);
};

export const insertSymbols = (str: string, symbol: string, ...indexes: number[]): string => {
  if (new Set(indexes).size !== indexes.length) {
    throw new Error("Duplicate indexes");
  }
  indexes = indexes.sort(function (a, b) {
    return a - b;
  });
  let chunks: Array<string> = [];
  let current = 0;
  indexes.forEach((value) => {
    chunks.push(str.slice(current, value + 1));
    current = value + 1;
  });
  indexes[indexes.length - 1] < str.length && chunks.push(str.slice(current, str.length));
  return chunks.join(symbol);
};