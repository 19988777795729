import {ApiClientInterface} from "./clients";
import {ArchivedProject, Collection, DetailedProject, OwnedProject, PrimaryMarketProject, UrlResult} from "./models";
import {Filter as PrimaryMarketProjectsFilter} from "../components/pages/projects/filter/ProjectsFilter/ProjectsFilter";
import {Filter as ArchivedProjectsFilter} from "../views/dashboard/lender/investment/ArchivedProjects/ArchivedProjects";
import buildQuery from "../utils/router/buildQuery";
import {ProjectsCount} from "./models/project/ProjectsCount";

export class ProjectProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public async getOne(uuid: string): Promise<DetailedProject> {
    return this.apiClient.get(`/${uuid}`);
  }

  public async getPrimaryMarketProjects(page: number, perPage: number, filter: PrimaryMarketProjectsFilter): Promise<Collection<PrimaryMarketProject>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/primary-market?${query}`);
  }

  public async getArchivedProjects(page: number, perPage: number, filter: ArchivedProjectsFilter): Promise<Collection<ArchivedProject>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/archived?${query}`);
  }
  public async getMyArchivedProjects(page: number, perPage: number, filter: ArchivedProjectsFilter): Promise<Collection<ArchivedProject>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/my-archive?${query}`);
  }

  public async exportPrimaryMarketStatistics(page: number, perPage: number, filter: PrimaryMarketProjectsFilter): Promise<UrlResult> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/export-primary-market-statistics?${query}`);
  }

  public async getProjectsCount(): Promise<ProjectsCount> {
    return this.apiClient.get(`/projects-count`);
  }

  //Method for borrower cabinet
  public async getOwned(page: number, perPage: number): Promise<Collection<OwnedProject>> {
    return this.apiClient.get("/owned", {page, perPage: perPage});
  }
}