import React, {createContext, PropsWithChildren} from "react";

export type AgentReportFilter = {
    dateFrom: Date;
    dateTo: Date;
}

export type AgentReportContextType = {
    filter: AgentReportFilter;
    setFilter: (filter: AgentReportFilter) => void;
}

type Props = {
    props: AgentReportContextType;
}

export const AgentReportContext = createContext<AgentReportContextType>({
  filter: {
    dateFrom: new Date(),
    dateTo: new Date(),
  },
  setFilter: () => {},
});

function AgentReportContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <AgentReportContext.Provider value={value.props}>
    { children }
  </AgentReportContext.Provider>;
}

export default AgentReportContextProvider;