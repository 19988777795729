import React, {FC} from "react";
import './ProfileInput.scoped.scss';

const ProfileInput: FC<any> = ({children}) => {
  return (
    <div className="profile-input">
      { children }
    </div>
  );
};

export default ProfileInput;