import React, {FC} from "react";
import Slider, {SliderProps} from "rc-slider";
import './RangeSlider.scoped.scss';
import {SliderValue} from "../../../../utils";

export type RangeSliderProps = {
    handleChange: (value: SliderValue) => void;
}

const RangeSlider: FC<SliderProps & RangeSliderProps> = ({handleChange, ...props}) => {
  const onChange = (value: number[] | number): void => {
    if (typeof value === "number") {
      handleChange({min: value, max: value});
      return;
    }
    const [first, second] = value;
    handleChange({min: first, max: second});
  };
  return (
    <Slider
      {...props}
      range
      railStyle={{backgroundColor: "#b9b9b9"}}
      trackStyle={{backgroundColor: "#22a873"}}
      handleStyle={{backgroundColor: "#bfffd2", borderColor: "#bfffd2", opacity: "1"}}
      onChange={onChange}
    />
  );
};

export default RangeSlider;