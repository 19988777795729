import {ChangeCredentialManager} from "./ChangeCredentialManager";
import {ApiClientInterface} from "./clients";

export class ChangeEmailManager extends ChangeCredentialManager {
  constructor(apiClient: ApiClientInterface) {
    super(apiClient);
  }

  public async change(confirmationUuid: string, newEmail: string): Promise<void> {
    return this.apiClient.post("change", {confirmationUuid, newEmail});
  }
}