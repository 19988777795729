import React, {Dispatch, FormEvent, SetStateAction, useContext, useRef, useState} from "react";
import {createProjectAutoInvestmentSettingsManager} from "../../../../di";
import {useErrorCallback, useProfileData} from "../../../../hooks";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import './ProjectAutoInvest.scoped.scss';
import {Button, InputMoney, Money, PrimaryButton, Toggle} from "../../../common";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {
  AutoInvestmentSettings,
  InitialProjectSettings,
  isFundRaising,
  Money as MoneyModel,
  ProjectStatus
} from "../../../../api-client";
import {cn, resultIf} from "../../../../utils";
import {useParams} from "react-router-dom";
import {useWindowWidth} from "../hooks";
import {formatDate} from "../../../../formatters";


interface Props {
    activeToggle: boolean;
    maxSum: MoneyModel;
    minSum: MoneyModel;
    setActiveToggle: Dispatch<SetStateAction<boolean>>;
    autoInvestmentSettingsGlobal: AutoInvestmentSettings | null;
    setMinSum: Dispatch<SetStateAction<MoneyModel>>;
    setMaxSum: Dispatch<SetStateAction<MoneyModel>>;
    autoInvestmentSettings: InitialProjectSettings | null;
    left: MoneyModel
}

const ProjectAutoInvest = (props: Props) => {
  const {
    activeToggle,
    maxSum,
    minSum,
    setActiveToggle,
    autoInvestmentSettingsGlobal,
    setMinSum,
    setMaxSum,
    autoInvestmentSettings,
    left
  } = props;
  const project = useContext(DetailedProjectContext).project;

  const handleError = useErrorCallback();
  const profileData = useProfileData();
  const [loading, setLoading] = useState(false);
  const focused = useRef<boolean>(false);
  const {confirmationUuid} = useParams();
  const [activeButton, setActiveButton] = useState<boolean>(false);

  const isMobile = useWindowWidth();

  const handleSubmitToSave = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    try {
      const manager = await createProjectAutoInvestmentSettingsManager();
      await manager.setLenderSettings({
        isActivated: activeToggle,
        projectUuid: project.uuid,
        maxAmount: maxSum,
        minAmount: minSum,
      });
      setActiveButton(false);
    } catch (error) {
      if (error instanceof Error) {
        await handleError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleToggle = async (newToggleState: boolean): Promise<void> => {
    setLoading(true);
    try {
      const manager = await createProjectAutoInvestmentSettingsManager();
      await manager.setLenderSettings({
        isActivated: newToggleState,
        projectUuid: project.uuid
      });
      setActiveToggle(newToggleState);
    } catch (error) {
      if (error instanceof Error) {
        await handleError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  let disabledConditionToAI =
            !profileData.lenderVerified ||
            project.isReadonly ||
            minSum.amount > maxSum.amount ||
            loading ||
            !activeButton ||
            project.isProjectAISettingsFrozen;

  let AIError = minSum.amount > maxSum.amount;
  if (autoInvestmentSettingsGlobal) {
    const {minAmount, maxAmount} = autoInvestmentSettingsGlobal;

    if (minAmount) {
      disabledConditionToAI ||= minSum.amount < minAmount.amount;
      AIError ||= minSum.amount < minAmount.amount;
    }

    if (maxAmount) {
      disabledConditionToAI ||= maxSum.amount > maxAmount.amount;
      AIError ||= maxSum.amount > maxAmount.amount;
    }
  }

  let date = null;
  if (project.status === ProjectStatus.AUTO_INVESTMENT && project.conductedAutoInvestmentDate) date = formatDate(project.conductedAutoInvestmentDate, "DD.MM.YYYY HH:mm");
  else if (project.accounting.scheduledAutoInvestmentDate) date = formatDate(project.accounting.scheduledAutoInvestmentDate, "DD.MM.YYYY HH:mm");
  return (
    <div
      className={cn("detailed-project-invest", resultIf(isFundRaising(project.status), "detailed-project-autoinvest"))}>
      <div
        className={cn("detailed-project-invest__container detailed-project-invest__ai-container",
          resultIf(confirmationUuid !== undefined, "detailed-project-invest__container-none"))}>
        <div className={"detailed-project-invest__toggle-container"}>
          <Toggle active={activeToggle} onToggled={() => handleToggle(!activeToggle)}
            size={isMobile ? 's' : 'm'}
            disabled={!autoInvestmentSettingsGlobal && !autoInvestmentSettings || project.isProjectAISettingsFrozen}/>
          {!project.isProjectAISettingsFrozen && <div
            className={cn("detailed-project-invest__toggle-title", resultIf(activeToggle, "active"))}>Вход
            в проект по АИ
          </div>}
          {project.isProjectAISettingsFrozen && <div
            className="detailed-project-invest__toggle-title">Вход
            в проект по АИ
          </div>}
        </div>
        {loading ? <div>Loading..</div> : !profileData.lenderVerified &&
        <div className={"detailed-project-invest__money__error"}> Чтобы начать инвестировать,
          вам необходимо пройти верификацию
        </div>}
        {loading ?
          <div>Loading..</div> : !autoInvestmentSettingsGlobal && !autoInvestmentSettings &&
          <div
            className={"detailed-project-invest__money__error"}>Необходимо включить
            глобальные настройки автоинвестирования</div>}
        {project.isProjectAISettingsFrozen &&
        <div className={"detailed-project-invest__money detailed-project-invest__money__error"}>
          Изменение настроек недоступно во время проведения <br/>автоинвеста по проекту.<br/>
          Возможность изменить настройки скоро появится.
        </div>}
        {loading ? <div>Loading..</div> : activeToggle && <div>
          <div className="detailed-project-invest__main">
            <div className={"detailed-project-invest__money detailed-project-invest__money--additional"}>
              <div className={"detailed-project-invest__title-money"}>
                Дата проведения АИ:
              </div>
              <div>
                {date ? date : "Дата не указана"}
              </div>
            </div>
          </div>
          <div className="detailed-project-invest__main">
            <div className={"detailed-project-invest__money detailed-project-invest__money--additional"}>
              <div>
                Доступно:
              </div>
              <div className={"detailed-project-invest__money-container"}>
                <Money money={profileData.balance}/>
              </div>
            </div>
            <div className={"detailed-project-invest__money detailed-project-invest__money--additional"}>
              <div>
                Остаток лимита поручения АИ:
              </div>
              <div className={"detailed-project-invest__money-container"}>
                <Money money={left}/>
              </div>
            </div>
            <div className={"detailed-project-invest__money detailed-project-invest__money--additional"}>
              <div>
                Минимальная инвестиция:
              </div>
              <div className={"detailed-project-invest__money-container"}>
                <Money
                  money={project.accounting.minAutoInvestSum}
                />
              </div>
            </div>
            <form className="detailed-project-invest__input" onSubmit={handleSubmitToSave}>
              <div className="detailed-project-invest__input-additional">
                Минимальная сумма сделки
              </div>
              <InputMoney
                money={minSum}
                onValueChanged={(money) => {
                  setMinSum(money);
                  setActiveButton(true);
                }}
                onFocus={() => focused.current = true}
                onBlur={() => focused.current = false}
                disabled={project.isProjectAISettingsFrozen}
              />
              <div
                className="detailed-project-invest__input-additional">
                Максимальная сумма сделки
              </div>
              <InputMoney
                money={maxSum}
                onValueChanged={(money) => {
                  setMaxSum(money);
                  setActiveButton(true);
                }}
                onFocus={() => focused.current = true}
                onBlur={() => focused.current = false}
                disabled={project.isProjectAISettingsFrozen}
              />
              {AIError &&
              <div className={"detailed-project-invest__money detailed-project-invest__money__error"}>
                Значение выходит за пределы поручения
              </div>}
              <PrimaryButton expanded large
                color={disabledConditionToAI ? PrimaryButtonColor.GRAY : PrimaryButtonColor.GREEN}>
                <Button
                  disabled={disabledConditionToAI}
                  className="detailed-project-invest__button-save"
                  type='submit'>
                  Сохранить
                </Button>
              </PrimaryButton>
            </form>
          </div>
        </div>}
      </div>
    </div>
  );
}
;

export default ProjectAutoInvest;