import React from "react";
import {ClassInjector} from "../../../../../injectors";
import './PrimaryHeading.scoped.scss';
import {ClassNameDecorator} from "../../../../../decorators";
import {ChildrenProps} from "../../../../../../utils";

const PrimaryHeading: ClassNameDecorator<ChildrenProps> = ({children, className}) => {
  return (
    <ClassInjector classNames="primary-heading" oldClassName={className}>
      { children }
    </ClassInjector>
  );
};

export default PrimaryHeading;