import React from "react";

const DownloadSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.595" height="18.115" viewBox="0 0 20.595 18.115">
      <g id="Сгруппировать_12109" data-name="Сгруппировать 12109" transform="translate(19.595 1) rotate(90)">
        <path id="Контур_14351" data-name="Контур 14351" d="M0,0H5.8V18.595H0" transform="translate(10.316)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <line id="Линия_154" data-name="Линия 154" x2="10.316" transform="translate(0 9.563)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        <path id="Контур_14352" data-name="Контур 14352" d="M0,0,3.63,3.63,0,7.261" transform="translate(7.172 5.933)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      </g>
    </svg>
  );
};

export default DownloadSvg;