import {FaqItem} from "../../../types";

export const questions: Array<FaqItem> = [
  {
    id: 1,
    question: "Зачем вообще кредитору продавать долг?",
    answer:  "Самая основная причина продажи долга - срочно нужны деньги, " +
            "мы предлагаем деньги сейчас, без ожидания и беготни по судам.\n" +
            "Также есть две побочные причины: нет желания / возможности / " +
            " времени самостоятельно заниматься взысканием задолженности или нет веры в успех взыскания.\n" +
            "Бывает три основные причины:\n" +
            "1.Кредитор не верит, что это хороший долг и что его удастся вернуть. " +
            " А мы знаем нюансы и знаем, что сможем его взыскать.\n" +
            "2.Кредитор не хочет заниматься этим делом, не хочет тратить нервы/ресурсы/время. " +
            "Таких кейсов бывает около 25%. А у нас есть ресурсы и отлажен процесс взыскания.\n" +
            "3.Кредитору срочно нужны деньги. Ему нужно расплатиться по своим обязательствам, " +
            "либо есть хорошая возможность вложиться сейчас, некогда ждать." +
            " А мы предлагаем деньги сейчас, без ожидания и беготни по судам."
  },
  {
    id: 2,
    question: "Какие существуют риски?",
    answer: "Есть риск, когда проект затягивается на срок более " +
            "12 месяцев и становится инвестиционно-непривлекательным.\nВ этом случаем мы бросаем все " +
            "силы на то, чтобы вернуть сумму инвестиции. Но так как мы непрерывно и системно работаем над " +
            "ошибками таких проектов было 7 из более чем 72 за все время, и в основном в начале деятельности."
  },
  {
    id: 3,
    question: "Какие риски несет инвестор?",
    answer: "Инвестор рискует суммой своего вклада в цессию.\n" +
            "При этом если инвестор составляет себе диверсифицированный портфель, то не заработать просто невозможно." +
            "По факту у нас нет ни одного инвестора, который бы зарабатывал менее 20% годовых, следуя этой стратегии."
  },
  {
    id: 4,
    question: "Какие гарантии успеха в суде?",
    answer: "По каждому проекту на ознакомление инвестору мы направляем презентацию, в которой рассказываем о долге,"
            + " должнике, основные моменты аналитического и юридического заключения, экономику проекта. " +
            "Говорим о рисках, которые могут быть в долге (если таковые имеются) и стратегии взыскания " + "" +
            " (на что мы рассчитываем, приобретая тот или иной долг).\n" +
            "Юридическую сторону долга мы всегда прорабатываем очень тщательно. " +
            " Если видим какие-то риски (даже если долг просужен), то смотрим первичную документацию, " +
            "просим Кредитора подписать доп соглашение с Дебитором, например, или другие документы, " +
            "которые бы гарантировали, подтверждали долг и снимали определенные риски."
  }
];