import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useId, useState} from "react";
import {Questions, QuestionType, UserInput} from "../../../../api-client";
import {Errors as ValidationErrors} from "../../../../utils";
import {useEffectOnUpdate} from "../../../../hooks";
import {InputErrors} from "../../../common";
import './SurveyInputGroup.scoped.scss';

interface Props {
  questions: Questions,
  addAnswer: (answer: UserInput) => void;
  setErrors: Dispatch<SetStateAction<Record<number, ValidationErrors>>>,
  errors: Record<number,ValidationErrors>
}
export const SurveyInputGroup = ({questions, addAnswer, setErrors, errors}: Props) => {
  const {text, index} = questions;
  const fallbackId = useId();
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event:ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    if (inputValue) {
      addAnswer({
        type: QuestionType.TEXT_INPUT,
        questionIndex: index,
        text: inputValue
      });
    }
  }, [inputValue]);

  const validateBlock = (): void => {
    if (inputValue === "") {
      setErrors((prevState) => ({...prevState, ...{[index]:["Обязательно для заполнения"]}}));
    }
    else delete errors[index];
  };

  useEffectOnUpdate(() => {
    validateBlock();
  }, [inputValue]);

  return <div className="survey__input-group">
    <label className="survey__input-group__question">
      Вопрос №{index}. {text}
    </label>
    <input id={fallbackId} className="survey__input-group__input"
      value={inputValue}
      onChange={handleInputChange}/>
    {errors[index] && <InputErrors errors={errors[index]}/>}
  </div>;
};