import React from "react";
import './CloseButton.scoped.scss';
import {ChildrenProps, cn} from "../../../../../utils";
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

export enum CloseButtonSize {
    Small = "close-button--small",
    Medium = "close-button--medium",
    Large = "close-button--large",
}

interface Props extends ChildrenProps {
    size?: CloseButtonSize;
}

const CloseButton: ClassNameDecorator<Props> = ({size = CloseButtonSize.Medium, children, className}) => {
  return (
    <ClassInjector
      classNames={
        cn("close-button", size)
      }
      oldClassName={className}
    >
      { children }
    </ClassInjector>
  );
};

export default CloseButton;