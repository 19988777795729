import React, {ButtonHTMLAttributes, FC} from "react";
import './Button.scoped.scss';
import {cn, resultIf} from "../../../../utils";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    disabled?: boolean | undefined
}

const Button: FC<ButtonProps> = ({children, disabled=false, ...props}) => {
  return (
    <button
      {...props}
      className={cn(
        props.className,
        resultIf(disabled, "disabled")
      )}
    >
      { children }
    </button>
  );
};

export default Button;