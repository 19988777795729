import React, {useEffect, useState} from "react";
import {useEffectOnMount, useErrorCallback, useNavigateByName, useProfileData} from "../../../../hooks";
import {createAgentDocumentProvider} from "../../../../di";
import {Button, CloseButton, PrimaryButton, SignAgentsContractModal} from "../../../../components";
import {PrimaryButtonColor} from "../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../utils";
import './SignContract.scoped.scss';
import useBodyOverflow from "../../../../hooks/useBodyOverflow";
import IFrame from "../../../../components/common/utils/IFrame";

const SignContract = () => {
  const profileData = useProfileData();
  const navigate = useNavigateByName();
  const handleError = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [documentUrl, setDocumentUrl] = useState("");
  const [documentUuid, setDocumentUuid] = useState("");
  const [contractModalOpened, setContractModalOpened] = useState(true);
  const [smsModalOpened, setSmsModalOpened] = useState(false);

  useEffect(() => {
    if (!contractModalOpened) navigate(RouteDictionary.MY_MONEY);
  }, [contractModalOpened]);

  useBodyOverflow();

  const isVerified = () => {
    return profileData.lenderVerified;
  };
  useEffectOnMount(() => {
    if (!isVerified()) {
      navigate(RouteDictionary.PROFILE);
    }
  });

  useEffectOnMount(() => {
    const callback = async () => {
      setLoading(true);
      try {
        const provider = await createAgentDocumentProvider();
        const result = await provider.getDocumentUri();
        setDocumentUrl(result.agreementUri);
        setDocumentUuid(result.uuid);

      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    };
    (async () => {
      await callback();
    })();
  });

  const closePage = () => {
    setContractModalOpened(false);
    navigate(RouteDictionary.MY_MONEY);
  };

  return (
    <div className="sign-contract">
      <div className="sign-contract__close">
        <CloseButton>
          <Button onClick={closePage}/>
        </CloseButton>
      </div>
      <div className="sign-contract__content">
        <div className="sign-contract__block">
          <div className="sign-contract__header">
            Ознакомьтесь и примите условия агентского договора
          </div>
          <div className="sign-contract__text">
            {loading && "Загружаем"}
            {documentUrl && <div className="sign-contract__document">
              <IFrame src={documentUrl}/>
            </div>}
          </div>
          <div className="sign-contract__submit">
            <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
              <Button onClick={() => setSmsModalOpened(true)}>
                Заключить агентский договор
              </Button>
            </PrimaryButton>
          </div>
        </div>
      </div>
      <SignAgentsContractModal active={smsModalOpened} 
        onClose={() => setContractModalOpened(false)}
        closeSmsModal={() => setSmsModalOpened(false)}
        documentUuid={documentUuid}/>
    </div>);
};

export default SignContract;