import React from "react";

const SearchSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16.824" height="17.591" viewBox="0 0 16.824 17.591">
      <g id="Сгруппировать_12468" data-name="Сгруппировать 12468" transform="translate(-1136 -284)">
        <g id="Эллипс_513" data-name="Эллипс 513" transform="translate(1138 286)" fill="none" stroke="#9299a2" strokeWidth="2">
          <circle cx="5.365" cy="5.365" r="5.365" stroke="none"/>
          <circle cx="5.365" cy="5.365" r="6.365" fill="none"/>
        </g>
        <line id="Линия_850" data-name="Линия 850" x2="3.832" y2="4.215" transform="translate(1147.58 295.963)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeWidth="2"/>
      </g>
    </svg>
  );
};

export default SearchSvg;