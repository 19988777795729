import React, {FC, useMemo} from "react";
import {RangeSlider} from "../../../common";
import {SliderProps} from "rc-slider";
import {RangeSliderProps} from "../../../common/selects/RangeSlider/RangeSlider";
import {MAX_MATURITY_DATE, MIN_MATURITY_DATE} from "../../../../utils";
import './MaturityDateSlider.scoped.scss';

interface Props {
    currentValue: Array<number | undefined | null>
}

const MaturityDateSlider: FC<SliderProps & RangeSliderProps & Props> = ({handleChange, currentValue, ...props}) => {
  const value = useMemo((): Array<number> => {
    return [
      currentValue[0] === undefined || currentValue[0] === 0 || currentValue[0] === null ? MIN_MATURITY_DATE : currentValue[0],
      currentValue[1] === undefined || currentValue[1] === 0 || currentValue[1] === null ? MAX_MATURITY_DATE : currentValue[1],
    ];
  }, [currentValue]);
  return (
    <div>
      <RangeSlider
        {...props}
        min={MIN_MATURITY_DATE}
        max={MAX_MATURITY_DATE}
        defaultValue={[MIN_MATURITY_DATE, MAX_MATURITY_DATE]}
        // marks={{1: "1 мес.", 15: "Без ограничений"}}
        pushable={true}
        handleChange={handleChange}
        value={value}
      />
      <div className="slider-labels">
        <div>
          { currentValue[0] ?  currentValue[0] + " мес." : "1 мес."}
        </div>
        <div>
          { currentValue[1] && (currentValue[1] < MAX_MATURITY_DATE) ?  currentValue[1] + " мес." : "Без ограничений"}
        </div>
      </div>
    </div>

  );
};

export default MaturityDateSlider;