import React, {FC} from "react";
import {createEconomicClassProvider} from "../../../../di";
import DebtClassList from "../DebtClassList";

interface Props {
    selectedClasses: Array<string>;
    onSelected: (selected: Array<string>) => void;
}

const mainEconomicClasses = createEconomicClassProvider().getMainClasses();

const EconomicClasses: FC<Props> = ({selectedClasses, onSelected}) => {
  return (
    <DebtClassList
      classList={mainEconomicClasses}
      selectedClasses={selectedClasses}
      onSelected={onSelected}
    />
  );
};

export default EconomicClasses;