import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {PhoneManager} from "../PhoneManager";

export class PhoneManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/phone`,
      headers
    );
  }

  public createPhoneManager(): PhoneManager {
    return new PhoneManager(this.apiClientFactory.createClient());
  }
}