import React, {useState} from "react";
import './TransactionsPartialFilter.scoped.scss';
import {TransactionDirection} from "../../../../api-client";
import {Nullable} from "../../../../utils";
import PrinterSvg from "../../../svg/PrinterSvg";
import DownloadSvg from "../../../svg/DownloadSvg";
import CommonFilterIcon from "../../../filters/CommonFilterIcon";
import {FilterIcon} from "../../../filters/CommonFilterIcon/CommonFilterIcon";
import FilterSearch from "../../../filters/FilterSearch";
import {createTransactionProvider} from "../../../../di";
import apiClientConfig from 'configs/app';
import TransactionDateRangeSelect from "../TransactionDateRangeSelect";
import {DateRangeType, transformToDates} from "../../../../api-client/enums/DateRangeType";
import loading from '../../../../assets/images/svg/loading.svg';


export type Filter = {
    search: string;
    dateFrom: string;
    dateTo: string;
    transactionDirection: Nullable<TransactionDirection>;
    projectUuids: Array<string>;
}

type Props = {
    filter: Filter;
    onChange: (filter: Filter) => void;
}

const TransactionsPartialFilter = ({filter, onChange}: Props) => {
  const handleSearch = (search: string): void => {
    onChange({...filter, search: search});
  };

  let [isLoading, setLoading] = useState(false);

  const exportStatement = async (): Promise<void> => {
    setLoading(true);
    const provider = await createTransactionProvider();
    const response = await provider.exportStatement(filter);
    window.location.assign(apiClientConfig.apiBaseUrl + response.url);
    setLoading(false);

  };
  const [dateRange, setDateRange] = useState<DateRangeType | null>(null);
  const handlePrint = () => {
    window.print();
  };
  const handleDate = (dateRange: DateRangeType) => {
    setDateRange(dateRange);
    const [dateTo, dateFrom] = transformToDates(dateRange);
    onChange(
      {
        ...filter,
        dateFrom: dateFrom ? dateFrom.toISOString() : "",
        dateTo: dateTo ? dateTo.toISOString() : ""
      });
  };

  return (
    <div className="transaction-partial-filter">
      <div className="transaction-partial-filter__navigation">
        <TransactionDateRangeSelect onChange={handleDate}
          selectedDirection={dateRange}/>
        <FilterSearch search={filter.search} onSearchChange={handleSearch} placeholder="Проект..."/>
        <div
          onClick={exportStatement}>
          <CommonFilterIcon type={FilterIcon.Download}>
            { isLoading && <img src={loading} alt={"Loading"} /> }
            {!isLoading && <DownloadSvg/>}
          </CommonFilterIcon>
        </div>
        <div onClick={handlePrint}>

          <CommonFilterIcon>
            <PrinterSvg/>
          </CommonFilterIcon>
        </div>

      </div>
    </div>
  );
};

export default TransactionsPartialFilter;