import {
  AlertNotificationData,
  AlertNotificationType
} from "../components/alert-notifications/AlertNotification/AlertNotification";
import {makeCounter} from "../utils";
import {useAppDispatch} from "./store";
import {add} from "../store/alertNotifications";

const counter = makeCounter();

export const useNotifier = () => {
  const dispatch = useAppDispatch();
  const createData = (type: AlertNotificationType, message: string): AlertNotificationData => {
    const id = counter();
    return {
      id,
      type,
      message
    };
  };
  return {
    createError: (message: string) => {
      dispatch(add(createData(AlertNotificationType.ERROR, message)));
    },
    createSuccess: (message: string) => {
      dispatch(add(createData(AlertNotificationType.INFO, message)));
    }
  };
};