import React, {FC} from "react";
import './DashboardHeader.scss';
import {ChildrenProps} from "../../../utils";

type DashboardHeaderExtensions = {
    Main: typeof DashboardHeaderMain;
}

const DashboardHeaderMain: FC<ChildrenProps> = ({children}) => {
  return (
    <div className="dashboard-header__main">
      { children }
    </div>
  );
};

const DashboardHeader: FC<ChildrenProps> & DashboardHeaderExtensions = ({children}) => {
  return (
    <div className="dashboard-header">
      { children }
    </div>
  );
};

DashboardHeader.Main = DashboardHeaderMain;

export default DashboardHeader;