import {Modal} from "../index";
import React, {useState} from "react";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {Button, PrimaryButton} from "../../buttons";
import styles from "./IncomeCertificateModal.module.scss";
import {useErrorCallback, useProfileData} from "../../../../hooks";
import {InputErrors} from "../../inputs";
import {Checkbox} from "../../form";
import {createAgreementProvider} from "../../../../di";
import apiClientConfig from "../../../../configs/app";
import LoadingIcon from "../../../../assets/images/svg/loading.svg";

interface Props {
  active: boolean,
  onClose: () => void,
  investUuid?: string,
  startYear: number
}

const IncomeCertificateModal = ({active, onClose, investUuid, startYear}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [sendToEmail, setSendToEmail] = useState<boolean>(true);
  const [download, setDownload] = useState<boolean>(true);
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState<number>(currentYear - 1);
  const profileData = useProfileData();
  const userEmail = () => {
    if (profileData.email === null) {
      return "Не указан";
    }
    return profileData.email.email;
  };
  const handleError = useErrorCallback();

  const orderIncomeCertificate = async (sendToEmail: boolean, year: number, download: boolean, investUuid?: string) => {
    setLoading(true);
    try {
      const provider = await createAgreementProvider();
      if (investUuid) {
        const result = await provider.orderIncomeCertificate(investUuid, year, sendToEmail);
        download && window.open(apiClientConfig.apiBaseUrl + result.url, '_blank');
      }
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        await handleError(err);
        setError(err.message);
      }
    }
    finally {
      setLoading(false);
    }
  };
  const years = Array.from(new Array(currentYear - startYear), (val, index) => currentYear - 1 - index);
  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedYear = parseInt(event.target.value);
    setYear(selectedYear);
  };

  return <Modal active={active} onClose={onClose}>
    <Modal.Close />
    <div className={styles.modal__container}>
      {error && <InputErrors errors={["Произошла ошибка"]}/>}
      <div className={styles.modal__title}>параметры справки</div>
      <div className={styles.modal__dateContainer}>
        <div className={styles.modal__date}>период:</div>
        <select className={styles.modal__select} onChange={handleYearChange} value={year}>
          {
            years.map((yearItem, index) => {
              return <option className={styles.modal__select} key={`year${index}`} value={yearItem}>{yearItem}</option>;
            })
          }
        </select>
      </div>
      <Checkbox checked={download} onChange={() => setDownload((agree) => !agree)}>
        <div className={styles.modal__text}>сохранить на устройстве</div>
      </Checkbox>
      <Checkbox checked={sendToEmail} onChange={() => setSendToEmail((agree) => !agree)}>
        <div className={styles.modal__text}>отправить на почту {userEmail()}</div>
      </Checkbox>
      { loading && <img src={LoadingIcon} alt={"Loading"} className={styles.modal__loading} /> }
      <PrimaryButton color={(!sendToEmail && !download)? PrimaryButtonColor.GRAY: PrimaryButtonColor.GREEN}>
        <Button className={styles.modal__button} disabled={!sendToEmail && !download || loading}
          onClick={() => orderIncomeCertificate(sendToEmail, year, download, investUuid)}>заказать
          справку</Button>
      </PrimaryButton>
    </div>
  </Modal>;
};

export default IncomeCertificateModal;