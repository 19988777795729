import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {NotificationSettingsManager} from "../NotificationSettingsManager";

export class NotificationSettingsManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers = {}) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/notification-settings`,
      headers
    );
  }

  createNotificationSettingsManager(token: string): NotificationSettingsManager {
    return new NotificationSettingsManager(this.apiClientFactory.createAuthorizedClient(token));
  }
    
}