import React, {useEffect} from "react";
import {createAuthTokenManager} from "../../../di";
import {useAppDispatch, useNavigateByName} from "../../../hooks";
import {clear} from "../../../store/profile";

const Logout = () => {
  let navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  useEffect(() => {
    createAuthTokenManager().remove();
    dispatch(clear());
    navigate('index');
  }, []);
  return (
    <>
    </>
  );
};

export default Logout;