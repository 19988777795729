import React, {FC, useMemo} from "react";
import {Money as MoneyModel} from "api-client";
import {cn, formatAmount as formatAmountUtil} from "utils";
import './Money.scoped.scss';

export enum MoneyMode {
    WRITE_OFF = 'money--write-off',
    PENALTY = 'money--penalty',
    ADDITIONAL = 'money--additional'
}

type Props = {
    money: MoneyModel;
    mode?: MoneyMode | undefined;
    withPennies?: boolean;
}

const Money: FC<Props> = ({money , mode, withPennies = true}) => {
  const formatAmount = useMemo(() => {
    return formatAmountUtil(money.amount / 100);
  }, [money]);
  const [rubles, pennies] = formatAmount.split(".");
  return (
    <span className={cn(
      "money",
      mode
    )}>
      <span className="money__rubles">
        { rubles }
      </span>
      { withPennies && <span className="money__pennies">
        ,{pennies}
      </span> }
      <span className="money__pennies">
                &nbsp;₽
      </span>
    </span>
  );
};

export default Money;