import React, {FC, useState} from "react";
import {ProfileCredentialInput} from "../index";
import {Phone} from "../../../../api-client";
import hidePhone from "../../../../formatters/hidePhone";
import {AsLink, Button, ColoredText} from "../../../common";
import ChangeUsernameModal from "../../../common/modals/ChangeUsernameModal/ChangeUsernameModal";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";

interface Props {
    phone: Phone;
}

const PhoneInput: FC<Props> = ({phone}) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <ProfileCredentialInput>
        <ProfileCredentialInput.Title>
          { hidePhone(phone) }
        </ProfileCredentialInput.Title>
        <div>
          <ColoredText color={TextColor.PRIMARY_GREEN}>
            <AsLink>
              <Button onClick={() => setModalOpened(true)}>
                Изменить
              </Button>
            </AsLink>
          </ColoredText>
        </div>
      </ProfileCredentialInput>
      <ChangeUsernameModal active={modalOpened} onClose={() => setModalOpened(false)} />
    </>
  );
};

export default PhoneInput;