import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LenderStats, Money} from "api-client";

export type MyMoneyState = {
    total: {
        invested: Money,
        reservedInvestment: Money,
        depositAmount: Money,
        comebackAmount: Money,
        withdrawAmount: Money
    },
    loanPayment: {
        projects: number
    },
    fundRaising: {
        projects: number
    },
    current: {
        projects: number,
        invested: Money,
        averageInitialTerm: number,
        forecastInterestRate: number,
        incomeLeft: Money,
        incomePaid: Money,
        forecastSimpleInterestRate: number,
        forecastYearROI: number,
    },
    closed: {
        projects: number,
        invested: Money,
        averageInitialTerm: number,
        comebackAmount: Money,
        netProfit: Money,
        interestRate: number,
        simpleInterestRate: number,
        forecastInterestRate: number,
        forecastSimpleInterestRate: number,
        forecastInitialTerm: number,
        forecastYearROI: number,
        factYearROI: number,
    }
}

const initialState: MyMoneyState = {
  total: {
    invested: new Money(0, "RUB"),
    reservedInvestment: new Money(0, "RUB"),
    depositAmount: new Money(0, "RUB"),
    comebackAmount: new Money(0, "RUB"),
    withdrawAmount: new Money(0, "RUB"),
  },
  loanPayment: {
    projects: 0
  },
  fundRaising: {
    projects: 0
  },
  current: {
    projects: 0,
    invested: new Money(0, "RUB"),
    averageInitialTerm: 0,
    forecastInterestRate: 0,
    incomeLeft: new Money(0, "RUB"),
    incomePaid: new Money(0, "RUB"),
    forecastSimpleInterestRate: 0,
    forecastYearROI: 0,
  },
  closed: {
    projects: 0,
    invested: new Money(0, "RUB"),
    averageInitialTerm: 0,
    comebackAmount: new Money(0, "RUB"),
    netProfit: new Money(0, "RUB"),
    interestRate: 0,
    simpleInterestRate: 0,
    forecastInterestRate: 0,
    forecastSimpleInterestRate: 0,
    forecastInitialTerm: 0,
    forecastYearROI: 0,
    factYearROI: 0,
  }
};

export const myMoneySlice = createSlice({
  name: "my-money",
  initialState,
  reducers: {
    set: (state: MyMoneyState, {payload}: PayloadAction<LenderStats>) => {
      state.total.invested = payload.total.invested;
      state.total.reservedInvestment = payload.total.reservedInvestment;
      state.total.depositAmount = payload.total.depositAmount;
      state.total.comebackAmount = payload.total.comebackAmount;
      state.total.withdrawAmount = payload.total.withdrawAmount;
      state.loanPayment.projects = payload.loanPayment.projects;
      state.fundRaising.projects = payload.fundRaising.projects;
      state.current.projects = payload.current.projects;
      state.current.invested = payload.current.invested;
      state.current.averageInitialTerm = payload.current.averageInitialTerm;
      state.current.forecastInterestRate = payload.current.forecastInterestRate;
      state.current.incomeLeft = payload.current.incomeLeft;
      state.current.incomePaid = payload.current.incomePaid;
      state.current.forecastSimpleInterestRate = payload.current.forecastSimpleInterestRate;
      state.current.forecastYearROI = payload.current.forecastYearROI;
      state.closed.projects = payload.closed.projects;
      state.closed.invested = payload.closed.invested;
      state.closed.averageInitialTerm = payload.closed.averageInitialTerm;
      state.closed.comebackAmount = payload.closed.comebackAmount;
      state.closed.netProfit = payload.closed.netProfit;
      state.closed.interestRate = payload.closed.interestRate;
      state.closed.simpleInterestRate = payload.closed.simpleInterestRate;
      state.closed.forecastInitialTerm = payload.closed.forecastInitialTerm;
      state.closed.forecastInterestRate = payload.closed.forecastInterestRate;
      state.closed.forecastSimpleInterestRate = payload.closed.forecastSimpleInterestRate;
      state.closed.forecastYearROI = payload.closed.forecastYearROI;
      state.closed.factYearROI = payload.closed.factYearROI;
    }
  }
});

export const {
  set,
} = myMoneySlice.actions;

export default myMoneySlice.reducer;

