import React, {FC} from "react";
import {useLoggedInStatus} from "../../hooks";
import {Navigate, Outlet} from "react-router-dom";
import {createRouteUrlProvider} from "../../di";
import {RouteDictionary} from "../../utils";

const AuthProtectedRouteGroup: FC<any> = () => {
  const routeProvider = createRouteUrlProvider();
  const isLoggedIn = useLoggedInStatus();
  return (
    isLoggedIn ? <Outlet /> : <Navigate to={routeProvider.getByName(RouteDictionary.ENTER)} />
  );
};

export default AuthProtectedRouteGroup;