export class Phone {
  constructor(
        public readonly countryCode: string,
        public readonly number: string
  ) {}

  get fullNumber(): string {
    return this.countryCode + this.number;
  }

  isValid(): boolean {
    return this.number.length === 10;
  }
}
