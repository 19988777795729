import React, {useEffect, useState} from "react";
import {cn, resultIf} from "../../../../utils";
import {Image} from "../../../common";
import arrowDown from "assets/images/svg/history-arrow.svg";
import {Flex} from "../../../layout";
import {AlignItems} from "../../../layout/Flex/Flex";
import IdentificationDateSelect from "../../identification/IdentificationDateSelect";
import {Filter} from "../TransactionsPartialFilter/TransactionsPartialFilter";
import {useSearchParams} from "react-router-dom";
import './TransactionDateRangeCalendarSelect.scoped.scss';
import {useDebounce} from "../../../../hooks";
import moment from "moment";

type Props = {
  filter: Filter;
  onChange: (filter: Filter) => void;
}

const TransactionDateRangeCalendarSelect = ({filter, onChange}: Props) => {
  const [dateFrom, setDateFrom] = useState<Date | null>(null);
  const [dateTo, setDateTo] = useState<Date | null>(null);
  const [toggle, setToggle] = useState(false);

  const [params, setParams] = useSearchParams();

  const handleClick = () => {
    setToggle(!toggle);
  };

  const handleDate = () => {
    onChange(
      {
        ...filter,
        dateFrom: dateFrom ? moment(dateFrom).format('YYYY-MM-DDTHH:mm:ssZ') : "",
        dateTo: dateTo ? moment(dateTo).format('YYYY-MM-DDTHH:mm:ssZ') : ""
      });
  };
  useEffect(() => {
    const dateFrom = params.get(`filter[dateFrom]`);
    const dateTo = params.get(`filter[dateTo]`);
    dateFrom && setDateFrom(new Date(dateFrom));
    dateTo && setDateTo(new Date(dateTo));
  }, []);

  const debounceDateFrom = useDebounce(dateFrom, 2000);
  const debounceDateTo = useDebounce(dateTo, 2000);

  useEffect(() => {
    if (debounceDateFrom && debounceDateTo) handleDate();
  }, [debounceDateFrom, debounceDateTo]);

  return (
    <div className="transaction-date-range-calendar-select">
      <div className="transaction-date-range-calendar-select__title">
        <div className="transaction-date-range-calendar-select__show">Дата</div>
        <div onClick={handleClick}>
          <Flex alignItems={AlignItems.CENTER} gap="0.5rem">
            <Image
              src={arrowDown}
              alt="Arrow down"
              className={cn(
                "transaction-date-range-calendar-select__image",
                resultIf(toggle, "active")
              )}
            />
          </Flex>
        </div>
      </div>
      {toggle && <div className="transaction-date-range-calendar-select__content">
        <IdentificationDateSelect value={dateFrom} onSelected={setDateFrom} header='с'/>
        <IdentificationDateSelect value={dateTo} onSelected={setDateTo} header='по'/>
      </div>}
    </div>
  );
};

export default TransactionDateRangeCalendarSelect;