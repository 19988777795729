export default {
  get smsCodeLength(): number {
    return parseInt(process.env.REACT_APP_SMS_CODE_LENGTH || "0");
  },
  get smsCodeTimeout(): number {
    return parseInt(process.env.REACT_APP_SMS_CODE_TIMEOUT || "0");
  },
  get smsMaxAttempts(): number {
    return parseInt(process.env.REACT_APP_SMS_MAX_ATTEMPTS || "0");
  }
};