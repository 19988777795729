import React from "react";
import {Environment, RouteDictionary} from "../../../../utils";
import {RouteMap} from "../../../layout/NavigationDropdown/NavigationDropdown";
import {NavigationDropdown} from "../../../layout";
import apiClientConfig from "../../../../configs/app";

const routesMapProd: RouteMap = {
  "/dashboard/my-projects/fundraising": {text: "В процессе сбора средств", route: RouteDictionary.FUNDRAISING_PROJECTS},
  "/dashboard/my-projects/active": {text: "Текущие", route: RouteDictionary.ACTIVE_PROJECTS}
};

const routesMapDevAndTest: RouteMap = {
  "/dashboard/my-projects/fundraising": {text: "В процессе сбора средств", route: RouteDictionary.FUNDRAISING_PROJECTS},
  "/dashboard/my-projects/active": {text: "Текущие", route: RouteDictionary.ACTIVE_PROJECTS},
  "/dashboard/my-projects/orders": {text: "Заявки", route: RouteDictionary.ORDERS_PROJECTS},
};

const MyProjectsNavigationDropdown = () => {
  const devAndTest = (apiClientConfig.applicationEnvironment === Environment.dev)
      || (apiClientConfig.applicationEnvironment === Environment.test);
  return (
    <NavigationDropdown routesMap={devAndTest? routesMapDevAndTest: routesMapProd} placeholderText="В процессе сбора средств" />
  );
};

export default MyProjectsNavigationDropdown;