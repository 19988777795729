import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {OfferSaleManager} from "../OfferSaleManager";

export class OfferSaleManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/offer`,
      headers
    );
  }

  public createOfferSaleManager(token: string): OfferSaleManager {
    return new OfferSaleManager(this.apiClientFactory.createAuthorizedClient(token));
  }
}