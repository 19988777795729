import React from "react";

const FilterSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.778" height="18" viewBox="0 0 19.778 18">
      <g id="Сгруппировать_961" data-name="Сгруппировать 961" transform="translate(488.778 -310) rotate(90)">
        <g id="Сгруппировать_960" data-name="Сгруппировать 960" transform="translate(311 470)">
          <line id="Линия_130" data-name="Линия 130" y2="17.778" transform="translate(8)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <circle id="Эллипс_111" data-name="Эллипс 111" cx="1.778" cy="1.778" r="1.778" transform="translate(6.222 7.111)" fill="#9299a2" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <line id="Линия_131" data-name="Линия 131" y2="17.778" transform="translate(1.778)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <circle id="Эллипс_112" data-name="Эллипс 112" cx="1.778" cy="1.778" r="1.778" transform="translate(0 10.667)" fill="#9299a2" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <line id="Линия_132" data-name="Линия 132" y2="17.778" transform="translate(14.222)" fill="none" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
          <circle id="Эллипс_113" data-name="Эллипс 113" cx="1.778" cy="1.778" r="1.778" transform="translate(12.444 3.556)" fill="#9299a2" stroke="#9299a2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
        </g>
      </g>
    </svg>
  );
};

export default FilterSvg;