import {ApiClientInterface} from "./clients";
import {Collection, Money, UrlResult, UuidResult} from "./models";
import {InvestmentConfirmation, OwnedInvestment} from "./models";
import {LoanPaymentProjectInvestment, InvestmentConfirmationResult} from "./models";
import {InvestmentForSale} from "./models/investment/InvestmentForSale";
import buildQuery from "../utils/router/buildQuery";
import {Filter} from "../components/pages/my-projects/my-investments/MyInvestments/MyInvestments";
import {ProjectsCount} from "./models/project/ProjectsCount";

export class InvestmentManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public createInvestmentConfirmation(projectUuid: string, money: Money): Promise<UuidResult>{
    return this.apiClient.post("/confirmation", {projectUuid, money});
  }

  public createInvestment(confirmationUuid: string, smsCode: string): Promise<InvestmentConfirmationResult> {
    return this.apiClient.post("", {confirmationUuid, smsCode});
  }

  public getConfirmation(confirmationUuid: string): Promise<InvestmentConfirmation>{
    return this.apiClient.get(`/confirmation/${confirmationUuid}`);
  }

  public sendCode(confirmationUuid: string): Promise<void>{
    return this.apiClient.post(`/confirmation/${confirmationUuid}/send-code`);
  }

  public getOneLoanPayment(uuid: string): Promise<InvestmentForSale>{
    return this.apiClient.get(`/loan-payment/${uuid}`);
  }

  public getListOwnedInvestments(page = 1, perPage = 25, filter: Filter): Promise<Collection<OwnedInvestment>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/owned-investments?${query}`);
  }

  public exportListOwnedInvestments(page = 1, perPage = 25, filter: Filter): Promise<UrlResult> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`/export-owned-investments-statistics?${query}`);
  }

  public getListLoanPaymentOwned(page = 1, perPage = 25): Promise<Collection<LoanPaymentProjectInvestment>> {
    return this.apiClient.get(`/loan-payment/owned?page=${page}&perPage=${perPage}`);
  }

  public async getProjectsCount(): Promise<ProjectsCount> {
    return this.apiClient.get(`/projects-count`);
  }
}