import {Image} from "../../../../../../../components";
import documentIcon from "../../../../../../../assets/images/svg/document.svg";
import {formatDate} from "../../../../../../../formatters";
import React from "react";
import styles from '../DocumentsToProjectsItem.module.scss';
import {CommercialOffer as CommercialOfferType} from "api-client/models/documents/CommercialOffer";

interface Props {
    commercialOfferDocument: CommercialOfferType
}

export const CommercialOffer = ({commercialOfferDocument}: Props) => {
  return (
    <li key={commercialOfferDocument.number} className={styles.documentsToProjectBody__list}>
      <a className={styles.documentsToProjectBody__item}
        href={commercialOfferDocument.link} target="_blank" rel="noreferrer">
        <Image className={styles.documentsToProjectBody__icon}
          src={documentIcon}/>
        <div className={styles.documentsToProjectBody__title}>Коммерческое предложение</div>
        <div className={styles.documentsToProjectBody__size}></div>
        <div className={styles.documentsToProjectBody__watch}>Нажмите, чтобы посмотреть</div>
        {commercialOfferDocument.date &&
          <div className={styles.documentsToProjectBody__date}>{formatDate(new Date(commercialOfferDocument.date))}</div>
        }<div className={styles.documentsToProjectBody__dateText}>дата публикации</div>
      </a>
    </li>
  );
};