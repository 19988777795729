import {ApiClientInterface} from "./clients";
import {LenderStats, UrlResult} from "./models";

export class StatsProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public getStats(): Promise<LenderStats> {
    return this.apiClient.get("/lender");
  }

  public exportStatistics(): Promise<UrlResult> {
    return this.apiClient.get(`/export-statistics`);
  }
}