type Words = Array<string>

export const declOfNum = (count: number, words: Words) => {
  const countRemainder = count % 100;
  const tenRemainder = countRemainder % 10;
  if (countRemainder > 10 && countRemainder < 20) {
    return words[2];
  }
  if (tenRemainder > 1 && tenRemainder < 5) {
    return words[1];
  }
  if (tenRemainder === 1) {
    return words[0];
  }
  return words[2];
};