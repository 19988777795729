import React, {useMemo, useState} from "react";
import {
  Block,
  Button,
  Container,
  DashboardHeader,
  Heading,
  Pagination,
  PrimaryHeading,
  Section
} from "../../../../../components";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import './ArchivedProjects.scoped.scss';
import CloseButton from "../../../../../components/common/buttons/decorators/CloseButton";
import {useErrorCallback, useNavigateByName} from "../../../../../hooks";
import {CloseButtonSize} from "../../../../../components/common/buttons/decorators/CloseButton/CloseButton";
import {DataCollection, RouteDictionary} from "../../../../../utils";
import {createProjectProvider} from "../../../../../di";
import {ArchivedProject as ArchivedProjectModel} from "../../../../../api-client";
import {ArchivedProjectsFilter} from "../../../../../components/pages/archived";
import {ArchivedProject} from "../../../../../components/pages";

export type Filter = {
    name: string;
}

const ArchivedProjects = () => {
  const navigate = useNavigateByName();
  const onExit = () => {
    navigate(RouteDictionary.PRIMARY_MARKET);
  };
  const handleError = useErrorCallback();
  const [filter, setFilter] = useState<Filter>({name: ""});
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] =
        useState<DataCollection<ArchivedProjectModel>>(new DataCollection());

  const loadData = async (page: number, perPage: number, filter: Filter) => {
    setLoading(true);
    try {
      const provider = await createProjectProvider();
      const projects = await provider.getArchivedProjects(page, perPage, filter);
      const dataCollection = new DataCollection<ArchivedProjectModel>();
      dataCollection.setCollection(projects);
      setProjects(dataCollection);
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const projectsList = useMemo(() => {
    return projects.items.map((project: ArchivedProjectModel) => {
      return <ArchivedProject project={project} key={project.uuid} />;
    });
  }, [projects]);

  return (
    <Section>
      <Container>
        <DashboardHeader>
          <DashboardHeader.Main>
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1}>
                Статистика проектов
              </Heading>
            </PrimaryHeading>
            <div>
              <CloseButton size={CloseButtonSize.Small}>
                <Button onClick={onExit} />
              </CloseButton>
            </div>
          </DashboardHeader.Main>
        </DashboardHeader>
        <Pagination
          onLoad={loadData}
          count={projects.count}
          totalCount={projects.totalCount}
          filterObject={filter}
          initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
        >
          <Block>
            <Block.Content>
              <Block.Header className="archived-projects-block-header" withPadding>
                <Pagination.Filter>
                  <ArchivedProjectsFilter
                    filter={filter}
                    onChange={(filter: Filter) => setFilter(filter)}
                    countFinishedProjects={projects.totalCount}
                  />
                </Pagination.Filter>
              </Block.Header>
              <Block.Body className="archived-projects-block-body" withPadding={false}>
                <Pagination.Content loading={loading}>
                  <Pagination.Header className="archived-projects-header">
                    <div className="archived-projects-header__item archived-projects-header__primary">
                      Проект
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__category">
                      Категория
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__investment">
                      Размер инвестиции
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__loan">
                      Размер взыскания
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__term">
                      Срок
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__simple">
                      Доходность простая
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__yearly">
                      Доходность годовая
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__transactions">
                      Транзакции
                    </div>
                    <div className="archived-projects-header__item archived-projects-header__stages">
                      События
                    </div>
                  </Pagination.Header>
                  <ul className="archived-projects__list">
                    { projectsList }
                  </ul>
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator />
        </Pagination>
      </Container>
    </Section>
  );
};

export default ArchivedProjects;