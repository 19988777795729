import React, {FC, PropsWithChildren, ReactNode} from "react";
import './SmsConfirmation.scoped.scss';
import modalLeft from "../../../assets/images/svg/modal-left.svg";
import {Button, Image} from "../../common";

interface Props {
    header: ReactNode;
    onClose: () => void;
}

const SmsConfirmation: FC<PropsWithChildren<Props>> = ({header, onClose, children}) => {
  return (
    <div className="sms-confirmation">
      <Button onClick={onClose} className="sms-confirmation__button">
        <Image src={modalLeft} />
      </Button>
      <header className="sms-confirmation__header">
        { header }
      </header>
      { children }
    </div>
  );
};

export default SmsConfirmation;