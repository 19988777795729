import {ApiClientInterface} from "./clients";
import {
  EntrepreneurIdentification, Identification,
  IndividualIdentification,
  LegalEntityIdentification
} from "./models";

export class IdentificationManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public findOne():Promise<Identification>
  {
    return this.apiClient.get("/");
  }

  public createIndividualIdentification(
    identification: IndividualIdentification
  ): Promise<void> {
    return this.apiClient.post("/individual", {identification});
  }

  public createLegalEntityIdentification(
    identification: LegalEntityIdentification
  ): Promise<void> {
    return this.apiClient.post("/legal-entity", {identification});
  }

  public createEntrepreneurIdentification(
    identification: EntrepreneurIdentification
  ): Promise<void> {
    return this.apiClient.post("/entrepreneur", {identification});
  }
}

export default IdentificationManager;