import React from 'react';
import {Block, ColoredText, CustomLink, Flex, Image, Money} from "../../../index";
import {AlignItems} from "../../../layout/Flex/Flex";
import portfolioIconGreen from "../../../../assets/images/svg/portfel.svg";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";
import {declOfNum, RouteDictionary} from "utils";
import {formatPercents} from "formatters";
import './Portfolio.scoped.scss';
import PortfolioBody from "../PortfolioBody";
import {useMyMoneyData} from "../../../../hooks/useMyMoneyData";

const Portfolio = () => {
  const stats  = useMyMoneyData();

  const projectMoney = [
    {title: 'Инвестировано ', count: <Money money={stats.current.invested}/>,
      content: 'Сумма ваших инвестиций с вознаграждением за инвестиции в текущих проектах'},
    {title: 'Осталось к получению ', count: <Money money={stats.current.incomeLeft}/>,
      content: 'Сумма прогнозов будущих взысканий на вашу долю за вычетом вознаграждений за доход по текущим проектам '},
    {title: 'Возвращено ', count: <Money money={stats.current.incomePaid}/>,
      content: 'Сумма взысканий на вашу долю за вычетом вознаграждений за доход и инкассо платежей кредитору по текущим проектам '},
  ];

  const projectOptions = [
    {
      header: '',
      title: 'Прогноз ROI',
      count: `${formatPercents(stats.current.forecastSimpleInterestRate)}`,
      content: 'Средневзвешенный прогноз простой доходности ROI по текущим проектам'
    },
    {header: '', title: 'Прогноз годового ROI', count: `${formatPercents(stats.current.forecastYearROI)}`,
      content: 'Средневзвешенный прогноз годовой доходности ROI по текущим проектам'},
    {header: '', title: 'Прогноз годового IRR', count: `${formatPercents(stats.current.forecastInterestRate)}`,
      content: 'Средневзвешенный прогноз годовой доходности IRR по текущим проектам'},
    {header: '', title: 'Плановый срок', count: `${stats.current.averageInitialTerm} мес.`,
      content: 'Средневзвешенный плановый срок по текущим проектам'},
  ];

  return (
    <div className="my-money__portfolio">
      <Block>
        <Block.Content>
          <Block.Header className="my-money__block-header" withPadding>
            <Flex alignItems={AlignItems.CENTER} gap="1rem">
              <div className="my-money__header-inner">
                <div className="my-money__icon">
                  <Image src={portfolioIconGreen} alt="Portfolio icon"/>
                </div>
                <div className="my-money__heading my-money__heading--main">
                  Текущий портфель
                </div>
              </div>
              <ColoredText color={TextColor.PRIMARY_GREEN}>
                <div className="my-money__heading my-money__heading--main">
                  <ColoredText color={TextColor.PRIMARY_GREEN}>
                    <CustomLink to={RouteDictionary.MY_PROJECTS}>
                      {stats.current.projects} {declOfNum(stats.loanPayment.projects, ["проект", "проекта", "проектов"])}
                    </CustomLink>
                  </ColoredText>
                </div>
              </ColoredText>
            </Flex>
          </Block.Header>
          <PortfolioBody projectMoney={projectMoney} projectOptions={projectOptions}/>
        </Block.Content>
      </Block>
    </div>
  );
};

export default Portfolio;