import React, {FC} from "react";
import {
  isAnnounced,
  isFundRaising,
  isLoanPayment,
  isPaid,
  isRefunded,
  OwnedInvestment as MyInvestment,
  translateProjectStatus
} from "../../../../../api-client";
import {BoldText, CustomLink, Money} from "../../../../common";
import {ProjectCategory} from "../../../../project";
import {formatDate} from "../../../../../formatters";
import styles from './OwnesInvestment.module.scss';
import {RouteDictionary} from "../../../../../utils";

interface Props {
    investment: MyInvestment;
}

const OwnedInvestment: FC<Props> = ({investment}) => {
  if (isAnnounced(investment.project.status)) return null;
  return (
    <li className={styles.ownedInvestment}>
      <div className={styles.ownedInvestment__categoryContainer}>
        <div className={styles.ownedInvestment__category}>
          <ProjectCategory debtClass={investment.project.debtClass}/>
        </div>
        {investment.project.hasExtraCreditorPayment && <div className={styles.ownedInvestment__incasso}>
          инкассо
        </div>}
      </div>
      <BoldText>
        <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: investment.project.uuid}} key={investment.project.uuid}
          className={styles.ownedInvestment__linkToProject}>
          <div className={styles.ownedInvestment__primary}>
            {investment.project.name[0].text}
          </div>
        </CustomLink>
      </BoldText>
      <div className={styles.ownedInvestment__sum}>
        <div className={styles.ownedInvestment__rowHeader}>
          Инвестировано
        </div>
        <div>
          <Money money={investment.investedSum}/>
        </div>
      </div>

      <div className={styles.ownedInvestment__forecast}>
        <div className={styles.ownedInvestment__rowHeader}>
          Прогноз возврата
        </div>
        <div>
          {investment.project.recoveryForecast && <Money money={investment.recoveryForecast}/>}
        </div>
      </div>

      <div className={styles.ownedInvestment__term}>
        <div className={styles.ownedInvestment__rowHeader}>
          Возвращено
        </div>
        <div>
          {investment.project.actualPayment && <Money money={investment.project.actualPayment}/>}
        </div>
      </div>
      {isFundRaising(investment.project.status) &&
        <div className={styles.ownedInvestment__statusContainer}>
          <BoldText>
            <div className={styles.ownedInvestment__status}>
              {translateProjectStatus(investment.project.status)}
            </div>
          </BoldText>
          <div className={styles.ownedInvestment__date}>
            <div className={styles.ownedInvestment__rowHeader}>
              Сбор до
            </div>
            <div>
              {formatDate(investment.project.maturityDate)}
            </div>
          </div>
        </div>
      }
      {isLoanPayment(investment.project.status) &&
        <div className={styles.ownedInvestment__statusContainer}>
          <BoldText>
            <div className={styles.ownedInvestment__status}>
              {translateProjectStatus(investment.project.status)}
            </div>
          </BoldText>
          <div className={styles.ownedInvestment__date}>
            <div className={styles.ownedInvestment__rowHeader}>
              Начало проекта
            </div>
            <div>
              {investment.project.loanIssuedAtDate ? formatDate(investment.project.loanIssuedAtDate) : '-'}
            </div>
          </div>
          <div className={styles.ownedInvestment__money}>
            <div className={styles.ownedInvestment__rowHeader}>
              Плановая дата окончания
            </div>
            <div>
              {investment.project.projectEndDate? formatDate(investment.project.projectEndDate) : 'Дата не указана'}
            </div>
          </div>
        </div>
      }
      {isPaid(investment.project.status) &&
        <div className={styles.ownedInvestment__statusContainer}>
          <BoldText>
            <div className={styles.ownedInvestment__status}>
              {translateProjectStatus(investment.project.status)}
            </div>
          </BoldText>
          <div className={styles.ownedInvestment__date}>
            <div className={styles.ownedInvestment__rowHeader}>
              Конец проекта
            </div>
            <div>
              {formatDate(investment.project.paidAt)}
            </div>
          </div>
        </div>
      }
      {isRefunded(investment.project.status) &&
        <div className={styles.ownedInvestment__statusContainer}>
          <BoldText>
            <div className={styles.ownedInvestment__status}>
              {translateProjectStatus(investment.project.status)}
            </div>
          </BoldText>
          <div className={styles.ownedInvestment__date}>
            <div className={styles.ownedInvestment__rowHeader}>
              Дата отмены
            </div>
            <div>
              {formatDate(investment.project.maturityDate)}
            </div>
          </div>
        </div>
      }
    </li>
  );
};

export default OwnedInvestment;