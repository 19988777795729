import React, {createContext, FC, PropsWithChildren} from "react";

export type ProjectFilterConditionContextType = {
    isSearchActive: () => boolean;
    areClassesEmpty: () => boolean;
    isMaturityDateEmpty: () => boolean;
    isYearProfitEmpty: () => boolean;
}

type Props = {
    props: ProjectFilterConditionContextType
}

export const ProjectFilterConditionContext = createContext<ProjectFilterConditionContextType>({
  isSearchActive: () => false,
  areClassesEmpty: () => false,
  isMaturityDateEmpty: () => false,
  isYearProfitEmpty: () => false,
});

function ProjectFilterConditionContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <ProjectFilterConditionContext.Provider value={value.props}>
    { children }
  </ProjectFilterConditionContext.Provider>;
}

export default ProjectFilterConditionContextProvider;