import {useMemo} from "react";
import {Money as MoneyModel} from "../api-client";
import config from "../configs/app";

export function getAmountWithCommission(investmentAmount: number): MoneyModel {
  const investmentFee = useMemo((): MoneyModel => {
    return new MoneyModel(investmentAmount * config.appFeePercent / 100, "RUB");
  }, [investmentAmount]);

  return new MoneyModel(investmentAmount + investmentFee.amount, "RUB");
}