import {ApiClientInterface} from "./clients";
import buildQuery from "../utils/router/buildQuery";
import {Collection, Transaction, UrlResult} from "./models";
import {Filter} from "../components/pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";


export class TransactionProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  getList(page = 1, perPage = 25, filter?: Filter): Promise<Collection<Transaction>> {
    const query = buildQuery({page, perPage, filter});
    return this.apiClient.get(`?${query}`);
  }

  exportStatement(filter?: Filter): Promise<UrlResult> {
    const query = buildQuery({filter});
    return this.apiClient.get(`/export-statement?${query}`);
  }
}