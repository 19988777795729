import React, {FormEvent, useState} from "react";
import {Questions, QuestionType, Survey as SurveyModel, SurveyAnswer, UserInput} from "../../../../api-client";
import {PrimaryButton} from "../../../common";
import {PrimaryButtonColor} from "../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {SurveyCheckboxGroup} from "../SurveyCheckbox";
import {SurveyRadioButtonGroup} from "../SurveyRadioButtonGroup";
import {SurveyInputGroup} from "../SurveyInputGroup";
import {Errors as ValidationErrors} from "../../../../utils";
import './SurveyForm.scoped.scss';

interface Props {
    survey: SurveyModel,
    addAnswer: (answer: UserInput) => void;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    surveyAnswer: SurveyAnswer
}

export const SurveyForm = ({survey, addAnswer, handleSubmit, surveyAnswer}: Props) => {
  const [errors, setErrors] = useState<Record<number, ValidationErrors>>({});
  const dataValid = (Object.keys(errors).length === 0) && (survey.questions.questions.length === surveyAnswer.answers.length);

  const renderQuestions = () => {
    if (survey.questions.questions === undefined || survey.questions.questions.length === 0) return <>Нет
      вопросов</>;
    const renderedQuestions = survey.questions.questions.map((questions: Questions) => {
      if (questions.questionType === QuestionType.CHECKBOX) return <SurveyCheckboxGroup key={questions.index}
        addAnswer={addAnswer}
        questions={questions}
        setErrors={setErrors}
        errors={errors}/>;
      if (questions.questionType === QuestionType.RADIOBUTTON) return <SurveyRadioButtonGroup
        addAnswer={addAnswer}
        key={questions.index}
        questions={questions}/>;
      if (questions.questionType === QuestionType.TEXT_INPUT) return <SurveyInputGroup
        addAnswer={addAnswer}
        key={questions.index}
        questions={questions}
        setErrors={setErrors}
        errors={errors}/>;
    });
    return <>{renderedQuestions}</>;
  };
  return <form onSubmit={handleSubmit}>
    {renderQuestions()}
    <PrimaryButton color={dataValid ? PrimaryButtonColor.GREEN : PrimaryButtonColor.GRAY} >
      <button type="submit" className="survey-form__submit-button" disabled={!dataValid}>
        Отправить
      </button>
    </PrimaryButton>
  </form>;
};

