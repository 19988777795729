import React, {useEffect, useState} from "react";
import RadioButtonGroup, {Direction} from "../../../common/form/RadioButtonGroup/RadioButtonGroup";
import {RadioButton} from "../../../common";
import {Questions, QuestionType, UserInput} from "../../../../api-client";
import './SurveyRadioButtonGroup.scoped.scss';

interface Props {
    questions: Questions;
    addAnswer: (answer: UserInput) => void;
}

export const SurveyRadioButtonGroup = ({questions, addAnswer}: Props) => {
  const {index, text, options} = questions;
  const [value, setValue] = useState<number>();

  const handleRadioButtonChange = (answer: number) => {
    setValue(answer);
  };

  useEffect(() => {
    if (value) {
      addAnswer({
        type: QuestionType.RADIOBUTTON,
        questionIndex: index,
        chosenIndex: value
      });
    }
  }, [value]);

  return <div className="survey__radio-button-group">
    <label className="survey__radio-button-group__question">Вопрос №{index}. {text}</label>
    <RadioButtonGroup direction={Direction.HORIZONTAL}>
      {options?.list.map((option) => (
        <RadioButton key={option.text}
          checked={value === option.index}
          onChange={() => handleRadioButtonChange(option.index)}
          name={text}>
          {option.text}
        </RadioButton>)
      )}
    </RadioButtonGroup>
  </div>;
};