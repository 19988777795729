import React, {FC, ImgHTMLAttributes} from "react";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {}

const Image: FC<Props> = ({...props}) => {
  return (
    <img {...props} />
  );
};

export default Image;