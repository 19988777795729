import React, {FC, useState} from "react";
import {ProfileCredentialInput} from "../index";
import {Email} from "../../../../api-client";
import {AsLink, Button, ChangeEmailModal, ColoredText} from "../../../common";
import {TextColor} from "../../../common/typography/texts/ColoredText/ColoredText";

interface Props {
    email: Email | null;
}

const EmailInput: FC<Props> = ({email}) => {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <ProfileCredentialInput>
        <ProfileCredentialInput.Title>
          { email !== null ? email.email : "Не указан" }
        </ProfileCredentialInput.Title>
        <div>
          <ColoredText color={TextColor.PRIMARY_GREEN}>
            <AsLink>
              <Button onClick={() => setModalOpened(true)}>
                Изменить
              </Button>
            </AsLink>
          </ColoredText>
        </div>
      </ProfileCredentialInput>
      <ChangeEmailModal active={modalOpened} onClose={() => setModalOpened(false)} />
    </>
  );
};

export default EmailInput;