import React, {FC, useContext, useState} from "react";
import {Errors as ValidationErrors, numbersOnly} from "../../../../../utils";
import {IdentificationBlock} from "../index";
import {CommonInput, Heading, InputErrors, SecondaryHeading} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import IdentificationContext from "../../../../context/IdentificationContext";
import * as constants from "../../../../../utils/constants";
import {useEffectOnMount, useEffectOnUpdate} from "../../../../../hooks";
import {
  IdentificationCompany as ApiCompany
} from '../../../../../api-client/models/identification/common/IdentificationCompany';

export type IdentificationCompanyErrors = {
    companyName?: ValidationErrors;
    companySiteUrl?: ValidationErrors;
    companyInn?: ValidationErrors;
    companyKpp?: ValidationErrors;
    companyOgrnip?: ValidationErrors;
    companyAddress?: ValidationErrors;
}

interface Props {
    onValidated: (value: boolean) => void;
    company?: ApiCompany;
}

const IdentificationCompany: FC<Props> = ({onValidated, company}) => {
  const [errors, setErrors] = useState<IdentificationCompanyErrors>({});
  const context: any = useContext(IdentificationContext);


  useEffectOnMount(() => {
    if (company) {
      context.setCompanyName(company.name);
      context.setCompanyInn(company.inn);
      context.setCompanyKpp(company.kpp);
      if (company.ogrnip){
        context.setCompanyOgrnip(company.ogrnip);
      }
      context.setCompanyAddress(company.address);
      context.setCompanySiteUrl(company.siteUrl ?? '');
    }
  });

  const validateBlock = (): boolean => {
    const errors: IdentificationCompanyErrors = {};
    if (context.companyName === "") {
      errors.companyName = ["Название компании обязательно для заполнения"];
    }
    if (context.companyInn === "") {
      errors.companyInn = ["ИНН компании обязательно для заполнения"];
    } else if (!numbersOnly(context.companyInn)) {
      errors.companyInn = ["ИНН должен состоять из цифр"];
    } else if (context.companyInn.length !== constants.INN_LEGAL_ENTITY_LENGTH) {
      errors.companyInn = [`Длина ИНН - ${constants.INN_LEGAL_ENTITY_LENGTH} символов`];
    }
    if (context.companyKpp === "") {
      errors.companyKpp = ["КПП компании обязательно для заполнения"];
    } else if (!numbersOnly(context.companyKpp)) {
      errors.companyKpp = ["КПП должен состоять из цифр"];
    } else if (context.companyKpp.length !== constants.KPP_LENGTH) {
      errors.companyKpp = [`Длина КПП - ${constants.KPP_LENGTH} символов`];
    }
    if (context.companyOgrnip === "") {
      errors.companyOgrnip = ["ОГРН компании обязателен для заполнения"];
    } else if (!numbersOnly(context.companyOgrnip)) {
      errors.companyOgrnip = ["ОГРН должен состоять из цифр"];
    } else if (context.companyOgrnip.length !== constants.MIN_OGRNIP_LENGTH) {
      errors.companyOgrnip = [`Длина ОГРН - ${constants.MIN_OGRNIP_LENGTH} символов`];
    }
    if (context.companyAddress === "") {
      errors.companyAddress = ["Адрес компании обязателен для заполнения"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    onValidated(validateBlock());
  }, [
    context.companyName,
    context.companySiteUrl,
    context.companyInn,
    context.companyKpp,
    context.companyOgrnip,
    context.companyAddress,
  ]);

  return (
    <IdentificationBlock>
      <IdentificationBlock.Info>
        lorem ipsum
      </IdentificationBlock.Info>
      <SecondaryHeading>
        <Heading headingType={HeadingType.H2}>
          Компания
        </Heading>
      </SecondaryHeading>
      <div className="form__group">
        <CommonInput
          value={context.companyName}
          onChange={(value) => context.setCompanyName(value)}
          title="Название компании"
          id="companyName"
          required
        />
        { errors.companyName && <InputErrors errors={errors.companyName} /> }
      </div>
      <div className="form__group">
        <CommonInput
          value={context.companySiteUrl}
          onChange={(value) => context.setCompanySiteUrl(value)}
          title="Сайт компании"
          id="companySiteUrl"
        />
        { errors.companySiteUrl && <InputErrors errors={errors.companySiteUrl} /> }
      </div>
      <div className="form__group">
        <CommonInput
          value={context.companyInn}
          onChange={(value) => context.setCompanyInn(value)}
          title="ИНН компании"
          id="companyInn"
          required
        />
        { errors.companyInn && <InputErrors errors={errors.companyInn} /> }
      </div>
      <div className="form__group">
        <CommonInput
          value={context.companyKpp}
          onChange={(value) => context.setCompanyKpp(value)}
          title="КПП компании"
          id="companyKpp"
          required
        />
        { errors.companyKpp && <InputErrors errors={errors.companyKpp} /> }
      </div>
      <div className="form__group">
        <CommonInput
          value={context.companyOgrnip}
          onChange={(value) => context.setCompanyOgrnip(value)}
          title="ОГРН компании"
          id="companyOgrnip"
          required
        />
        { errors.companyOgrnip && <InputErrors errors={errors.companyOgrnip} /> }
      </div>
      <div className="form__group">
        <CommonInput
          value={context.companyAddress}
          onChange={(value) => context.setCompanyAddress(value)}
          title="Адрес компании"
          id="companyAddress"
          required
        />
        { errors.companyAddress && <InputErrors errors={errors.companyAddress} /> }
      </div>
    </IdentificationBlock>
  );
};

export default IdentificationCompany;