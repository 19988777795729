import React, {FC} from "react";
import './ProfileCredentialBlock.scoped.scss';
import {ChildrenProps} from "../../../../utils";

const ProfileCredentialHeading: FC<ChildrenProps> = ({children}) => {
  return (
    <div className="profile-credential__heading">
      { children }
    </div>
  );
};

const ProfileCredentialDescription: FC<ChildrenProps> = ({children}) => {
  return (
    <div className="profile-credential__description">
      { children }
    </div>
  );
};

type ProfileCredentialExtensions = {
    Heading: typeof ProfileCredentialHeading;
    Description: typeof ProfileCredentialDescription;
}

interface Props extends ChildrenProps {}

// eslint-disable-next-line react/prop-types
const ProfileCredentialBlock: FC<Props> & ProfileCredentialExtensions = ({children}) => {
  return (
    <div className="profile-credential">
      { children }
    </div>
  );
};

ProfileCredentialBlock.Heading = ProfileCredentialHeading;
ProfileCredentialBlock.Description = ProfileCredentialDescription;

export default ProfileCredentialBlock;