import React, {createContext, PropsWithChildren} from "react";

export type InvestmentCountContextType = {
   activeCount?: number;
   fundraisingCount?: number;
   ordersCount?: number;
}
type Props = {
    props: InvestmentCountContextType;
}
export const InvestmentCountContext = createContext<InvestmentCountContextType>({
  activeCount: 0,
  fundraisingCount: 0,
  ordersCount: 0,
});

function InvestmentCountContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <InvestmentCountContext.Provider value={value.props}>
    { children }
  </InvestmentCountContext.Provider>;
}

export default InvestmentCountContextProvider;