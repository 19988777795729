export enum TransactionDirection {
    ALL = "ALL",
    INCOME = "CREDIT",
    OUTGOING = "DEBIT",
}

type TransactionDirectionRecord = Record<TransactionDirection, string>;

export const transactionDirectionMapped: TransactionDirectionRecord = {
  [TransactionDirection.ALL]: "Все транзакции",
  [TransactionDirection.INCOME]: "Входящие",
  [TransactionDirection.OUTGOING]: "Исходящие",
};

export const translateTransactionDirection = (direction: TransactionDirection): string => {
  return transactionDirectionMapped[direction];
};