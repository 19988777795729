import {Button, Image} from "../../../../../../../components";
import arrowRight from "../../../../../../../assets/images/icons/arrow-right.svg";
import arrowDown from "../../../../../../../assets/images/icons/arrow-down.svg";
import React, {useState} from "react";
import styles from "../DocumentsToProjectsItem.module.scss";
import {ProjectDocumentsAgreement} from "../../../../../../../api-client/models/documents/ProjectDocumentsAgreement";
import {Agreement} from "../../../../../../../api-client/models/documents/Agreement";
import {AgreementType} from "../../../../../../../api-client/enums/AgreementType";
import {AgencyOrder, AgentReport, CommercialOffer} from "../index";
import {createAgreementProvider} from "../../../../../../../di";
import apiClientConfig from "../../../../../../../configs/app";
import {useErrorCallback} from "../../../../../../../hooks";
import {ProjectStatus} from "../../../../../../../api-client";
import IncomeCertificateModal
  from "../../../../../../../components/common/modals/IncomeCertificateModal/IncomeCertificateModal";


interface Props {
    projectDocument: ProjectDocumentsAgreement
}

export const DocumentsToProjectItemComponent = ({projectDocument}: Props) => {
  const [loadingAgentReport, setLoadingAgentReport] = useState(false);
  const [active, setActive] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  const closeModal = () => {
    setActiveModal(false);
  };
  const handleError = useErrorCallback();
  const downloadAgentReport = async (investUuid?: string) => {
    setLoadingAgentReport(true);
    try {
      const provider = await createAgreementProvider();
      if (investUuid) {
        const result = await provider.createAgentReport(investUuid);
        window.open(apiClientConfig.apiBaseUrl + result.url, '_blank');
      }
    } catch (error) {
      if (error instanceof Error) {
        await handleError(error);
      }
    } finally {
      setLoadingAgentReport(false);
    }
  };

  const isPaidLoanPayment = (projectDocument.projectStatus === ProjectStatus.PAID || projectDocument.projectStatus === ProjectStatus.LOAN_PAYMENT);
  const getInvestmentUuid = (document: Agreement) => {
    if (document.investmentUuid) return document.investmentUuid;
    return document.tags.find(tag => tag.objectType === 'investment')?.objectUuid;
  };

  const convertDocumentsToInvestmentUuid = (documents: Agreement[]) => {
    const newGroupDocuments: Record<string, Agreement[]> = {};
    documents.forEach((document: Agreement) => {
      const {investmentUuid} = document;
      if (investmentUuid !== undefined) {
        if (!newGroupDocuments[investmentUuid]) newGroupDocuments[investmentUuid] = [];
        newGroupDocuments[investmentUuid].push(document);
      }
    });
    return newGroupDocuments;
  };

  const convertDocumentsToProjectUuid = (documents: Agreement[]) => {
    const newGroupDocuments: Record<string, Agreement[]> = {};
    documents.forEach((document: Agreement) => {
      // Если нет investmentUuid, группируем по projectUuid
      const {investmentUuid, tags} = document;
      const projectTag = tags.find(tag => tag.objectType === 'project');
      const projectUuid = projectTag?.objectUuid;

      if (!investmentUuid && projectUuid) {
        if (!newGroupDocuments[projectUuid]) newGroupDocuments[projectUuid] = [];
        newGroupDocuments[projectUuid].push(document);
      }
    });
    return newGroupDocuments;
  };

  let agentReportCounter = 0;

  return (
    <>
      <li key={projectDocument.uuid} className={styles.documentsToProjectItemTitle__list}>
        <div className={styles.documentsToProjectItemTitle__item}>
          {projectDocument.projectName ?? "Не указано"}
          <Button className={styles.documentsToProjectItemTitle__arrowButton}
            onClick={() => setActive((prevState) => !prevState)}>
            <Image className={styles.documentsToProjectItemTitle__arrowButton__image}
              src={active ? arrowDown : arrowRight}/>
          </Button>
        </div>
      </li>
      {active && <ul className={styles.documentsToProjectBody}>
        {projectDocument.commercialOffer && (
          <CommercialOffer commercialOfferDocument={projectDocument.commercialOffer}/>
        )}

        {/* Отображаем документы с группировкой по projectUuid (если нет investmentUuid) */}
        {projectDocument.documents && Object.values(convertDocumentsToProjectUuid(projectDocument.documents)).map((documents: Agreement[], index) => (
          <li className={styles.documentsToProjectBody__invest} key={index}>
            {documents.map((document: Agreement) => (
              <ul className={styles.documentsToProjectBody__investContainer} key={document.uuid}>
                <AgencyOrder document={document}/>
              </ul>
            ))}
          </li>
        ))}

        {/* Отображаем документы с группировкой по investmentUuid */}
        {projectDocument.documents && Object.values(convertDocumentsToInvestmentUuid(projectDocument.documents)).map((documents: Agreement[], index) => (
          <li className={styles.documentsToProjectBody__invest} key={index}>
            {documents.map((document: Agreement) => {
              if (document.type !== AgreementType.PROJECT_ACCEPT) {
                return (
                  <ul className={styles.documentsToProjectBody__investContainer} key={document.uuid}>
                    {(document.type === AgreementType.INVESTMENT_OFFER) &&
                    <AgencyOrder document={document}/>}
                    {(document.type === AgreementType.AUTO_INVESTMENT_SETTINGS) &&
                    <AgencyOrder document={document}/>}
                  </ul>);
              }
            })}
            {documents.map((document: Agreement) => {
              if (document.type !== AgreementType.PROJECT_ACCEPT) {
                if (document.type === AgreementType.PROJECT_REPORT) agentReportCounter++;
                return (
                  <ul className={styles.documentsToProjectBody__investContainer} key={document.uuid}>
                    {(document.type === AgreementType.PROJECT_REPORT) &&
                    <AgentReport document={document} isSupplemented={agentReportCounter > 1}/>}
                  </ul>);
              }
            })}

            <ul className={styles.documentsToProjectBody__investContainer}>
              {isPaidLoanPayment &&
              <li
                className={styles.documentsToProjectBody__makeReport && styles.documentsToProjectBody__downloadAgentReport}>
                <Button
                  className={styles.documentsToProjectBody__orderReport}
                  onClick={() => downloadAgentReport(getInvestmentUuid(documents[0]))}>
                  заказать текущий отчет агента
                </Button>
                {loadingAgentReport && <div>Загрузка...</div>}
              </li>}

              {isPaidLoanPayment && projectDocument.loanIssuedAt && (new Date().getFullYear() !== new Date(projectDocument.loanIssuedAt).getFullYear()) &&
              <IncomeCertificateModal active={activeModal}
                onClose={closeModal}
                investUuid={getInvestmentUuid(documents[0])}
                startYear={new Date(projectDocument.loanIssuedAt).getFullYear()}/>}
            </ul>

            {isPaidLoanPayment && projectDocument.loanIssuedAt && (new Date().getFullYear() !== new Date(projectDocument.loanIssuedAt).getFullYear()) && (
              <li
                className={styles.documentsToProjectBody__makeReport && styles.documentsToProjectBody__downloadAgentReport}
              >
                <Button
                  className={styles.documentsToProjectBody__incomeCertificate}
                  onClick={() => setActiveModal(true)}>
                  заказать справку о доходах
                </Button>
              </li>
            )}
          </li>
        ))}
      </ul>
      }
    </>
  );
};




