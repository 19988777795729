import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ApplicationManager} from "../ApplicationManager";

export class ApplicationManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers = {}) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/application`,
      headers
    );
  }

  createApplicationManager(token: string): ApplicationManager {
    return new ApplicationManager(this.apiClientFactory.createAuthorizedClient(token));
  }
}