import {ApiClientInterface} from "./clients";
import {Collection} from "./models";
import {TransactionProject} from "./models/transaction/TransactionProject";

export class TransactionProjectProvider {
  constructor(private readonly apiClient: ApiClientInterface) {}

  getProjects(): Promise<Collection<TransactionProject>> {
    return this.apiClient.get("projects");
  }
}