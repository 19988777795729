import React from "react";
import {Banner} from "../index";
import {ChildrenProps} from "../../../utils";
import {BannerType} from "../Banner/Banner";

type Props = ChildrenProps;

const BannerAlert = ({children}: Props) => {
  return (
    <>
      <Banner type={BannerType.ALERT}>
        {children}
      </Banner>
    </>
  );
};

export default BannerAlert;