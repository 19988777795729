import React, {Children, useState} from "react";
import {ChildrenProps} from "../../../../utils";

type Props = {
    step: number;
} & ChildrenProps;

const MultiStepForm = ({step, children}: Props) => {
  const childrenArray = Children.toArray(children);

  const childToRender = () => {
    return childrenArray[step];
  };

  return (
    <>
      { childToRender() }
    </>
  );
};

export default MultiStepForm;