import React, {FC, useState} from "react";
import {IdentificationBlock} from "../../index";
import {
  Button,
  Checkbox,
  ColoredText,
  CustomLink,
  Heading,
  InputErrors,
  PrimaryButton,
  SecondaryHeading
} from "../../../../common";
import {HeadingType} from "../../../../common/typography/headings/Heading/Heading";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {Errors as ValidationErrors, RouteDictionary} from "../../../../../utils";
import {useEffectOnUpdate, useProfileData} from "../../../../../hooks";
import {TextColor} from "../../../../common/typography/texts/ColoredText/ColoredText";

export type IdentificationConclusionErrors = {
    rulesAccepted?: ValidationErrors;
    processingAccepted?: ValidationErrors
}

interface Props {
    dataValid: boolean;
    loading: boolean;
}

const IdentificationConclusion: FC<Props> = ({dataValid, loading}) => {
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const [processingAccepted, setProcessingAccepted] = useState(false);
  const [errors, setErrors] = useState<IdentificationConclusionErrors>({});
  const profileData = useProfileData();
  const isVerified = () => {
    return profileData.lenderVerified;
  };

  const validateBlock = (): boolean => {
    const errors: IdentificationConclusionErrors = {};
    if (!rulesAccepted) {
      errors.rulesAccepted = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
    }
    if (!processingAccepted) {
      errors.processingAccepted = ["Для продолжения работы на Платформе вам необходимо дать согласие\n"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateBlock();
  }, [rulesAccepted, processingAccepted]);
  // const rulesPath = `${config.publicBaseUrl}/pravila-platformy.docx`;
    
  const canSubmit = dataValid && rulesAccepted && processingAccepted;

  return (
    <IdentificationBlock>
      <IdentificationBlock.Info>
        lorem ipsum
      </IdentificationBlock.Info>
      <SecondaryHeading>
        <Heading headingType={HeadingType.H2}>
          Подтверждение верификации
        </Heading>
      </SecondaryHeading>
      {!isVerified() && <div className="form__group">
        <div className="form__group">
          <Checkbox onChange={() => setRulesAccepted((rulesAccepted) => !rulesAccepted)} id={"rulesAccepted"}>
            Согласен с <ColoredText color={TextColor.PRIMARY_GREEN}>
              <CustomLink to={RouteDictionary.RULES_SERVICE} target={'_blank'}>
                Правилами
              </CustomLink>
            </ColoredText> Онлайн-сервиса Кономика
          </Checkbox>
          {errors.rulesAccepted && <InputErrors errors={errors.rulesAccepted}/>}
        </div>
        <div className="form__group">
          <Checkbox onChange={() => setProcessingAccepted((processingAccepted) => !processingAccepted)}
            id={"processingAccepted"}>
            Согласен на обработку моих персональных данных в соответствии с условиями <ColoredText color={TextColor.PRIMARY_GREEN}>
              <CustomLink to={RouteDictionary.PERSONAL_DATA_POLICY} target={'_blank'}>
                Политики обработки персональных данных,
              </CustomLink>
            </ColoredText> а также на передачу моих персональных данных лицам, с которыми у меня с помощью услуг ООО Кономика
            будут заключены договоры гражданско-правового характера.
          </Checkbox>
          {errors.processingAccepted && <InputErrors errors={errors.processingAccepted}/>}
        </div>
      </div>}
      <div className="form__group">
        <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
          <Button type={"submit"} disabled={loading || (isVerified() ? !dataValid: !canSubmit)} >
            {isVerified() ? "Подтвердить изменения"
              : "Подтвердить верификацию"}
          </Button>
        </PrimaryButton>
      </div>
      <div className="identification-files__warning">
        {isVerified() ? "Наш менеджер проверит обновленные данные и при необходимости может связаться с Вами."
          : "После заполнения и отправки формы, с Вами свяжется наш менеджер для прохождения верификации."}
      </div>
    </IdentificationBlock>
  );
};

export default IdentificationConclusion;