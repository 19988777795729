import React from "react";
import {ChildrenProps} from "../../utils";
import './LayoutNotFound.scoped.scss';
import {useProfileData} from "../../hooks";
import LayoutCabinet from "layouts/LayoutCabinet";
import {LayoutPublic} from "../index";

const LayoutNotFound = ({children}: ChildrenProps) => {
  const profileData = useProfileData();
  const isVerified = profileData.lenderVerified;
  const Container = isVerified ? LayoutCabinet : LayoutPublic;

  if (!children) {
    return (
      <Container>
        <h2 className="not-found__text">Страница не найдена</h2>
      </Container>
    );
  }
  // TODO: style 404 layout
  return (
    <>
      {children}
    </>
  );
};

export default LayoutNotFound;