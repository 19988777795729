import React, {FC} from "react";
import Modal from "../Modal";
import {createAutoInvestmentSettingsManager} from "../../../../di";
import {RouteDictionary, sleep} from "../../../../utils";
import {useAppDispatch, useNavigateByName} from "../../../../hooks";
import {ConfirmSmsCodeForm} from "../../utils";
import SmsConfirmation from "../../../confirmation/SmsConfirmation";
import {ModalProps} from "../Modal/Modal";
import {set} from "../../../../store/overlay";

//TODO: REWORK TO ONE UUID
interface Props extends ModalProps {
    autoInvestmentConfirmation: {
        confirmationUuid: string
    };
}

const AutoInvestmentConfirmationModal: FC<Props> = ({active, onClose, autoInvestmentConfirmation}) => {
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  const confirmCode = async (smsCode: string): Promise<void> => {
    const manager = await createAutoInvestmentSettingsManager();
    return await manager.confirmCode(autoInvestmentConfirmation.confirmationUuid, smsCode);
  };

  const resendCode = async (): Promise<void> => {
    await sleep(3000);
  };

  const onSuccess = async (): Promise<void> => {
    dispatch(set(false));
    navigate(RouteDictionary.MY_PROJECTS);
  };
  return (
    <Modal active={active} onClose={onClose}>
      <SmsConfirmation header="Подтверждение" onClose={onClose}>
        <ConfirmSmsCodeForm
          sendCallback={confirmCode}
          resendCallback={resendCode}
          successCallback={onSuccess}
          prefix="offer-conf"
        />
      </SmsConfirmation>
    </Modal>
  );
};

export default AutoInvestmentConfirmationModal;