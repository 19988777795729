type SmsFormState = {
    attemptsLeft: number;
    nextTryDate?: Date;
}

const KEY = 'smsFormState';

export class SmsFormStateStorage {
  private readonly storageKey: string = "";

  constructor(private readonly prefix: string) {
    this.storageKey = prefix + "-" + KEY;
  }

  get(): SmsFormState | null {
    const value = localStorage.getItem(this.storageKey);
    if (!value) {
      return null;
    }
    const data = JSON.parse(value);
    return {
      attemptsLeft: data.attemptsLeft,
      nextTryDate: new Date(data.nextTryDate)
    };
  }

  has(): boolean {
    return this.get() !== null;
  }

  set(data: SmsFormState): void {
    const value = JSON.stringify(data);
    localStorage.setItem(this.storageKey, value);
  }

  setAttempts(numberOfAttempts: number): void {
    const value = localStorage.getItem(this.storageKey);
    if (value) {
      const data = JSON.parse(value);
      data.attemptsLeft = numberOfAttempts;
      this.set(data);
      return;
    }
    this.set({attemptsLeft: numberOfAttempts});
  }

  remove(): void {
    localStorage.removeItem(this.storageKey);
  }
}