import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ProjectProvider} from "../ProjectProvider";

export class ProjectProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/project`,
      headers
    );
  }

  public createProjectProvider(token: string): ProjectProvider {
    return new ProjectProvider(this.apiClientFactory.createAuthorizedClient(token));
  }
}