import React, {FC} from "react";
import {createLegalClassProvider} from "../../../../di";
import DebtClassList from "../DebtClassList";

interface Props {
    selectedClasses: Array<string>;
    onSelected: (selected: Array<string>) => void;
}

const mainLegalClasses = createLegalClassProvider().getMainClasses();

const LegalClasses: FC<Props> = ({selectedClasses, onSelected}) => {
  return (
    <DebtClassList
      classList={mainLegalClasses}
      selectedClasses={selectedClasses}
      onSelected={onSelected}
    />
  );
};

export default LegalClasses;