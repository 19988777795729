import moment from "moment/moment";

export enum DateRangeType {
    DAY = "DAY",
    WEEK = "WEEK",
    MONTH = "MONTH",
    QUARTER = "QUARTER",
    YEAR = "YEAR",
    ALL = "ALL",
}

type DateRangeTypeRecord = Record<DateRangeType, string>;

export const dateRangeMapped: DateRangeTypeRecord = {
  [DateRangeType.ALL]: "Все время",
  [DateRangeType.DAY]: "День",
  [DateRangeType.WEEK]: "Неделя",
  [DateRangeType.MONTH]: "Месяц",
  [DateRangeType.QUARTER]: "Квартал",
  [DateRangeType.YEAR]: "Год",
};

export const translateDateRangeType = (dateRange: DateRangeType): string => {
  return dateRangeMapped[dateRange];
};

export const transformToDates = (dateRange: DateRangeType): Array<Date> => {
  const startDate = moment();
  switch (dateRange) {
  case DateRangeType.DAY:
    return [startDate.toDate(), startDate.subtract(1, 'days').toDate()];
  case DateRangeType.WEEK:
    return [startDate.toDate(), startDate.subtract(7, 'days').toDate()];
  case DateRangeType.MONTH:
    return [startDate.toDate(), startDate.subtract(30, 'days').toDate()];
  case DateRangeType.QUARTER:
    return [startDate.toDate(), startDate.subtract(90, 'days').toDate()];
  case DateRangeType.YEAR:
    return [startDate.toDate(), startDate.subtract(365, 'days').toDate()];
  default:
    return [];
  }
};