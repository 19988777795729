export class LegalClassProvider {
  private classes: Array<string> = ['1', '2', '3', '4', '5'];

  public getMainClasses(): Array<string> {
    return this.classes;
  }

  public getAllClasses(): Array<string> {
    return this.classes.reduce((accum: Array<string>, current) => {
      accum.push(`${current}-`, current);
      return accum;
    }, []);
  }
}