import React, {useContext, useMemo} from "react";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import './ProjectDocuments.scoped.scss';
import {Block} from "../../../layout";
import DetailedProjectNavigation from "../DetailedProjectNavigation";
import {ProjectDocument} from "../../../../api-client";
import {useMountAnimation, useWindowWidth} from "../hooks";
import {cn, resultIf} from "../../../../utils";
import ProjectDropdown from "../ProjectDropdown";
import {
  SecondaryProjectContext
} from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectContext/SecondaryProjectContext";
import SecondaryProjectNavigation
  from "../../../../views/dashboard/lender/investment/purchase/SecondaryProjectNavigation";
import {CommercialOffer} from "../../../../views/dashboard/user/profile/Documents/DocumentsElements";
import {
  DifferentDocument
} from "../../../../views/dashboard/user/profile/Documents/DocumentsElements/DifferentDocument";

const ProjectDocuments = () => {
  const project = useContext(DetailedProjectContext).project;
  const mounted = useMountAnimation();
  const isMobile = useWindowWidth();
  const offer = !!useContext(SecondaryProjectContext);
  const navigation = useMemo(() => {
    if (offer) {
      return <SecondaryProjectNavigation />;
    }
    else {
      return <DetailedProjectNavigation />;
    }
  }, [offer]);

  const renderDocuments = () => {
    if (project.documents === undefined || project.documents.length === 0) {
      return <div className="project-documents__placeholder">Документы не прилагаются</div>;
    }
    const renderedDocuments = project.documents.map((document: ProjectDocument) => {
      return <DifferentDocument document={document} key={document.name}/>;
    });
    return <ul className="project-documents__list">{renderedDocuments}</ul>;
  };
  return (
    <>
      { isMobile ? <ProjectDropdown header="Документы">
        <div className="project-documents">
          {project.accounting.commercialOffer && <CommercialOffer commercialOfferDocument={project.accounting.commercialOffer}/>}
          { renderDocuments() }
        </div>
      </ProjectDropdown> : <Block>
        <Block.Content>
          <Block.Header withPadding className="detailed-project__header">
            { navigation }
          </Block.Header>
          <Block.Body withPadding>
            <div className={cn("project-documents", resultIf(mounted, "mounted"))}>
              {project.accounting.commercialOffer && <CommercialOffer commercialOfferDocument={project.accounting.commercialOffer}/>}
              { renderDocuments() }
            </div>
          </Block.Body>
        </Block.Content>
      </Block>
      }
    </>
  );
};

export default ProjectDocuments;