import React, {useMemo, useState} from "react";
import './ActiveProjects.scoped.scss';
import {useErrorCallback} from "../../../../../hooks";
import {DataCollection} from "../../../../../utils";
import {LoanPaymentProjectInvestment, Money} from "../../../../../api-client";
import {createInvestmentManager} from "../../../../../di";
import {Pagination} from "../../../../pagination";
import {Block} from "../../../../layout";
import {MyActiveProject} from "../index";
import InvestmentCountContextProvider from "../../InvestmentCountContext";
//This component isn't used
const ActiveProjects = () => {
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [myInvestments, setMyInvestments] =
        useState<DataCollection<LoanPaymentProjectInvestment>>(new DataCollection());

  const loadData = async (page: number, perPage: number): Promise<void> => {
    setLoading(true);
    try {
      const manager = await createInvestmentManager();
      const dataCollection = new DataCollection<LoanPaymentProjectInvestment>();
      const investments = await manager.getListLoanPaymentOwned(page, perPage);
      dataCollection.setCollection(investments);
      setMyInvestments(dataCollection);
    } catch (err: any) {
      await errorCallback(err);
    } finally {
      setLoading(false);
    }
  };

  const projects = useMemo(() => {
    return myInvestments.items.map((item: LoanPaymentProjectInvestment) => {
      //TODO: ADD UUID TO INVESTMENT MODEL
      return <MyActiveProject investment={item} key={item.project.uuid}/>;
    });
  }, [myInvestments]);

  const myInvestmentAmount = useMemo(() => {
    const invested = myInvestments.items.reduce((prev, cur) => {
      return prev + cur.investedSum.amount;
    }, 0);
    return new Money(invested, "RUB");
  }, [myInvestments]);

  return (
    <div className="active-projects">
      <Pagination
        onLoad={loadData}
        count={myInvestments.count}
        totalCount={myInvestments.totalCount}
      >
        <Block>
          <Block.Content>
            <Block.Header>
              <InvestmentCountContextProvider props={{activeCount:myInvestments.totalCount}}>
                {/*<MyProjectsHeader invested={myInvestmentAmount}/>*/}
              </InvestmentCountContextProvider>
            </Block.Header>
            <Block.Body withPadding={false} className="my-projects__block-body">
              <Pagination.Content loading={loading}>
                <Pagination.Header className="active-projects-header">
                  <div className="active-projects-header__item">
                    Проект
                  </div>
                  <div className="active-projects-header__item active-projects-header__category">
                    Категория
                  </div>
                  <div className="active-projects-header__item active-projects-header__sum">
                    Инвестировано
                  </div>
                  <div className="active-projects-header__item active-projects-header__actual-payment">
                    Фактическая выплата
                  </div>
                  <div className="active-projects-header__item active-projects-header__forecast">
                    Прогноз взыскания
                  </div>
                  <div className="active-projects-header__item">
                    Срок
                  </div>
                  <div className="active-projects-header__item active-projects-header__simple">
                    Доходность простая
                  </div>
                  <div className="active-projects-header__item active-projects-header__yearly">
                    Доходность годовая
                  </div>
                  <div className="active-projects-header__item active-projects-header__transactions">
                    Транзакции
                  </div>
                  <div className="active-projects-header__item active-projects-header__stages">
                    События
                  </div>
                  <div className="active-projects-header__item">
                    Действия
                  </div>
                </Pagination.Header>
                <ul className="active-projects__list">
                  {projects}
                </ul>
              </Pagination.Content>
            </Block.Body>
          </Block.Content>
        </Block>
        <div className="active-projects__paginator">
          <Pagination.Paginator/>
        </div>
      </Pagination>
    </div>
  );
};

export default ActiveProjects;