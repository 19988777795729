import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {InvestmentManager} from "../InvestmentManager";

export class InvestmentManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers = {}) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/investment`,
      headers
    );
  }

  createInvestmentManager(token: string): InvestmentManager {
    return new InvestmentManager(this.apiClientFactory.createAuthorizedClient(token));
  }
    
}