import React, {FC} from "react";
import {cn, resultIf} from "../../../../utils";
import './Toggle.scoped.scss';

interface Props  {
    active: boolean;
    onToggled: () => void;
    disabled?: boolean;
    size?: 's' | 'm';
    className?: string;
}

const Toggle: FC<Props> = ({active, onToggled, size = 'm', className, disabled, ...props}) => {
  const handleClick = () => {
    if (!disabled) {
      onToggled();
    }
  };
  return (
    <div
      {...props}
      className={cn(
        size === 'm' ? "toggle-m" : 'toggle-s',
        resultIf(active, "active"),
        resultIf(disabled === true, "disabled"),
        className)}
      onClick={handleClick}
    >
      <div className={cn(size === 'm' ? "toggle-m__button" : "toggle-s__button")}>
      </div>
    </div>
  );
};

export default Toggle;