import React, {FC, IframeHTMLAttributes} from "react";
import {cn} from "../../../../utils";
import './IFrame.scoped.scss';

interface Props extends IframeHTMLAttributes<HTMLIFrameElement> {}

const IFrame: FC<Props> = ({...props}) => {
  return (
    <iframe {...props} className={cn("document-preview", props.className)}></iframe>
  );
};

export default IFrame;