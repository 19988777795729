import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ChangeUsernameManager} from "../ChangeUsernameManager";

export class ChangeUsernameManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/change-username`,
      headers
    );
  }

  public createChangeUsernameManager(token: string) {
    return new ChangeUsernameManager(
      this.apiClientFactory.createAuthorizedClient(token)
    );
  }
}