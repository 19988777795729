import React from "react";
import {DataCollection} from "../../../../../../../utils";
import {ProjectDocumentsAgreement} from "../../../../../../../api-client/models/documents/ProjectDocumentsAgreement";
import {DocumentsToProjectItemComponent} from "../DocumentsToProjectsItemComponent";


interface Props {
    documentsList: DataCollection<ProjectDocumentsAgreement>
}

export const DocumentsToProjectItem = ({documentsList}: Props) => {
  return <>
    {documentsList.items.map((projectDocument: ProjectDocumentsAgreement) => {
      if ((projectDocument.documents?.length === 0) &&
          !projectDocument.commercialOffer) return null;
      return (
        <DocumentsToProjectItemComponent projectDocument={projectDocument} key={projectDocument.uuid}/>
      );
    })}
  </>;
};