import React, {FC} from "react";
import './MyOrder.scoped.scss';
import {PreviewOrder} from "../../../../../api-client";
import FavoriteSvg from "../../../../svg/FavoriteSvg";
import {BoldText, CustomLink, Money, PrimaryButton} from "../../../../common";
import {ProjectCategory} from "../../../../project";
import ProjectProgress from "../../../projects/ProjectProgress";
import {PrimaryButtonColor} from "../../../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {RouteDictionary} from "../../../../../utils";
import {GridRowHeader} from "../../../../layout/grid";
import {ProjectFavorite} from "../../../projects";
import {calculateCollectedPercentage} from "../../../../../utils/pages";
import {formatDate, formatPercents} from "../../../../../formatters";

interface Props {
    order: PreviewOrder;
}

const MyOrder: FC<Props> = ({order}) => {

  return <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: order.projectUuid}}
    className={"my-order__link"}>
    <li className="my-order">
      <div className="my-order__column my-order__primary">
        <div className="my-order__title">
          <ProjectFavorite>
            <FavoriteSvg width={8} height={10} active/>
            <div>
              {order.paymentCode}
            </div>
          </ProjectFavorite>
          <BoldText>
            <div className="my-order__name">
              {order.name}
            </div>
          </BoldText>
        </div>
      </div>
      <div className="my-order__column my-order__category">
        <GridRowHeader>
          Категория
        </GridRowHeader>
        <div>
          <BoldText>
            <ProjectCategory debtClass={order.debtClass} delimiter="/"/>
          </BoldText>
        </div>
      </div>
      <div className="my-order__column my-order__sum">
        <GridRowHeader>
          Размер инвестиции
        </GridRowHeader>
        <div className="my-order__text">
          <Money money={order.investment}/>
        </div>
      </div>
      <div className="my-order__column my-order__forecast">
        <GridRowHeader>
          Прогноз взыскания
        </GridRowHeader>
        <div className="my-order__text">
          <Money money={order.debtPrice}/>
        </div>
      </div>
      <div className="my-order__column my-order__term">
        <GridRowHeader>
          Срок
        </GridRowHeader>
        <div className="my-order__text">
          {order.initialTerm} мес.
        </div>
      </div>
      <div className="my-order__column my-order__simple">
        <GridRowHeader>
          Доходность простая
        </GridRowHeader>
        <div className="my-order__text">
          {formatPercents(order.simpleInterestRate)}
        </div>
      </div>
      <div className="my-order__column my-order__yearly">
        <GridRowHeader>
          Доходность годовая
        </GridRowHeader>
        <div className="my-order__text">
          {formatPercents(order.interestRate)}
        </div>
      </div>
      <div className="my-order__column my-order__collected">
        <GridRowHeader>
          Собрано
        </GridRowHeader>
        <div className="my-order__progress">
          <ProjectProgress
            percentage={calculateCollectedPercentage(order.investedSum, order.debtPrice)}/>
        </div>
      </div>
      <div className="my-order__column my-order__date">
        <GridRowHeader>
          Сбор до
        </GridRowHeader>
        <div className="my-order__text">
          {formatDate(new Date(order.maturityDate))}
        </div>
      </div>
      <div className="my-order__column my-order__actions">
        {!(order.investedSum.amount === order.debtPrice.amount) &&
        <PrimaryButton expanded className="my-order__link" color={PrimaryButtonColor.GREEN}>
          <div className={"my-order__button"}>
            Выдать поручение
          </div>
        </PrimaryButton>}
      </div>
      <div className="my-order__column">
        <GridRowHeader>
          Статус заявки
        </GridRowHeader>
        <div className="my-order__status">
          {order.status}
        </div>
      </div>
    </li>
  </CustomLink>;
};

export default MyOrder;