import React, {FC} from "react";
import './IdentificationBlockGroup.scss';
import {ChildrenProps} from "../../../../../utils";

interface Props extends ChildrenProps {}

const IdentificationBlockGroup: FC<Props> = ({children}) => {
  return (
    <div className="identification-block-group">
      { children }
    </div>
  );
};

export default IdentificationBlockGroup;