import React, {InputHTMLAttributes, useRef} from "react";
import {Phone} from "../../../../api-client";
import './InputNumber.scoped.scss';
import Information from "../../utils/Information";
import {cn} from "../../../../utils";
import formatPhone from "../../../../formatters/formatPhone";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    phone: Phone;
    onPhoneInput?: (phone: Phone) => void;
    title?: string;
    helpingText?: string;
    valid?: boolean;
}

const InputNumber = ({phone, onPhoneInput, title = "Телефон", helpingText, valid, ...props}: Props) => {
  const numberElem = useRef<HTMLInputElement>(null);

  const changeCallback = (number: string) => {
    const newPhone = new Phone(phone.countryCode, number);
    if (number === "" || !isNaN(Number(number))) {
      onPhoneInput && onPhoneInput(newPhone);
    }
  };

  return (
    <div
      className={cn(
        "form__input-container",
        `${valid ? "form__input-container--valid" : "input-number__container"}`
      )}
    >
      <div className="form__input--left">
        <label className="d-block w-100" htmlFor={props.id}>
          <div className="form__input-text">
            {title}
          </div>
          <div className={cn("d-flex g-1 align-items-center")}>
            {!valid && <div className={cn(
              "input-number__code", `${phone.number.length > 0 ? 'form__input--black' : ''}`
            )}>
              {phone.countryCode}
            </div>}
            <input
              value={valid ? formatPhone(phone) : phone.number}
              className={cn(
                "form__input",
                `${valid ? "input-number--valid" : "input-number__input"}`,
                `${phone.number.length > 0 ? 'form__input--black' : ''}`
              )}
              onChange={(event) => changeCallback(event.target.value)}
              ref={numberElem}
              disabled={valid}
              {...props}
            />
          </div>
        </label>
      </div>
      <div className="form__input--right">
        {helpingText && <Information>{helpingText}</Information>}
      </div>
    </div>
  );
};

export default InputNumber;