import React, {useContext, useMemo} from "react";
import {ProjectStage, ProjectStageEnum, translateProjectStage} from "../../../../api-client";
import './ProjectStages.scoped.scss';
import okSign from "../../../../assets/images/svg/ok-sign.svg";
import moment from "moment/moment";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import ProjectDropdown from "../ProjectDropdown";
import {Image} from "../../../common";
import {useMountAnimation, useWindowWidth} from "../hooks";
import {formatDate, formatTime} from "../../../../formatters";
import {Block} from "../../../layout";
import DetailedProjectNavigation from "../DetailedProjectNavigation";

const ProjectStages = () => {
  const project = useContext(DetailedProjectContext).project;
  const mounted = useMountAnimation();
  const isMobile = useWindowWidth();

  const stageName = (stage: ProjectStage): string => {
    if (Object.keys(ProjectStageEnum).includes(stage.name)) {
      return translateProjectStage(stage.name as ProjectStageEnum);
    }
    return stage.name;
  };

  const renderStages = () => {
    if (project.stages.length === 0) {
      return <div className="project-stages__placeholder">
        События не указаны
      </div>;
    }
    const renderedStages = project.stages.map((stage) => {
      const date = moment(stage.createdAt).toDate();
      return <li className="project-stage__element" key={stage.uuid}>
        <div className="project-stage">
          <div className="project-stage__left">
            <div>
              <Image src={okSign} />
            </div>
            <div>
              <div className="project-stage__name">
                { stageName(stage) }
              </div>
              { stage.content !== null && <div className="project-stage__content">
                { stage.content }
              </div> }
            </div>
          </div>
          <div>
            <div className="project-stage__date">
              { formatDate(date) }
            </div>
            <div className="project-stage__date">
              { formatTime(date) }
            </div>
          </div>
        </div>
      </li>;
    });
    return <ul className="project-stages__list">{renderedStages}</ul>;
  };

  return (
    <div className="project-stages-container">
      { isMobile ?
        <ProjectDropdown header="События">
          { renderStages() }
        </ProjectDropdown>
        : <Block>
          <Block.Content>
            <Block.Header withPadding className="detailed-project__header">
              <DetailedProjectNavigation />
            </Block.Header>
            <Block.Body>
              { renderStages() }
            </Block.Body>
          </Block.Content>
        </Block> }
    </div>
  );
};

export default ProjectStages;