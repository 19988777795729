import React, {useState} from "react";
import './Control.scoped.scss';
import {useEffectOnMount, useNavigateByName, useProfileData} from "../../../../../hooks";
import {
  BackButton,
  Button,
  ChangeAvatar,
  CloseButton, CustomLink,
  EmailInput,
  Heading,
  PhoneInput,
  PillButton,
  ProfileCredentialBlock,
  SecondaryHeading
} from "../../../../../components";
import PasswordInput from "../../../../../components/user/profile/PasswordInput";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import {PillLinkColor} from "../../../../../components/common/buttons/decorators/PillButton/PillButton";
import ProfilePersonalInput from "../../../../../components/user/profile/ProfilePersonalInput";
import {RouteDictionary} from "../../../../../utils";
import useBodyOverflow from "../../../../../hooks/useBodyOverflow";

const Control = () => {
  const profileData = useProfileData();
  const navigate = useNavigateByName();
  const [loading, setLoading] = useState(true);

  const closePage = () => {
    navigate(RouteDictionary.PROFILE);
  };

  const formatPassportDate = (date: Date): string => {
    const newDate = new Date(date);
    return [
      newDate.getDate().toString().padStart(2, '0'),
      (newDate.getMonth() + 1).toString().padStart(2, '0'),
      newDate.getFullYear().toString()
    ].join('.');
  };

  useEffectOnMount(() => {
    if (!profileData.lenderVerified) {
      closePage();
      return;
    }
    setLoading(false);
  });

  useBodyOverflow();

  return (
    <div className="profile-control__background">
      <div className="profile-control__close">
        <CloseButton>
          <Button onClick={closePage} />
        </CloseButton>
      </div>
      <div className="profile-controls">
        { loading ? "Загрузка" : <>
          <div className="profile-control">
            <div className="profile-control__back">
              <BackButton onClick={closePage} />
            </div>
            <div className="profile-control__avatar">
              <ChangeAvatar />
            </div>
            <div className="profile-credentials">
              <ProfileCredentialBlock>
                <ProfileCredentialBlock.Heading>
                  Телефон
                </ProfileCredentialBlock.Heading>
                <ProfileCredentialBlock.Description>
                  Используется для подтверждения сделок
                </ProfileCredentialBlock.Description>
                <PhoneInput phone={profileData.phone} />
              </ProfileCredentialBlock>
              <ProfileCredentialBlock>
                <ProfileCredentialBlock.Heading>
                  Почта
                </ProfileCredentialBlock.Heading>
                <ProfileCredentialBlock.Description>
                  Используется для коммуникации с платформой
                </ProfileCredentialBlock.Description>
                <EmailInput email={profileData.email} />
              </ProfileCredentialBlock>
              <ProfileCredentialBlock>
                <ProfileCredentialBlock.Heading>
                  Пароль
                </ProfileCredentialBlock.Heading>
                <ProfileCredentialBlock.Description>
                  Используется для входа в Личный Кабинет
                </ProfileCredentialBlock.Description>
                <PasswordInput />
              </ProfileCredentialBlock>
            </div>
          </div>
          <div className="profile-control">
            <div className="profile-control__header">
              <SecondaryHeading>
                <Heading headingType={HeadingType.H2}>
                  Ваши паспортные данные
                </Heading>
              </SecondaryHeading>
              <PillButton color={PillLinkColor.GREEN}>
                <CustomLink to={RouteDictionary.IDENTIFICATION}>
                  Изменить
                </CustomLink>
              </PillButton>
            </div>
            <div className="profile-control__list">
              <ProfilePersonalInput>
                <div>
                  Фамилия
                </div>
                <div>
                  { profileData.personalData!.lastName }
                </div>
              </ProfilePersonalInput>
              <ProfilePersonalInput>
                <div>
                  Имя
                </div>
                <div>
                  { profileData.personalData!.firstName }
                </div>
              </ProfilePersonalInput>
              <ProfilePersonalInput>
                <div>
                  Отчество
                </div>
                <div>
                  { profileData.personalData!.middleName ?? "Не указано" }
                </div>
              </ProfilePersonalInput>
            </div>
            <div className="profile-control__grid">
              <div className="profile-control__grid-small">
                <ProfilePersonalInput>
                  <div>
                    Серия
                  </div>
                  <div>
                    { profileData.personalData!.passport.series }
                  </div>
                </ProfilePersonalInput>
              </div>
              <div className="profile-control__grid-small">
                <ProfilePersonalInput>
                  <div>
                    Номер
                  </div>
                  <div>
                    { profileData.personalData!.passport.number }
                  </div>
                </ProfilePersonalInput>
              </div>
              <div className="profile-control__grid-small">
                <ProfilePersonalInput>
                  <div>
                    Дата выдачи
                  </div>
                  <div>
                    { formatPassportDate(profileData.personalData!.passport.issuedDate) }
                  </div>
                </ProfilePersonalInput>
              </div>
              <div className="profile-control__grid-small">
                <ProfilePersonalInput>
                  <div>
                    Код подразделения
                  </div>
                  <div>
                    { profileData.personalData!.passport.departmentCode }
                  </div>
                </ProfilePersonalInput>
              </div>
              <div className="profile-control__grid-row">
                <ProfilePersonalInput>
                  <div>
                    Кем выдан
                  </div>
                  <div>
                    { profileData.personalData!.passport.issuedBy }
                  </div>
                </ProfilePersonalInput>
              </div>
              <div className="profile-control__grid-row">
                <ProfilePersonalInput>
                  <div>
                    Место рождения
                  </div>
                  <div>
                    { profileData.personalData!.birthplace }
                  </div>
                </ProfilePersonalInput>
              </div>
            </div>
          </div>
        </> }
      </div>
    </div>
  );
};

export default Control;