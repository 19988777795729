import React from "react";
import './AsLink.scoped.scss';
import {ClassInjector} from "../../../../injectors";
import {ChildrenProps} from "../../../../../utils";
import {ClassNameDecorator} from "../../../../decorators";

const AsLink: ClassNameDecorator<ChildrenProps> = ({children, className}) => {
  return (
    <ClassInjector
      classNames={"link-button"}
      oldClassName={className}
    >
      { children }
    </ClassInjector>
  );
};

export default AsLink;