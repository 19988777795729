import React, {useMemo, useState} from "react";
import {
  Block,
  Button,
  Container,
  DashboardHeader,
  Heading,
  Pagination,
  PrimaryHeading,
  Section
} from "../../../../../components";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import CloseButton from "../../../../../components/common/buttons/decorators/CloseButton";
import {CloseButtonSize} from "../../../../../components/common/buttons/decorators/CloseButton/CloseButton";
import {useErrorCallback, useNavigateByName} from "../../../../../hooks";
import {DataCollection, RouteDictionary} from "../../../../../utils";
import {ArchivedProject, ArchivedProjectsFilter} from "../../../../../components/pages";
import {Filter} from "../../investment/ArchivedProjects/ArchivedProjects";
import {ArchivedProject as ArchivedProjectModel} from "../../../../../api-client";
import {createProjectProvider} from "../../../../../di";
import './MyArchive.scoped.scss';

const MyArchive = () => {
  const navigate = useNavigateByName();
  const onExit = () => {
    navigate(RouteDictionary.MY_PROJECTS);
  };
  const handleError = useErrorCallback();
  const [filter, setFilter] = useState<Filter>({name: ""});
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] =
        useState<DataCollection<ArchivedProjectModel>>(new DataCollection());

  const loadData = async (page: number, perPage: number, filter: Filter) => {
    setLoading(true);
    try {
      const provider = await createProjectProvider();
      const projects = await provider.getMyArchivedProjects(page, perPage, filter);
      const dataCollection = new DataCollection<ArchivedProjectModel>();
      dataCollection.setCollection(projects);
      setProjects(dataCollection);
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const projectsList = useMemo(() => {
    return projects.items.map((project: ArchivedProjectModel) => {
      return <ArchivedProject project={project} key={project.uuid}/>;
    });
  }, [projects]);


  return (
    <>
      <Container>
        <Section>
          <DashboardHeader>
            <DashboardHeader.Main>
              <PrimaryHeading>
                <Heading headingType={HeadingType.H1}>
                  Статистика проектов
                </Heading>
              </PrimaryHeading>
              <div className="archived-projects__buttons-container">
                <CloseButton size={CloseButtonSize.Small}>
                  <Button onClick={onExit}/>
                </CloseButton>
              </div>
            </DashboardHeader.Main>
          </DashboardHeader>
          <Pagination
            onLoad={loadData}
            count={projects.count}
            totalCount={projects.totalCount}
            filterObject={filter}
            initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
          >
            <Block>
              <Block.Content>
                <Block.Header className="archived-projects-block-header" withPadding>
                  <Pagination.Filter>
                    <ArchivedProjectsFilter
                      filter={filter}
                      onChange={(filter: Filter) => setFilter(filter)}
                      countFinishedProjects={projects.totalCount}
                    />
                  </Pagination.Filter>
                </Block.Header>
                <Block.Body className="archived-projects-block-body" withPadding={false}>
                  <Pagination.Content loading={loading}>
                    <Pagination.Header className="archived-projects-header">
                      <div
                        className="archived-projects-header__item archived-projects-header__primary">
                        Проект
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__category">
                        Категория
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header">
                        Статус
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__investment">
                        {projects.items[0]?.investment ? 'Инвестировано' : 'Сумма сбора'}
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__actual-payment">
                        Фактическая выплата
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__loan">
                        {projects.items[0]?.recoveryForecast ? 'Прогноз взыскания' : 'Размер взыскания'}
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__term">
                        Срок
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__simple">
                        Доходность простая
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__yearly">
                        Доходность годовая
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__transactions">
                        Транзакции
                      </div>
                      <div
                        className="archived-projects-header__item archived-projects-header__stages">
                        События
                      </div>
                    </Pagination.Header>
                    <ul className="archived-projects__list">
                      {projectsList}
                    </ul>
                  </Pagination.Content>
                </Block.Body>
              </Block.Content>
            </Block>
            <Pagination.Paginator/>
          </Pagination>
        </Section>
      </Container>
    </>
  );
};

export default MyArchive;