import React, {FC} from "react";
import './ProjectProgress.scoped.scss';
import {cn, resultIf} from "../../../../utils";
import {formatPercents} from "../../../../formatters";

interface Props {
    percentage: number;
}

const ProjectProgress: FC<Props> = ({percentage}) => {
  const isFinished = (): boolean => {
    return percentage >= 100;
  };
  return (
    <div className="project-progress">
      <div
        style={{width: `${percentage}%`}}
        className={cn(
          "project-progress__filled",
          resultIf(isFinished(), "finished")
        )}
      ></div>
      <div className="project-progress__percentage">
        { formatPercents(percentage) }
      </div>
    </div>
  );
};

export default ProjectProgress;