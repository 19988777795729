import React, {FC, ReactNode} from "react";
import {ClassInjector} from "../../../../../injectors";
import './SecondaryHeading.scss';
import {ClassNameDecorator} from "../../../../../decorators";

interface Props {
    children: ReactNode;
}

const SecondaryHeading: ClassNameDecorator<Props> = ({children, className}) => {
  return (
    <ClassInjector classNames="secondary-heading" oldClassName={className}>
      { children }
    </ClassInjector>
  );
};

export default SecondaryHeading;