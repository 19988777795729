import React from "react";
import {ChildrenProps, cn} from "../../../../../utils";
import './SecondaryButton.scss';
import {ClassInjector} from "../../../../injectors";
import {ClassNameDecorator} from "../../../../decorators";

export enum SecondaryButtonColor {
    GREEN = "secondary-button--green",
    ORANGE = "secondary-button--orange"
}

interface Props extends ChildrenProps {
    color: SecondaryButtonColor;
}

const SecondaryButton: ClassNameDecorator<Props> = ({color, children, className}) => {
  return (
    <ClassInjector classNames={
      cn(
        "secondary-button",
        `${color}`
      )
    } oldClassName={className}>
      { children }
    </ClassInjector>
  );
};

export default SecondaryButton;