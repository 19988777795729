import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ChangeEmailManager} from "../ChangeEmailManager";

export class ChangeEmailManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/change-email`,
      headers
    );
  }

  public createChangeEmailManager(authToken: string): ChangeEmailManager {
    return new ChangeEmailManager(this.apiClientFactory.createAuthorizedClient(authToken));
  }
}