import React, {useEffect, useMemo, useState} from "react";
import {
  Block, BoldText,
  Button, Checkbox, CloseButton,
  Container, Heading,
  Information, InputMoney, Money, PrimaryButton, PrimaryHeading,
  ProjectCategory,
  Section, TertiaryButton,
} from "../../../../../components";
import 'rc-slider/assets/index.css';
import './CreateOffer.scoped.scss';
import {useErrorCallback, useNavigateByName} from "../../../../../hooks";
import {
  createInvestmentManager,
  createOfferSaleManager,
} from "../../../../../di";
import {useParams} from "react-router-dom";
import './CreateOffer.scoped.scss';
import {CreateOfferParams} from "../../../../../api-client/models/offer/CreateOfferParams";
import {RouteDictionary} from "../../../../../utils";
import {PrimaryButtonColor} from "../../../../../components/common/buttons/decorators/PrimaryButton/PrimaryButton";
import {InvestmentForSale} from "../../../../../api-client/models/investment/InvestmentForSale";
import OfferConfirmationModal from "../../../../../components/common/modals/OfferConfirmationModal";
import {HeadingType} from "../../../../../components/common/typography/headings/Heading/Heading";
import MainGrid from "../../../../../components/layout/MainGrid";
import {WithdrawFAQ} from "../../../../../components/pages";
import {TertiaryButtonColor} from "../../../../../components/common/buttons/decorators/TertiaryButton/TertiaryButton";
import {formatPercents} from "../../../../../formatters";


const CreateOffer = () => {
  const {investmentUuid} = useParams();
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [investment, setInvestment] = useState<InvestmentForSale | null>(null);
  const [focused, setFocused] = useState(false);
  const handleError = useErrorCallback();
  const [codeSent, setCodeSent] = useState(false);
  const [confirmationUuid, setConfirmationUuid] = useState("");
  const [offer, setOffer] = useState<CreateOfferParams | null>(null);
  const [rulesAccepted, setRulesAccepted] = useState(false);
  const navigate = useNavigateByName();
  const [money, calculateInvestment] = useState(investment?.money);
  const simpleSalesman = useMemo(() => money ? (money.amount / investment!.money.amount - 1) * 100 : 0,[money, investment]);

  useEffect(() => {
    const callback = async () => {
      setLoading(true);
      try {
        const investmentProvider = await createInvestmentManager();
        const investment = await investmentProvider.getOneLoanPayment(investmentUuid!);
        setInvestment(investment);
        setOffer({
          price: investment.money,
          investmentMoney: investment.money,
          parentInvestmentUuid: investment.uuid
        });
      } catch (err: any) {
        await errorCallback(err);
        setInvestment(null);
      } finally {
        setLoading(false);
      }
    };
    callback();
  }, []);

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    try {
      const manager = await createOfferSaleManager();
      const result = await manager.createConfirmation(offer!);
      setConfirmationUuid(result.uuid);
      await manager.sendCode(result.uuid);
      setCodeSent(true);
    } catch (error: any) {
      await handleError(error);
    } finally {
      setLoading(false);
    }
  };

  const closePage = () => {
    navigate(RouteDictionary.ACTIVE_PROJECTS);
  };
  return (
    <Container>
      <Section className="secondary-offer">
        <MainGrid>
          <div className="secondary-offer__top">
            <PrimaryHeading>
              <Heading headingType={HeadingType.H1} className="secondary-offer-section__heading">
                Настройка параметров продажи инвестиции
              </Heading>
            </PrimaryHeading>
            <CloseButton>
              <Button onClick={closePage} />
            </CloseButton>
          </div>
        </MainGrid>
        {loading && <div>Загрузка...</div>}
        {!loading && investment === null && <div>Инвестиция не найдена</div>}
        {investment &&
        <MainGrid>
          <div className="secondary-offer">
            <div className="secondary-offer-main">
              <Block>
                <Block.Content>
                  <Block.Header withPadding>
                    <div className="secondary-offer-heading">
                      Доступная к продаже инвестиция
                    </div>
                  </Block.Header>
                  <Block.Body withPadding={false}>
                    <div className="secondary-offer-header">
                      <div className="secondary-offer-header__item">
                        Проект
                      </div>
                      <div
                        className="secondary-offer-header__item secondary-offer-header__category">
                        Категория
                      </div>
                      <div
                        className="secondary-offer-header__item secondary-offer-header__sum">
                        Размер инвестиции
                      </div>
                      <div
                        className="secondary-offer-header__item secondary-offer-header__forecast">
                        Прогноз взыскания
                      </div>
                      <div
                        className="secondary-offer-header__item secondary-offer-header__simple">
                        Доходность простая
                      </div>
                      <div
                        className="secondary-offer-header__item secondary-offer-header__yearly">
                        Доходность годовая
                      </div>
                    </div>
                    <div className="secondary-offer-value">
                      <div className="secondary-offer-value__item secondary-offer-value__primary">
                        <div className='secondary-offer-value--code'>
                          №{investment.project.paymentCode}
                        </div>
                        <BoldText>
                          <div>
                            {investment.project.name[0].text}
                          </div>
                        </BoldText>
                      </div>
                      <div
                        className="secondary-offer-value__item secondary-offer-value__category">
                        <div
                          className="secondary-offer-mobile-header">
                          Категория
                        </div>
                        <BoldText>
                          <ProjectCategory debtClass={investment.project.debt.debtClass}/>
                        </BoldText>
                      </div>
                      <div className="secondary-offer-value__item secondary-offer-value__sum">
                        <div
                          className="secondary-offer-mobile-header">
                          Размер инвестиции
                        </div>
                        <Money money={investment.money} />
                      </div>
                      <div
                        className="secondary-offer-value__item secondary-offer-value__forecast">
                        <div
                          className="secondary-offer-mobile-header">
                          Прогноз взыскания
                        </div>
                        <Money money={investment.project.accounting.finalDebtAmount}/>
                      </div>
                      <div
                        className="secondary-offer-value__item secondary-offer-value__simple">
                        <div
                          className="secondary-offer-mobile-header">
                          Доходность простая
                        </div>
                        {formatPercents(investment.project.accounting.simpleInterestRate)}
                      </div>
                      <div
                        className="secondary-offer-value__item secondary-offer-header__yearly">
                        <div
                          className="secondary-offer-mobile-header">
                          Доходность годовая
                        </div>
                        {formatPercents(investment.project.accounting.interestRate)}
                      </div>
                    </div>
                    <div className="secondary-offer-form__container">
                      <BoldText>
                        <div className="secondary-offer-form__heading">
                          Установить цену
                        </div>
                      </BoldText>
                      <div className="secondary-offer-form__input">
                        <InputMoney
                          placeholder="350 000"
                          money={offer!.price}
                          onValueChanged={(value) => setOffer({...offer!, price: value})}
                          onFocus={() => setFocused(true)}
                          onBlur={() => setFocused(false)}
                        />
                        <div className="secondary-offer-form__input-calculate">
                          <TertiaryButton color={TertiaryButtonColor.GREEN}>
                            <Button onClick={() => calculateInvestment(() => {
                              return (offer?.price);})}>
                              Расчитать
                            </Button>
                          </TertiaryButton>
                        </div>
                      </div>
                      <div className="secondary-offer-form__explanation-container">
                        <div className="secondary-offer-form__explanation">
                          *данная сумма не обязательно должна быть на счёте, это
                          максимальная сумма в отношении которой действуют настройки.
                          Если на счету сумма менее назначенной...
                        </div>
                      </div>
                    </div>
                    <div className="secondary-offer__calculation">
                      <div className="secondary-offer-calc secondary-offer-calc--first">
                        Расчет для покупателя:
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Размер инвестиции
                        </div>
                        <div className="secondary-offer-calc__value">
                          <Money money={money ? money : investment.money} />
                        </div>
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Прогноз взыскания
                        </div>
                        <div className="secondary-offer-calc__value">
                          <Money money={investment.project.accounting.finalDebtAmount}/>
                        </div>
                      </div>

                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Доходность простая
                        </div>
                        <div
                          className="secondary-offer-calc__value secondary-offer-calc__value--centered">
                          {/*
                                                        (Прогноз взыскания/ цену инвестиции - 1 )* 100%
                                                        */}
                          {
                            Math.round( money ?
                              (investment!.project.accounting.finalDebtAmount.amount / money.amount - 1) * 100 :
                              (investment!.project.accounting.finalDebtAmount.amount / investment.money.amount - 1) * 100)}%
                        </div>
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Доходность годовая
                        </div>
                        {/*
                                                        простая доходность / количество оставшихся лет
                                                        */}
                        <div
                          className="secondary-offer-calc__value secondary-offer-calc__value--centered">
                          {
                            Math.round( money ?
                              ((investment!.project.accounting.finalDebtAmount.amount / money.amount - 1) * 100) / (investment.project.accounting.initialTerm / 12):
                              ((investment!.project.accounting.finalDebtAmount.amount / investment.money.amount - 1) * 100) / (investment.project.accounting.initialTerm / 12))
                          }%
                        </div>
                      </div>
                    </div>
                    <div className="secondary-offer__calculation">
                      <div className="secondary-offer-calc secondary-offer-calc--first">
                        Расчет для продавца:
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Цена брутто
                          <Information>
                            Брутто-цена — цена за товар, услугу включающая в себя налог на добавленную стоимость.
                          </Information>
                        </div>
                        <div className="secondary-offer-calc__value">
                          <Money money={money ? money : investment.money} />
                        </div>
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Цена нетто
                          <Information>
                            Нетто цена - чистая цена товара на месте купли-продажи, не включающая скидки и надбавки, связанные с различием базисных условий договора, субсидии и т. п.
                          </Information>
                        </div>
                        <div className="secondary-offer-calc__value">
                          <Money money={money ? money : investment.money} />
                        </div>
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Доходность простая
                        </div>
                        <div
                          className="secondary-offer-calc__value secondary-offer-calc__value--centered">
                          {/*
                                                        (цена инвестиции/ сумму инвестиции - 1 )* 100%
                                                        */}
                          {Math.round(simpleSalesman)}%
                        </div>
                      </div>
                      <div className="secondary-offer-calc">
                        <div className="secondary-offer-calc__type">
                          Доходность годовая
                        </div>
                        <div
                          className="secondary-offer-calc__value secondary-offer-calc__value--centered">
                          {/*
                                                        простая доходность / количество оставшихся лет
                                                        */}
                          {Math.round(simpleSalesman / (investment.project.accounting.initialTerm / 12))}%
                        </div>
                      </div>
                    </div>
                    <div className="secondary-offer-form__submit">
                      <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
                        <Button onClick={handleSubmit} disabled={!rulesAccepted}>
                          Подтвердить
                        </Button>
                      </PrimaryButton>
                      <div className="secondary-offer__check">
                        <label onClick={() => setRulesAccepted((accepted) => !accepted)}>
                          <Checkbox
                            checked={rulesAccepted}
                          >
                            Подтверждая установленную цену продажи инвестиции, Вы предлагаете
                            пользователям Платформы заключить с вами договор уступки права
                            требования согласно данному образцу.
                          </Checkbox>
                        </label>
                      </div>
                    </div>
                  </Block.Body>
                </Block.Content>
              </Block>
            </div>
          </div>
          <WithdrawFAQ />
          <OfferConfirmationModal
            active={codeSent}
            onClose={() => setCodeSent(false)}
            offerSaleConfirmation={{confirmationUuid, money: offer!.price}}
          />
        </MainGrid>
        }
      </Section>
    </Container>
  );
};

export default CreateOffer;