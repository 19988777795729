import React, {FC} from "react";
import './DebtClass.scoped.scss';
import {cn} from "../../../../utils";

interface Props {
    value: string,
    onSelected: (value: string) => void;
    active: boolean
}

const DebtClass: FC<Props> = ({value, onSelected, active}) => {
  return (
    <div
      className={cn("debt-class", `${active ? 'active' : ''}`)}
      onClick={() => onSelected(value)}
    >
      { value }
    </div>
  );
};

export default DebtClass;