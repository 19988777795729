import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {StatsProvider} from "../StatsProvider";

export class StatsProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/stats`,
      headers
    );
  }

  public createStatsProvider(authToken: string) {
    return new StatsProvider(this.apiClientFactory.createAuthorizedClient(authToken));
  }
}