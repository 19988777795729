import {PaymentType, TransactionTypeMapped, TransactionType, ReferralTransaction as TransactionModel} from "../../../../api-client";
import {cn, resultIf} from "../../../../utils";
import {formatDate} from "../../../../formatters";
import {Money} from "../../../common";
import {MoneyMode} from "../../../common/utils/Money/Money";
import React, {FC} from "react";
import "./ReferralTransaction.scoped.scss";

interface Props {
    transaction: TransactionModel
}

const ReferralTransaction: FC<Props> = ({transaction}) =>  {
  const isIncome = () => {
    return transaction.paymentType != PaymentType.DEBIT;
  };

  return (
    <li
      className={cn(
        "referral-transaction active",
      )}
      key={transaction.uuid}
    >
      <div className="referral-transaction__column transaction__debt">
        <span className="referral-transaction__text transaction__project">
          { TransactionTypeMapped[transaction.transactionType.toLowerCase() as TransactionType] || transaction.transactionType || 'Unknown Type' }
        </span>
      </div>
      <div className="referral-transaction__column transaction__investment">
        <div className="referral-transaction__text">
          <span className="referral-transaction__id">
            { transaction.uuid }
          </span>
        </div>
      </div>
      <div className="referral-transaction__column transaction__date">
        <div className="referral-transaction__text">
          { formatDate(transaction.date) }
        </div>
      </div>
      <div className="referral-transaction__column transaction__money">
        <div className={resultIf(isIncome(), 'transaction--incoming')}>
          <span>
            { isIncome() ? "+" : "-" }
          </span>
          <Money  money={transaction.money} mode={resultIf(isIncome(), MoneyMode.WRITE_OFF)}/>
        </div>
      </div>
    </li>
  );
};

export default ReferralTransaction;