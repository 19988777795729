import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {PageProvider} from "../PageProvider";

export class PageProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/page`,
      headers
    );
  }

  public createPageProvider(): PageProvider {
    return new PageProvider(this.apiClientFactory.createClient());
  }
}