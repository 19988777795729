import React, {FormEvent, useEffect, useMemo, useState} from "react";
import {Block, Container, Section} from "../../../layout";
import {CustomLink, Heading, PrimaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import {SurveyConfirmationModal} from "../../../common/modals";
import {DetailedProject, Survey as SurveyModel, SurveyAnswer} from "../../../../api-client";
import {createProjectProvider, createSurveyProvider} from "../../../../di";
import {useErrorCallback, useLoading, useSurveyData} from "../../../../hooks";
import {useParams} from "react-router-dom";
import {renderDocuments} from "../SurveyDocuments";
import {RouteDictionary} from "../../../../utils";
import {SurveyForm} from "../SurveyForm";
import './Survey.scoped.scss';

const Survey = () => {
  const [surveyModalOpened, setSurveyModalOpened] = useState<boolean>(false);
  const [survey, setSurvey] = useState<SurveyModel>();
  const [project, setProject] = useState<DetailedProject>();
  const errorCallback = useErrorCallback();
  const [loading, withLoading] = useLoading();
  const {uuid} = useParams();
  const {addAnswer, surveyUuid, answers, setSurveyUuid} = useSurveyData();

  const surveyAnswer: SurveyAnswer = useMemo(() => {
    return {
      surveyUuid: surveyUuid,
      answers: answers
    };
  }, [surveyUuid, answers]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSurveyModalOpened(true);
  };

  useEffect(() => {
    const callback = async () => {
      try {
        const provider = await createSurveyProvider();
        const surveyModel = await provider.getOne(uuid!);
        if (surveyModel.projectUuid) {
          const projectProvider = await createProjectProvider();
          const project = await projectProvider.getOne(surveyModel.projectUuid);
          setProject(project);
        }
        setSurvey(surveyModel);
        setSurveyUuid(surveyModel.uuid);
      } catch (error) {
        if (error instanceof Error) {
          await errorCallback(error);
        }
      }
    };
    (async () => {
      await withLoading(callback);
    })();
  }, []);

  const closeModal = () => {
    setSurveyModalOpened(false);
  };
  if (loading) return <div>Загружаем...</div>;
  if (!survey) return <div>Some error</div>;

  return (
    <Section>
      <Container>
        <div className="survey">
          <PrimaryHeading>
            <div>
              <Heading headingType={HeadingType.H3} className="survey__header">
                {survey.title}
              </Heading>
              {project && <CustomLink to={RouteDictionary.DETAILED_PROJECT} params={{uuid: project.uuid}} className="survey__project">
                {project.name[0].text}
              </CustomLink>}
            </div>
          </PrimaryHeading>
          <div className="survey__container">
            <Block>
              <Block.Content>
                <Block.Header className="survey__description">
                  {survey.description}
                </Block.Header>
                <Block.Body>
                  {survey.files.length > 0 && renderDocuments(survey.files)}
                  <SurveyForm survey={survey} addAnswer={addAnswer} handleSubmit={handleSubmit} surveyAnswer={surveyAnswer}/>
                </Block.Body>
              </Block.Content>
            </Block>
            <SurveyConfirmationModal active={surveyModalOpened}
              onClose={closeModal} surveyAnswer={surveyAnswer}/>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Survey;