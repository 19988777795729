import React, {FC, SyntheticEvent, useMemo, useState} from "react";
import {
  useErrorCallback,
  usePassportData,
  usePersonalData,
  usePersonOgrnipData
} from "../../../../hooks";
import {EntrepreneurIdentification, Identification} from "../../../../api-client";
import {createIdentificationManager} from "../../../../di";
import IdentificationContext from "../../../context/IdentificationContext";
import {
  IdentificationBlockGroup,
  IdentificationConclusion,
  IdentificationPassport,
  IdentificationPersonal
} from "../blocks";

interface Props {
    onSubmit: () => void;
    identification: Identification|undefined;
}

const IdentificationEntrepreneur: FC<Props> = ({onSubmit, identification}) => {
  const value = {...usePersonalData(), ...usePersonOgrnipData(), ...usePassportData()};
  const handleError = useErrorCallback();
  const [personalValid, setPersonalValid] = useState(false);
  const [passportValid, setPassportValid] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);


  const dataValid= personalValid && passportValid;

  const identificationInput: EntrepreneurIdentification = useMemo(() => {
    return {
      person: {
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        birthday: value.birthday === null ? (new Date()).toISOString() : value.birthday.toISOString(),
        birthplace: value.birthplace,
        gender: value.gender
      },
      inn: value.inn,
      registerAddress: value.registerAddress,
      email: value.email,
      ogrnip: value.ogrnip,
      entrepreneurDocument: value.entrepreneurDocument,
      passport: {
        series: value.passportSeries,
        number: value.passportNumber,
        issuedDate: value.passportIssuedDate === null ? (new Date()).toISOString() : value.passportIssuedDate.toISOString(),
        issuedBy: value.passportIssuedBy,
        departmentCode: value.passportDepartmentCode,
        firstPassportScans: value.firstPassportScans,
        secondPassportScans: value.secondPassportScans,
        thirdPassportScans: value.thirdPassportScans,
      },
    };
  }, [
    value.firstName,
    value.middleName,
    value.lastName,
    value.birthday,
    value.birthplace,
    value.gender,
    value.inn,
    value.registerAddress,
    value.email,
    value.ogrnip,
    value.entrepreneurDocument,
    value.passportSeries,
    value.passportNumber,
    value.passportIssuedDate,
    value.passportIssuedBy,
    value.passportDepartmentCode,
    value.firstPassportScans,
    value.secondPassportScans,
    value.thirdPassportScans,
  ]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const manager = await createIdentificationManager();
      await manager.createEntrepreneurIdentification(identificationInput);
      onSubmit();
    } catch (error: any) {
      await handleError(error);
    }
    finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <IdentificationContext.Provider value={value}>
        <IdentificationBlockGroup>
          <IdentificationPersonal isEntrepreneur onValidated={(value: boolean) => setPersonalValid(value)} identification={identification} />
          <IdentificationPassport onValidated={(value: boolean) => setPassportValid(value)} passport={identification?.passport} />
          <IdentificationConclusion dataValid={dataValid} loading={loading}/>
        </IdentificationBlockGroup>
      </IdentificationContext.Provider>
    </form>
  );
};

export default IdentificationEntrepreneur;