import React, {AllHTMLAttributes, FC, PropsWithChildren} from "react";
import {ChildrenProps, cn} from "../../../../../utils";
import {Information} from "../../../../common";
import './IdentificationBlock.scss';

const IdentificationBlockInfo: FC<PropsWithChildren<any>> = ({children}) => {
  return (
    <div className="identification-block__info">
      <Information>
        { children }
      </Information>
    </div>
  );
};

type IdentificationBlockExtensions = {
    Info: typeof IdentificationBlockInfo;
}

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const IdentificationBlock: FC<PropsWithChildren<Props>> & IdentificationBlockExtensions = ({children, ...props}) => {
  return (
    <div {...props} className={cn("identification-block", props.className)}>
      { children }
    </div>
  );
};

IdentificationBlock.Info = IdentificationBlockInfo;

export default IdentificationBlock;