import React from "react";
import './WithinBlockDecorator.scoped.scss';
import {ClassInjector} from "../../../../injectors";
import {ChildrenProps} from "../../../../../utils";
import {ClassNameDecorator} from "../../../../decorators";

const WithinBlockDecorator: ClassNameDecorator<ChildrenProps> = ({children, className}) => {
  return (
    <ClassInjector
      classNames={"within-block"}
      oldClassName={className}
    >
      { children }
    </ClassInjector>
  );
};

export default WithinBlockDecorator;