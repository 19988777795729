import React, {FC, SyntheticEvent, useState} from "react";
import Modal from "../Modal";
import {Errors as ValidationErrors, sleep} from "../../../../utils";
import {useEffectOnUpdate, useErrorCallback, useNavigateByName, useNotifier} from "../../../../hooks";
import {ConfirmSmsCodeForm} from "../../utils";
import {createChangeUsernameManager, createVerificationManager} from "../../../../di";
import {Phone} from "../../../../api-client";
import {InputNumber} from "../../inputs";
import {Button, PrimaryButton} from "../../buttons";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {MultiStepForm} from "../../generics";
import {ModalProps} from "../Modal/Modal";

type FormErrors = {
    phone?: ValidationErrors
}

const ChangeUsernameModal: FC<ModalProps> = ({active, onClose}) => {
  const [errors, setErrors] = useState<FormErrors>({});
  const [step, setStep] = useState(0);
  const [phone, setPhone] = useState<Phone>(new Phone("+7", ""));
  const [loading, setLoading] = useState(false);
  const [confirmationUuid, setConfirmationUuid] = useState("");
  const notifier = useNotifier();
  const navigate = useNavigateByName();
  const handleError = useErrorCallback();

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    if (phone.fullNumber === "") {
      errors.phone = ["Номер телефона обязателен"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateForm();
  }, [phone]);

  useEffectOnUpdate(() => {
    if (active) {
      const callback = async () => {
        const manager = await createChangeUsernameManager();
        const result = await manager.request();
        setConfirmationUuid(result.uuid);
      };
      (async () => {
        setLoading(true);
        try {
          await callback();
        } catch (error: any) {
          await handleError(error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [active]);


  const sendConfirmationSms = async (smsCode: string): Promise<void> => {
    const manager = await createChangeUsernameManager();
    await manager.confirm(confirmationUuid, smsCode);
  };

  const resendConfirmationSms = async () => {
    //TODO: ADD RESENDING SMS
    await sleep(3000);
  };

  const successConfirmationCallback = () => {
    setStep(1);
  };

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      setLoading(true);
      try {
        const manager = await createChangeUsernameManager();
        await manager.change(confirmationUuid, phone);
        setStep(2);
      } catch (error: any) {
        await handleError(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const sendSms = async (smsCode: string) => {
    const manager = await createVerificationManager();
    await manager.verifyPhone(phone, smsCode);
  };

  const resendSms = async () => {
    await sleep(3000);
  };

  const successCallback = () => {
    notifier.createSuccess("Ваш номер телефона был изменен");
    onClose();
    navigate('logout');
  };

  return (
    <Modal active={active} onClose={onClose}>
      <Modal.Close />
      <MultiStepForm step={step}>
        <div>
          <ConfirmSmsCodeForm
            sendCallback={sendConfirmationSms}
            resendCallback={resendConfirmationSms}
            successCallback={successConfirmationCallback}
            prefix={`change-username-confirmation-${confirmationUuid}`}
          />
        </div>
        <div>
          { loading && <div>Загрузка</div> }
          <form onSubmit={handleSubmit}>
            <div className="form__group">
              <label htmlFor="phone">
                Новый номер телефона
              </label>
              <InputNumber id="phone" phone={phone} onPhoneInput={setPhone} />
            </div>
            <div className="form__group">
              <PrimaryButton expanded large color={PrimaryButtonColor.GREEN} disabled={loading}>
                <Button type="submit">
                  Отправить
                </Button>
              </PrimaryButton>
            </div>
          </form>
        </div>
        <div>
          <ConfirmSmsCodeForm
            sendCallback={sendSms}
            resendCallback={resendSms}
            successCallback={successCallback}
            phone={phone}
            prefix={`change-username-${confirmationUuid}`}
          />
        </div>
      </MultiStepForm>
    </Modal>
  );
};

export default ChangeUsernameModal;