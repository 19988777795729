import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ProjectAutoInvestmentSettingsManager} from "../ProjectAutoInvestmentSettingsManager";

export class ProjectAutoInvestmentSettingsManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers = {}) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/project-auto-investment-settings`,
      headers
    );
  }

  createProjectAutoInvestmentSettingsManager(token: string): ProjectAutoInvestmentSettingsManager {
    return new ProjectAutoInvestmentSettingsManager(this.apiClientFactory.createAuthorizedClient(token));
  }
}