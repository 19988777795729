import {Heading, SecondaryHeading} from "../../../common";
import {HeadingType} from "../../../common/typography/headings/Heading/Heading";
import React, {useMemo, useState} from "react";
import {Block, Container, Section} from "../../../layout";
import {Pagination} from "../../../pagination";
import {Filter} from "../../../pages/transactions/TransactionsPartialFilter/TransactionsPartialFilter";
import {useErrorCallback} from "../../../../hooks";
import {DataCollection} from "../../../../utils";
import {
  ReferralTransaction as ReferralTransactionModel,
} from "../../../../api-client";
import {
  createReferralTransactionProvider,
} from "../../../../di";
import "./ReferralTransactions.scss";
import ReferralTransaction from "../ReferralTransaction";

const ReferralTransactions = () => {
  const errorCallback = useErrorCallback();
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] =
        useState<DataCollection<ReferralTransactionModel>>(new DataCollection());

  const defaultFilter: Filter = {
    search: "",
    dateFrom: "",
    dateTo: "",
    transactionDirection: null,
    projectUuids: []
  };
  const [filter, setFilter] = useState<Filter>(defaultFilter);


  const loadData = async (page: number, perPage: number, filter: Filter) => {
    setLoading(true);
    try {
      const provider = await createReferralTransactionProvider();
      const transactions = await provider.getList(page, perPage, filter);
      const dataCollection = new DataCollection<ReferralTransactionModel>();
      dataCollection.setCollection(transactions);
      setTransactions(dataCollection);
    } catch (err: any) {
      await errorCallback(err);
    } finally {
      setLoading(false);
    }
  };

  const transactionsList = useMemo(() => {
    return transactions.items.map((transaction) => {
      return <ReferralTransaction transaction={transaction} key={transaction.uuid} />;
    });
  }, [transactions]);
  return (
    <Section className="referral-transactions-section">
      <Container>
        <div>
          <SecondaryHeading>
            <Heading headingType={HeadingType.H2}>
              Реферальные транзакции
            </Heading>
          </SecondaryHeading>
        </div>
        <Pagination
          onLoad={loadData}
          count={transactions.count}
          totalCount={transactions.totalCount}
          filterObject={filter}
          initialChangeFilter={(value: Partial<Filter>) => setFilter({...filter, ...value})}
        >
          <Block>
            <Block.Content>
              <Block.Body className="referral-transactions-body" withPadding={false}>
                <Pagination.Content loading={loading}>
                  <div className="referral-transactions-header">
                    <div className="referral-transactions-header__item">
                      Тип транзакции
                    </div>
                    <div className="referral-transactions-header__item">
                      ID транзакции
                    </div>
                    <div className="referral-transactions-header__item">
                      Дата
                    </div>
                    <div className="referral-transactions-header__item">
                      Сумма платежа
                    </div>
                  </div>
                  <ul className="referral-transactions__list">
                    { transactionsList }
                  </ul>
                </Pagination.Content>
              </Block.Body>
            </Block.Content>
          </Block>
          <Pagination.Paginator />
        </Pagination>
      </Container>
    </Section>
  );
};
export default ReferralTransactions;