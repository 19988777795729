import {ApiClientInterface} from "./clients";
import {Survey} from "./models";

export class SurveyProvider {
  constructor(private readonly apiClient: ApiClientInterface) {
  }

  public async getOne(uuid: string): Promise<Survey> {
    return this.apiClient.get(`/${uuid}`);
  }

  public async getList(): Promise<Survey[]> {
    return this.apiClient.get(`/`);
  }
}