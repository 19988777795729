import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {TransactionInfoProvider} from "../TransactionInfoProvider";

export class TransactionInfoProviderFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/transaction-info`,
      headers
    );
  }

  public createTransactionInfoProvider(token: string): TransactionInfoProvider {
    return new TransactionInfoProvider(this.apiClientFactory.createAuthorizedClient(token));
  }
}