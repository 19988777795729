import {ReferralUser as ReferralUserModel} from "../../../../api-client";
import React, {FC} from "react";
import {formatDate} from "../../../../formatters";
import './ReferralUser.scss';

interface Props {
    user: ReferralUserModel
}

const ReferralUser: FC<Props> = ({user}) =>  {
  const userShowType: Record<string, string> = {
    "BORROWER_INDIVIDUAL": "Индивидуальный заёмщик",
    "BORROWER_LEGAL_ENTITY": "Юридический заёмщик",
    "LENDER_INDIVIDUAL": "Индивидуальный кредитор",
    "LENDER_ENTREPRENEUR": "Кредитор предприниматель",
    "LENDER_LEGAL_ENTITY": "Юридический кредитор"
  };
  return (
    <li className="referral-users__column">
      <div className="referral-users__name">
        <span>{user.name}</span>
      </div>
      <div className="referral-users__register-date">
        <span>{formatDate(user.registerDate)}</span>
      </div>
      <div className="referral-users__income">
        <span>{userShowType[user.userType]}</span>
      </div>
    </li>
  );
};

export default ReferralUser;