import React, {
  FC,
  PropsWithChildren,
  ReactNode
} from "react";
import './BlockDropdown.scoped.scss';
import {useDropdown, useToggle} from "../../../../hooks";
import Block from "../Block";
import {AsLink, Button, Image} from "../../../common";
import {cn, resultIf} from "../../../../utils";
import dropdownCloseArrow from "../../../../assets/images/svg/project-arrow_b-l.svg";
import RotatedOpener from "../../../common/utils/RotatedOpener";

interface Props {
    header: ReactNode;
    showToggle?: boolean;
    headerClassname?: string;
}

const BlockDropdown: FC<PropsWithChildren<Props>> = ({header, showToggle = true, headerClassname, children}) => {
  const [active, toggle] = useToggle(true);
  const [contentElement, height] = useDropdown(active);

  const blockHeader = (): ReactNode => {
    return <div className="block-dropdown__header">
      <div className={cn("block-dropdown__heading", resultIf(!showToggle, "block-dropdown__heading--only"))}>
        { header }
      </div>
      { showToggle && <RotatedOpener active={active} onClicked={toggle} /> }
    </div>;
  };

  return (
    <div className={cn("block-dropdown", resultIf(active, "active"))}>
      <Block>
        <Block.Content>
          <Block.Header withPadding className={cn(headerClassname)}>
            { blockHeader() }
          </Block.Header>
          <Block.Body className="block-dropdown__body" style={{maxHeight: height}}>
            <div className="block-dropdown__content" ref={contentElement}>
              <div className="block-dropdown__content-inner">
                { children }
              </div>
            </div>
          </Block.Body>
          <Block.Footer>
            <div className="block-dropdown__footer">
              { !showToggle ?
                <div className="block-dropdown__toggle">
                  <AsLink>
                    <Button onClick={toggle}>
                      {active ? "Скрыть" : "Подробнее"}
                    </Button>
                  </AsLink>
                </div>
                :
                <div className="block-dropdown__toggle-arrow">
                  <div className="block-dropdown__footer-close" onClick={toggle}>
                    <Image src={dropdownCloseArrow} alt="Dropdown close arrow" />
                  </div>
                </div>
              }
            </div>
          </Block.Footer>
        </Block.Content>
      </Block>
    </div>
  );
};

export default BlockDropdown;