import React, {useState} from "react";
import './ChangeAvatar.scoped.scss';
import {ChangeAvatarModal} from "../../common/modals";
import {GenderImage} from "../../common/images/types";
import {useProfileData} from "../../../hooks";
import {Image} from "../../common";
import changeAvatar from "../../../assets/images/svg/change.svg";

const ChangeAvatar = () => {
  const profileData = useProfileData();
  const [editModalOpened, setEditModalOpened] = useState(false);
  return (
    <>
      <div className="change-avatar" onClick={() => setEditModalOpened(true)}>
        <div className="change-avatar__photo">
          <GenderImage gender={profileData.gender!} />
        </div>
        <div className="change-avatar__edit">
          <Image src={changeAvatar} />
        </div>
      </div>
      <ChangeAvatarModal active={editModalOpened} onClose={() => setEditModalOpened(false)} />
    </>
  );
};

export default ChangeAvatar;