import React, {FC, useMemo} from "react";
import {DebtClass} from "../index";
import './DebtClassList.scoped.scss';

interface Props {
    classList: Array<string>;
    selectedClasses: Array<string>;
    onSelected: (selected: Array<string>) => void;
}

const DebtClassList: FC<Props> = ({classList, selectedClasses, onSelected}) => {
  const select = (value: string): void => {
    if (classSelected(value)) {
      onSelected(selectedClasses.filter((element) => {
        return element !== value;
      }));
      return;
    }
    onSelected([...selectedClasses, value]);
    return;
  };

  const classSelected = (value: string): boolean => {
    return selectedClasses.includes(value);
  };

  const classMap = useMemo(() => {
    return classList.map((debtClass) => {
      return <DebtClass key={debtClass} value={debtClass} onSelected={select} active={classSelected(debtClass)} />;
    });
  }, [selectedClasses]);
  return (
    <div className="debt-class-list">
      { classMap }
    </div>
  );
};

export default DebtClassList;