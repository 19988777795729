import React, {useContext, useMemo} from "react";
import FavoriteSvg from "../../../../../../components/svg/FavoriteSvg";
import {Information, Money, ProjectCategory} from "../../../../../../components";
import "./SecondaryProjectMain.scoped.scss";
import {SecondaryProjectContext} from "../SecondaryProjectContext/SecondaryProjectContext";
import {formatPercents} from "../../../../../../formatters";

const SecondaryProjectMain = () => {
  const offer = useContext(SecondaryProjectContext).offer;
  const project = offer.project;
  const renderPaymentBases = useMemo(() => {
    const paymentBases = project.paymentBases;
    if (paymentBases.length === 0) {
      return <div>
        Основания будут добавлены в процессе наполнения проекта.
      </div>;
    }
    return paymentBases.map((paymentBase) => {
      return <div key={paymentBase.heading} className="detailed-project-additional__item">
        <div className="detailed-project-additional__heading">
          {paymentBase.heading}
        </div>
        <div className="detailed-project-additional__content">
          {paymentBase.content}
        </div>
      </div>;
    });
  }, [project]);
  return (
    <div className="secondary-project-main">
      <div className="secondary-project-main__top">
        <div className="secondary-project-main__status">
          <div>
            <FavoriteSvg width={12} height={16} active/>
          </div>
          <div className="secondary-project-main__code">
            {project.paymentCode}
          </div>
          <div>
            {project.company.status}
          </div>
        </div>
        <div className="secondary-project-main__category">
          <div className="secondary-project-main__class">
            <ProjectCategory debtClass={project.debt.debtClass}/>
          </div>
          <Information>
            Категория проекта
          </Information>
        </div>
      </div>
      <div className="secondary-project-main__project">
        <div>
          <div className="secondary-project-main__name">
            {project.name[0].text}
          </div>
          <div className="secondary-project-main__info">
            ИНН {project.company.inn}. {project.company.name}.
          </div>
        </div>
        <div className="secondary-project-main__investment-money">
          <div className="secondary-project-stat__type secondary-project-stat__type--sum">
            Сумма Вашего участия:
          </div>
          <div className="secondary-project-stat__value secondary-project-stat__value--sum">
            <Money money={offer.investmentMoney}/>
          </div>
        </div>
      </div>
      <div className="secondary-project-main__stats">
        <div className="secondary-project-stat">
          <div className="secondary-project-stat__type">
            Номинал долга
          </div>
          <div className="secondary-project-stat__value">
            <Money money={project.accounting.principalDebtAmount}/>
          </div>
        </div>
        <div className="secondary-project-stat">
          <div className="secondary-project-stat__type">
            Прогноз взыскания
          </div>
          <div className="secondary-project-stat__value">
            <Money money={project.accounting.finalDebtAmount}/>
          </div>
        </div>
        <div className="secondary-project-stat">
          <div className="secondary-project-stat__type">
            Срок
          </div>
          <div className="secondary-project-stat__value">
            {project.accounting.initialTerm} мес.
          </div>
        </div>
        <div className="secondary-project-stat">
          <div className="secondary-project-stat__type">
            Доходность простая
          </div>
          <div className="secondary-project-stat__value secondary-project-stat__value--centered">
            {formatPercents(project.accounting.simpleInterestRate)}
          </div>
        </div>
        <div className="secondary-project-stat">
          <div className="secondary-project-stat__type">
            Доходность годовая
          </div>
          <div className="secondary-project-stat__value secondary-project-stat__value--centered">
            {formatPercents(project.accounting.interestRate)}
          </div>
        </div>
      </div>
      <div className="secondary-project-additional">
        <div className="secondary-project-additional__help">
          <Information>
            Some more info
          </Information>
        </div>
        <div className="secondary-project-additional__title">
          Основания предполагать будущее погашение долга
        </div>
        <div className="secondary-project-additional__items">
          {renderPaymentBases}
        </div>
      </div>
    </div>
  );
};

export default SecondaryProjectMain;