import React, {useContext} from "react";
import {ProjectsContext} from "../../../../../views/dashboard/lender/investment/projects/ProjectsContext";
import ProjectFilterClear from "../ProjectFilterClear";
import {MIN_MATURITY_DATE, MIN_YEAR_PROFIT} from "../../../../../utils";
import {ProjectFilterConditionContext} from "../ProjectFilterConditionContext";
import './ProjectFilterFields.scoped.scss';

const ProjectFilterFields = () => {
  const {filter, setFilter} = useContext(ProjectsContext);
  const {
    isSearchActive,
    areClassesEmpty,
    isYearProfitEmpty,
    isMaturityDateEmpty
  } = useContext(ProjectFilterConditionContext);
  const getSliderValue = (value: number | null, defaultValue: number): string => {
    return value === null ? `${defaultValue}` : `${value}`;
  };
  return (
    <div className="project-filter-fields">
      { isSearchActive() && <ProjectFilterClear
        action={() => setFilter({...filter, search: ""})}
      >
        Поиск по &quot;{ filter.search }&quot;
      </ProjectFilterClear>
      }
      { areClassesEmpty() && <ProjectFilterClear
        action={() => setFilter({...filter, economicClasses: [], legalClasses: []})}
      >
        { filter.economicClasses.concat(filter.legalClasses).join(", ") }
      </ProjectFilterClear>
      }
      { isMaturityDateEmpty() && <ProjectFilterClear
        action={() => setFilter({...filter, initialTermFrom: null, initialTermTo: null})}
      >
        Срок взыскания: {getSliderValue(
          filter.initialTermFrom,
          MIN_MATURITY_DATE
        )} мес. - {filter.initialTermTo === null ? "Без ограничений" : `${filter.initialTermTo}мес.`}
      </ProjectFilterClear>
      }
      { isYearProfitEmpty() && <ProjectFilterClear
        action={() => setFilter({...filter, interestRateFrom: null, interestRateTo: null})}
      >
        Доходность годовая: {getSliderValue(
          filter.interestRateFrom,
          MIN_YEAR_PROFIT
        )}% - {filter.interestRateTo === null ? "Без ограничений" : `${filter.interestRateTo}%`}
      </ProjectFilterClear>
      }
    </div>
  );
};

export default ProjectFilterFields;