import React from "react";
import {Container, Section} from "../../../components";

const HowItWorks = () => {
  return (
    <Section>
      <Container>
        Как это работает
      </Container>
    </Section>
  );
};

export default HowItWorks;