import React, {AllHTMLAttributes, FC, useContext} from "react";
import {cn} from "../../../../../../utils";
import './ModalClose.scoped.scss';
import {ModalContext} from "../../ModalContext";

interface Props extends AllHTMLAttributes<HTMLDivElement> {}

const ModalClose: FC<Props> = ({...props}) => {
  const context = useContext(ModalContext);
  const handleClick = () => {
    context.onClose();
  };
  return (
    <div
      {...props}
      onClick={handleClick}
      className={cn("my-modal-close", props.className)}
    ></div>
  );
};

export default ModalClose;