import React from "react";
import {CustomLink} from "../../../common";
import './ProfileNavigation.scoped.scss';
import {RouteDictionary} from "../../../../utils";
import {matchPath, useLocation} from "react-router-dom";

const ProfileNavigation = () => {
  const {pathname} = useLocation();
  return (
    <>
      <nav className="profile-navigation">
        <CustomLink
          to={RouteDictionary.REFERRAL}
          className="profile-navigation__link"
          explicitActive={matchPath('dashboard/profile', pathname) !== null}
        >
          Реферальный доход
        </CustomLink>
      </nav>
    </>
  );
};

export default ProfileNavigation;