import {NamedUrl} from "./types";
import {RouteProvider} from "./RouteProvider";
import {invariant} from "../invariant";

export class RouteUrlProvider {
  constructor(private readonly routeProvider: RouteProvider) {}

  public getByName(name: string, params?: Object): string {
    const current = this.routeProvider.getUrls().filter((namedUrl: NamedUrl) => {
      return namedUrl.name === name;
    });
    invariant(current.length === 1, `Error when mapping urls, got ${name}`);
    let url = current[0].url;
    if (params) {
      Object.entries(params).forEach((param) => {
        const regexp = new RegExp(":" + param[0], 'gi');
        url = url.replace(regexp, param[1]);
      });
    }
    return url;
  }
}