import {Unauthorized, ValidationError} from "api-client";
import {AuthTokenExpired, RouteDictionary} from "utils";
import {useNavigateByName} from "./useNavigateByName";
import {createAuthTokenManager} from "../di";
import {useAppDispatch} from "./store";
import {clear} from "../store/profile";
import {useNotifier} from "./useNotifier";
import {useCallback} from "react";

export const useErrorCallback = () => {
  const navigate = useNavigateByName();
  const dispatch = useAppDispatch();
  const notifier = useNotifier();
  const tokenManager = createAuthTokenManager();
  const errorCallback = useCallback(async (error: Error) => {
    if (error instanceof Unauthorized || error instanceof AuthTokenExpired) {
      //TODO: make redirectTo store
      tokenManager.remove();
      await dispatch(clear());
      await navigate(RouteDictionary.ENTER);
      return;
    } else if (error instanceof ValidationError) {
      notifier.createError(error.message);
    }
  }, [navigate, dispatch, notifier, tokenManager]);
  return errorCallback;
};