import React, {FC} from "react";
import Modal from "../Modal";
import {ModalProps} from "../Modal/Modal";

const GuestModal: FC<ModalProps> = ({active, onClose}) => {
  return (
    <Modal active={active} onClose={onClose}>
      <div>
        Вы находитесь в режиме просмотра
      </div>
      <div>
        Пройдите идентификацию для получения полного доступа к услугам платформы
      </div>
    </Modal>
  );
};

export default GuestModal;