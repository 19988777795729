import React, {AllHTMLAttributes} from "react";
import {cn} from "../../../../utils";
import './Hamburger.scoped.scss';

export enum HamburgerColor {
    GREEN = "hamburger--green",
    BLACK = "hamburger--black"
}

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    rows: number;
    active?: boolean;
    color?: HamburgerColor;
}

const Hamburger = ({active, rows = 3, color = HamburgerColor.BLACK, ...props}: Props) => {
  const renderRows = () => {
    let rowsArr: JSX.Element[] = [];
    for (let i = 0; i < rows; ++i) {
      rowsArr.push(<span key={i} className="hamburger__row"></span>);
    }
    return rowsArr;
  };
  return (
    <>
      <div
        {...props}
        className={cn(
          "hamburger",
          `${active ? "hamburger--active" : ""}`,
          `${color}`,
          props.className
        )}
      >
        <div className="hamburger__inner">
          { renderRows() }
        </div>
      </div>
    </>
  );
};

export default Hamburger;