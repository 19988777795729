import {ChangeCredentialManager} from "./ChangeCredentialManager";
import {ApiClientInterface} from "./clients";
import {Phone} from "./models";

export class ChangeUsernameManager extends ChangeCredentialManager {
  constructor(apiClient: ApiClientInterface) {
    super(apiClient);
  }

  async change(confirmationUuid: string, phone: Phone): Promise<void> {
    return this.apiClient.post("/change", {confirmationUuid, phone});
  }
}