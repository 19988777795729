import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  Email,
  LenderIdentificationStatus,
  Money,
  PersonalData,
  Phone,
  Profile,
  Role,
  UserGender,
  UserStatus
} from "api-client";
import {Nullable} from "../utils";

export type ProfileState = {
    uuid?: string;
    role: Role;
    status: UserStatus;
    username: string;
    name: string;
    gender: Nullable<UserGender>;
    balance: Money;
    reservedFunds: Money;
    paymentCode?: string;
    bankAccounts: Array<string>,
    selectedBankAccount: string,
    email: Nullable<Email>;
    phone: Phone;
    phoneVerified: boolean;
    lenderVerified: boolean;
    personalData: Nullable<PersonalData>;
    lenderCanDeposits: boolean;
    lenderStatus: LenderIdentificationStatus;
    hasIdentificationDraft: boolean;
    identificationDate: string;
    isEntrepreneur: boolean;
    isLegalEntity: boolean;
    agentCode: Nullable<string>;
    hasTransactions: boolean;
    newNotificationCount: number;
}

const initialState: ProfileState = {
  uuid: undefined,
  role: Role.LENDER,
  status: UserStatus.UNKNOWN,
  username: "",
  name: "",
  gender: null,
  balance: new Money(0, ""),
  reservedFunds: new Money(0, ""),
  paymentCode: "",
  //TODO: ADD BANK ACCOUNTS TO API
  bankAccounts: ["48568455593262719", "48568455593262718", "48568455593262717"],
  selectedBankAccount: "48568455593262719",
  email: null,
  phone: new Phone("", ""),
  phoneVerified: false,
  lenderVerified: false,
  personalData: null,
  lenderCanDeposits: false,
  lenderStatus: LenderIdentificationStatus.NOT_VERIFIED,
  hasIdentificationDraft: false,
  identificationDate: "",
  isEntrepreneur: false,
  isLegalEntity: false,
  agentCode: null,
  hasTransactions: false,
  newNotificationCount: 0
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    set: (state: ProfileState, {payload}: PayloadAction<Profile>) => {
      state.uuid = payload.uuid;
      state.role = payload.role;
      state.status = payload.status;
      state.username = payload.username;
      state.name = payload.name ?? null;
      state.gender = payload.gender ?? null;
      state.balance = payload.balance;
      state.reservedFunds = payload.reservedFunds;
      state.paymentCode = payload.paymentCode;
      state.email = payload.email;
      state.phone = payload.phone;
      state.phoneVerified = payload.phoneVerified;
      state.lenderVerified = payload.lender!.verified;
      state.personalData = payload.lender!.personalData ?? null;
      state.isEntrepreneur = state.status === UserStatus.ENTREPRENEUR;
      state.isLegalEntity = state.status === UserStatus.LEGAL_ENTITY;
      state.hasIdentificationDraft = payload.lender!.hasIdentificationDraft;
      state.lenderStatus = payload.lender!.identificationStatus;
      state.agentCode = payload.lender!.agentCode ?? null;
      state.hasTransactions = payload.lender!.hasTransactions;
      state.newNotificationCount = payload.newNotificationCount;
    },
    subtractBalance: (state: ProfileState, {payload}: PayloadAction<number>) => {
      if (state.balance) {
        state.balance = new Money(state.balance.amount - payload, state.balance.currencyCode);
      }
    },
    setIdentificationDraft: (state: ProfileState, {payload}: PayloadAction<boolean>) => {
      state.hasIdentificationDraft = payload;
    },
    addAgentCode: (state: ProfileState, {payload}: PayloadAction<string>) => {
      state.agentCode = payload;
    },
    changeGender: (state: ProfileState, {payload}: PayloadAction<UserGender>) => {
      state.gender = payload;
    },
    changeNotificationCount: (state: ProfileState, {payload}: PayloadAction<number>) => {
      state.newNotificationCount = payload;
    },
    selectBankAccount: (state: ProfileState, {payload}: PayloadAction<string>) => {
      state.selectedBankAccount = payload;
    },
    clear: (state: ProfileState) => {
      delete state.uuid;
      delete state.paymentCode;
    }
  }
});

export const {
  set,
  setIdentificationDraft,
  addAgentCode,
  subtractBalance,
  changeGender,
  selectBankAccount,
  changeNotificationCount,
  clear
} = profileSlice.actions;

export default profileSlice.reducer;

