import React, {FC, useState} from "react";
import {useAppDispatch, useErrorCallback, useProfileData} from "../../../../hooks";
import Modal from "../Modal";
import {GuestModal} from "../index";
import './ChangeAvatarModal.scoped.scss';
import {Button, PrimaryButton} from "../../buttons";
import {PrimaryButtonColor} from "../../buttons/decorators/PrimaryButton/PrimaryButton";
import {UserGender} from "../../../../api-client";
import {GenderImage} from "../../images/types";
import {WithinBlockDecorator} from "../../images";
import {cn, resultIf} from "../../../../utils";
import {createProfileManager} from "../../../../di";
import {changeGender as changeStoreGender} from "../../../../store/profile";
import {ModalProps} from "../Modal/Modal";

const ChangeAvatarModal: FC<ModalProps> = ({active, onClose}) => {
  const profileData = useProfileData();
  const [gender, setGender] = useState<UserGender | null>(profileData.gender);
  const [loading, setLoading] = useState(false);
  const handleError = useErrorCallback();
  const dispatch = useAppDispatch();
  const isActive = (newGender: UserGender): boolean => {
    return newGender === gender;
  };
  const changeGender = async () => {
    if (gender !== null && gender !== profileData.gender) {
      setLoading(true);
      try {
        const profileManager = await createProfileManager();
        await profileManager.changeGender(gender);
        dispatch(changeStoreGender(gender));
        onClose();
      } catch (err: any) {
        await handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      { profileData.lenderVerified ? <Modal active={active} className="avatar-modal" onClose={onClose}>
        <Modal.Close />
        <div className="avatar-modal__content">
          <div className="avatar-modal__header">
            Выберите аватар
          </div>
          <div className="avatar-modal__body">
            <div className="avatar-modal__types">
              <div
                className={cn(
                  "avatar-modal__type",
                  resultIf(isActive(UserGender.MALE), "active")
                )}
                onClick={() => setGender(UserGender.MALE)}
              >
                <div className="avatar-modal__image">
                  <WithinBlockDecorator>
                    <GenderImage gender={UserGender.MALE} />
                  </WithinBlockDecorator>
                </div>
              </div>
              <div
                className={cn(
                  "avatar-modal__type",
                  resultIf(isActive(UserGender.FEMALE), "active")
                )}
                onClick={() => setGender(UserGender.FEMALE)}
              >
                <div className="avatar-modal__image">
                  <WithinBlockDecorator>
                    <GenderImage gender={UserGender.FEMALE} />
                  </WithinBlockDecorator>
                </div>
              </div>
            </div>
            <div className="avatar-modal__submit">
              <PrimaryButton className="avatar-modal__button" large expanded color={PrimaryButtonColor.GREEN}>
                <Button onClick={changeGender}>
                  { loading ? "Обновляем..." : "Ок" }
                </Button>
              </PrimaryButton>
            </div>
          </div>
        </div>
      </Modal> : <GuestModal active={active} onClose={onClose} /> }
    </>
  );
};

export default ChangeAvatarModal;