import React, {AllHTMLAttributes, FC, ReactNode} from "react";
import {cn, resultIf} from "../../../../utils";
import './BlockHeader.scoped.scss';

interface Props extends AllHTMLAttributes<HTMLDivElement> {
    withPadding?: boolean | undefined;
    single?: boolean | undefined;
    children: ReactNode | undefined;
}

const BlockHeader: FC<Props> = ({withPadding = false, single = false, children, ...props}) => {
  return (
    <header className={cn("block-header", resultIf(single, "block-header--single"))}>
      <div
        {...props}
        className={cn(
          "block-header__inner",
          resultIf(withPadding, "block-header--padding"),
          props.className
        )}
      >
        { children }
      </div>
    </header>
  );
};

export default BlockHeader;