import React, {FC, ImgHTMLAttributes} from "react";
import maleImage from '../../../../assets/images/svg/male.svg';
import femaleImage from '../../../../assets/images/svg/female.svg';
import {UserGender} from "../../../../api-client";
import Image from "../Image";

const genderMap: Record<UserGender, string> = {
  [UserGender.MALE]: maleImage.toString(),
  [UserGender.FEMALE]: femaleImage.toString()
};

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
    gender: UserGender
}

const GenderImage: FC<Props> = ({gender, ...props}) => {
  return (
    <Image {...props} src={genderMap[gender]} alt="Человек" />
  );
};

export default GenderImage;