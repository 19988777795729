import React, {FC, PropsWithChildren, useContext, useMemo} from "react";
import {ProjectsContext} from "../../../../views/dashboard/lender/investment/projects/ProjectsContext";
import ProjectFilterConditionContextProvider, {
  ProjectFilterConditionContextType
} from "./ProjectFilterConditionContext";

interface Props {}

const ProjectFilterActive: FC<PropsWithChildren<Props>> = ({children}) => {
  const {filter} = useContext(ProjectsContext);
  const isSearchActive = (): boolean => {
    return filter.search !== "";
  };
  const areClassesEmpty = (): boolean => {
    return filter.legalClasses.length !== 0 || filter.economicClasses.length !== 0;
  };
  const isMaturityDateEmpty = (): boolean => {
    return filter.initialTermFrom !== null || filter.initialTermTo !== null;
  };
  const isYearProfitEmpty = (): boolean => {
    return filter.interestRateFrom !== null || filter.interestRateTo !== null;
  };
  const isFilterActive: boolean = useMemo((): boolean => {
    return isSearchActive() || areClassesEmpty() || isMaturityDateEmpty() || isYearProfitEmpty();
  }, [filter]);

  const value: ProjectFilterConditionContextType = {
    isSearchActive,
    areClassesEmpty,
    isMaturityDateEmpty,
    isYearProfitEmpty
  };
  return (
    <>
      { isFilterActive && <ProjectFilterConditionContextProvider props={value}>
        { children }
      </ProjectFilterConditionContextProvider> }
    </>
  );
};

export default ProjectFilterActive;