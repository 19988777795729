import React, {AllHTMLAttributes, ReactNode, useContext} from "react";
import {cn, resultIf} from "../../../../../../utils";
import {MultiSelectContext, MultiSelectContextType} from "../../MultiSelectContext";
import './MultiSelectContent.scoped.scss';

interface Props<T> extends AllHTMLAttributes<HTMLDivElement> {
    onSelected: (selected: Array<T>) => void;
    compareOptions?: (first: T, second: T) => boolean;
}

const MultiSelectContent = <T, >({onSelected, compareOptions, ...props}: Props<T>) => {
  const context: MultiSelectContextType<T> = useContext(MultiSelectContext);

  const renderOptions = (): ReactNode => {
    //TODO: REPLACE INDEX WITH PROPER KEY
    return context.options.map((option: T, index: number) => {
      return <li
        key={index}
        className={cn("multi-select__element", resultIf(isSelected(option), "selected"))}
        onClick={() => handleSelect(option)}
      >
        {
          context.render(option)
        }
      </li>;
    }, []);
  };

  const handleSelect = (option: T) => {
    if (!isSelected(option)) {
      onSelected([...context.selectedOptions, option]);
      return;
    }
    const newOptions: T[] = context.selectedOptions.filter((filteredOption: T) => {
      return compareOptions === undefined
        ? filteredOption !== option
        : compareOptions(filteredOption, option);
    });
    onSelected(newOptions);
  };

  const isSelected = (option: T): boolean => {
    return context.selectedOptions.indexOf(option) > -1;
  };

  return (
    <div
      {...props}
      className={cn(
        "multi-select__content",
        resultIf(context.active, "active"),
        props.className
      )
      }
    >
      <ul className="multi-select__list">
        {renderOptions()}
      </ul>
    </div>
  );
};

export default MultiSelectContent;