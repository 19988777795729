import {ApiClientFactory} from "./clients";
import {Headers} from "../types";
import {RegistrationManager} from "../RegistrationManager";

export class RegistrationManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/registration`,
      headers
    );
  }

  public createRegistrationManager() {
    return new RegistrationManager(this.apiClientFactory.createClient());
  }
}