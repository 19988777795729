import {ApiClientInterface} from "./clients";
import {BearerToken, VerifiedPhone} from "./models";
import buildQuery from "../utils/router/buildQuery";

type ServerBearerToken = {
    accessToken: string;
    accessTokenExpiredAt: string;
    refreshToken: string;
    refreshTokenExpiredAt: string;
};

export class AuthManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  async login(username: string, password: string): Promise<BearerToken> {
    const data = await this.apiClient.post("/login", {
      username,
      password
    });
    return AuthManager.createBearerToken(data);
  }

  async autologin(token: string): Promise<BearerToken> {
    const data = await this.apiClient.post("/autologin", {token});
    return AuthManager.createBearerToken(data);
  }

  async refresh(token: string): Promise<BearerToken> {
    const data = await this.apiClient.post("/refresh", {token});
    return AuthManager.createBearerToken(data);
  }

  async isPhoneVerified(username: string): Promise<VerifiedPhone> {
    return await this.apiClient.get(`/is-phone-verified?${buildQuery({username})}`);
  }

  private static createBearerToken(data: ServerBearerToken): BearerToken {
    return new BearerToken(
      data.accessToken,
      new Date(data.accessTokenExpiredAt),
      data.refreshToken,
      new Date(data.refreshTokenExpiredAt)
    );
  }
}