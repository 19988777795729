import {ApiClientFactory, ApiClientFactoryInterface} from "./clients";
import {Headers} from "../types";
import {ChangePasswordManager} from "../ChangePasswordManager";

export class ChangePasswordManagerFactory {
  private readonly apiClientFactory: ApiClientFactoryInterface;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/change-password`,
      headers
    );
  }

  public createChangePasswordManager(token: string) {
    return new ChangePasswordManager(
      this.apiClientFactory.createAuthorizedClient(token)
    );
  }
}