import React, {createContext, PropsWithChildren} from "react";

export type NotificationsFilter = {
    isRead?: boolean;
    isImportant?: boolean;
}

export type NotificationsFilterContextType = {
    filter: NotificationsFilter;
    setFilter: (filter: NotificationsFilter) => void;
}

type Props = {
    props: NotificationsFilterContextType;
}

export const NotificationsContext = createContext<NotificationsFilterContextType>({
  filter: {},
  setFilter: () => {}
});

function NotificationsContextProvider(props: PropsWithChildren<Props>) {
  const {children, ...value} = props;
  return <NotificationsContext.Provider value={value.props}>
    { children }
  </NotificationsContext.Provider>;
}

export default NotificationsContextProvider;