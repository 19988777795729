import React, {FC} from "react";
import './TransactionsProjectSelect.scoped.scss';
import {Image, MultiSelect} from "../../../common";
import arrowDown from "../../../../assets/images/svg/history-arrow.svg";
import {cn, resultIf} from "../../../../utils";
import {MultiSelectContext} from "../../../common/selects/MultiSelect/MultiSelectContext";
import {TransactionProject} from "../../../../api-client";

interface Props {
    projects: Array<TransactionProject>,
    selectedProjects: Array<TransactionProject>,
    onSelected: (projects: Array<TransactionProject>) => void;
}

const TransactionsProjectSelect: FC<Props> = ({projects, selectedProjects, onSelected}) => {
  return (
    <div className="transactions-project-select">
      <MultiSelect
        options={projects}
        selectedOptions={selectedProjects}
        renderOption={(item: TransactionProject) => item.projectName}>
        <MultiSelect.Head className="transactions-project-select__header">
          <MultiSelectContext.Consumer>
            {({active, selectedOptions}) => <>
              {/*{selectedProjects[1] ? selectedProjects[1] : "Все проекты"}*/}
              <div className="transactions-project-select__heading">
                {selectedOptions.length === 0 ? 'Все проекты' : `Выбрано ${selectedOptions.length} проектов`}
              </div>
              <Image
                src={arrowDown}
                alt="Arrow down"
                className={cn(
                  "transactions-project-select__image",
                  resultIf(active, "active")
                )}
              />
            </>}
          </MultiSelectContext.Consumer>
        </MultiSelect.Head>
        <MultiSelect.Content
          className="transactions-project-select__content"
          onSelected={onSelected}
        />
      </MultiSelect>
    </div>
  );
};

export default TransactionsProjectSelect;