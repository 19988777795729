import React, {useContext, useMemo} from "react";
import './ProjectStrategy.scoped.scss';
import ProjectDropdown from "../ProjectDropdown";
import {useMountAnimation, useWindowWidth} from "../hooks";
import {cn, resultIf} from "../../../../utils";
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import DetailedProjectHtml from "../DetailedProjectHtml";
import {Block} from "../../../layout";
import DetailedProjectNavigation from "../DetailedProjectNavigation";

const ProjectStrategy = () => {
  const project = useContext(DetailedProjectContext).project;
  const mounted = useMountAnimation();
  const isMobile = useWindowWidth();

  const strategy = useMemo(() => {
    return project.information !== null && <div className={cn("project-legal", resultIf(mounted, "mounted"))}>
      <DetailedProjectHtml translations={project.information.strategyContent} />
    </div>;
  }, [mounted]);

  return (
    <div className="project-strategy-container">
      { isMobile ?
        <ProjectDropdown header="Стратегия взыскания">
          { strategy }
        </ProjectDropdown>
        : <Block>
          <Block.Content>
            <Block.Header withPadding className="detailed-project__header">
              <DetailedProjectNavigation />
            </Block.Header>
            <Block.Body>
              { strategy }
            </Block.Body>
          </Block.Content>
        </Block> }
    </div>
  );
};

export default ProjectStrategy;