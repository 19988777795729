import {Button, Image} from "../../../../../../../components";
import documentIcon from "../../../../../../../assets/images/svg/document.svg";
import FileSize from "../../../../../../../components/common/utils/FileSize/FileSize";
import {formatDate} from "../../../../../../../formatters";
import React, {useState} from "react";
import styles from '../DocumentsToProjectsItem.module.scss';
import dots from "../../../../../../../assets/images/svg/dots-green.svg";
import {Agreement} from "../../../../../../../api-client/models/documents/Agreement";
import {downloadPDF} from "../../../../../../../utils/downloadPDF";
import {useErrorCallback} from "../../../../../../../hooks";
import {createAgreementProvider} from "../../../../../../../di";
import apiClientConfig from "../../../../../../../configs/app";
import {translateAgreementType} from "../../../../../../../api-client/enums/AgreementType";

interface Props {
    document: Agreement
}

export const AgencyOrder = ({document}: Props) => {
  const [activeDownload, setActiveDownload] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleError = useErrorCallback();

  const downloadZIP = async (url: string) => {
    setLoading(true);
    try {
      const provider = await createAgreementProvider();
      const result = await provider.exportZip(url);
      window.open(apiClientConfig.apiBaseUrl + result.url, '_blank');
    } catch (error) {
      if (error instanceof Error) {
        await handleError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <li className={styles.documentsToProjectBody__list}>
      <div className={styles.documentsToProjectBody__item}>
        <Image className={styles.documentsToProjectBody__icon}
          src={documentIcon}/>
        <a href={document.digitalSign ? `${document.file.url}/signed-document` : document.file.url}
          target="_blank" rel="noreferrer"
          className={styles.documentsToProjectBody__title}>{translateAgreementType(document.type)}</a>
        <a href={document.digitalSign ? `${document.file.url}/signed-document` : document.file.url}
          target="_blank" rel="noreferrer" className={styles.documentsToProjectBody__size}>pdf, <FileSize
            file={document.file}/></a>
        <a href={document.digitalSign ? `${document.file.url}/signed-document` : document.file.url}
          target="_blank" rel="noreferrer" className={styles.documentsToProjectBody__watch}>Нажмите, чтобы
          посмотреть
        </a>
        <a href={document.digitalSign ? `${document.file.url}/signed-document` : document.file.url}
          target="_blank" rel="noreferrer"
          className={styles.documentsToProjectBody__date}>{formatDate(document.createdAt)}</a>
        <a href={document.digitalSign ? `${document.file.url}/signed-document` : document.file.url}
          target="_blank" rel="noreferrer" className={styles.documentsToProjectBody__dateText}>дата
          подписания
        </a>

        {document.digitalSign &&
        <div className={styles.documentsToProjectBody__makeReport}>
          <Button className={styles.documentsToProjectBody__buttonSave}
            onClick={() => setActiveDownload((prevState) => !prevState)}>
            <div/>
            скачать <Image className={styles.documentsToProjectItemTitle__arrowButton__image__download}
              src={dots}/>
          </Button>
          {activeDownload && <div className={styles.documentsToProjectBody__download}>
            <Button
              className={styles.documentsToProjectBody__buttonDownload}
              onClick={() => downloadPDF(`${document.file.url}/signed-document`, document.file.name)}>
              <div/>
              скачать PDF
            </Button>
            <Button
              className={styles.documentsToProjectBody__buttonDownload}
              onClick={() => downloadZIP(document.file.url)}>
              <div/>
              скачать ZIP
            </Button>
            {loading && <div>Загрузка...</div>}
          </div>}
        </div>}
      </div>
    </li>
  );
};