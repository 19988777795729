import {ApiClientInterface} from "./clients";
import {Phone, VerifiedPhone} from "./models";
import buildQuery from "../utils/router/buildQuery";

export class PhoneManager {
  constructor(private readonly apiClient: ApiClientInterface) {}

  public createPhone(phone: Phone): Promise<Phone> {
    return this.apiClient.post("/create", {phone});
  }

  public isVerified(phone: Phone): Promise<VerifiedPhone> {
    return this.apiClient.get(`/stats?${buildQuery({phone})}`);
  }
}