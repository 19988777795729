import React from "react";
import {useParams} from "react-router-dom";
import {useEffectOnMount, useErrorCallback, useNavigateByName} from "../../../hooks";
import {createVerificationManager} from "../../../di";

const VerifyEmail = () => {
  const {verificationCode} = useParams();
  const navigate = useNavigateByName();
  const handleError = useErrorCallback();
  useEffectOnMount(() => {
    const verificationCallback = async () => {
      try {
        const manager = await createVerificationManager();
        await manager.verifyEmail(verificationCode!);
        navigate('profile');
        return;
      } catch (error: any) {
        await handleError(error);
        navigate('404');
        return;
      }
    };
    (async () => {
      await verificationCallback();
    })();
  });
  return (
    <>
    </>
  );
};

export default VerifyEmail;