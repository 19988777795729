import React, {FC, PropsWithChildren, useRef, useState} from "react";
import './Information.scoped.scss';
import {Image} from "../../images";
import questionImage from "../../../../assets/images/icons/question.svg";
import {CSSTransition} from "react-transition-group";

interface Props {
    className?:string;
    position?: 'top' | 'bottom' | 'left'
}

const Information: FC<PropsWithChildren<Props>> = (props) => {
  const {children, className, position='top'} = props;

  const [active, setActive] = useState(false);
  const timeoutRef = useRef(0);

  const onHover = () => {
    clearTimeout(timeoutRef.current);
    setActive(true);
  };

  const onLeave = () => {
    timeoutRef.current = window.setTimeout(() => {
      setActive(false);
    }, 250);
  };

  const getClassNames = () => {
    let styles = ['information__answer'];
    if (position === 'bottom') styles.push('information__position_bottom');
    if (position === 'left') styles.push('information__position_left');
    if (className) styles.push(className);
    return styles.join(' ');
  };

  return (
    <div
      className="information"
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <Image src={questionImage} />
      <CSSTransition
        in={active}
        timeout={250}
        classNames="information__answer-"
      >
        <div className={getClassNames()}>
          <div className="information__text">
            { children }
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Information;