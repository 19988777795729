import React, {FC} from "react";
import {Filter} from "../../../../views/dashboard/lender/investment/ArchivedProjects/ArchivedProjects";
import './ArchivedProjectsFilter.scoped.scss';
import FilterSearch from "../../../filters/FilterSearch";

interface Props {
    filter: Filter;
    onChange: (filter: Filter) => void;
    countFinishedProjects: number;
}

const ArchivedProjectsFilter: FC<Props> = ({filter, onChange, countFinishedProjects}) => {
  const handleSearch = (search: string): void => {
    onChange({...filter, name: search});
  };
  return (
    <div className="archived-projects-filter">
      <div className="archived-projects-filter__title">
        Статистика проектов ({countFinishedProjects})
      </div>
      <div className="archived-projects-filter__nav">
        <FilterSearch
          search={filter.name}
          onSearchChange={handleSearch}
          className="archived-projects-filter__search"
        />
      </div>
    </div>
  );
};

export default ArchivedProjectsFilter;