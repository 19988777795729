import {ApiClientFactory} from "./clients";
import {Headers} from "../types";
import {OrdersProvider} from "../OrdersProvider";

export class OrdersProviderFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/order`,
      headers
    );
  }

  public createOrderProvider(authToken: string): OrdersProvider {
    return new OrdersProvider(
      this.apiClientFactory.createAuthorizedClient(authToken)
    );
  }
}