import React from "react";
import {Container, Section} from "../../../components";

const Platform = () => {
  return (
    <Section>
      <Container>
        О платформе
      </Container>
    </Section>
  );
};

export default Platform;