import React, {FC} from "react";
import './TransactionDateRangeSelect.scoped.scss';
import {Nullable} from "../../../../utils";
import {OptionBox} from "../../../common";
import {OptionBoxContext} from "../../../common/selects/OptionBox/OptionBoxContext";
import {Flex} from "../../../layout";
import {AlignItems} from "../../../layout/Flex/Flex";
import CommonFilterIcon from "../../../filters/CommonFilterIcon";
import {CalendarSvg} from "../../../svg";
import {dateRangeMapped, DateRangeType} from "../../../../api-client/enums/DateRangeType";

interface Props {
    onChange: (selected: DateRangeType) => void;
    selectedDirection: Nullable<DateRangeType>;
}

type DateRangeOption = {
    type: keyof typeof DateRangeType;
    title: string;
}

const options: Array<DateRangeOption> = Object.entries(dateRangeMapped)
  .map(([key, value]): DateRangeOption => {
    return {
      type: key as keyof typeof DateRangeType,
      title: value
    };
  });

const TransactionDateRangeSelect: FC<Props> = ({onChange, selectedDirection}) => {
  const selectedOption = (): Nullable<DateRangeOption> => {
    return selectedDirection !== null ? options.filter((option) => {
      return option.type === selectedDirection;
    })[0] : null;
  };
  const selectOption = (option: DateRangeOption): void => {
    onChange(option.type as DateRangeType);
  };
  const renderOption = (option: DateRangeOption) => {
    return option.title;
  };
  return (
    <div className="transaction-date-range-select">
      <OptionBox
        options={options}
        selectedOption={selectedOption()}
        renderOption={renderOption}
      >
        <OptionBox.Head className="transaction-date-range-select__header">
          <OptionBoxContext.Consumer>
            {({active, selectedOption, render}) => <Flex alignItems={AlignItems.CENTER} gap="0.5rem">
              <CommonFilterIcon>
                <CalendarSvg/>
              </CommonFilterIcon>
            </Flex>}
          </OptionBoxContext.Consumer>
        </OptionBox.Head>
        <OptionBox.Content
          className="transaction-date-range-select__content"
          onSelected={selectOption}
        />
      </OptionBox>
    </div>
  );
};

export default TransactionDateRangeSelect;