import React, {useEffect, useState} from 'react';

type Props = {
    seconds: number;
    callback: any;
}

const CountdownTimer = ({seconds, callback}: Props) => {
  const [timeLeft, setTimeLeft] = useState(seconds);
  useEffect(() => {
    if (seconds) {
      const intervalId = setInterval(() => {
        if (timeLeft === 0) {
          return;
        }
        passSecond();
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [seconds]);
  useEffect(() => {
    if (timeLeft === 0) {
      callback();
      return;
    }
  }, [timeLeft]);
  const passSecond = () => {
    setTimeLeft((timeLeft) => timeLeft - 1);
  };
  const formatTime = (time: number) => {
    let minutes: number | string = Math.floor(time / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let seconds: number | string = time % 60;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    return `${minutes}:${seconds}`;
  };
  return (
    <>
      <div>
        {formatTime(timeLeft)}
      </div>
    </>
  );
};

export default CountdownTimer;