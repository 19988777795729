import React from "react";
import {BlockDropdown} from "../../../layout";
import './ReferralBlock.scoped.scss';

const ReferralBlock = () => {
  const header = () => {
    return <>
      <div className="referral-block__heading">
        Принцип работы реферальной программы
      </div>
      <div className="referral-block__heading-mobile">
        Принцип реферальной программы
      </div>
    </>;
  };

  return (
    <div className="referral-block">
      <BlockDropdown header={header()}>
        <div className="referral-block__content">
          <div>
            Пользователь платформы имеет возможность получать дополнительный доход от реферальной
            программы, мы будем учитывать всех рефералов (приглашенных вами пользователей),
            пришедших по Вашей реферальной ссылке.
          </div>
          <div>
            Пользователь получает уникальную многоразовую ссылку - индивидуальный
            реферальный код, в котором содержится информация об учетной записи Рефера.
            Один реферальный линк действует на неограниченное количество Рефералов и сделок.
          </div>
          <div>
            Порядок расчета вознаграждения
          </div>
          <div>
            Вы будете получать 0,5% от суммы всех сделок займа, заключенных рефералом-инвестором
            в течение 6 месяцев и 0,5% от суммы всех привлеченных рефералом-заемщиком
            в течение 3 месяцев.
          </div>
          <div>
            Доход вы получаете только с совершенных сделок займа
          </div>
          <div>
            Порядок выплаты
          </div>
          <div>
            Платформа осуществляет перечисление вашего вознаграждения один раз в месяц за
            предыдущий учетный месяц, при условии, что сумма начисленного вознаграждения не
            менее ____. Если вы являетесь физическим лицом, то вознаграждение выплачивается за
            минусом удержанного подоходного налога.
          </div>
        </div>
      </BlockDropdown>
    </div>
  );
};

export default ReferralBlock;