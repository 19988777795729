import React, {ButtonHTMLAttributes, FC} from "react";
import './BackButton.scoped.scss';
import {cn} from "../../../../utils";
import {Button} from "../index";
import {Image} from "../../images";
import modalLeft from "../../../../assets/images/svg/modal-left.svg";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const BackButton: FC<Props> = ({...props}) => {
  return (
    <Button {...props} className={cn("back-button", props.className)}>
      <Image src={modalLeft} />
    </Button>
  );
};

export default BackButton;