import {ApiClientFactory} from "./clients";
import {Headers} from "../types";
import {ProfileManager} from "../ProfileManager";

export class ProfileManagerFactory {
  private readonly apiClientFactory: ApiClientFactory;

  constructor(baseUrl: string, headers: Headers) {
    this.apiClientFactory = new ApiClientFactory(
      `${baseUrl}/api/v1/profile`,
      headers
    );
  }

  public createProfileManager(authToken: string): ProfileManager {
    return new ProfileManager(
      this.apiClientFactory.createAuthorizedClient(authToken)
    );
  }
}