import React, {FC, useCallback} from "react";
import './Notification.scoped.scss';
import {Notification as NotificationModel} from "../../../../api-client";
import {Image} from "../../../common";
import notificationNotReadImage from "../../../../assets/images/svg/notifications-status-not-read.svg";
import {formatDate, formatTime} from "../../../../formatters";
import FileSize from "../../../common/utils/FileSize/FileSize";
import NotificationContent from "../NotificationContent";

interface Props {
    notification: NotificationModel
}

const Notification: FC<Props> = ({notification}) => {
  const renderNotificationStatus = useCallback(() => {
    return notification.isRead
      ? <div />
      : <Image src={notificationNotReadImage} />;
  }, [notification.isRead]);

  return (
    <div className="notification">
      { notification.isImportant && <div className="notification__important">
        Важное!
      </div> }
      <div className="notification__status">
        { renderNotificationStatus() }
      </div>
      <NotificationContent notification={notification} />
      <div className="notification__date">
        <div>
          { formatDate(notification.createdAt) }
        </div>
        <div>
          { formatTime(notification.createdAt, "hh:mm") }
        </div>
      </div>
      { notification.file !== undefined && <div className="notification__file">
        <div className="notification__file-title">
          <div className="notification__file-name">
            { notification.file.name }
          </div>
          <div className="notification__file-size">
            <FileSize file={notification.file} />
          </div>
        </div>
        <div>
          <a href={notification.file.url} target="_blank" rel="noreferrer noopener" className="notification__file-url">
            Скачать
          </a>
        </div>
      </div> }
    </div>
  );
};

export default Notification;