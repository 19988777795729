import React from "react";
import {Container, Section} from "../../../components";

const AboutUs = () => {
  return (
    <Section>
      <Container>
        О нас
      </Container>
    </Section>
  );
};

export default AboutUs;