import React, {useContext} from "react";
import styles from './ProjectMainStats.module.scss';
import {DetailedProjectContext} from "../DetailedProjectContext/DetailedProjectContext";
import {Money as MoneyModel, ProjectStatus} from "../../../../api-client";
import {Money} from "../../../common";
import {formatPercents} from "../../../../formatters";
import {useWindowWidth} from "../hooks";

const ProjectMainStats = () => {
  const project = useContext(DetailedProjectContext).project;
  const isMobile = useWindowWidth(1260);

  const extraCreditorPaymentForecast =
      project.accounting.extraCreditorPaymentForecast ??
      new MoneyModel(0, project.accounting.finalDebtAmount.currencyCode);

  const recoveryForecast = new MoneyModel(
    project.accounting.finalDebtAmount.amount - extraCreditorPaymentForecast.amount,
    project.accounting.finalDebtAmount.currencyCode
  );
  const isLoanPaymentPaid = project.status === ProjectStatus.LOAN_PAYMENT || project.status === ProjectStatus.PAID;
  const isPaid = project.status === ProjectStatus.PAID;
  return (<>
    {!isMobile?  <div className={styles.detailedProjectMain}>
      <div className={styles.detailedProjectMain__stats}>
        <div className={styles.detailedProjectStat__title}>
          План
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Сумма сбора
          </div>
          <div className={styles.detailedProjectStat__value}>
            <Money money={project.accounting.debtPrice}/>
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Взыскание
          </div>
          <div className={styles.detailedProjectStat__value}>
            <Money money={recoveryForecast}/>
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Срок
          </div>
          <div className={styles.detailedProjectStat__value}>
            {project.accounting.initialTerm} мес.
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            ROI
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.simpleInterestRate)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Годовой ROI
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.forecastYearROI)}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__type}>
            Годовой IRR
          </div>
          <div className={styles.detailedProjectStat__value}>
            {formatPercents(project.accounting.interestRate)}
          </div>
        </div>
      </div>
      <div className={styles.detailedProjectMain__stats}>
        <div className={styles.detailedProjectStat__title}>
          Факт
        </div>
        <div className={styles.detailedProjectStat}></div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__value}>
            {isLoanPaymentPaid && project.accounting.actualPayment? <Money money={project.accounting.actualPayment}/> : '-'}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__value}>
            {isLoanPaymentPaid && project.accounting.realTerm? `${project.accounting.realTerm} мес.` : '-'}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__value}>
            {isPaid && project.accounting.factROI? formatPercents(project.accounting.factROI) : '-'}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__value}>
            {isPaid && project.accounting.factYearROI? formatPercents(project.accounting.factYearROI) : '-'}
          </div>
        </div>
        <div className={styles.detailedProjectStat}>
          <div className={styles.detailedProjectStat__value}>
            {isPaid && project.accounting.factYearIRR? formatPercents(project.accounting.factYearIRR) : '-'}
          </div>
        </div>
      </div>
    </div>
      :<div className={styles.detailedProjectMain}>
        <div className={styles.detailedProjectMain__stats}>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
            </div>
            <div className={styles.detailedProjectStat__title}>
              План
            </div>
            <div className={styles.detailedProjectStat__title}>
              Факт
            </div>
          </div>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
              Сумма сбора
            </div>
            <div className={styles.detailedProjectStat__value}>
              <Money money={project.accounting.debtPrice}/>
            </div>
            <div className={styles.detailedProjectStat__value}>
            </div>
          </div>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
              Взыскание
            </div>
            <div className={styles.detailedProjectStat__value}>
              <Money money={recoveryForecast}/>
            </div>
            <div className={styles.detailedProjectStat__value}>
              {isLoanPaymentPaid && project.accounting.actualPayment? <Money money={project.accounting.actualPayment}/> : '-'}
            </div>
          </div>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
              Срок
            </div>
            <div className={styles.detailedProjectStat__value}>
              {project.accounting.initialTerm} мес.
            </div>
            <div className={styles.detailedProjectStat__value}>
              {isLoanPaymentPaid && project.accounting.realTerm? `${project.accounting.realTerm} мес.`: '-'}
            </div>
          </div>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
              ROI
            </div>
            <div className={styles.detailedProjectStat__value}>
              {formatPercents(project.accounting.simpleInterestRate)}
            </div>
            <div className={styles.detailedProjectStat__value}>
              {isPaid && project.accounting.factROI? formatPercents(project.accounting.factROI) : '-'}
            </div>
          </div>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
              Годовой ROI
            </div>
            <div className={styles.detailedProjectStat__value}>
              {formatPercents(project.accounting.forecastYearROI)}
            </div>
            <div className={styles.detailedProjectStat__value}>
              {isPaid && project.accounting.factYearROI? formatPercents(project.accounting.factYearROI) : '-'}
            </div>
          </div>
          <div className={styles.detailedProjectStat}>
            <div className={styles.detailedProjectStat__type}>
              Годовой IRR
            </div>
            <div className={styles.detailedProjectStat__value}>
              {formatPercents(project.accounting.interestRate)}
            </div>
            <div className={styles.detailedProjectStat__value}>
              {isPaid && project.accounting.factYearIRR? formatPercents(project.accounting.factYearIRR) : '-'}
            </div>
          </div>
        </div>
      </div>}
  </>
  );
};

export default ProjectMainStats;