import React, {FC} from "react";
import {ProfileInput} from "../index";
import {ChildrenProps} from "../../../../utils";
import './ProfilePersonalInput.scss';

interface Props extends ChildrenProps {}

const ProfilePersonalInput: FC<Props> = ({children}) => {
  return (
    <ProfileInput>
      <div className="profile-personal-input">
        { children }
      </div>
    </ProfileInput>
  );
};

export default ProfilePersonalInput;