import React from "react";
import {LayoutNotFound} from "layouts";

const NotFound = () => {
  return (
    <LayoutNotFound>
      {/*TODO: maybe style this*/}
    </LayoutNotFound>
  );
};

export default NotFound;