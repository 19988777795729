import React, {SyntheticEvent, useMemo, useState, useEffect} from "react";
import {BoldText, Button, InputErrors, InputNumber, InputPassword, PrimaryButton} from "../../common";
import {AuthInput} from "../../../views/auth/Enter/Enter";
import {Errors as ValidationErrors} from "../../../utils";
import {identifyUser} from "../../../utils";
import {createAuthManager, createAuthTokenManager, createPhoneManager} from "../../../di";
import {PrimaryButtonColor} from "../../common/buttons/decorators/PrimaryButton/PrimaryButton";
import {useEffectOnUpdate, useNavigateByName} from "../../../hooks";
import {Unauthorized} from "../../../api-client";
import './EnterCreds.scoped.scss';

type Props = {
    input: AuthInput;
    onNewNumber: () => void;
    onPhoneNotVerified: () => void;
    onPasswordNotPresent: () => void;
}

type LoginInput = {
    username: string;
    password: string;
}

type LoginErrors = {
    username?: ValidationErrors
    password?: ValidationErrors
    credentials?: ValidationErrors
}

const EnterCreds = ({input, onNewNumber, onPhoneNotVerified, onPasswordNotPresent}: Props) => {
  const [canLogin, setCanLogin] = useState(false);
  const navigate = useNavigateByName();
  const [errors, setErrors] = useState<LoginErrors>({});
  
  //carrot quest identification
  useEffect(() => {
    identifyUser({phone: input.phone.fullNumber,});
  }, [input]);
  
  const canSubmit = (): boolean => {
    const phoneValid = input.phone.isValid();
    return canLogin ? phoneValid && input.password !== "" : phoneValid;
  };

  const loginInput = useMemo((): LoginInput => {
    return {username: input.phone.fullNumber, password: input.password};
  }, [input.phone, input.password]);

  const validateForm = () => {
    const errors: LoginErrors = {};
    if (!input.phone.number) {
      errors.username = ["Номер телефона обязателен для заполнения"];
    }
    if (canLogin && !input.password) {
      errors.password = ["Пароль обязателен для заполнения"];
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffectOnUpdate(() => {
    validateForm();
  }, [loginInput]);

  useEffectOnUpdate(() => {
    setCanLogin(false);
  }, [input.phone]);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (validateForm()) {
      if (canLogin) {
        await handleLogin();
        return;
      }
      
      const manager = await createPhoneManager();
      const result = await manager.isVerified(input.phone);
      if (!result.exists) {
        onNewNumber();
      } else if (!result.verified) {
        onPhoneNotVerified();
      } else if (!result.hasUser) {
        onPasswordNotPresent();
      } else {
        setCanLogin(true);
      }
    }
  };

  const handleLogin = async () => {
    try {
      const authManager = createAuthManager();
      const token = await authManager.login(input.phone.fullNumber, input.password);
      const authTokenManager = createAuthTokenManager();
      authTokenManager.create(token);
      navigate('redirect-after-auth');
    } catch (err: any) {
      if (err instanceof Unauthorized) {
        setErrors({
          credentials: ["Неправильный логин/пароль"]
        });
      }
    }
  };

  return (
    <>
      <div>
        <BoldText>
          <div className="enter-creds__heading">
            Вход / регистрация
          </div>
        </BoldText>
        <div className="enter-creds__text">
          Введите свой номер телефона
        </div>
        <form onSubmit={handleSubmit} className="enter-creds__form">
          <div className="form__group">
            <InputNumber
              phone={input.phone}
              onPhoneInput={input.setPhone}
              helpingText="Используется для связи с платформой"
            />
            { errors.username && <InputErrors errors={errors.username} /> }
          </div>
          { canLogin && <div className="form__group">
            <InputPassword
              autoFocus
              password={input.password}
              onInput={input.setPassword}
              title="Пароль"
              id="password"
            />
            { errors.password && <InputErrors errors={errors.password} /> }
          </div> }
          <div className="form__group">
            <PrimaryButton expanded large color={PrimaryButtonColor.GREEN}>
              <Button type="submit" disabled={!canSubmit()}>
                Далее
              </Button>
            </PrimaryButton>
          </div>
          { errors.credentials && <div className="form__group"><InputErrors errors={errors.credentials} /></div> }
        </form>
      </div>
    </>
  );
};

export default EnterCreds;